/*
 * @Description: 城市数据
 * @Author: Cary
 * @Date: 2020-06-10 12:50:34
 * @FilePath: \alien-docsc:\Users\Administrator\Desktop\user\src\data\city.js
 */
var city = [
    {
      "value": 2,
      "parent_id": 1,
      "label": "北京市",
      "lng": "116.407394",
      "lat": "39.904211",
      "level": "province",
      "children": [
        {
          "value": 3,
          "parent_id": 2,
          "label": "北京市",
          "lng": "116.407394",
          "lat": "39.904211",
          "level": "city",
          "children": [
            {
              "value": 4,
              "parent_id": 3,
              "label": "东城区",
              "lng": "116.41649",
              "lat": "39.928341",
              "level": "district"
            },
            {
              "value": 5,
              "parent_id": 3,
              "label": "西城区",
              "lng": "116.365873",
              "lat": "39.912235",
              "level": "district"
            },
            {
              "value": 6,
              "parent_id": 3,
              "label": "朝阳区",
              "lng": "116.443205",
              "lat": "39.921506",
              "level": "district"
            },
            {
              "value": 7,
              "parent_id": 3,
              "label": "丰台区",
              "lng": "116.287039",
              "lat": "39.858421",
              "level": "district"
            },
            {
              "value": 8,
              "parent_id": 3,
              "label": "石景山区",
              "lng": "116.222933",
              "lat": "39.906611",
              "level": "district"
            },
            {
              "value": 9,
              "parent_id": 3,
              "label": "海淀区",
              "lng": "116.298262",
              "lat": "39.95993",
              "level": "district"
            },
            {
              "value": 10,
              "parent_id": 3,
              "label": "门头沟区",
              "lng": "116.101719",
              "lat": "39.940338",
              "level": "district"
            },
            {
              "value": 11,
              "parent_id": 3,
              "label": "房山区",
              "lng": "116.143486",
              "lat": "39.748823",
              "level": "district"
            },
            {
              "value": 12,
              "parent_id": 3,
              "label": "通州区",
              "lng": "116.656434",
              "lat": "39.909946",
              "level": "district"
            },
            {
              "value": 13,
              "parent_id": 3,
              "label": "顺义区",
              "lng": "116.654642",
              "lat": "40.130211",
              "level": "district"
            },
            {
              "value": 14,
              "parent_id": 3,
              "label": "昌平区",
              "lng": "116.231254",
              "lat": "40.220804",
              "level": "district"
            },
            {
              "value": 15,
              "parent_id": 3,
              "label": "大兴区",
              "lng": "116.341483",
              "lat": "39.726917",
              "level": "district"
            },
            {
              "value": 16,
              "parent_id": 3,
              "label": "怀柔区",
              "lng": "116.631931",
              "lat": "40.316053",
              "level": "district"
            },
            {
              "value": 17,
              "parent_id": 3,
              "label": "平谷区",
              "lng": "117.121351",
              "lat": "40.140595",
              "level": "district"
            },
            {
              "value": 18,
              "parent_id": 3,
              "label": "密云区",
              "lng": "116.843047",
              "lat": "40.376894",
              "level": "district"
            },
            {
              "value": 19,
              "parent_id": 3,
              "label": "延庆区",
              "lng": "115.974981",
              "lat": "40.456591",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 20,
      "parent_id": 1,
      "label": "天津市",
      "lng": "117.200983",
      "lat": "39.084158",
      "level": "province",
      "children": [
        {
          "value": 21,
          "parent_id": 20,
          "label": "天津市",
          "lng": "117.200983",
          "lat": "39.084158",
          "level": "city",
          "children": [
            {
              "value": 22,
              "parent_id": 21,
              "label": "和平区",
              "lng": "117.214699",
              "lat": "39.117196",
              "level": "district"
            },
            {
              "value": 23,
              "parent_id": 21,
              "label": "河东区",
              "lng": "117.251584",
              "lat": "39.128294",
              "level": "district"
            },
            {
              "value": 24,
              "parent_id": 21,
              "label": "河西区",
              "lng": "117.223371",
              "lat": "39.109563",
              "level": "district"
            },
            {
              "value": 25,
              "parent_id": 21,
              "label": "南开区",
              "lng": "117.150738",
              "lat": "39.138205",
              "level": "district"
            },
            {
              "value": 26,
              "parent_id": 21,
              "label": "河北区",
              "lng": "117.196648",
              "lat": "39.147869",
              "level": "district"
            },
            {
              "value": 27,
              "parent_id": 21,
              "label": "红桥区",
              "lng": "117.151533",
              "lat": "39.167345",
              "level": "district"
            },
            {
              "value": 28,
              "parent_id": 21,
              "label": "东丽区",
              "lng": "117.31362",
              "lat": "39.086802",
              "level": "district"
            },
            {
              "value": 29,
              "parent_id": 21,
              "label": "西青区",
              "lng": "117.008826",
              "lat": "39.141152",
              "level": "district"
            },
            {
              "value": 30,
              "parent_id": 21,
              "label": "津南区",
              "lng": "117.35726",
              "lat": "38.937928",
              "level": "district"
            },
            {
              "value": 31,
              "parent_id": 21,
              "label": "北辰区",
              "lng": "117.135488",
              "lat": "39.224791",
              "level": "district"
            },
            {
              "value": 32,
              "parent_id": 21,
              "label": "武清区",
              "lng": "117.044387",
              "lat": "39.384119",
              "level": "district"
            },
            {
              "value": 33,
              "parent_id": 21,
              "label": "宝坻区",
              "lng": "117.309874",
              "lat": "39.717564",
              "level": "district"
            },
            {
              "value": 34,
              "parent_id": 21,
              "label": "滨海新区",
              "lng": "117.698407",
              "lat": "39.01727",
              "level": "district"
            },
            {
              "value": 35,
              "parent_id": 21,
              "label": "宁河区",
              "lng": "117.826724",
              "lat": "39.330087",
              "level": "district"
            },
            {
              "value": 36,
              "parent_id": 21,
              "label": "静海区",
              "lng": "116.974232",
              "lat": "38.94745",
              "level": "district"
            },
            {
              "value": 37,
              "parent_id": 21,
              "label": "蓟州区",
              "lng": "117.408296",
              "lat": "40.045851",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 38,
      "parent_id": 1,
      "label": "河北省",
      "lng": "114.530235",
      "lat": "38.037433",
      "level": "province",
      "children": [
        {
          "value": 39,
          "parent_id": 38,
          "label": "石家庄市",
          "lng": "114.514793",
          "lat": "38.042228",
          "level": "city",
          "children": [
            {
              "value": 40,
              "parent_id": 39,
              "label": "长安区",
              "lng": "114.539395",
              "lat": "38.036347",
              "level": "district"
            },
            {
              "value": 41,
              "parent_id": 39,
              "label": "桥西区",
              "lng": "114.461088",
              "lat": "38.004193",
              "level": "district"
            },
            {
              "value": 42,
              "parent_id": 39,
              "label": "新华区",
              "lng": "114.463377",
              "lat": "38.05095",
              "level": "district"
            },
            {
              "value": 43,
              "parent_id": 39,
              "label": "井陉矿区",
              "lng": "114.062062",
              "lat": "38.065151",
              "level": "district"
            },
            {
              "value": 44,
              "parent_id": 39,
              "label": "裕华区",
              "lng": "114.531202",
              "lat": "38.00643",
              "level": "district"
            },
            {
              "value": 45,
              "parent_id": 39,
              "label": "藁城区",
              "lng": "114.847023",
              "lat": "38.021453",
              "level": "district"
            },
            {
              "value": 46,
              "parent_id": 39,
              "label": "鹿泉区",
              "lng": "114.313654",
              "lat": "38.085953",
              "level": "district"
            },
            {
              "value": 47,
              "parent_id": 39,
              "label": "栾城区",
              "lng": "114.648318",
              "lat": "37.900199",
              "level": "district"
            },
            {
              "value": 48,
              "parent_id": 39,
              "label": "井陉县",
              "lng": "114.145242",
              "lat": "38.032118",
              "level": "district"
            },
            {
              "value": 49,
              "parent_id": 39,
              "label": "正定县",
              "lng": "114.570941",
              "lat": "38.146444",
              "level": "district"
            },
            {
              "value": 50,
              "parent_id": 39,
              "label": "行唐县",
              "lng": "114.552714",
              "lat": "38.438377",
              "level": "district"
            },
            {
              "value": 51,
              "parent_id": 39,
              "label": "灵寿县",
              "lng": "114.382614",
              "lat": "38.308665",
              "level": "district"
            },
            {
              "value": 52,
              "parent_id": 39,
              "label": "高邑县",
              "lng": "114.611121",
              "lat": "37.615534",
              "level": "district"
            },
            {
              "value": 53,
              "parent_id": 39,
              "label": "深泽县",
              "lng": "115.20092",
              "lat": "38.184033",
              "level": "district"
            },
            {
              "value": 54,
              "parent_id": 39,
              "label": "赞皇县",
              "lng": "114.386111",
              "lat": "37.665663",
              "level": "district"
            },
            {
              "value": 55,
              "parent_id": 39,
              "label": "无极县",
              "lng": "114.97634",
              "lat": "38.179192",
              "level": "district"
            },
            {
              "value": 56,
              "parent_id": 39,
              "label": "平山县",
              "lng": "114.195918",
              "lat": "38.247888",
              "level": "district"
            },
            {
              "value": 57,
              "parent_id": 39,
              "label": "元氏县",
              "lng": "114.525409",
              "lat": "37.766513",
              "level": "district"
            },
            {
              "value": 58,
              "parent_id": 39,
              "label": "赵县",
              "lng": "114.776297",
              "lat": "37.756578",
              "level": "district"
            },
            {
              "value": 59,
              "parent_id": 39,
              "label": "辛集市",
              "lng": "115.217658",
              "lat": "37.943121",
              "level": "district"
            },
            {
              "value": 60,
              "parent_id": 39,
              "label": "晋州市",
              "lng": "115.044213",
              "lat": "38.033671",
              "level": "district"
            },
            {
              "value": 61,
              "parent_id": 39,
              "label": "新乐市",
              "lng": "114.683776",
              "lat": "38.343319",
              "level": "district"
            }
          ]
        },
        {
          "value": 62,
          "parent_id": 38,
          "label": "唐山市",
          "lng": "118.180193",
          "lat": "39.630867",
          "level": "city",
          "children": [
            {
              "value": 63,
              "parent_id": 62,
              "label": "路南区",
              "lng": "118.154354",
              "lat": "39.625058",
              "level": "district"
            },
            {
              "value": 64,
              "parent_id": 62,
              "label": "路北区",
              "lng": "118.200692",
              "lat": "39.624437",
              "level": "district"
            },
            {
              "value": 65,
              "parent_id": 62,
              "label": "古冶区",
              "lng": "118.447635",
              "lat": "39.733578",
              "level": "district"
            },
            {
              "value": 66,
              "parent_id": 62,
              "label": "开平区",
              "lng": "118.261841",
              "lat": "39.671001",
              "level": "district"
            },
            {
              "value": 67,
              "parent_id": 62,
              "label": "丰南区",
              "lng": "118.085169",
              "lat": "39.576031",
              "level": "district"
            },
            {
              "value": 68,
              "parent_id": 62,
              "label": "丰润区",
              "lng": "118.162215",
              "lat": "39.832582",
              "level": "district"
            },
            {
              "value": 69,
              "parent_id": 62,
              "label": "曹妃甸区",
              "lng": "118.460379",
              "lat": "39.27307",
              "level": "district"
            },
            {
              "value": 70,
              "parent_id": 62,
              "label": "滦县",
              "lng": "118.703598",
              "lat": "39.740593",
              "level": "district"
            },
            {
              "value": 71,
              "parent_id": 62,
              "label": "滦南县",
              "lng": "118.682379",
              "lat": "39.518996",
              "level": "district"
            },
            {
              "value": 72,
              "parent_id": 62,
              "label": "乐亭县",
              "lng": "118.912571",
              "lat": "39.425608",
              "level": "district"
            },
            {
              "value": 73,
              "parent_id": 62,
              "label": "迁西县",
              "lng": "118.314715",
              "lat": "40.1415",
              "level": "district"
            },
            {
              "value": 74,
              "parent_id": 62,
              "label": "玉田县",
              "lng": "117.738658",
              "lat": "39.900401",
              "level": "district"
            },
            {
              "value": 75,
              "parent_id": 62,
              "label": "遵化市",
              "lng": "117.965892",
              "lat": "40.189201",
              "level": "district"
            },
            {
              "value": 76,
              "parent_id": 62,
              "label": "迁安市",
              "lng": "118.701144",
              "lat": "39.999174",
              "level": "district"
            }
          ]
        },
        {
          "value": 77,
          "parent_id": 38,
          "label": "秦皇岛市",
          "lng": "119.518197",
          "lat": "39.888701",
          "level": "city",
          "children": [
            {
              "value": 78,
              "parent_id": 77,
              "label": "海港区",
              "lng": "119.564962",
              "lat": "39.94756",
              "level": "district"
            },
            {
              "value": 79,
              "parent_id": 77,
              "label": "山海关区",
              "lng": "119.775799",
              "lat": "39.978848",
              "level": "district"
            },
            {
              "value": 80,
              "parent_id": 77,
              "label": "北戴河区",
              "lng": "119.484522",
              "lat": "39.834596",
              "level": "district"
            },
            {
              "value": 81,
              "parent_id": 77,
              "label": "抚宁区",
              "lng": "119.244847",
              "lat": "39.876253",
              "level": "district"
            },
            {
              "value": 82,
              "parent_id": 77,
              "label": "青龙满族自治县",
              "lng": "118.949684",
              "lat": "40.407578",
              "level": "district"
            },
            {
              "value": 83,
              "parent_id": 77,
              "label": "昌黎县",
              "lng": "119.199551",
              "lat": "39.700911",
              "level": "district"
            },
            {
              "value": 84,
              "parent_id": 77,
              "label": "卢龙县",
              "lng": "118.892986",
              "lat": "39.891946",
              "level": "district"
            }
          ]
        },
        {
          "value": 85,
          "parent_id": 38,
          "label": "邯郸市",
          "lng": "114.538959",
          "lat": "36.625594",
          "level": "city",
          "children": [
            {
              "value": 86,
              "parent_id": 85,
              "label": "邯山区",
              "lng": "114.531002",
              "lat": "36.594313",
              "level": "district"
            },
            {
              "value": 87,
              "parent_id": 85,
              "label": "丛台区",
              "lng": "114.492896",
              "lat": "36.636409",
              "level": "district"
            },
            {
              "value": 88,
              "parent_id": 85,
              "label": "复兴区",
              "lng": "114.462061",
              "lat": "36.639033",
              "level": "district"
            },
            {
              "value": 89,
              "parent_id": 85,
              "label": "峰峰矿区",
              "lng": "114.212802",
              "lat": "36.419739",
              "level": "district"
            },
            {
              "value": 90,
              "parent_id": 85,
              "label": "临漳县",
              "lng": "114.619536",
              "lat": "36.335025",
              "level": "district"
            },
            {
              "value": 91,
              "parent_id": 85,
              "label": "成安县",
              "lng": "114.670032",
              "lat": "36.444317",
              "level": "district"
            },
            {
              "value": 92,
              "parent_id": 85,
              "label": "大名县",
              "lng": "115.147814",
              "lat": "36.285616",
              "level": "district"
            },
            {
              "value": 93,
              "parent_id": 85,
              "label": "涉县",
              "lng": "113.6914",
              "lat": "36.584994",
              "level": "district"
            },
            {
              "value": 94,
              "parent_id": 85,
              "label": "磁县",
              "lng": "114.373946",
              "lat": "36.374011",
              "level": "district"
            },
            {
              "value": 95,
              "parent_id": 85,
              "label": "肥乡区",
              "lng": "114.800166",
              "lat": "36.548131",
              "level": "district"
            },
            {
              "value": 96,
              "parent_id": 85,
              "label": "永年区",
              "lng": "114.543832",
              "lat": "36.743966",
              "level": "district"
            },
            {
              "value": 97,
              "parent_id": 85,
              "label": "邱县",
              "lng": "115.200589",
              "lat": "36.811148",
              "level": "district"
            },
            {
              "value": 98,
              "parent_id": 85,
              "label": "鸡泽县",
              "lng": "114.889376",
              "lat": "36.91034",
              "level": "district"
            },
            {
              "value": 99,
              "parent_id": 85,
              "label": "广平县",
              "lng": "114.948606",
              "lat": "36.483484",
              "level": "district"
            },
            {
              "value": 100,
              "parent_id": 85,
              "label": "馆陶县",
              "lng": "115.282467",
              "lat": "36.547556",
              "level": "district"
            },
            {
              "value": 101,
              "parent_id": 85,
              "label": "魏县",
              "lng": "114.93892",
              "lat": "36.359868",
              "level": "district"
            },
            {
              "value": 102,
              "parent_id": 85,
              "label": "曲周县",
              "lng": "114.957504",
              "lat": "36.76607",
              "level": "district"
            },
            {
              "value": 103,
              "parent_id": 85,
              "label": "武安市",
              "lng": "114.203697",
              "lat": "36.696506",
              "level": "district"
            }
          ]
        },
        {
          "value": 104,
          "parent_id": 38,
          "label": "邢台市",
          "lng": "114.504677",
          "lat": "37.070834",
          "level": "city",
          "children": [
            {
              "value": 105,
              "parent_id": 104,
              "label": "桥东区",
              "lng": "114.507058",
              "lat": "37.071287",
              "level": "district"
            },
            {
              "value": 106,
              "parent_id": 104,
              "label": "桥西区",
              "lng": "114.468601",
              "lat": "37.059827",
              "level": "district"
            },
            {
              "value": 107,
              "parent_id": 104,
              "label": "邢台县",
              "lng": "114.561132",
              "lat": "37.05073",
              "level": "district"
            },
            {
              "value": 108,
              "parent_id": 104,
              "label": "临城县",
              "lng": "114.498761",
              "lat": "37.444498",
              "level": "district"
            },
            {
              "value": 109,
              "parent_id": 104,
              "label": "内丘县",
              "lng": "114.512128",
              "lat": "37.286669",
              "level": "district"
            },
            {
              "value": 110,
              "parent_id": 104,
              "label": "柏乡县",
              "lng": "114.693425",
              "lat": "37.482422",
              "level": "district"
            },
            {
              "value": 111,
              "parent_id": 104,
              "label": "隆尧县",
              "lng": "114.770419",
              "lat": "37.350172",
              "level": "district"
            },
            {
              "value": 112,
              "parent_id": 104,
              "label": "任县",
              "lng": "114.671936",
              "lat": "37.120982",
              "level": "district"
            },
            {
              "value": 113,
              "parent_id": 104,
              "label": "南和县",
              "lng": "114.683863",
              "lat": "37.005017",
              "level": "district"
            },
            {
              "value": 114,
              "parent_id": 104,
              "label": "宁晋县",
              "lng": "114.93992",
              "lat": "37.624564",
              "level": "district"
            },
            {
              "value": 115,
              "parent_id": 104,
              "label": "巨鹿县",
              "lng": "115.037477",
              "lat": "37.221112",
              "level": "district"
            },
            {
              "value": 116,
              "parent_id": 104,
              "label": "新河县",
              "lng": "115.250907",
              "lat": "37.520862",
              "level": "district"
            },
            {
              "value": 117,
              "parent_id": 104,
              "label": "广宗县",
              "lng": "115.142626",
              "lat": "37.074661",
              "level": "district"
            },
            {
              "value": 118,
              "parent_id": 104,
              "label": "平乡县",
              "lng": "115.030075",
              "lat": "37.063148",
              "level": "district"
            },
            {
              "value": 119,
              "parent_id": 104,
              "label": "威县",
              "lng": "115.266703",
              "lat": "36.975478",
              "level": "district"
            },
            {
              "value": 120,
              "parent_id": 104,
              "label": "清河县",
              "lng": "115.667208",
              "lat": "37.039991",
              "level": "district"
            },
            {
              "value": 121,
              "parent_id": 104,
              "label": "临西县",
              "lng": "115.501048",
              "lat": "36.870811",
              "level": "district"
            },
            {
              "value": 122,
              "parent_id": 104,
              "label": "南宫市",
              "lng": "115.408747",
              "lat": "37.359264",
              "level": "district"
            },
            {
              "value": 123,
              "parent_id": 104,
              "label": "沙河市",
              "lng": "114.503339",
              "lat": "36.854929",
              "level": "district"
            }
          ]
        },
        {
          "value": 124,
          "parent_id": 38,
          "label": "保定市",
          "lng": "115.464589",
          "lat": "38.874434",
          "level": "city",
          "children": [
            {
              "value": 125,
              "parent_id": 124,
              "label": "竞秀区",
              "lng": "115.45877",
              "lat": "38.877449",
              "level": "district"
            },
            {
              "value": 126,
              "parent_id": 124,
              "label": "莲池区",
              "lng": "115.497097",
              "lat": "38.883582",
              "level": "district"
            },
            {
              "value": 127,
              "parent_id": 124,
              "label": "满城区",
              "lng": "115.322334",
              "lat": "38.949119",
              "level": "district"
            },
            {
              "value": 128,
              "parent_id": 124,
              "label": "清苑区",
              "lng": "115.489959",
              "lat": "38.765148",
              "level": "district"
            },
            {
              "value": 129,
              "parent_id": 124,
              "label": "徐水区",
              "lng": "115.655774",
              "lat": "39.018736",
              "level": "district"
            },
            {
              "value": 130,
              "parent_id": 124,
              "label": "涞水县",
              "lng": "115.713904",
              "lat": "39.394316",
              "level": "district"
            },
            {
              "value": 131,
              "parent_id": 124,
              "label": "阜平县",
              "lng": "114.195104",
              "lat": "38.849152",
              "level": "district"
            },
            {
              "value": 132,
              "parent_id": 124,
              "label": "定兴县",
              "lng": "115.808296",
              "lat": "39.263145",
              "level": "district"
            },
            {
              "value": 133,
              "parent_id": 124,
              "label": "唐县",
              "lng": "114.982972",
              "lat": "38.748203",
              "level": "district"
            },
            {
              "value": 134,
              "parent_id": 124,
              "label": "高阳县",
              "lng": "115.778965",
              "lat": "38.700088",
              "level": "district"
            },
            {
              "value": 135,
              "parent_id": 124,
              "label": "容城县",
              "lng": "115.861657",
              "lat": "39.042784",
              "level": "district"
            },
            {
              "value": 136,
              "parent_id": 124,
              "label": "涞源县",
              "lng": "114.694283",
              "lat": "39.360247",
              "level": "district"
            },
            {
              "value": 137,
              "parent_id": 124,
              "label": "望都县",
              "lng": "115.155128",
              "lat": "38.695842",
              "level": "district"
            },
            {
              "value": 138,
              "parent_id": 124,
              "label": "安新县",
              "lng": "115.935603",
              "lat": "38.935369",
              "level": "district"
            },
            {
              "value": 139,
              "parent_id": 124,
              "label": "易县",
              "lng": "115.497457",
              "lat": "39.349393",
              "level": "district"
            },
            {
              "value": 140,
              "parent_id": 124,
              "label": "曲阳县",
              "lng": "114.745008",
              "lat": "38.622248",
              "level": "district"
            },
            {
              "value": 141,
              "parent_id": 124,
              "label": "蠡县",
              "lng": "115.583854",
              "lat": "38.488055",
              "level": "district"
            },
            {
              "value": 142,
              "parent_id": 124,
              "label": "顺平县",
              "lng": "115.13547",
              "lat": "38.837487",
              "level": "district"
            },
            {
              "value": 143,
              "parent_id": 124,
              "label": "博野县",
              "lng": "115.46438",
              "lat": "38.457364",
              "level": "district"
            },
            {
              "value": 144,
              "parent_id": 124,
              "label": "雄县",
              "lng": "116.10865",
              "lat": "38.99455",
              "level": "district"
            },
            {
              "value": 145,
              "parent_id": 124,
              "label": "涿州市",
              "lng": "115.974422",
              "lat": "39.485282",
              "level": "district"
            },
            {
              "value": 146,
              "parent_id": 124,
              "label": "定州市",
              "lng": "114.990392",
              "lat": "38.516461",
              "level": "district"
            },
            {
              "value": 147,
              "parent_id": 124,
              "label": "安国市",
              "lng": "115.326646",
              "lat": "38.418439",
              "level": "district"
            },
            {
              "value": 148,
              "parent_id": 124,
              "label": "高碑店市",
              "lng": "115.873886",
              "lat": "39.326839",
              "level": "district"
            }
          ]
        },
        {
          "value": 149,
          "parent_id": 38,
          "label": "张家口市",
          "lng": "114.886252",
          "lat": "40.768493",
          "level": "city",
          "children": [
            {
              "value": 150,
              "parent_id": 149,
              "label": "桥东区",
              "lng": "114.894189",
              "lat": "40.788434",
              "level": "district"
            },
            {
              "value": 151,
              "parent_id": 149,
              "label": "桥西区",
              "lng": "114.869657",
              "lat": "40.819581",
              "level": "district"
            },
            {
              "value": 152,
              "parent_id": 149,
              "label": "宣化区",
              "lng": "115.099494",
              "lat": "40.608793",
              "level": "district"
            },
            {
              "value": 153,
              "parent_id": 149,
              "label": "下花园区",
              "lng": "115.287352",
              "lat": "40.502652",
              "level": "district"
            },
            {
              "value": 154,
              "parent_id": 149,
              "label": "万全区",
              "lng": "114.740557",
              "lat": "40.766965",
              "level": "district"
            },
            {
              "value": 155,
              "parent_id": 149,
              "label": "崇礼区",
              "lng": "115.282668",
              "lat": "40.974675",
              "level": "district"
            },
            {
              "value": 156,
              "parent_id": 149,
              "label": "张北县",
              "lng": "114.720077",
              "lat": "41.158596",
              "level": "district"
            },
            {
              "value": 157,
              "parent_id": 149,
              "label": "康保县",
              "lng": "114.600404",
              "lat": "41.852368",
              "level": "district"
            },
            {
              "value": 158,
              "parent_id": 149,
              "label": "沽源县",
              "lng": "115.688692",
              "lat": "41.669668",
              "level": "district"
            },
            {
              "value": 159,
              "parent_id": 149,
              "label": "尚义县",
              "lng": "113.969618",
              "lat": "41.076226",
              "level": "district"
            },
            {
              "value": 160,
              "parent_id": 149,
              "label": "蔚县",
              "lng": "114.588903",
              "lat": "39.840842",
              "level": "district"
            },
            {
              "value": 161,
              "parent_id": 149,
              "label": "阳原县",
              "lng": "114.150348",
              "lat": "40.104663",
              "level": "district"
            },
            {
              "value": 162,
              "parent_id": 149,
              "label": "怀安县",
              "lng": "114.385791",
              "lat": "40.674193",
              "level": "district"
            },
            {
              "value": 163,
              "parent_id": 149,
              "label": "怀来县",
              "lng": "115.517861",
              "lat": "40.415343",
              "level": "district"
            },
            {
              "value": 164,
              "parent_id": 149,
              "label": "涿鹿县",
              "lng": "115.205345",
              "lat": "40.379562",
              "level": "district"
            },
            {
              "value": 165,
              "parent_id": 149,
              "label": "赤城县",
              "lng": "115.831498",
              "lat": "40.912921",
              "level": "district"
            }
          ]
        },
        {
          "value": 166,
          "parent_id": 38,
          "label": "承德市",
          "lng": "117.962749",
          "lat": "40.952942",
          "level": "city",
          "children": [
            {
              "value": 167,
              "parent_id": 166,
              "label": "双桥区",
              "lng": "117.943466",
              "lat": "40.974643",
              "level": "district"
            },
            {
              "value": 168,
              "parent_id": 166,
              "label": "双滦区",
              "lng": "117.799888",
              "lat": "40.959236",
              "level": "district"
            },
            {
              "value": 169,
              "parent_id": 166,
              "label": "鹰手营子矿区",
              "lng": "117.659499",
              "lat": "40.546361",
              "level": "district"
            },
            {
              "value": 170,
              "parent_id": 166,
              "label": "承德县",
              "lng": "118.173824",
              "lat": "40.768238",
              "level": "district"
            },
            {
              "value": 171,
              "parent_id": 166,
              "label": "兴隆县",
              "lng": "117.500558",
              "lat": "40.417358",
              "level": "district"
            },
            {
              "value": 172,
              "parent_id": 166,
              "label": "平泉县",
              "lng": "118.701951",
              "lat": "41.018405",
              "level": "district"
            },
            {
              "value": 173,
              "parent_id": 166,
              "label": "滦平县",
              "lng": "117.332801",
              "lat": "40.941482",
              "level": "district"
            },
            {
              "value": 174,
              "parent_id": 166,
              "label": "隆化县",
              "lng": "117.738937",
              "lat": "41.313791",
              "level": "district"
            },
            {
              "value": 175,
              "parent_id": 166,
              "label": "丰宁满族自治县",
              "lng": "116.646051",
              "lat": "41.209069",
              "level": "district"
            },
            {
              "value": 176,
              "parent_id": 166,
              "label": "宽城满族自治县",
              "lng": "118.485313",
              "lat": "40.611391",
              "level": "district"
            },
            {
              "value": 177,
              "parent_id": 166,
              "label": "围场满族蒙古族自治县",
              "lng": "117.760159",
              "lat": "41.938529",
              "level": "district"
            }
          ]
        },
        {
          "value": 178,
          "parent_id": 38,
          "label": "沧州市",
          "lng": "116.838834",
          "lat": "38.304477",
          "level": "city",
          "children": [
            {
              "value": 179,
              "parent_id": 178,
              "label": "新华区",
              "lng": "116.866284",
              "lat": "38.314416",
              "level": "district"
            },
            {
              "value": 180,
              "parent_id": 178,
              "label": "运河区",
              "lng": "116.843673",
              "lat": "38.283749",
              "level": "district"
            },
            {
              "value": 181,
              "parent_id": 178,
              "label": "沧县",
              "lng": "117.007478",
              "lat": "38.219856",
              "level": "district"
            },
            {
              "value": 182,
              "parent_id": 178,
              "label": "青县",
              "lng": "116.804305",
              "lat": "38.583021",
              "level": "district"
            },
            {
              "value": 183,
              "parent_id": 178,
              "label": "东光县",
              "lng": "116.537067",
              "lat": "37.888248",
              "level": "district"
            },
            {
              "value": 184,
              "parent_id": 178,
              "label": "海兴县",
              "lng": "117.497651",
              "lat": "38.143169",
              "level": "district"
            },
            {
              "value": 185,
              "parent_id": 178,
              "label": "盐山县",
              "lng": "117.230602",
              "lat": "38.058087",
              "level": "district"
            },
            {
              "value": 186,
              "parent_id": 178,
              "label": "肃宁县",
              "lng": "115.829758",
              "lat": "38.422801",
              "level": "district"
            },
            {
              "value": 187,
              "parent_id": 178,
              "label": "南皮县",
              "lng": "116.708347",
              "lat": "38.038421",
              "level": "district"
            },
            {
              "value": 188,
              "parent_id": 178,
              "label": "吴桥县",
              "lng": "116.391508",
              "lat": "37.627661",
              "level": "district"
            },
            {
              "value": 189,
              "parent_id": 178,
              "label": "献县",
              "lng": "116.122725",
              "lat": "38.190185",
              "level": "district"
            },
            {
              "value": 190,
              "parent_id": 178,
              "label": "孟村回族自治县",
              "lng": "117.104298",
              "lat": "38.053409",
              "level": "district"
            },
            {
              "value": 191,
              "parent_id": 178,
              "label": "泊头市",
              "lng": "116.578367",
              "lat": "38.083437",
              "level": "district"
            },
            {
              "value": 192,
              "parent_id": 178,
              "label": "任丘市",
              "lng": "116.082917",
              "lat": "38.683591",
              "level": "district"
            },
            {
              "value": 193,
              "parent_id": 178,
              "label": "黄骅市",
              "lng": "117.329949",
              "lat": "38.371402",
              "level": "district"
            },
            {
              "value": 194,
              "parent_id": 178,
              "label": "河间市",
              "lng": "116.099517",
              "lat": "38.446624",
              "level": "district"
            }
          ]
        },
        {
          "value": 195,
          "parent_id": 38,
          "label": "廊坊市",
          "lng": "116.683752",
          "lat": "39.538047",
          "level": "city",
          "children": [
            {
              "value": 196,
              "parent_id": 195,
              "label": "安次区",
              "lng": "116.694544",
              "lat": "39.502569",
              "level": "district"
            },
            {
              "value": 197,
              "parent_id": 195,
              "label": "广阳区",
              "lng": "116.71069",
              "lat": "39.522786",
              "level": "district"
            },
            {
              "value": 198,
              "parent_id": 195,
              "label": "固安县",
              "lng": "116.298657",
              "lat": "39.438214",
              "level": "district"
            },
            {
              "value": 199,
              "parent_id": 195,
              "label": "永清县",
              "lng": "116.50568",
              "lat": "39.330689",
              "level": "district"
            },
            {
              "value": 200,
              "parent_id": 195,
              "label": "香河县",
              "lng": "117.006093",
              "lat": "39.761424",
              "level": "district"
            },
            {
              "value": 201,
              "parent_id": 195,
              "label": "大城县",
              "lng": "116.653793",
              "lat": "38.705449",
              "level": "district"
            },
            {
              "value": 202,
              "parent_id": 195,
              "label": "文安县",
              "lng": "116.457898",
              "lat": "38.87292",
              "level": "district"
            },
            {
              "value": 203,
              "parent_id": 195,
              "label": "大厂回族自治县",
              "lng": "116.989574",
              "lat": "39.886547",
              "level": "district"
            },
            {
              "value": 204,
              "parent_id": 195,
              "label": "霸州市",
              "lng": "116.391484",
              "lat": "39.125744",
              "level": "district"
            },
            {
              "value": 205,
              "parent_id": 195,
              "label": "三河市",
              "lng": "117.078294",
              "lat": "39.982718",
              "level": "district"
            }
          ]
        },
        {
          "value": 206,
          "parent_id": 38,
          "label": "衡水市",
          "lng": "115.670177",
          "lat": "37.73892",
          "level": "city",
          "children": [
            {
              "value": 207,
              "parent_id": 206,
              "label": "桃城区",
              "lng": "115.67545",
              "lat": "37.735465",
              "level": "district"
            },
            {
              "value": 208,
              "parent_id": 206,
              "label": "冀州区",
              "lng": "115.579308",
              "lat": "37.550856",
              "level": "district"
            },
            {
              "value": 209,
              "parent_id": 206,
              "label": "枣强县",
              "lng": "115.724259",
              "lat": "37.513417",
              "level": "district"
            },
            {
              "value": 210,
              "parent_id": 206,
              "label": "武邑县",
              "lng": "115.887531",
              "lat": "37.801665",
              "level": "district"
            },
            {
              "value": 211,
              "parent_id": 206,
              "label": "武强县",
              "lng": "115.982461",
              "lat": "38.041368",
              "level": "district"
            },
            {
              "value": 212,
              "parent_id": 206,
              "label": "饶阳县",
              "lng": "115.725833",
              "lat": "38.235892",
              "level": "district"
            },
            {
              "value": 213,
              "parent_id": 206,
              "label": "安平县",
              "lng": "115.519278",
              "lat": "38.234501",
              "level": "district"
            },
            {
              "value": 214,
              "parent_id": 206,
              "label": "故城县",
              "lng": "115.965874",
              "lat": "37.347409",
              "level": "district"
            },
            {
              "value": 215,
              "parent_id": 206,
              "label": "景县",
              "lng": "116.270648",
              "lat": "37.69229",
              "level": "district"
            },
            {
              "value": 216,
              "parent_id": 206,
              "label": "阜城县",
              "lng": "116.175262",
              "lat": "37.862505",
              "level": "district"
            },
            {
              "value": 217,
              "parent_id": 206,
              "label": "深州市",
              "lng": "115.559574",
              "lat": "38.001535",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 218,
      "parent_id": 1,
      "label": "山西省",
      "lng": "112.562678",
      "lat": "37.873499",
      "level": "province",
      "children": [
        {
          "value": 219,
          "parent_id": 218,
          "label": "太原市",
          "lng": "112.548879",
          "lat": "37.87059",
          "level": "city",
          "children": [
            {
              "value": 220,
              "parent_id": 219,
              "label": "小店区",
              "lng": "112.565659",
              "lat": "37.736525",
              "level": "district"
            },
            {
              "value": 221,
              "parent_id": 219,
              "label": "迎泽区",
              "lng": "112.5634",
              "lat": "37.863451",
              "level": "district"
            },
            {
              "value": 222,
              "parent_id": 219,
              "label": "杏花岭区",
              "lng": "112.570604",
              "lat": "37.893955",
              "level": "district"
            },
            {
              "value": 223,
              "parent_id": 219,
              "label": "尖草坪区",
              "lng": "112.486691",
              "lat": "37.940387",
              "level": "district"
            },
            {
              "value": 224,
              "parent_id": 219,
              "label": "万柏林区",
              "lng": "112.515937",
              "lat": "37.85958",
              "level": "district"
            },
            {
              "value": 225,
              "parent_id": 219,
              "label": "晋源区",
              "lng": "112.47794",
              "lat": "37.715193",
              "level": "district"
            },
            {
              "value": 226,
              "parent_id": 219,
              "label": "清徐县",
              "lng": "112.358667",
              "lat": "37.607443",
              "level": "district"
            },
            {
              "value": 227,
              "parent_id": 219,
              "label": "阳曲县",
              "lng": "112.672952",
              "lat": "38.058488",
              "level": "district"
            },
            {
              "value": 228,
              "parent_id": 219,
              "label": "娄烦县",
              "lng": "111.797083",
              "lat": "38.067932",
              "level": "district"
            },
            {
              "value": 229,
              "parent_id": 219,
              "label": "古交市",
              "lng": "112.175853",
              "lat": "37.907129",
              "level": "district"
            }
          ]
        },
        {
          "value": 230,
          "parent_id": 218,
          "label": "大同市",
          "lng": "113.300129",
          "lat": "40.076763",
          "level": "city",
          "children": [
            {
              "value": 231,
              "parent_id": 230,
              "label": "城区",
              "lng": "113.298026",
              "lat": "40.075666",
              "level": "district"
            },
            {
              "value": 232,
              "parent_id": 230,
              "label": "矿区",
              "lng": "113.177206",
              "lat": "40.036858",
              "level": "district"
            },
            {
              "value": 233,
              "parent_id": 230,
              "label": "南郊区",
              "lng": "113.149693",
              "lat": "40.005404",
              "level": "district"
            },
            {
              "value": 234,
              "parent_id": 230,
              "label": "新荣区",
              "lng": "113.140004",
              "lat": "40.255866",
              "level": "district"
            },
            {
              "value": 235,
              "parent_id": 230,
              "label": "阳高县",
              "lng": "113.748944",
              "lat": "40.361059",
              "level": "district"
            },
            {
              "value": 236,
              "parent_id": 230,
              "label": "天镇县",
              "lng": "114.090867",
              "lat": "40.420237",
              "level": "district"
            },
            {
              "value": 237,
              "parent_id": 230,
              "label": "广灵县",
              "lng": "114.282758",
              "lat": "39.760281",
              "level": "district"
            },
            {
              "value": 238,
              "parent_id": 230,
              "label": "灵丘县",
              "lng": "114.23435",
              "lat": "39.442406",
              "level": "district"
            },
            {
              "value": 239,
              "parent_id": 230,
              "label": "浑源县",
              "lng": "113.699475",
              "lat": "39.693406",
              "level": "district"
            },
            {
              "value": 240,
              "parent_id": 230,
              "label": "左云县",
              "lng": "112.703008",
              "lat": "40.013442",
              "level": "district"
            },
            {
              "value": 241,
              "parent_id": 230,
              "label": "大同县",
              "lng": "113.61244",
              "lat": "40.040294",
              "level": "district"
            }
          ]
        },
        {
          "value": 242,
          "parent_id": 218,
          "label": "阳泉市",
          "lng": "113.580519",
          "lat": "37.856971",
          "level": "city",
          "children": [
            {
              "value": 243,
              "parent_id": 242,
              "label": "城区",
              "lng": "113.600669",
              "lat": "37.847436",
              "level": "district"
            },
            {
              "value": 244,
              "parent_id": 242,
              "label": "矿区",
              "lng": "113.555279",
              "lat": "37.868494",
              "level": "district"
            },
            {
              "value": 245,
              "parent_id": 242,
              "label": "郊区",
              "lng": "113.594163",
              "lat": "37.944679",
              "level": "district"
            },
            {
              "value": 246,
              "parent_id": 242,
              "label": "平定县",
              "lng": "113.630107",
              "lat": "37.804988",
              "level": "district"
            },
            {
              "value": 247,
              "parent_id": 242,
              "label": "盂县",
              "lng": "113.41233",
              "lat": "38.085619",
              "level": "district"
            }
          ]
        },
        {
          "value": 248,
          "parent_id": 218,
          "label": "长治市",
          "lng": "113.116404",
          "lat": "36.195409",
          "level": "city",
          "children": [
            {
              "value": 249,
              "parent_id": 248,
              "label": "城区",
              "lng": "113.123088",
              "lat": "36.20353",
              "level": "district"
            },
            {
              "value": 250,
              "parent_id": 248,
              "label": "郊区",
              "lng": "113.101211",
              "lat": "36.218388",
              "level": "district"
            },
            {
              "value": 251,
              "parent_id": 248,
              "label": "长治县",
              "lng": "113.051407",
              "lat": "36.052858",
              "level": "district"
            },
            {
              "value": 252,
              "parent_id": 248,
              "label": "襄垣县",
              "lng": "113.051491",
              "lat": "36.535817",
              "level": "district"
            },
            {
              "value": 253,
              "parent_id": 248,
              "label": "屯留县",
              "lng": "112.891998",
              "lat": "36.315663",
              "level": "district"
            },
            {
              "value": 254,
              "parent_id": 248,
              "label": "平顺县",
              "lng": "113.435961",
              "lat": "36.200179",
              "level": "district"
            },
            {
              "value": 255,
              "parent_id": 248,
              "label": "黎城县",
              "lng": "113.387155",
              "lat": "36.502328",
              "level": "district"
            },
            {
              "value": 256,
              "parent_id": 248,
              "label": "壶关县",
              "lng": "113.207049",
              "lat": "36.115448",
              "level": "district"
            },
            {
              "value": 257,
              "parent_id": 248,
              "label": "长子县",
              "lng": "112.8779",
              "lat": "36.122334",
              "level": "district"
            },
            {
              "value": 258,
              "parent_id": 248,
              "label": "武乡县",
              "lng": "112.864561",
              "lat": "36.837625",
              "level": "district"
            },
            {
              "value": 259,
              "parent_id": 248,
              "label": "沁县",
              "lng": "112.699226",
              "lat": "36.756063",
              "level": "district"
            },
            {
              "value": 260,
              "parent_id": 248,
              "label": "沁源县",
              "lng": "112.337446",
              "lat": "36.5002",
              "level": "district"
            },
            {
              "value": 261,
              "parent_id": 248,
              "label": "潞城市",
              "lng": "113.228852",
              "lat": "36.334104",
              "level": "district"
            }
          ]
        },
        {
          "value": 262,
          "parent_id": 218,
          "label": "晋城市",
          "lng": "112.851486",
          "lat": "35.490684",
          "level": "city",
          "children": [
            {
              "value": 263,
              "parent_id": 262,
              "label": "城区",
              "lng": "112.853555",
              "lat": "35.501571",
              "level": "district"
            },
            {
              "value": 264,
              "parent_id": 262,
              "label": "沁水县",
              "lng": "112.186738",
              "lat": "35.690141",
              "level": "district"
            },
            {
              "value": 265,
              "parent_id": 262,
              "label": "阳城县",
              "lng": "112.414738",
              "lat": "35.486029",
              "level": "district"
            },
            {
              "value": 266,
              "parent_id": 262,
              "label": "陵川县",
              "lng": "113.280688",
              "lat": "35.775685",
              "level": "district"
            },
            {
              "value": 267,
              "parent_id": 262,
              "label": "泽州县",
              "lng": "112.899137",
              "lat": "35.617221",
              "level": "district"
            },
            {
              "value": 268,
              "parent_id": 262,
              "label": "高平市",
              "lng": "112.92392",
              "lat": "35.797997",
              "level": "district"
            }
          ]
        },
        {
          "value": 269,
          "parent_id": 218,
          "label": "朔州市",
          "lng": "112.432991",
          "lat": "39.331855",
          "level": "city",
          "children": [
            {
              "value": 270,
              "parent_id": 269,
              "label": "朔城区",
              "lng": "112.432312",
              "lat": "39.319519",
              "level": "district"
            },
            {
              "value": 271,
              "parent_id": 269,
              "label": "平鲁区",
              "lng": "112.28833",
              "lat": "39.512155",
              "level": "district"
            },
            {
              "value": 272,
              "parent_id": 269,
              "label": "山阴县",
              "lng": "112.816413",
              "lat": "39.527893",
              "level": "district"
            },
            {
              "value": 273,
              "parent_id": 269,
              "label": "应县",
              "lng": "113.191098",
              "lat": "39.554247",
              "level": "district"
            },
            {
              "value": 274,
              "parent_id": 269,
              "label": "右玉县",
              "lng": "112.466989",
              "lat": "39.989063",
              "level": "district"
            },
            {
              "value": 275,
              "parent_id": 269,
              "label": "怀仁县",
              "lng": "113.131717",
              "lat": "39.821627",
              "level": "district"
            }
          ]
        },
        {
          "value": 276,
          "parent_id": 218,
          "label": "晋中市",
          "lng": "112.752652",
          "lat": "37.687357",
          "level": "city",
          "children": [
            {
              "value": 277,
              "parent_id": 276,
              "label": "榆次区",
              "lng": "112.708224",
              "lat": "37.697794",
              "level": "district"
            },
            {
              "value": 278,
              "parent_id": 276,
              "label": "榆社县",
              "lng": "112.975209",
              "lat": "37.070916",
              "level": "district"
            },
            {
              "value": 279,
              "parent_id": 276,
              "label": "左权县",
              "lng": "113.379403",
              "lat": "37.082943",
              "level": "district"
            },
            {
              "value": 280,
              "parent_id": 276,
              "label": "和顺县",
              "lng": "113.570415",
              "lat": "37.32957",
              "level": "district"
            },
            {
              "value": 281,
              "parent_id": 276,
              "label": "昔阳县",
              "lng": "113.706977",
              "lat": "37.61253",
              "level": "district"
            },
            {
              "value": 282,
              "parent_id": 276,
              "label": "寿阳县",
              "lng": "113.176373",
              "lat": "37.895191",
              "level": "district"
            },
            {
              "value": 283,
              "parent_id": 276,
              "label": "太谷县",
              "lng": "112.551305",
              "lat": "37.421307",
              "level": "district"
            },
            {
              "value": 284,
              "parent_id": 276,
              "label": "祁县",
              "lng": "112.335542",
              "lat": "37.357869",
              "level": "district"
            },
            {
              "value": 285,
              "parent_id": 276,
              "label": "平遥县",
              "lng": "112.176136",
              "lat": "37.189421",
              "level": "district"
            },
            {
              "value": 286,
              "parent_id": 276,
              "label": "灵石县",
              "lng": "111.77864",
              "lat": "36.847927",
              "level": "district"
            },
            {
              "value": 287,
              "parent_id": 276,
              "label": "介休市",
              "lng": "111.916711",
              "lat": "37.026944",
              "level": "district"
            }
          ]
        },
        {
          "value": 288,
          "parent_id": 218,
          "label": "运城市",
          "lng": "111.00746",
          "lat": "35.026516",
          "level": "city",
          "children": [
            {
              "value": 289,
              "parent_id": 288,
              "label": "盐湖区",
              "lng": "110.998272",
              "lat": "35.015101",
              "level": "district"
            },
            {
              "value": 290,
              "parent_id": 288,
              "label": "临猗县",
              "lng": "110.774547",
              "lat": "35.144277",
              "level": "district"
            },
            {
              "value": 291,
              "parent_id": 288,
              "label": "万荣县",
              "lng": "110.838024",
              "lat": "35.415253",
              "level": "district"
            },
            {
              "value": 292,
              "parent_id": 288,
              "label": "闻喜县",
              "lng": "111.22472",
              "lat": "35.356644",
              "level": "district"
            },
            {
              "value": 293,
              "parent_id": 288,
              "label": "稷山县",
              "lng": "110.983333",
              "lat": "35.604025",
              "level": "district"
            },
            {
              "value": 294,
              "parent_id": 288,
              "label": "新绛县",
              "lng": "111.224734",
              "lat": "35.616251",
              "level": "district"
            },
            {
              "value": 295,
              "parent_id": 288,
              "label": "绛县",
              "lng": "111.568236",
              "lat": "35.49119",
              "level": "district"
            },
            {
              "value": 296,
              "parent_id": 288,
              "label": "垣曲县",
              "lng": "111.670108",
              "lat": "35.297369",
              "level": "district"
            },
            {
              "value": 297,
              "parent_id": 288,
              "label": "夏县",
              "lng": "111.220456",
              "lat": "35.141363",
              "level": "district"
            },
            {
              "value": 298,
              "parent_id": 288,
              "label": "平陆县",
              "lng": "111.194133",
              "lat": "34.82926",
              "level": "district"
            },
            {
              "value": 299,
              "parent_id": 288,
              "label": "芮城县",
              "lng": "110.694369",
              "lat": "34.693579",
              "level": "district"
            },
            {
              "value": 300,
              "parent_id": 288,
              "label": "永济市",
              "lng": "110.447543",
              "lat": "34.8671",
              "level": "district"
            },
            {
              "value": 301,
              "parent_id": 288,
              "label": "河津市",
              "lng": "110.712063",
              "lat": "35.596383",
              "level": "district"
            }
          ]
        },
        {
          "value": 302,
          "parent_id": 218,
          "label": "忻州市",
          "lng": "112.734174",
          "lat": "38.416663",
          "level": "city",
          "children": [
            {
              "value": 303,
              "parent_id": 302,
              "label": "忻府区",
              "lng": "112.746046",
              "lat": "38.404242",
              "level": "district"
            },
            {
              "value": 304,
              "parent_id": 302,
              "label": "定襄县",
              "lng": "112.957237",
              "lat": "38.473506",
              "level": "district"
            },
            {
              "value": 305,
              "parent_id": 302,
              "label": "五台县",
              "lng": "113.255309",
              "lat": "38.728315",
              "level": "district"
            },
            {
              "value": 306,
              "parent_id": 302,
              "label": "代县",
              "lng": "112.960282",
              "lat": "39.066917",
              "level": "district"
            },
            {
              "value": 307,
              "parent_id": 302,
              "label": "繁峙县",
              "lng": "113.265563",
              "lat": "39.188811",
              "level": "district"
            },
            {
              "value": 308,
              "parent_id": 302,
              "label": "宁武县",
              "lng": "112.304722",
              "lat": "39.001524",
              "level": "district"
            },
            {
              "value": 309,
              "parent_id": 302,
              "label": "静乐县",
              "lng": "111.939498",
              "lat": "38.359306",
              "level": "district"
            },
            {
              "value": 310,
              "parent_id": 302,
              "label": "神池县",
              "lng": "112.211296",
              "lat": "39.090552",
              "level": "district"
            },
            {
              "value": 311,
              "parent_id": 302,
              "label": "五寨县",
              "lng": "111.846904",
              "lat": "38.910726",
              "level": "district"
            },
            {
              "value": 312,
              "parent_id": 302,
              "label": "岢岚县",
              "lng": "111.57285",
              "lat": "38.70418",
              "level": "district"
            },
            {
              "value": 313,
              "parent_id": 302,
              "label": "河曲县",
              "lng": "111.138472",
              "lat": "39.384482",
              "level": "district"
            },
            {
              "value": 314,
              "parent_id": 302,
              "label": "保德县",
              "lng": "111.086564",
              "lat": "39.022487",
              "level": "district"
            },
            {
              "value": 315,
              "parent_id": 302,
              "label": "偏关县",
              "lng": "111.508831",
              "lat": "39.436306",
              "level": "district"
            },
            {
              "value": 316,
              "parent_id": 302,
              "label": "原平市",
              "lng": "112.711058",
              "lat": "38.731402",
              "level": "district"
            }
          ]
        },
        {
          "value": 317,
          "parent_id": 218,
          "label": "临汾市",
          "lng": "111.518975",
          "lat": "36.088005",
          "level": "city",
          "children": [
            {
              "value": 318,
              "parent_id": 317,
              "label": "尧都区",
              "lng": "111.579554",
              "lat": "36.07884",
              "level": "district"
            },
            {
              "value": 319,
              "parent_id": 317,
              "label": "曲沃县",
              "lng": "111.47586",
              "lat": "35.641086",
              "level": "district"
            },
            {
              "value": 320,
              "parent_id": 317,
              "label": "翼城县",
              "lng": "111.718951",
              "lat": "35.738576",
              "level": "district"
            },
            {
              "value": 321,
              "parent_id": 317,
              "label": "襄汾县",
              "lng": "111.441725",
              "lat": "35.876293",
              "level": "district"
            },
            {
              "value": 322,
              "parent_id": 317,
              "label": "洪洞县",
              "lng": "111.674965",
              "lat": "36.253747",
              "level": "district"
            },
            {
              "value": 323,
              "parent_id": 317,
              "label": "古县",
              "lng": "111.920465",
              "lat": "36.266914",
              "level": "district"
            },
            {
              "value": 324,
              "parent_id": 317,
              "label": "安泽县",
              "lng": "112.250144",
              "lat": "36.147787",
              "level": "district"
            },
            {
              "value": 325,
              "parent_id": 317,
              "label": "浮山县",
              "lng": "111.848883",
              "lat": "35.968124",
              "level": "district"
            },
            {
              "value": 326,
              "parent_id": 317,
              "label": "吉县",
              "lng": "110.681763",
              "lat": "36.098188",
              "level": "district"
            },
            {
              "value": 327,
              "parent_id": 317,
              "label": "乡宁县",
              "lng": "110.847021",
              "lat": "35.970389",
              "level": "district"
            },
            {
              "value": 328,
              "parent_id": 317,
              "label": "大宁县",
              "lng": "110.75291",
              "lat": "36.465102",
              "level": "district"
            },
            {
              "value": 329,
              "parent_id": 317,
              "label": "隰县",
              "lng": "110.940637",
              "lat": "36.69333",
              "level": "district"
            },
            {
              "value": 330,
              "parent_id": 317,
              "label": "永和县",
              "lng": "110.632006",
              "lat": "36.759507",
              "level": "district"
            },
            {
              "value": 331,
              "parent_id": 317,
              "label": "蒲县",
              "lng": "111.096439",
              "lat": "36.411826",
              "level": "district"
            },
            {
              "value": 332,
              "parent_id": 317,
              "label": "汾西县",
              "lng": "111.56395",
              "lat": "36.652854",
              "level": "district"
            },
            {
              "value": 333,
              "parent_id": 317,
              "label": "侯马市",
              "lng": "111.372002",
              "lat": "35.619105",
              "level": "district"
            },
            {
              "value": 334,
              "parent_id": 317,
              "label": "霍州市",
              "lng": "111.755398",
              "lat": "36.56893",
              "level": "district"
            }
          ]
        },
        {
          "value": 335,
          "parent_id": 218,
          "label": "吕梁市",
          "lng": "111.144699",
          "lat": "37.519126",
          "level": "city",
          "children": [
            {
              "value": 336,
              "parent_id": 335,
              "label": "离石区",
              "lng": "111.150695",
              "lat": "37.51786",
              "level": "district"
            },
            {
              "value": 337,
              "parent_id": 335,
              "label": "文水县",
              "lng": "112.028866",
              "lat": "37.438101",
              "level": "district"
            },
            {
              "value": 338,
              "parent_id": 335,
              "label": "交城县",
              "lng": "112.156064",
              "lat": "37.551963",
              "level": "district"
            },
            {
              "value": 339,
              "parent_id": 335,
              "label": "兴县",
              "lng": "111.127667",
              "lat": "38.462389",
              "level": "district"
            },
            {
              "value": 340,
              "parent_id": 335,
              "label": "临县",
              "lng": "110.992093",
              "lat": "37.950758",
              "level": "district"
            },
            {
              "value": 341,
              "parent_id": 335,
              "label": "柳林县",
              "lng": "110.889007",
              "lat": "37.429772",
              "level": "district"
            },
            {
              "value": 342,
              "parent_id": 335,
              "label": "石楼县",
              "lng": "110.834634",
              "lat": "36.99857",
              "level": "district"
            },
            {
              "value": 343,
              "parent_id": 335,
              "label": "岚县",
              "lng": "111.671917",
              "lat": "38.279299",
              "level": "district"
            },
            {
              "value": 344,
              "parent_id": 335,
              "label": "方山县",
              "lng": "111.244098",
              "lat": "37.894631",
              "level": "district"
            },
            {
              "value": 345,
              "parent_id": 335,
              "label": "中阳县",
              "lng": "111.179657",
              "lat": "37.357058",
              "level": "district"
            },
            {
              "value": 346,
              "parent_id": 335,
              "label": "交口县",
              "lng": "111.181151",
              "lat": "36.982186",
              "level": "district"
            },
            {
              "value": 347,
              "parent_id": 335,
              "label": "孝义市",
              "lng": "111.778818",
              "lat": "37.146294",
              "level": "district"
            },
            {
              "value": 348,
              "parent_id": 335,
              "label": "汾阳市",
              "lng": "111.770477",
              "lat": "37.261756",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 349,
      "parent_id": 1,
      "label": "内蒙古自治区",
      "lng": "111.76629",
      "lat": "40.81739",
      "level": "province",
      "children": [
        {
          "value": 350,
          "parent_id": 349,
          "label": "呼和浩特市",
          "lng": "111.749995",
          "lat": "40.842356",
          "level": "city",
          "children": [
            {
              "value": 351,
              "parent_id": 350,
              "label": "新城区",
              "lng": "111.665544",
              "lat": "40.858289",
              "level": "district"
            },
            {
              "value": 352,
              "parent_id": 350,
              "label": "回民区",
              "lng": "111.623692",
              "lat": "40.808608",
              "level": "district"
            },
            {
              "value": 353,
              "parent_id": 350,
              "label": "玉泉区",
              "lng": "111.673881",
              "lat": "40.753655",
              "level": "district"
            },
            {
              "value": 354,
              "parent_id": 350,
              "label": "赛罕区",
              "lng": "111.701355",
              "lat": "40.792667",
              "level": "district"
            },
            {
              "value": 355,
              "parent_id": 350,
              "label": "土默特左旗",
              "lng": "111.163902",
              "lat": "40.729572",
              "level": "district"
            },
            {
              "value": 356,
              "parent_id": 350,
              "label": "托克托县",
              "lng": "111.194312",
              "lat": "40.277431",
              "level": "district"
            },
            {
              "value": 357,
              "parent_id": 350,
              "label": "和林格尔县",
              "lng": "111.821843",
              "lat": "40.378787",
              "level": "district"
            },
            {
              "value": 358,
              "parent_id": 350,
              "label": "清水河县",
              "lng": "111.647609",
              "lat": "39.921095",
              "level": "district"
            },
            {
              "value": 359,
              "parent_id": 350,
              "label": "武川县",
              "lng": "111.451303",
              "lat": "41.096471",
              "level": "district"
            }
          ]
        },
        {
          "value": 360,
          "parent_id": 349,
          "label": "包头市",
          "lng": "109.953504",
          "lat": "40.621157",
          "level": "city",
          "children": [
            {
              "value": 361,
              "parent_id": 360,
              "label": "东河区",
              "lng": "110.044106",
              "lat": "40.576319",
              "level": "district"
            },
            {
              "value": 362,
              "parent_id": 360,
              "label": "昆都仑区",
              "lng": "109.837707",
              "lat": "40.642578",
              "level": "district"
            },
            {
              "value": 363,
              "parent_id": 360,
              "label": "青山区",
              "lng": "109.901572",
              "lat": "40.643246",
              "level": "district"
            },
            {
              "value": 364,
              "parent_id": 360,
              "label": "石拐区",
              "lng": "110.060254",
              "lat": "40.681748",
              "level": "district"
            },
            {
              "value": 365,
              "parent_id": 360,
              "label": "白云鄂博矿区",
              "lng": "109.973803",
              "lat": "41.769511",
              "level": "district"
            },
            {
              "value": 366,
              "parent_id": 360,
              "label": "九原区",
              "lng": "109.967449",
              "lat": "40.610561",
              "level": "district"
            },
            {
              "value": 367,
              "parent_id": 360,
              "label": "土默特右旗",
              "lng": "110.524262",
              "lat": "40.569426",
              "level": "district"
            },
            {
              "value": 368,
              "parent_id": 360,
              "label": "固阳县",
              "lng": "110.060514",
              "lat": "41.034105",
              "level": "district"
            },
            {
              "value": 369,
              "parent_id": 360,
              "label": "达尔罕茂明安联合旗",
              "lng": "110.432626",
              "lat": "41.698992",
              "level": "district"
            }
          ]
        },
        {
          "value": 370,
          "parent_id": 349,
          "label": "乌海市",
          "lng": "106.794216",
          "lat": "39.655248",
          "level": "city",
          "children": [
            {
              "value": 371,
              "parent_id": 370,
              "label": "海勃湾区",
              "lng": "106.822778",
              "lat": "39.691156",
              "level": "district"
            },
            {
              "value": 372,
              "parent_id": 370,
              "label": "海南区",
              "lng": "106.891424",
              "lat": "39.441364",
              "level": "district"
            },
            {
              "value": 373,
              "parent_id": 370,
              "label": "乌达区",
              "lng": "106.726099",
              "lat": "39.505925",
              "level": "district"
            }
          ]
        },
        {
          "value": 374,
          "parent_id": 349,
          "label": "赤峰市",
          "lng": "118.88694",
          "lat": "42.257843",
          "level": "city",
          "children": [
            {
              "value": 375,
              "parent_id": 374,
              "label": "红山区",
              "lng": "118.953854",
              "lat": "42.296588",
              "level": "district"
            },
            {
              "value": 376,
              "parent_id": 374,
              "label": "元宝山区",
              "lng": "119.288611",
              "lat": "42.038902",
              "level": "district"
            },
            {
              "value": 377,
              "parent_id": 374,
              "label": "松山区",
              "lng": "118.916208",
              "lat": "42.299798",
              "level": "district"
            },
            {
              "value": 378,
              "parent_id": 374,
              "label": "阿鲁科尔沁旗",
              "lng": "120.0657",
              "lat": "43.872298",
              "level": "district"
            },
            {
              "value": 379,
              "parent_id": 374,
              "label": "巴林左旗",
              "lng": "119.362931",
              "lat": "43.960889",
              "level": "district"
            },
            {
              "value": 380,
              "parent_id": 374,
              "label": "巴林右旗",
              "lng": "118.66518",
              "lat": "43.534414",
              "level": "district"
            },
            {
              "value": 381,
              "parent_id": 374,
              "label": "林西县",
              "lng": "118.05545",
              "lat": "43.61812",
              "level": "district"
            },
            {
              "value": 382,
              "parent_id": 374,
              "label": "克什克腾旗",
              "lng": "117.545797",
              "lat": "43.264988",
              "level": "district"
            },
            {
              "value": 383,
              "parent_id": 374,
              "label": "翁牛特旗",
              "lng": "119.00658",
              "lat": "42.936188",
              "level": "district"
            },
            {
              "value": 384,
              "parent_id": 374,
              "label": "喀喇沁旗",
              "lng": "118.701937",
              "lat": "41.927363",
              "level": "district"
            },
            {
              "value": 385,
              "parent_id": 374,
              "label": "宁城县",
              "lng": "119.318876",
              "lat": "41.601375",
              "level": "district"
            },
            {
              "value": 386,
              "parent_id": 374,
              "label": "敖汉旗",
              "lng": "119.921603",
              "lat": "42.290781",
              "level": "district"
            }
          ]
        },
        {
          "value": 387,
          "parent_id": 349,
          "label": "通辽市",
          "lng": "122.243444",
          "lat": "43.652889",
          "level": "city",
          "children": [
            {
              "value": 388,
              "parent_id": 387,
              "label": "科尔沁区",
              "lng": "122.255671",
              "lat": "43.623078",
              "level": "district"
            },
            {
              "value": 389,
              "parent_id": 387,
              "label": "科尔沁左翼中旗",
              "lng": "123.312264",
              "lat": "44.126625",
              "level": "district"
            },
            {
              "value": 390,
              "parent_id": 387,
              "label": "科尔沁左翼后旗",
              "lng": "122.35677",
              "lat": "42.935105",
              "level": "district"
            },
            {
              "value": 391,
              "parent_id": 387,
              "label": "开鲁县",
              "lng": "121.319308",
              "lat": "43.601244",
              "level": "district"
            },
            {
              "value": 392,
              "parent_id": 387,
              "label": "库伦旗",
              "lng": "121.8107",
              "lat": "42.735656",
              "level": "district"
            },
            {
              "value": 393,
              "parent_id": 387,
              "label": "奈曼旗",
              "lng": "120.658282",
              "lat": "42.867226",
              "level": "district"
            },
            {
              "value": 394,
              "parent_id": 387,
              "label": "扎鲁特旗",
              "lng": "120.911676",
              "lat": "44.556389",
              "level": "district"
            },
            {
              "value": 395,
              "parent_id": 387,
              "label": "霍林郭勒市",
              "lng": "119.68187",
              "lat": "45.533962",
              "level": "district"
            }
          ]
        },
        {
          "value": 396,
          "parent_id": 349,
          "label": "鄂尔多斯市",
          "lng": "109.781327",
          "lat": "39.608266",
          "level": "city",
          "children": [
            {
              "value": 397,
              "parent_id": 396,
              "label": "东胜区",
              "lng": "109.963333",
              "lat": "39.822593",
              "level": "district"
            },
            {
              "value": 398,
              "parent_id": 396,
              "label": "康巴什区",
              "lng": "109.790076",
              "lat": "39.607472",
              "level": "district"
            },
            {
              "value": 399,
              "parent_id": 396,
              "label": "达拉特旗",
              "lng": "110.033833",
              "lat": "40.412438",
              "level": "district"
            },
            {
              "value": 400,
              "parent_id": 396,
              "label": "准格尔旗",
              "lng": "111.240171",
              "lat": "39.864361",
              "level": "district"
            },
            {
              "value": 401,
              "parent_id": 396,
              "label": "鄂托克前旗",
              "lng": "107.477514",
              "lat": "38.182362",
              "level": "district"
            },
            {
              "value": 402,
              "parent_id": 396,
              "label": "鄂托克旗",
              "lng": "107.97616",
              "lat": "39.08965",
              "level": "district"
            },
            {
              "value": 403,
              "parent_id": 396,
              "label": "杭锦旗",
              "lng": "108.736208",
              "lat": "39.833309",
              "level": "district"
            },
            {
              "value": 404,
              "parent_id": 396,
              "label": "乌审旗",
              "lng": "108.817607",
              "lat": "38.604136",
              "level": "district"
            },
            {
              "value": 405,
              "parent_id": 396,
              "label": "伊金霍洛旗",
              "lng": "109.74774",
              "lat": "39.564659",
              "level": "district"
            }
          ]
        },
        {
          "value": 406,
          "parent_id": 349,
          "label": "呼伦贝尔市",
          "lng": "119.765558",
          "lat": "49.211576",
          "level": "city",
          "children": [
            {
              "value": 407,
              "parent_id": 406,
              "label": "海拉尔区",
              "lng": "119.736176",
              "lat": "49.212188",
              "level": "district"
            },
            {
              "value": 408,
              "parent_id": 406,
              "label": "扎赉诺尔区",
              "lng": "117.670248",
              "lat": "49.510375",
              "level": "district"
            },
            {
              "value": 409,
              "parent_id": 406,
              "label": "阿荣旗",
              "lng": "123.459049",
              "lat": "48.126584",
              "level": "district"
            },
            {
              "value": 410,
              "parent_id": 406,
              "label": "莫力达瓦达斡尔族自治旗",
              "lng": "124.519023",
              "lat": "48.477728",
              "level": "district"
            },
            {
              "value": 411,
              "parent_id": 406,
              "label": "鄂伦春自治旗",
              "lng": "123.726201",
              "lat": "50.591842",
              "level": "district"
            },
            {
              "value": 412,
              "parent_id": 406,
              "label": "鄂温克族自治旗",
              "lng": "119.755239",
              "lat": "49.146592",
              "level": "district"
            },
            {
              "value": 413,
              "parent_id": 406,
              "label": "陈巴尔虎旗",
              "lng": "119.424026",
              "lat": "49.328916",
              "level": "district"
            },
            {
              "value": 414,
              "parent_id": 406,
              "label": "新巴尔虎左旗",
              "lng": "118.269819",
              "lat": "48.218241",
              "level": "district"
            },
            {
              "value": 415,
              "parent_id": 406,
              "label": "新巴尔虎右旗",
              "lng": "116.82369",
              "lat": "48.672101",
              "level": "district"
            },
            {
              "value": 416,
              "parent_id": 406,
              "label": "满洲里市",
              "lng": "117.378529",
              "lat": "49.597841",
              "level": "district"
            },
            {
              "value": 417,
              "parent_id": 406,
              "label": "牙克石市",
              "lng": "120.711775",
              "lat": "49.285629",
              "level": "district"
            },
            {
              "value": 418,
              "parent_id": 406,
              "label": "扎兰屯市",
              "lng": "122.737467",
              "lat": "48.013733",
              "level": "district"
            },
            {
              "value": 419,
              "parent_id": 406,
              "label": "额尔古纳市",
              "lng": "120.180506",
              "lat": "50.243102",
              "level": "district"
            },
            {
              "value": 420,
              "parent_id": 406,
              "label": "根河市",
              "lng": "121.520388",
              "lat": "50.780344",
              "level": "district"
            }
          ]
        },
        {
          "value": 421,
          "parent_id": 349,
          "label": "巴彦淖尔市",
          "lng": "107.387657",
          "lat": "40.743213",
          "level": "city",
          "children": [
            {
              "value": 422,
              "parent_id": 421,
              "label": "临河区",
              "lng": "107.363918",
              "lat": "40.751187",
              "level": "district"
            },
            {
              "value": 423,
              "parent_id": 421,
              "label": "五原县",
              "lng": "108.267561",
              "lat": "41.088421",
              "level": "district"
            },
            {
              "value": 424,
              "parent_id": 421,
              "label": "磴口县",
              "lng": "107.008248",
              "lat": "40.330523",
              "level": "district"
            },
            {
              "value": 425,
              "parent_id": 421,
              "label": "乌拉特前旗",
              "lng": "108.652114",
              "lat": "40.737018",
              "level": "district"
            },
            {
              "value": 426,
              "parent_id": 421,
              "label": "乌拉特中旗",
              "lng": "108.513645",
              "lat": "41.587732",
              "level": "district"
            },
            {
              "value": 427,
              "parent_id": 421,
              "label": "乌拉特后旗",
              "lng": "107.074621",
              "lat": "41.084282",
              "level": "district"
            },
            {
              "value": 428,
              "parent_id": 421,
              "label": "杭锦后旗",
              "lng": "107.151245",
              "lat": "40.88602",
              "level": "district"
            }
          ]
        },
        {
          "value": 429,
          "parent_id": 349,
          "label": "乌兰察布市",
          "lng": "113.132584",
          "lat": "40.994785",
          "level": "city",
          "children": [
            {
              "value": 430,
              "parent_id": 429,
              "label": "集宁区",
              "lng": "113.116453",
              "lat": "41.034134",
              "level": "district"
            },
            {
              "value": 431,
              "parent_id": 429,
              "label": "卓资县",
              "lng": "112.577528",
              "lat": "40.894691",
              "level": "district"
            },
            {
              "value": 432,
              "parent_id": 429,
              "label": "化德县",
              "lng": "114.010437",
              "lat": "41.90456",
              "level": "district"
            },
            {
              "value": 433,
              "parent_id": 429,
              "label": "商都县",
              "lng": "113.577816",
              "lat": "41.562113",
              "level": "district"
            },
            {
              "value": 434,
              "parent_id": 429,
              "label": "兴和县",
              "lng": "113.834173",
              "lat": "40.872301",
              "level": "district"
            },
            {
              "value": 435,
              "parent_id": 429,
              "label": "凉城县",
              "lng": "112.503971",
              "lat": "40.531555",
              "level": "district"
            },
            {
              "value": 436,
              "parent_id": 429,
              "label": "察哈尔右翼前旗",
              "lng": "113.214733",
              "lat": "40.785631",
              "level": "district"
            },
            {
              "value": 437,
              "parent_id": 429,
              "label": "察哈尔右翼中旗",
              "lng": "112.635577",
              "lat": "41.277462",
              "level": "district"
            },
            {
              "value": 438,
              "parent_id": 429,
              "label": "察哈尔右翼后旗",
              "lng": "113.191035",
              "lat": "41.436069",
              "level": "district"
            },
            {
              "value": 439,
              "parent_id": 429,
              "label": "四子王旗",
              "lng": "111.706617",
              "lat": "41.533462",
              "level": "district"
            },
            {
              "value": 440,
              "parent_id": 429,
              "label": "丰镇市",
              "lng": "113.109892",
              "lat": "40.436983",
              "level": "district"
            }
          ]
        },
        {
          "value": 441,
          "parent_id": 349,
          "label": "兴安盟",
          "lng": "122.037657",
          "lat": "46.082462",
          "level": "city",
          "children": [
            {
              "value": 442,
              "parent_id": 441,
              "label": "乌兰浩特市",
              "lng": "122.093123",
              "lat": "46.072731",
              "level": "district"
            },
            {
              "value": 443,
              "parent_id": 441,
              "label": "阿尔山市",
              "lng": "119.943575",
              "lat": "47.17744",
              "level": "district"
            },
            {
              "value": 444,
              "parent_id": 441,
              "label": "科尔沁右翼前旗",
              "lng": "121.952621",
              "lat": "46.079833",
              "level": "district"
            },
            {
              "value": 445,
              "parent_id": 441,
              "label": "科尔沁右翼中旗",
              "lng": "121.47653",
              "lat": "45.060837",
              "level": "district"
            },
            {
              "value": 446,
              "parent_id": 441,
              "label": "扎赉特旗",
              "lng": "122.899656",
              "lat": "46.723237",
              "level": "district"
            },
            {
              "value": 447,
              "parent_id": 441,
              "label": "突泉县",
              "lng": "121.593799",
              "lat": "45.38193",
              "level": "district"
            }
          ]
        },
        {
          "value": 448,
          "parent_id": 349,
          "label": "锡林郭勒盟",
          "lng": "116.048222",
          "lat": "43.933454",
          "level": "city",
          "children": [
            {
              "value": 449,
              "parent_id": 448,
              "label": "二连浩特市",
              "lng": "111.951002",
              "lat": "43.6437",
              "level": "district"
            },
            {
              "value": 450,
              "parent_id": 448,
              "label": "锡林浩特市",
              "lng": "116.086029",
              "lat": "43.933403",
              "level": "district"
            },
            {
              "value": 451,
              "parent_id": 448,
              "label": "阿巴嘎旗",
              "lng": "114.950248",
              "lat": "44.022995",
              "level": "district"
            },
            {
              "value": 452,
              "parent_id": 448,
              "label": "苏尼特左旗",
              "lng": "113.667248",
              "lat": "43.85988",
              "level": "district"
            },
            {
              "value": 453,
              "parent_id": 448,
              "label": "苏尼特右旗",
              "lng": "112.641783",
              "lat": "42.742892",
              "level": "district"
            },
            {
              "value": 454,
              "parent_id": 448,
              "label": "东乌珠穆沁旗",
              "lng": "116.974494",
              "lat": "45.498221",
              "level": "district"
            },
            {
              "value": 455,
              "parent_id": 448,
              "label": "西乌珠穆沁旗",
              "lng": "117.608911",
              "lat": "44.587882",
              "level": "district"
            },
            {
              "value": 456,
              "parent_id": 448,
              "label": "太仆寺旗",
              "lng": "115.282986",
              "lat": "41.877135",
              "level": "district"
            },
            {
              "value": 457,
              "parent_id": 448,
              "label": "镶黄旗",
              "lng": "113.847287",
              "lat": "42.232371",
              "level": "district"
            },
            {
              "value": 458,
              "parent_id": 448,
              "label": "正镶白旗",
              "lng": "115.029848",
              "lat": "42.28747",
              "level": "district"
            },
            {
              "value": 459,
              "parent_id": 448,
              "label": "正蓝旗",
              "lng": "115.99247",
              "lat": "42.241638",
              "level": "district"
            },
            {
              "value": 460,
              "parent_id": 448,
              "label": "多伦县",
              "lng": "116.485555",
              "lat": "42.203591",
              "level": "district"
            }
          ]
        },
        {
          "value": 461,
          "parent_id": 349,
          "label": "阿拉善盟",
          "lng": "105.728957",
          "lat": "38.851921",
          "level": "city",
          "children": [
            {
              "value": 462,
              "parent_id": 461,
              "label": "阿拉善左旗",
              "lng": "105.666275",
              "lat": "38.833389",
              "level": "district"
            },
            {
              "value": 463,
              "parent_id": 461,
              "label": "阿拉善右旗",
              "lng": "101.666917",
              "lat": "39.216185",
              "level": "district"
            },
            {
              "value": 464,
              "parent_id": 461,
              "label": "额济纳旗",
              "lng": "101.055731",
              "lat": "41.95455",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 465,
      "parent_id": 1,
      "label": "辽宁省",
      "lng": "123.431382",
      "lat": "41.836175",
      "level": "province",
      "children": [
        {
          "value": 466,
          "parent_id": 465,
          "label": "沈阳市",
          "lng": "123.465035",
          "lat": "41.677284",
          "level": "city",
          "children": [
            {
              "value": 467,
              "parent_id": 466,
              "label": "和平区",
              "lng": "123.420368",
              "lat": "41.789833",
              "level": "district"
            },
            {
              "value": 468,
              "parent_id": 466,
              "label": "沈河区",
              "lng": "123.458691",
              "lat": "41.796177",
              "level": "district"
            },
            {
              "value": 469,
              "parent_id": 466,
              "label": "大东区",
              "lng": "123.469948",
              "lat": "41.805137",
              "level": "district"
            },
            {
              "value": 470,
              "parent_id": 466,
              "label": "皇姑区",
              "lng": "123.442378",
              "lat": "41.824516",
              "level": "district"
            },
            {
              "value": 471,
              "parent_id": 466,
              "label": "铁西区",
              "lng": "123.333968",
              "lat": "41.820807",
              "level": "district"
            },
            {
              "value": 472,
              "parent_id": 466,
              "label": "苏家屯区",
              "lng": "123.344062",
              "lat": "41.664757",
              "level": "district"
            },
            {
              "value": 473,
              "parent_id": 466,
              "label": "浑南区",
              "lng": "123.449714",
              "lat": "41.714914",
              "level": "district"
            },
            {
              "value": 474,
              "parent_id": 466,
              "label": "沈北新区",
              "lng": "123.583196",
              "lat": "41.912487",
              "level": "district"
            },
            {
              "value": 475,
              "parent_id": 466,
              "label": "于洪区",
              "lng": "123.308119",
              "lat": "41.793721",
              "level": "district"
            },
            {
              "value": 476,
              "parent_id": 466,
              "label": "辽中区",
              "lng": "122.765409",
              "lat": "41.516826",
              "level": "district"
            },
            {
              "value": 477,
              "parent_id": 466,
              "label": "康平县",
              "lng": "123.343699",
              "lat": "42.72793",
              "level": "district"
            },
            {
              "value": 478,
              "parent_id": 466,
              "label": "法库县",
              "lng": "123.440294",
              "lat": "42.50108",
              "level": "district"
            },
            {
              "value": 479,
              "parent_id": 466,
              "label": "新民市",
              "lng": "122.836723",
              "lat": "41.985186",
              "level": "district"
            }
          ]
        },
        {
          "value": 480,
          "parent_id": 465,
          "label": "大连市",
          "lng": "121.614848",
          "lat": "38.914086",
          "level": "city",
          "children": [
            {
              "value": 481,
              "parent_id": 480,
              "label": "中山区",
              "lng": "121.644926",
              "lat": "38.918574",
              "level": "district"
            },
            {
              "value": 482,
              "parent_id": 480,
              "label": "西岗区",
              "lng": "121.612324",
              "lat": "38.914687",
              "level": "district"
            },
            {
              "value": 483,
              "parent_id": 480,
              "label": "沙河口区",
              "lng": "121.594297",
              "lat": "38.904788",
              "level": "district"
            },
            {
              "value": 484,
              "parent_id": 480,
              "label": "甘井子区",
              "lng": "121.525466",
              "lat": "38.953343",
              "level": "district"
            },
            {
              "value": 485,
              "parent_id": 480,
              "label": "旅顺口区",
              "lng": "121.261953",
              "lat": "38.851705",
              "level": "district"
            },
            {
              "value": 486,
              "parent_id": 480,
              "label": "金州区",
              "lng": "121.782655",
              "lat": "39.050001",
              "level": "district"
            },
            {
              "value": 487,
              "parent_id": 480,
              "label": "普兰店区",
              "lng": "121.938269",
              "lat": "39.392095",
              "level": "district"
            },
            {
              "value": 488,
              "parent_id": 480,
              "label": "长海县",
              "lng": "122.588494",
              "lat": "39.272728",
              "level": "district"
            },
            {
              "value": 489,
              "parent_id": 480,
              "label": "瓦房店市",
              "lng": "121.979543",
              "lat": "39.626897",
              "level": "district"
            },
            {
              "value": 490,
              "parent_id": 480,
              "label": "庄河市",
              "lng": "122.967424",
              "lat": "39.680843",
              "level": "district"
            }
          ]
        },
        {
          "value": 491,
          "parent_id": 465,
          "label": "鞍山市",
          "lng": "122.994329",
          "lat": "41.108647",
          "level": "city",
          "children": [
            {
              "value": 492,
              "parent_id": 491,
              "label": "铁东区",
              "lng": "122.991052",
              "lat": "41.089933",
              "level": "district"
            },
            {
              "value": 493,
              "parent_id": 491,
              "label": "铁西区",
              "lng": "122.969629",
              "lat": "41.119884",
              "level": "district"
            },
            {
              "value": 494,
              "parent_id": 491,
              "label": "立山区",
              "lng": "123.029091",
              "lat": "41.150401",
              "level": "district"
            },
            {
              "value": 495,
              "parent_id": 491,
              "label": "千山区",
              "lng": "122.944751",
              "lat": "41.068901",
              "level": "district"
            },
            {
              "value": 496,
              "parent_id": 491,
              "label": "台安县",
              "lng": "122.436196",
              "lat": "41.412767",
              "level": "district"
            },
            {
              "value": 497,
              "parent_id": 491,
              "label": "岫岩满族自治县",
              "lng": "123.280935",
              "lat": "40.29088",
              "level": "district"
            },
            {
              "value": 498,
              "parent_id": 491,
              "label": "海城市",
              "lng": "122.685217",
              "lat": "40.882377",
              "level": "district"
            }
          ]
        },
        {
          "value": 499,
          "parent_id": 465,
          "label": "抚顺市",
          "lng": "123.957208",
          "lat": "41.880872",
          "level": "city",
          "children": [
            {
              "value": 500,
              "parent_id": 499,
              "label": "新抚区",
              "lng": "123.912872",
              "lat": "41.862026",
              "level": "district"
            },
            {
              "value": 501,
              "parent_id": 499,
              "label": "东洲区",
              "lng": "124.038685",
              "lat": "41.853191",
              "level": "district"
            },
            {
              "value": 502,
              "parent_id": 499,
              "label": "望花区",
              "lng": "123.784225",
              "lat": "41.853641",
              "level": "district"
            },
            {
              "value": 503,
              "parent_id": 499,
              "label": "顺城区",
              "lng": "123.945075",
              "lat": "41.883235",
              "level": "district"
            },
            {
              "value": 504,
              "parent_id": 499,
              "label": "抚顺县",
              "lng": "124.097978",
              "lat": "41.922644",
              "level": "district"
            },
            {
              "value": 505,
              "parent_id": 499,
              "label": "新宾满族自治县",
              "lng": "125.039978",
              "lat": "41.734256",
              "level": "district"
            },
            {
              "value": 506,
              "parent_id": 499,
              "label": "清原满族自治县",
              "lng": "124.924083",
              "lat": "42.100538",
              "level": "district"
            }
          ]
        },
        {
          "value": 507,
          "parent_id": 465,
          "label": "本溪市",
          "lng": "123.685142",
          "lat": "41.486981",
          "level": "city",
          "children": [
            {
              "value": 508,
              "parent_id": 507,
              "label": "平山区",
              "lng": "123.769088",
              "lat": "41.299587",
              "level": "district"
            },
            {
              "value": 509,
              "parent_id": 507,
              "label": "溪湖区",
              "lng": "123.767646",
              "lat": "41.329219",
              "level": "district"
            },
            {
              "value": 510,
              "parent_id": 507,
              "label": "明山区",
              "lng": "123.817214",
              "lat": "41.308719",
              "level": "district"
            },
            {
              "value": 511,
              "parent_id": 507,
              "label": "南芬区",
              "lng": "123.744802",
              "lat": "41.100445",
              "level": "district"
            },
            {
              "value": 512,
              "parent_id": 507,
              "label": "本溪满族自治县",
              "lng": "124.120635",
              "lat": "41.302009",
              "level": "district"
            },
            {
              "value": 513,
              "parent_id": 507,
              "label": "桓仁满族自治县",
              "lng": "125.361007",
              "lat": "41.267127",
              "level": "district"
            }
          ]
        },
        {
          "value": 514,
          "parent_id": 465,
          "label": "丹东市",
          "lng": "124.35445",
          "lat": "40.000787",
          "level": "city",
          "children": [
            {
              "value": 515,
              "parent_id": 514,
              "label": "元宝区",
              "lng": "124.395661",
              "lat": "40.136434",
              "level": "district"
            },
            {
              "value": 516,
              "parent_id": 514,
              "label": "振兴区",
              "lng": "124.383237",
              "lat": "40.129944",
              "level": "district"
            },
            {
              "value": 517,
              "parent_id": 514,
              "label": "振安区",
              "lng": "124.470034",
              "lat": "40.201553",
              "level": "district"
            },
            {
              "value": 518,
              "parent_id": 514,
              "label": "宽甸满族自治县",
              "lng": "124.783659",
              "lat": "40.731316",
              "level": "district"
            },
            {
              "value": 519,
              "parent_id": 514,
              "label": "东港市",
              "lng": "124.152705",
              "lat": "39.863008",
              "level": "district"
            },
            {
              "value": 520,
              "parent_id": 514,
              "label": "凤城市",
              "lng": "124.066919",
              "lat": "40.452297",
              "level": "district"
            }
          ]
        },
        {
          "value": 521,
          "parent_id": 465,
          "label": "锦州市",
          "lng": "121.126846",
          "lat": "41.095685",
          "level": "city",
          "children": [
            {
              "value": 522,
              "parent_id": 521,
              "label": "古塔区",
              "lng": "121.128279",
              "lat": "41.117245",
              "level": "district"
            },
            {
              "value": 523,
              "parent_id": 521,
              "label": "凌河区",
              "lng": "121.150877",
              "lat": "41.114989",
              "level": "district"
            },
            {
              "value": 524,
              "parent_id": 521,
              "label": "太和区",
              "lng": "121.103892",
              "lat": "41.109147",
              "level": "district"
            },
            {
              "value": 525,
              "parent_id": 521,
              "label": "黑山县",
              "lng": "122.126292",
              "lat": "41.653593",
              "level": "district"
            },
            {
              "value": 526,
              "parent_id": 521,
              "label": "义县",
              "lng": "121.23908",
              "lat": "41.533086",
              "level": "district"
            },
            {
              "value": 527,
              "parent_id": 521,
              "label": "凌海市",
              "lng": "121.35549",
              "lat": "41.160567",
              "level": "district"
            },
            {
              "value": 528,
              "parent_id": 521,
              "label": "北镇市",
              "lng": "121.777395",
              "lat": "41.58844",
              "level": "district"
            }
          ]
        },
        {
          "value": 529,
          "parent_id": 465,
          "label": "营口市",
          "lng": "122.219458",
          "lat": "40.625364",
          "level": "city",
          "children": [
            {
              "value": 530,
              "parent_id": 529,
              "label": "站前区",
              "lng": "122.259033",
              "lat": "40.672563",
              "level": "district"
            },
            {
              "value": 531,
              "parent_id": 529,
              "label": "西市区",
              "lng": "122.206419",
              "lat": "40.666213",
              "level": "district"
            },
            {
              "value": 532,
              "parent_id": 529,
              "label": "鲅鱼圈区",
              "lng": "122.121521",
              "lat": "40.226661",
              "level": "district"
            },
            {
              "value": 533,
              "parent_id": 529,
              "label": "老边区",
              "lng": "122.380087",
              "lat": "40.680191",
              "level": "district"
            },
            {
              "value": 534,
              "parent_id": 529,
              "label": "盖州市",
              "lng": "122.349012",
              "lat": "40.40074",
              "level": "district"
            },
            {
              "value": 535,
              "parent_id": 529,
              "label": "大石桥市",
              "lng": "122.509006",
              "lat": "40.644482",
              "level": "district"
            }
          ]
        },
        {
          "value": 536,
          "parent_id": 465,
          "label": "阜新市",
          "lng": "121.670273",
          "lat": "42.021602",
          "level": "city",
          "children": [
            {
              "value": 537,
              "parent_id": 536,
              "label": "海州区",
              "lng": "121.657638",
              "lat": "42.011162",
              "level": "district"
            },
            {
              "value": 538,
              "parent_id": 536,
              "label": "新邱区",
              "lng": "121.792535",
              "lat": "42.087632",
              "level": "district"
            },
            {
              "value": 539,
              "parent_id": 536,
              "label": "太平区",
              "lng": "121.678604",
              "lat": "42.010669",
              "level": "district"
            },
            {
              "value": 540,
              "parent_id": 536,
              "label": "清河门区",
              "lng": "121.416105",
              "lat": "41.7831",
              "level": "district"
            },
            {
              "value": 541,
              "parent_id": 536,
              "label": "细河区",
              "lng": "121.68054",
              "lat": "42.025494",
              "level": "district"
            },
            {
              "value": 542,
              "parent_id": 536,
              "label": "阜新蒙古族自治县",
              "lng": "121.757901",
              "lat": "42.065175",
              "level": "district"
            },
            {
              "value": 543,
              "parent_id": 536,
              "label": "彰武县",
              "lng": "122.538793",
              "lat": "42.386543",
              "level": "district"
            }
          ]
        },
        {
          "value": 544,
          "parent_id": 465,
          "label": "辽阳市",
          "lng": "123.236974",
          "lat": "41.267794",
          "level": "city",
          "children": [
            {
              "value": 545,
              "parent_id": 544,
              "label": "白塔区",
              "lng": "123.174325",
              "lat": "41.270347",
              "level": "district"
            },
            {
              "value": 546,
              "parent_id": 544,
              "label": "文圣区",
              "lng": "123.231408",
              "lat": "41.283754",
              "level": "district"
            },
            {
              "value": 547,
              "parent_id": 544,
              "label": "宏伟区",
              "lng": "123.196672",
              "lat": "41.217649",
              "level": "district"
            },
            {
              "value": 548,
              "parent_id": 544,
              "label": "弓长岭区",
              "lng": "123.419803",
              "lat": "41.151847",
              "level": "district"
            },
            {
              "value": 549,
              "parent_id": 544,
              "label": "太子河区",
              "lng": "123.18144",
              "lat": "41.295023",
              "level": "district"
            },
            {
              "value": 550,
              "parent_id": 544,
              "label": "辽阳县",
              "lng": "123.105694",
              "lat": "41.205329",
              "level": "district"
            },
            {
              "value": 551,
              "parent_id": 544,
              "label": "灯塔市",
              "lng": "123.339312",
              "lat": "41.426372",
              "level": "district"
            }
          ]
        },
        {
          "value": 552,
          "parent_id": 465,
          "label": "盘锦市",
          "lng": "122.170584",
          "lat": "40.719847",
          "level": "city",
          "children": [
            {
              "value": 553,
              "parent_id": 552,
              "label": "双台子区",
              "lng": "122.039787",
              "lat": "41.19965",
              "level": "district"
            },
            {
              "value": 554,
              "parent_id": 552,
              "label": "兴隆台区",
              "lng": "122.070769",
              "lat": "41.119898",
              "level": "district"
            },
            {
              "value": 555,
              "parent_id": 552,
              "label": "大洼区",
              "lng": "122.082574",
              "lat": "41.002279",
              "level": "district"
            },
            {
              "value": 556,
              "parent_id": 552,
              "label": "盘山县",
              "lng": "121.996411",
              "lat": "41.242639",
              "level": "district"
            }
          ]
        },
        {
          "value": 557,
          "parent_id": 465,
          "label": "铁岭市",
          "lng": "123.726035",
          "lat": "42.223828",
          "level": "city",
          "children": [
            {
              "value": 558,
              "parent_id": 557,
              "label": "银州区",
              "lng": "123.842305",
              "lat": "42.286129",
              "level": "district"
            },
            {
              "value": 559,
              "parent_id": 557,
              "label": "清河区",
              "lng": "124.159191",
              "lat": "42.546565",
              "level": "district"
            },
            {
              "value": 560,
              "parent_id": 557,
              "label": "铁岭县",
              "lng": "123.728933",
              "lat": "42.223395",
              "level": "district"
            },
            {
              "value": 561,
              "parent_id": 557,
              "label": "西丰县",
              "lng": "124.727392",
              "lat": "42.73803",
              "level": "district"
            },
            {
              "value": 562,
              "parent_id": 557,
              "label": "昌图县",
              "lng": "124.111099",
              "lat": "42.785791",
              "level": "district"
            },
            {
              "value": 563,
              "parent_id": 557,
              "label": "调兵山市",
              "lng": "123.567117",
              "lat": "42.467521",
              "level": "district"
            },
            {
              "value": 564,
              "parent_id": 557,
              "label": "开原市",
              "lng": "124.038268",
              "lat": "42.546307",
              "level": "district"
            }
          ]
        },
        {
          "value": 565,
          "parent_id": 465,
          "label": "朝阳市",
          "lng": "120.450879",
          "lat": "41.573762",
          "level": "city",
          "children": [
            {
              "value": 566,
              "parent_id": 565,
              "label": "双塔区",
              "lng": "120.453744",
              "lat": "41.565627",
              "level": "district"
            },
            {
              "value": 567,
              "parent_id": 565,
              "label": "龙城区",
              "lng": "120.413376",
              "lat": "41.576749",
              "level": "district"
            },
            {
              "value": 568,
              "parent_id": 565,
              "label": "朝阳县",
              "lng": "120.389754",
              "lat": "41.497825",
              "level": "district"
            },
            {
              "value": 569,
              "parent_id": 565,
              "label": "建平县",
              "lng": "119.64328",
              "lat": "41.403128",
              "level": "district"
            },
            {
              "value": 570,
              "parent_id": 565,
              "label": "喀喇沁左翼蒙古族自治县",
              "lng": "119.741223",
              "lat": "41.12815",
              "level": "district"
            },
            {
              "value": 571,
              "parent_id": 565,
              "label": "北票市",
              "lng": "120.77073",
              "lat": "41.800683",
              "level": "district"
            },
            {
              "value": 572,
              "parent_id": 565,
              "label": "凌源市",
              "lng": "119.401574",
              "lat": "41.245445",
              "level": "district"
            }
          ]
        },
        {
          "value": 573,
          "parent_id": 465,
          "label": "葫芦岛市",
          "lng": "120.836939",
          "lat": "40.71104",
          "level": "city",
          "children": [
            {
              "value": 574,
              "parent_id": 573,
              "label": "连山区",
              "lng": "120.869231",
              "lat": "40.774461",
              "level": "district"
            },
            {
              "value": 575,
              "parent_id": 573,
              "label": "龙港区",
              "lng": "120.893786",
              "lat": "40.735519",
              "level": "district"
            },
            {
              "value": 576,
              "parent_id": 573,
              "label": "南票区",
              "lng": "120.749727",
              "lat": "41.107107",
              "level": "district"
            },
            {
              "value": 577,
              "parent_id": 573,
              "label": "绥中县",
              "lng": "120.344311",
              "lat": "40.32558",
              "level": "district"
            },
            {
              "value": 578,
              "parent_id": 573,
              "label": "建昌县",
              "lng": "119.837124",
              "lat": "40.824367",
              "level": "district"
            },
            {
              "value": 579,
              "parent_id": 573,
              "label": "兴城市",
              "lng": "120.756479",
              "lat": "40.609731",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 580,
      "parent_id": 1,
      "label": "吉林省",
      "lng": "125.32568",
      "lat": "43.897016",
      "level": "province",
      "children": [
        {
          "value": 581,
          "parent_id": 580,
          "label": "长春市",
          "lng": "125.323513",
          "lat": "43.817251",
          "level": "city",
          "children": [
            {
              "value": 582,
              "parent_id": 581,
              "label": "南关区",
              "lng": "125.350173",
              "lat": "43.863989",
              "level": "district"
            },
            {
              "value": 583,
              "parent_id": 581,
              "label": "宽城区",
              "lng": "125.326581",
              "lat": "43.943612",
              "level": "district"
            },
            {
              "value": 584,
              "parent_id": 581,
              "label": "朝阳区",
              "lng": "125.288254",
              "lat": "43.833762",
              "level": "district"
            },
            {
              "value": 585,
              "parent_id": 581,
              "label": "二道区",
              "lng": "125.374327",
              "lat": "43.865577",
              "level": "district"
            },
            {
              "value": 586,
              "parent_id": 581,
              "label": "绿园区",
              "lng": "125.256135",
              "lat": "43.880975",
              "level": "district"
            },
            {
              "value": 587,
              "parent_id": 581,
              "label": "双阳区",
              "lng": "125.664662",
              "lat": "43.525311",
              "level": "district"
            },
            {
              "value": 588,
              "parent_id": 581,
              "label": "九台区",
              "lng": "125.839573",
              "lat": "44.151742",
              "level": "district"
            },
            {
              "value": 589,
              "parent_id": 581,
              "label": "农安县",
              "lng": "125.184887",
              "lat": "44.432763",
              "level": "district"
            },
            {
              "value": 590,
              "parent_id": 581,
              "label": "榆树市",
              "lng": "126.533187",
              "lat": "44.840318",
              "level": "district"
            },
            {
              "value": 591,
              "parent_id": 581,
              "label": "德惠市",
              "lng": "125.728755",
              "lat": "44.522056",
              "level": "district"
            }
          ]
        },
        {
          "value": 592,
          "parent_id": 580,
          "label": "吉林市",
          "lng": "126.549572",
          "lat": "43.837883",
          "level": "city",
          "children": [
            {
              "value": 593,
              "parent_id": 592,
              "label": "昌邑区",
              "lng": "126.574709",
              "lat": "43.881818",
              "level": "district"
            },
            {
              "value": 594,
              "parent_id": 592,
              "label": "龙潭区",
              "lng": "126.562197",
              "lat": "43.910802",
              "level": "district"
            },
            {
              "value": 595,
              "parent_id": 592,
              "label": "船营区",
              "lng": "126.540966",
              "lat": "43.833445",
              "level": "district"
            },
            {
              "value": 596,
              "parent_id": 592,
              "label": "丰满区",
              "lng": "126.562274",
              "lat": "43.821601",
              "level": "district"
            },
            {
              "value": 597,
              "parent_id": 592,
              "label": "永吉县",
              "lng": "126.497741",
              "lat": "43.672582",
              "level": "district"
            },
            {
              "value": 598,
              "parent_id": 592,
              "label": "蛟河市",
              "lng": "127.344229",
              "lat": "43.724007",
              "level": "district"
            },
            {
              "value": 599,
              "parent_id": 592,
              "label": "桦甸市",
              "lng": "126.746309",
              "lat": "42.972096",
              "level": "district"
            },
            {
              "value": 600,
              "parent_id": 592,
              "label": "舒兰市",
              "lng": "126.965607",
              "lat": "44.406105",
              "level": "district"
            },
            {
              "value": 601,
              "parent_id": 592,
              "label": "磐石市",
              "lng": "126.060427",
              "lat": "42.946285",
              "level": "district"
            }
          ]
        },
        {
          "value": 602,
          "parent_id": 580,
          "label": "四平市",
          "lng": "124.350398",
          "lat": "43.166419",
          "level": "city",
          "children": [
            {
              "value": 603,
              "parent_id": 602,
              "label": "铁西区",
              "lng": "124.345722",
              "lat": "43.146155",
              "level": "district"
            },
            {
              "value": 604,
              "parent_id": 602,
              "label": "铁东区",
              "lng": "124.409591",
              "lat": "43.162105",
              "level": "district"
            },
            {
              "value": 605,
              "parent_id": 602,
              "label": "梨树县",
              "lng": "124.33539",
              "lat": "43.30706",
              "level": "district"
            },
            {
              "value": 606,
              "parent_id": 602,
              "label": "伊通满族自治县",
              "lng": "125.305393",
              "lat": "43.345754",
              "level": "district"
            },
            {
              "value": 607,
              "parent_id": 602,
              "label": "公主岭市",
              "lng": "124.822929",
              "lat": "43.504676",
              "level": "district"
            },
            {
              "value": 608,
              "parent_id": 602,
              "label": "双辽市",
              "lng": "123.502723",
              "lat": "43.518302",
              "level": "district"
            }
          ]
        },
        {
          "value": 609,
          "parent_id": 580,
          "label": "辽源市",
          "lng": "125.14366",
          "lat": "42.887766",
          "level": "city",
          "children": [
            {
              "value": 610,
              "parent_id": 609,
              "label": "龙山区",
              "lng": "125.136627",
              "lat": "42.90158",
              "level": "district"
            },
            {
              "value": 611,
              "parent_id": 609,
              "label": "西安区",
              "lng": "125.149281",
              "lat": "42.927324",
              "level": "district"
            },
            {
              "value": 612,
              "parent_id": 609,
              "label": "东丰县",
              "lng": "125.531021",
              "lat": "42.677371",
              "level": "district"
            },
            {
              "value": 613,
              "parent_id": 609,
              "label": "东辽县",
              "lng": "124.991424",
              "lat": "42.92625",
              "level": "district"
            }
          ]
        },
        {
          "value": 614,
          "parent_id": 580,
          "label": "通化市",
          "lng": "125.939697",
          "lat": "41.728401",
          "level": "city",
          "children": [
            {
              "value": 615,
              "parent_id": 614,
              "label": "东昌区",
              "lng": "125.927101",
              "lat": "41.702859",
              "level": "district"
            },
            {
              "value": 616,
              "parent_id": 614,
              "label": "二道江区",
              "lng": "126.042678",
              "lat": "41.774044",
              "level": "district"
            },
            {
              "value": 617,
              "parent_id": 614,
              "label": "通化县",
              "lng": "125.759259",
              "lat": "41.679808",
              "level": "district"
            },
            {
              "value": 618,
              "parent_id": 614,
              "label": "辉南县",
              "lng": "126.046783",
              "lat": "42.684921",
              "level": "district"
            },
            {
              "value": 619,
              "parent_id": 614,
              "label": "柳河县",
              "lng": "125.744735",
              "lat": "42.284605",
              "level": "district"
            },
            {
              "value": 620,
              "parent_id": 614,
              "label": "梅河口市",
              "lng": "125.710859",
              "lat": "42.539253",
              "level": "district"
            },
            {
              "value": 621,
              "parent_id": 614,
              "label": "集安市",
              "lng": "126.19403",
              "lat": "41.125307",
              "level": "district"
            }
          ]
        },
        {
          "value": 622,
          "parent_id": 580,
          "label": "白山市",
          "lng": "126.41473",
          "lat": "41.943972",
          "level": "city",
          "children": [
            {
              "value": 623,
              "parent_id": 622,
              "label": "浑江区",
              "lng": "126.416093",
              "lat": "41.945409",
              "level": "district"
            },
            {
              "value": 624,
              "parent_id": 622,
              "label": "江源区",
              "lng": "126.591178",
              "lat": "42.056747",
              "level": "district"
            },
            {
              "value": 625,
              "parent_id": 622,
              "label": "抚松县",
              "lng": "127.449763",
              "lat": "42.221207",
              "level": "district"
            },
            {
              "value": 626,
              "parent_id": 622,
              "label": "靖宇县",
              "lng": "126.813583",
              "lat": "42.388896",
              "level": "district"
            },
            {
              "value": 627,
              "parent_id": 622,
              "label": "长白朝鲜族自治县",
              "lng": "128.200789",
              "lat": "41.420018",
              "level": "district"
            },
            {
              "value": 628,
              "parent_id": 622,
              "label": "临江市",
              "lng": "126.918087",
              "lat": "41.811979",
              "level": "district"
            }
          ]
        },
        {
          "value": 629,
          "parent_id": 580,
          "label": "松原市",
          "lng": "124.825042",
          "lat": "45.141548",
          "level": "city",
          "children": [
            {
              "value": 630,
              "parent_id": 629,
              "label": "宁江区",
              "lng": "124.86562",
              "lat": "45.209915",
              "level": "district"
            },
            {
              "value": 631,
              "parent_id": 629,
              "label": "前郭尔罗斯蒙古族自治县",
              "lng": "124.823417",
              "lat": "45.118061",
              "level": "district"
            },
            {
              "value": 632,
              "parent_id": 629,
              "label": "长岭县",
              "lng": "123.967483",
              "lat": "44.275895",
              "level": "district"
            },
            {
              "value": 633,
              "parent_id": 629,
              "label": "乾安县",
              "lng": "124.041139",
              "lat": "45.003773",
              "level": "district"
            },
            {
              "value": 634,
              "parent_id": 629,
              "label": "扶余市",
              "lng": "126.049803",
              "lat": "44.9892",
              "level": "district"
            }
          ]
        },
        {
          "value": 635,
          "parent_id": 580,
          "label": "白城市",
          "lng": "122.838714",
          "lat": "45.619884",
          "level": "city",
          "children": [
            {
              "value": 636,
              "parent_id": 635,
              "label": "洮北区",
              "lng": "122.851029",
              "lat": "45.621716",
              "level": "district"
            },
            {
              "value": 637,
              "parent_id": 635,
              "label": "镇赉县",
              "lng": "123.199607",
              "lat": "45.84835",
              "level": "district"
            },
            {
              "value": 638,
              "parent_id": 635,
              "label": "通榆县",
              "lng": "123.088238",
              "lat": "44.81291",
              "level": "district"
            },
            {
              "value": 639,
              "parent_id": 635,
              "label": "洮南市",
              "lng": "122.798579",
              "lat": "45.356807",
              "level": "district"
            },
            {
              "value": 640,
              "parent_id": 635,
              "label": "大安市",
              "lng": "124.292626",
              "lat": "45.506996",
              "level": "district"
            }
          ]
        },
        {
          "value": 641,
          "parent_id": 580,
          "label": "延边朝鲜族自治州",
          "lng": "129.471868",
          "lat": "42.909408",
          "level": "city",
          "children": [
            {
              "value": 642,
              "parent_id": 641,
              "label": "延吉市",
              "lng": "129.508804",
              "lat": "42.89125",
              "level": "district"
            },
            {
              "value": 643,
              "parent_id": 641,
              "label": "图们市",
              "lng": "129.84371",
              "lat": "42.968044",
              "level": "district"
            },
            {
              "value": 644,
              "parent_id": 641,
              "label": "敦化市",
              "lng": "128.232131",
              "lat": "43.372642",
              "level": "district"
            },
            {
              "value": 645,
              "parent_id": 641,
              "label": "珲春市",
              "lng": "130.366036",
              "lat": "42.862821",
              "level": "district"
            },
            {
              "value": 646,
              "parent_id": 641,
              "label": "龙井市",
              "lng": "129.427066",
              "lat": "42.76631",
              "level": "district"
            },
            {
              "value": 647,
              "parent_id": 641,
              "label": "和龙市",
              "lng": "129.010106",
              "lat": "42.546675",
              "level": "district"
            },
            {
              "value": 648,
              "parent_id": 641,
              "label": "汪清县",
              "lng": "129.771607",
              "lat": "43.312522",
              "level": "district"
            },
            {
              "value": 649,
              "parent_id": 641,
              "label": "安图县",
              "lng": "128.899772",
              "lat": "43.11195",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 650,
      "parent_id": 1,
      "label": "黑龙江省",
      "lng": "126.661665",
      "lat": "45.742366",
      "level": "province",
      "children": [
        {
          "value": 651,
          "parent_id": 650,
          "label": "哈尔滨市",
          "lng": "126.534967",
          "lat": "45.803775",
          "level": "city",
          "children": [
            {
              "value": 652,
              "parent_id": 651,
              "label": "道里区",
              "lng": "126.616973",
              "lat": "45.75577",
              "level": "district"
            },
            {
              "value": 653,
              "parent_id": 651,
              "label": "南岗区",
              "lng": "126.668784",
              "lat": "45.760174",
              "level": "district"
            },
            {
              "value": 654,
              "parent_id": 651,
              "label": "道外区",
              "lng": "126.64939",
              "lat": "45.792057",
              "level": "district"
            },
            {
              "value": 655,
              "parent_id": 651,
              "label": "平房区",
              "lng": "126.637611",
              "lat": "45.597911",
              "level": "district"
            },
            {
              "value": 656,
              "parent_id": 651,
              "label": "松北区",
              "lng": "126.516914",
              "lat": "45.794504",
              "level": "district"
            },
            {
              "value": 657,
              "parent_id": 651,
              "label": "香坊区",
              "lng": "126.662593",
              "lat": "45.707716",
              "level": "district"
            },
            {
              "value": 658,
              "parent_id": 651,
              "label": "呼兰区",
              "lng": "126.587905",
              "lat": "45.889457",
              "level": "district"
            },
            {
              "value": 659,
              "parent_id": 651,
              "label": "阿城区",
              "lng": "126.958098",
              "lat": "45.548669",
              "level": "district"
            },
            {
              "value": 660,
              "parent_id": 651,
              "label": "双城区",
              "lng": "126.312624",
              "lat": "45.383218",
              "level": "district"
            },
            {
              "value": 661,
              "parent_id": 651,
              "label": "依兰县",
              "lng": "129.567877",
              "lat": "46.325419",
              "level": "district"
            },
            {
              "value": 662,
              "parent_id": 651,
              "label": "方正县",
              "lng": "128.829536",
              "lat": "45.851694",
              "level": "district"
            },
            {
              "value": 663,
              "parent_id": 651,
              "label": "宾县",
              "lng": "127.466634",
              "lat": "45.745917",
              "level": "district"
            },
            {
              "value": 664,
              "parent_id": 651,
              "label": "巴彦县",
              "lng": "127.403781",
              "lat": "46.086549",
              "level": "district"
            },
            {
              "value": 665,
              "parent_id": 651,
              "label": "木兰县",
              "lng": "128.043466",
              "lat": "45.950582",
              "level": "district"
            },
            {
              "value": 666,
              "parent_id": 651,
              "label": "通河县",
              "lng": "128.746124",
              "lat": "45.990205",
              "level": "district"
            },
            {
              "value": 667,
              "parent_id": 651,
              "label": "延寿县",
              "lng": "128.331643",
              "lat": "45.451897",
              "level": "district"
            },
            {
              "value": 668,
              "parent_id": 651,
              "label": "尚志市",
              "lng": "128.009894",
              "lat": "45.209586",
              "level": "district"
            },
            {
              "value": 669,
              "parent_id": 651,
              "label": "五常市",
              "lng": "127.167618",
              "lat": "44.931991",
              "level": "district"
            }
          ]
        },
        {
          "value": 670,
          "parent_id": 650,
          "label": "齐齐哈尔市",
          "lng": "123.918186",
          "lat": "47.354348",
          "level": "city",
          "children": [
            {
              "value": 671,
              "parent_id": 670,
              "label": "龙沙区",
              "lng": "123.957531",
              "lat": "47.317308",
              "level": "district"
            },
            {
              "value": 672,
              "parent_id": 670,
              "label": "建华区",
              "lng": "123.955464",
              "lat": "47.354364",
              "level": "district"
            },
            {
              "value": 673,
              "parent_id": 670,
              "label": "铁锋区",
              "lng": "123.978293",
              "lat": "47.340517",
              "level": "district"
            },
            {
              "value": 674,
              "parent_id": 670,
              "label": "昂昂溪区",
              "lng": "123.8224",
              "lat": "47.15516",
              "level": "district"
            },
            {
              "value": 675,
              "parent_id": 670,
              "label": "富拉尔基区",
              "lng": "123.629189",
              "lat": "47.208843",
              "level": "district"
            },
            {
              "value": 676,
              "parent_id": 670,
              "label": "碾子山区",
              "lng": "122.887775",
              "lat": "47.516872",
              "level": "district"
            },
            {
              "value": 677,
              "parent_id": 670,
              "label": "梅里斯达斡尔族区",
              "lng": "123.75291",
              "lat": "47.309537",
              "level": "district"
            },
            {
              "value": 678,
              "parent_id": 670,
              "label": "龙江县",
              "lng": "123.205323",
              "lat": "47.338665",
              "level": "district"
            },
            {
              "value": 679,
              "parent_id": 670,
              "label": "依安县",
              "lng": "125.306278",
              "lat": "47.893548",
              "level": "district"
            },
            {
              "value": 680,
              "parent_id": 670,
              "label": "泰来县",
              "lng": "123.416631",
              "lat": "46.393694",
              "level": "district"
            },
            {
              "value": 681,
              "parent_id": 670,
              "label": "甘南县",
              "lng": "123.507429",
              "lat": "47.922405",
              "level": "district"
            },
            {
              "value": 682,
              "parent_id": 670,
              "label": "富裕县",
              "lng": "124.473793",
              "lat": "47.774347",
              "level": "district"
            },
            {
              "value": 683,
              "parent_id": 670,
              "label": "克山县",
              "lng": "125.875705",
              "lat": "48.037031",
              "level": "district"
            },
            {
              "value": 684,
              "parent_id": 670,
              "label": "克东县",
              "lng": "126.24872",
              "lat": "48.04206",
              "level": "district"
            },
            {
              "value": 685,
              "parent_id": 670,
              "label": "拜泉县",
              "lng": "126.100213",
              "lat": "47.595851",
              "level": "district"
            },
            {
              "value": 686,
              "parent_id": 670,
              "label": "讷河市",
              "lng": "124.88287",
              "lat": "48.466592",
              "level": "district"
            }
          ]
        },
        {
          "value": 687,
          "parent_id": 650,
          "label": "鸡西市",
          "lng": "130.969333",
          "lat": "45.295075",
          "level": "city",
          "children": [
            {
              "value": 688,
              "parent_id": 687,
              "label": "鸡冠区",
              "lng": "130.981185",
              "lat": "45.304355",
              "level": "district"
            },
            {
              "value": 689,
              "parent_id": 687,
              "label": "恒山区",
              "lng": "130.904963",
              "lat": "45.210668",
              "level": "district"
            },
            {
              "value": 690,
              "parent_id": 687,
              "label": "滴道区",
              "lng": "130.843613",
              "lat": "45.348763",
              "level": "district"
            },
            {
              "value": 691,
              "parent_id": 687,
              "label": "梨树区",
              "lng": "130.69699",
              "lat": "45.092046",
              "level": "district"
            },
            {
              "value": 692,
              "parent_id": 687,
              "label": "城子河区",
              "lng": "131.011304",
              "lat": "45.33697",
              "level": "district"
            },
            {
              "value": 693,
              "parent_id": 687,
              "label": "麻山区",
              "lng": "130.478187",
              "lat": "45.212088",
              "level": "district"
            },
            {
              "value": 694,
              "parent_id": 687,
              "label": "鸡东县",
              "lng": "131.124079",
              "lat": "45.260412",
              "level": "district"
            },
            {
              "value": 695,
              "parent_id": 687,
              "label": "虎林市",
              "lng": "132.93721",
              "lat": "45.762685",
              "level": "district"
            },
            {
              "value": 696,
              "parent_id": 687,
              "label": "密山市",
              "lng": "131.846635",
              "lat": "45.529774",
              "level": "district"
            }
          ]
        },
        {
          "value": 697,
          "parent_id": 650,
          "label": "鹤岗市",
          "lng": "130.297943",
          "lat": "47.350189",
          "level": "city",
          "children": [
            {
              "value": 698,
              "parent_id": 697,
              "label": "向阳区",
              "lng": "130.294235",
              "lat": "47.342468",
              "level": "district"
            },
            {
              "value": 699,
              "parent_id": 697,
              "label": "工农区",
              "lng": "130.274684",
              "lat": "47.31878",
              "level": "district"
            },
            {
              "value": 700,
              "parent_id": 697,
              "label": "南山区",
              "lng": "130.286788",
              "lat": "47.315174",
              "level": "district"
            },
            {
              "value": 701,
              "parent_id": 697,
              "label": "兴安区",
              "lng": "130.239245",
              "lat": "47.252849",
              "level": "district"
            },
            {
              "value": 702,
              "parent_id": 697,
              "label": "东山区",
              "lng": "130.317002",
              "lat": "47.338537",
              "level": "district"
            },
            {
              "value": 703,
              "parent_id": 697,
              "label": "兴山区",
              "lng": "130.303481",
              "lat": "47.357702",
              "level": "district"
            },
            {
              "value": 704,
              "parent_id": 697,
              "label": "萝北县",
              "lng": "130.85155",
              "lat": "47.576444",
              "level": "district"
            },
            {
              "value": 705,
              "parent_id": 697,
              "label": "绥滨县",
              "lng": "131.852759",
              "lat": "47.289115",
              "level": "district"
            }
          ]
        },
        {
          "value": 706,
          "parent_id": 650,
          "label": "双鸭山市",
          "lng": "131.141195",
          "lat": "46.676418",
          "level": "city",
          "children": [
            {
              "value": 707,
              "parent_id": 706,
              "label": "尖山区",
              "lng": "131.158415",
              "lat": "46.64635",
              "level": "district"
            },
            {
              "value": 708,
              "parent_id": 706,
              "label": "岭东区",
              "lng": "131.164723",
              "lat": "46.592721",
              "level": "district"
            },
            {
              "value": 709,
              "parent_id": 706,
              "label": "四方台区",
              "lng": "131.337592",
              "lat": "46.597264",
              "level": "district"
            },
            {
              "value": 710,
              "parent_id": 706,
              "label": "宝山区",
              "lng": "131.401589",
              "lat": "46.577167",
              "level": "district"
            },
            {
              "value": 711,
              "parent_id": 706,
              "label": "集贤县",
              "lng": "131.141311",
              "lat": "46.728412",
              "level": "district"
            },
            {
              "value": 712,
              "parent_id": 706,
              "label": "友谊县",
              "lng": "131.808063",
              "lat": "46.767299",
              "level": "district"
            },
            {
              "value": 713,
              "parent_id": 706,
              "label": "宝清县",
              "lng": "132.196853",
              "lat": "46.327457",
              "level": "district"
            },
            {
              "value": 714,
              "parent_id": 706,
              "label": "饶河县",
              "lng": "134.013872",
              "lat": "46.798163",
              "level": "district"
            }
          ]
        },
        {
          "value": 715,
          "parent_id": 650,
          "label": "大庆市",
          "lng": "125.103784",
          "lat": "46.589309",
          "level": "city",
          "children": [
            {
              "value": 716,
              "parent_id": 715,
              "label": "萨尔图区",
              "lng": "125.135591",
              "lat": "46.629092",
              "level": "district"
            },
            {
              "value": 717,
              "parent_id": 715,
              "label": "龙凤区",
              "lng": "125.135326",
              "lat": "46.562247",
              "level": "district"
            },
            {
              "value": 718,
              "parent_id": 715,
              "label": "让胡路区",
              "lng": "124.870596",
              "lat": "46.652357",
              "level": "district"
            },
            {
              "value": 719,
              "parent_id": 715,
              "label": "红岗区",
              "lng": "124.891039",
              "lat": "46.398418",
              "level": "district"
            },
            {
              "value": 720,
              "parent_id": 715,
              "label": "大同区",
              "lng": "124.812364",
              "lat": "46.039827",
              "level": "district"
            },
            {
              "value": 721,
              "parent_id": 715,
              "label": "肇州县",
              "lng": "125.268643",
              "lat": "45.699066",
              "level": "district"
            },
            {
              "value": 722,
              "parent_id": 715,
              "label": "肇源县",
              "lng": "125.078223",
              "lat": "45.51932",
              "level": "district"
            },
            {
              "value": 723,
              "parent_id": 715,
              "label": "林甸县",
              "lng": "124.863603",
              "lat": "47.171717",
              "level": "district"
            },
            {
              "value": 724,
              "parent_id": 715,
              "label": "杜尔伯特蒙古族自治县",
              "lng": "124.442572",
              "lat": "46.862817",
              "level": "district"
            }
          ]
        },
        {
          "value": 725,
          "parent_id": 650,
          "label": "伊春市",
          "lng": "128.841125",
          "lat": "47.727535",
          "level": "city",
          "children": [
            {
              "value": 726,
              "parent_id": 725,
              "label": "伊春区",
              "lng": "128.907257",
              "lat": "47.728237",
              "level": "district"
            },
            {
              "value": 727,
              "parent_id": 725,
              "label": "南岔区",
              "lng": "129.283467",
              "lat": "47.138034",
              "level": "district"
            },
            {
              "value": 728,
              "parent_id": 725,
              "label": "友好区",
              "lng": "128.836291",
              "lat": "47.841032",
              "level": "district"
            },
            {
              "value": 729,
              "parent_id": 725,
              "label": "西林区",
              "lng": "129.312851",
              "lat": "47.480735",
              "level": "district"
            },
            {
              "value": 730,
              "parent_id": 725,
              "label": "翠峦区",
              "lng": "128.669754",
              "lat": "47.726394",
              "level": "district"
            },
            {
              "value": 731,
              "parent_id": 725,
              "label": "新青区",
              "lng": "129.533599",
              "lat": "48.290455",
              "level": "district"
            },
            {
              "value": 732,
              "parent_id": 725,
              "label": "美溪区",
              "lng": "129.129314",
              "lat": "47.63509",
              "level": "district"
            },
            {
              "value": 733,
              "parent_id": 725,
              "label": "金山屯区",
              "lng": "129.429117",
              "lat": "47.413074",
              "level": "district"
            },
            {
              "value": 734,
              "parent_id": 725,
              "label": "五营区",
              "lng": "129.245343",
              "lat": "48.10791",
              "level": "district"
            },
            {
              "value": 735,
              "parent_id": 725,
              "label": "乌马河区",
              "lng": "128.799477",
              "lat": "47.727687",
              "level": "district"
            },
            {
              "value": 736,
              "parent_id": 725,
              "label": "汤旺河区",
              "lng": "129.571108",
              "lat": "48.454651",
              "level": "district"
            },
            {
              "value": 737,
              "parent_id": 725,
              "label": "带岭区",
              "lng": "129.020888",
              "lat": "47.028379",
              "level": "district"
            },
            {
              "value": 738,
              "parent_id": 725,
              "label": "乌伊岭区",
              "lng": "129.43792",
              "lat": "48.590322",
              "level": "district"
            },
            {
              "value": 739,
              "parent_id": 725,
              "label": "红星区",
              "lng": "129.390983",
              "lat": "48.239431",
              "level": "district"
            },
            {
              "value": 740,
              "parent_id": 725,
              "label": "上甘岭区",
              "lng": "129.02426",
              "lat": "47.974707",
              "level": "district"
            },
            {
              "value": 741,
              "parent_id": 725,
              "label": "嘉荫县",
              "lng": "130.403134",
              "lat": "48.888972",
              "level": "district"
            },
            {
              "value": 742,
              "parent_id": 725,
              "label": "铁力市",
              "lng": "128.032424",
              "lat": "46.986633",
              "level": "district"
            }
          ]
        },
        {
          "value": 743,
          "parent_id": 650,
          "label": "佳木斯市",
          "lng": "130.318878",
          "lat": "46.799777",
          "level": "city",
          "children": [
            {
              "value": 744,
              "parent_id": 743,
              "label": "向阳区",
              "lng": "130.365346",
              "lat": "46.80779",
              "level": "district"
            },
            {
              "value": 745,
              "parent_id": 743,
              "label": "前进区",
              "lng": "130.375062",
              "lat": "46.814102",
              "level": "district"
            },
            {
              "value": 746,
              "parent_id": 743,
              "label": "东风区",
              "lng": "130.403664",
              "lat": "46.822571",
              "level": "district"
            },
            {
              "value": 747,
              "parent_id": 743,
              "label": "郊区",
              "lng": "130.327194",
              "lat": "46.810085",
              "level": "district"
            },
            {
              "value": 748,
              "parent_id": 743,
              "label": "桦南县",
              "lng": "130.553343",
              "lat": "46.239184",
              "level": "district"
            },
            {
              "value": 749,
              "parent_id": 743,
              "label": "桦川县",
              "lng": "130.71908",
              "lat": "47.023001",
              "level": "district"
            },
            {
              "value": 750,
              "parent_id": 743,
              "label": "汤原县",
              "lng": "129.905072",
              "lat": "46.730706",
              "level": "district"
            },
            {
              "value": 751,
              "parent_id": 743,
              "label": "同江市",
              "lng": "132.510919",
              "lat": "47.642707",
              "level": "district"
            },
            {
              "value": 752,
              "parent_id": 743,
              "label": "富锦市",
              "lng": "132.037686",
              "lat": "47.250107",
              "level": "district"
            },
            {
              "value": 753,
              "parent_id": 743,
              "label": "抚远市",
              "lng": "134.307884",
              "lat": "48.364687",
              "level": "district"
            }
          ]
        },
        {
          "value": 754,
          "parent_id": 650,
          "label": "七台河市",
          "lng": "131.003082",
          "lat": "45.771396",
          "level": "city",
          "children": [
            {
              "value": 755,
              "parent_id": 754,
              "label": "新兴区",
              "lng": "130.932143",
              "lat": "45.81593",
              "level": "district"
            },
            {
              "value": 756,
              "parent_id": 754,
              "label": "桃山区",
              "lng": "131.020202",
              "lat": "45.765705",
              "level": "district"
            },
            {
              "value": 757,
              "parent_id": 754,
              "label": "茄子河区",
              "lng": "131.068075",
              "lat": "45.785215",
              "level": "district"
            },
            {
              "value": 758,
              "parent_id": 754,
              "label": "勃利县",
              "lng": "130.59217",
              "lat": "45.755063",
              "level": "district"
            }
          ]
        },
        {
          "value": 759,
          "parent_id": 650,
          "label": "牡丹江市",
          "lng": "129.633168",
          "lat": "44.551653",
          "level": "city",
          "children": [
            {
              "value": 760,
              "parent_id": 759,
              "label": "东安区",
              "lng": "129.626641",
              "lat": "44.58136",
              "level": "district"
            },
            {
              "value": 761,
              "parent_id": 759,
              "label": "阳明区",
              "lng": "129.635615",
              "lat": "44.596104",
              "level": "district"
            },
            {
              "value": 762,
              "parent_id": 759,
              "label": "爱民区",
              "lng": "129.591537",
              "lat": "44.596042",
              "level": "district"
            },
            {
              "value": 763,
              "parent_id": 759,
              "label": "西安区",
              "lng": "129.616058",
              "lat": "44.577625",
              "level": "district"
            },
            {
              "value": 764,
              "parent_id": 759,
              "label": "林口县",
              "lng": "130.284033",
              "lat": "45.278046",
              "level": "district"
            },
            {
              "value": 765,
              "parent_id": 759,
              "label": "绥芬河市",
              "lng": "131.152545",
              "lat": "44.412308",
              "level": "district"
            },
            {
              "value": 766,
              "parent_id": 759,
              "label": "海林市",
              "lng": "129.380481",
              "lat": "44.594213",
              "level": "district"
            },
            {
              "value": 767,
              "parent_id": 759,
              "label": "宁安市",
              "lng": "129.482851",
              "lat": "44.34072",
              "level": "district"
            },
            {
              "value": 768,
              "parent_id": 759,
              "label": "穆棱市",
              "lng": "130.524436",
              "lat": "44.918813",
              "level": "district"
            },
            {
              "value": 769,
              "parent_id": 759,
              "label": "东宁市",
              "lng": "131.122915",
              "lat": "44.087585",
              "level": "district"
            }
          ]
        },
        {
          "value": 770,
          "parent_id": 650,
          "label": "黑河市",
          "lng": "127.528293",
          "lat": "50.245129",
          "level": "city",
          "children": [
            {
              "value": 771,
              "parent_id": 770,
              "label": "爱辉区",
              "lng": "127.50045",
              "lat": "50.252105",
              "level": "district"
            },
            {
              "value": 772,
              "parent_id": 770,
              "label": "嫩江县",
              "lng": "125.221192",
              "lat": "49.185766",
              "level": "district"
            },
            {
              "value": 773,
              "parent_id": 770,
              "label": "逊克县",
              "lng": "128.478749",
              "lat": "49.564252",
              "level": "district"
            },
            {
              "value": 774,
              "parent_id": 770,
              "label": "孙吴县",
              "lng": "127.336303",
              "lat": "49.425647",
              "level": "district"
            },
            {
              "value": 775,
              "parent_id": 770,
              "label": "北安市",
              "lng": "126.490864",
              "lat": "48.241365",
              "level": "district"
            },
            {
              "value": 776,
              "parent_id": 770,
              "label": "五大连池市",
              "lng": "126.205516",
              "lat": "48.517257",
              "level": "district"
            }
          ]
        },
        {
          "value": 777,
          "parent_id": 650,
          "label": "绥化市",
          "lng": "126.968887",
          "lat": "46.653845",
          "level": "city",
          "children": [
            {
              "value": 778,
              "parent_id": 777,
              "label": "北林区",
              "lng": "126.985504",
              "lat": "46.6375",
              "level": "district"
            },
            {
              "value": 779,
              "parent_id": 777,
              "label": "望奎县",
              "lng": "126.486075",
              "lat": "46.832719",
              "level": "district"
            },
            {
              "value": 780,
              "parent_id": 777,
              "label": "兰西县",
              "lng": "126.288117",
              "lat": "46.25245",
              "level": "district"
            },
            {
              "value": 781,
              "parent_id": 777,
              "label": "青冈县",
              "lng": "126.099195",
              "lat": "46.70391",
              "level": "district"
            },
            {
              "value": 782,
              "parent_id": 777,
              "label": "庆安县",
              "lng": "127.507824",
              "lat": "46.880102",
              "level": "district"
            },
            {
              "value": 783,
              "parent_id": 777,
              "label": "明水县",
              "lng": "125.906301",
              "lat": "47.173426",
              "level": "district"
            },
            {
              "value": 784,
              "parent_id": 777,
              "label": "绥棱县",
              "lng": "127.114832",
              "lat": "47.236015",
              "level": "district"
            },
            {
              "value": 785,
              "parent_id": 777,
              "label": "安达市",
              "lng": "125.346156",
              "lat": "46.419633",
              "level": "district"
            },
            {
              "value": 786,
              "parent_id": 777,
              "label": "肇东市",
              "lng": "125.961814",
              "lat": "46.051126",
              "level": "district"
            },
            {
              "value": 787,
              "parent_id": 777,
              "label": "海伦市",
              "lng": "126.930106",
              "lat": "47.45117",
              "level": "district"
            }
          ]
        },
        {
          "value": 788,
          "parent_id": 650,
          "label": "大兴安岭地区",
          "lng": "124.711526",
          "lat": "52.335262",
          "level": "city",
          "children": [
            {
              "value": 789,
              "parent_id": 788,
              "label": "加格达奇区",
              "lng": "124.139595",
              "lat": "50.408735",
              "level": "district"
            },
            {
              "value": 790,
              "parent_id": 788,
              "label": "呼玛县",
              "lng": "126.652396",
              "lat": "51.726091",
              "level": "district"
            },
            {
              "value": 791,
              "parent_id": 788,
              "label": "塔河县",
              "lng": "124.709996",
              "lat": "52.334456",
              "level": "district"
            },
            {
              "value": 792,
              "parent_id": 788,
              "label": "漠河县",
              "lng": "122.538591",
              "lat": "52.972272",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 793,
      "parent_id": 1,
      "label": "上海市",
      "lng": "121.473662",
      "lat": "31.230372",
      "level": "province",
      "children": [
        {
          "value": 794,
          "parent_id": 793,
          "label": "上海市",
          "lng": "121.473662",
          "lat": "31.230372",
          "level": "city",
          "children": [
            {
              "value": 795,
              "parent_id": 794,
              "label": "黄浦区",
              "lng": "121.484428",
              "lat": "31.231739",
              "level": "district"
            },
            {
              "value": 796,
              "parent_id": 794,
              "label": "徐汇区",
              "lng": "121.436128",
              "lat": "31.188464",
              "level": "district"
            },
            {
              "value": 797,
              "parent_id": 794,
              "label": "长宁区",
              "lng": "121.424622",
              "lat": "31.220372",
              "level": "district"
            },
            {
              "value": 798,
              "parent_id": 794,
              "label": "静安区",
              "lng": "121.447453",
              "lat": "31.227906",
              "level": "district"
            },
            {
              "value": 799,
              "parent_id": 794,
              "label": "普陀区",
              "lng": "121.395514",
              "lat": "31.249603",
              "level": "district"
            },
            {
              "value": 800,
              "parent_id": 794,
              "label": "虹口区",
              "lng": "121.505133",
              "lat": "31.2646",
              "level": "district"
            },
            {
              "value": 801,
              "parent_id": 794,
              "label": "杨浦区",
              "lng": "121.525727",
              "lat": "31.259822",
              "level": "district"
            },
            {
              "value": 802,
              "parent_id": 794,
              "label": "闵行区",
              "lng": "121.380831",
              "lat": "31.1129",
              "level": "district"
            },
            {
              "value": 803,
              "parent_id": 794,
              "label": "宝山区",
              "lng": "121.489612",
              "lat": "31.405457",
              "level": "district"
            },
            {
              "value": 804,
              "parent_id": 794,
              "label": "嘉定区",
              "lng": "121.265374",
              "lat": "31.375869",
              "level": "district"
            },
            {
              "value": 805,
              "parent_id": 794,
              "label": "浦东新区",
              "lng": "121.544379",
              "lat": "31.221517",
              "level": "district"
            },
            {
              "value": 806,
              "parent_id": 794,
              "label": "金山区",
              "lng": "121.342455",
              "lat": "30.741798",
              "level": "district"
            },
            {
              "value": 807,
              "parent_id": 794,
              "label": "松江区",
              "lng": "121.227747",
              "lat": "31.032243",
              "level": "district"
            },
            {
              "value": 808,
              "parent_id": 794,
              "label": "青浦区",
              "lng": "121.124178",
              "lat": "31.150681",
              "level": "district"
            },
            {
              "value": 809,
              "parent_id": 794,
              "label": "奉贤区",
              "lng": "121.474055",
              "lat": "30.917766",
              "level": "district"
            },
            {
              "value": 810,
              "parent_id": 794,
              "label": "崇明区",
              "lng": "121.397421",
              "lat": "31.623728",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 811,
      "parent_id": 1,
      "label": "江苏省",
      "lng": "118.762765",
      "lat": "32.060875",
      "level": "province",
      "children": [
        {
          "value": 812,
          "parent_id": 811,
          "label": "南京市",
          "lng": "118.796682",
          "lat": "32.05957",
          "level": "city",
          "children": [
            {
              "value": 813,
              "parent_id": 812,
              "label": "玄武区",
              "lng": "118.797757",
              "lat": "32.048498",
              "level": "district"
            },
            {
              "value": 814,
              "parent_id": 812,
              "label": "秦淮区",
              "lng": "118.79476",
              "lat": "32.039113",
              "level": "district"
            },
            {
              "value": 815,
              "parent_id": 812,
              "label": "建邺区",
              "lng": "118.731793",
              "lat": "32.003731",
              "level": "district"
            },
            {
              "value": 816,
              "parent_id": 812,
              "label": "鼓楼区",
              "lng": "118.770182",
              "lat": "32.066601",
              "level": "district"
            },
            {
              "value": 817,
              "parent_id": 812,
              "label": "浦口区",
              "lng": "118.628003",
              "lat": "32.058903",
              "level": "district"
            },
            {
              "value": 818,
              "parent_id": 812,
              "label": "栖霞区",
              "lng": "118.909153",
              "lat": "32.096388",
              "level": "district"
            },
            {
              "value": 819,
              "parent_id": 812,
              "label": "雨花台区",
              "lng": "118.779051",
              "lat": "31.99126",
              "level": "district"
            },
            {
              "value": 820,
              "parent_id": 812,
              "label": "江宁区",
              "lng": "118.840015",
              "lat": "31.952612",
              "level": "district"
            },
            {
              "value": 821,
              "parent_id": 812,
              "label": "六合区",
              "lng": "118.822132",
              "lat": "32.323584",
              "level": "district"
            },
            {
              "value": 822,
              "parent_id": 812,
              "label": "溧水区",
              "lng": "119.028288",
              "lat": "31.651099",
              "level": "district"
            },
            {
              "value": 823,
              "parent_id": 812,
              "label": "高淳区",
              "lng": "118.89222",
              "lat": "31.327586",
              "level": "district"
            }
          ]
        },
        {
          "value": 824,
          "parent_id": 811,
          "label": "无锡市",
          "lng": "120.31191",
          "lat": "31.491169",
          "level": "city",
          "children": [
            {
              "value": 825,
              "parent_id": 824,
              "label": "锡山区",
              "lng": "120.357858",
              "lat": "31.589715",
              "level": "district"
            },
            {
              "value": 826,
              "parent_id": 824,
              "label": "惠山区",
              "lng": "120.298433",
              "lat": "31.680335",
              "level": "district"
            },
            {
              "value": 827,
              "parent_id": 824,
              "label": "滨湖区",
              "lng": "120.283811",
              "lat": "31.527276",
              "level": "district"
            },
            {
              "value": 828,
              "parent_id": 824,
              "label": "梁溪区",
              "lng": "120.303108",
              "lat": "31.566155",
              "level": "district"
            },
            {
              "value": 829,
              "parent_id": 824,
              "label": "新吴区",
              "lng": "120.352782",
              "lat": "31.550966",
              "level": "district"
            },
            {
              "value": 830,
              "parent_id": 824,
              "label": "江阴市",
              "lng": "120.286129",
              "lat": "31.921345",
              "level": "district"
            },
            {
              "value": 831,
              "parent_id": 824,
              "label": "宜兴市",
              "lng": "119.823308",
              "lat": "31.340637",
              "level": "district"
            }
          ]
        },
        {
          "value": 832,
          "parent_id": 811,
          "label": "徐州市",
          "lng": "117.284124",
          "lat": "34.205768",
          "level": "city",
          "children": [
            {
              "value": 833,
              "parent_id": 832,
              "label": "鼓楼区",
              "lng": "117.185576",
              "lat": "34.288646",
              "level": "district"
            },
            {
              "value": 834,
              "parent_id": 832,
              "label": "云龙区",
              "lng": "117.251076",
              "lat": "34.253164",
              "level": "district"
            },
            {
              "value": 835,
              "parent_id": 832,
              "label": "贾汪区",
              "lng": "117.464958",
              "lat": "34.436936",
              "level": "district"
            },
            {
              "value": 836,
              "parent_id": 832,
              "label": "泉山区",
              "lng": "117.194469",
              "lat": "34.225522",
              "level": "district"
            },
            {
              "value": 837,
              "parent_id": 832,
              "label": "铜山区",
              "lng": "117.169461",
              "lat": "34.180779",
              "level": "district"
            },
            {
              "value": 838,
              "parent_id": 832,
              "label": "丰县",
              "lng": "116.59539",
              "lat": "34.693906",
              "level": "district"
            },
            {
              "value": 839,
              "parent_id": 832,
              "label": "沛县",
              "lng": "116.936353",
              "lat": "34.760761",
              "level": "district"
            },
            {
              "value": 840,
              "parent_id": 832,
              "label": "睢宁县",
              "lng": "117.941563",
              "lat": "33.912597",
              "level": "district"
            },
            {
              "value": 841,
              "parent_id": 832,
              "label": "新沂市",
              "lng": "118.354537",
              "lat": "34.36958",
              "level": "district"
            },
            {
              "value": 842,
              "parent_id": 832,
              "label": "邳州市",
              "lng": "118.012531",
              "lat": "34.338888",
              "level": "district"
            }
          ]
        },
        {
          "value": 843,
          "parent_id": 811,
          "label": "常州市",
          "lng": "119.974061",
          "lat": "31.811226",
          "level": "city",
          "children": [
            {
              "value": 844,
              "parent_id": 843,
              "label": "天宁区",
              "lng": "119.999219",
              "lat": "31.792787",
              "level": "district"
            },
            {
              "value": 845,
              "parent_id": 843,
              "label": "钟楼区",
              "lng": "119.902369",
              "lat": "31.802089",
              "level": "district"
            },
            {
              "value": 846,
              "parent_id": 843,
              "label": "新北区",
              "lng": "119.971697",
              "lat": "31.830427",
              "level": "district"
            },
            {
              "value": 847,
              "parent_id": 843,
              "label": "武进区",
              "lng": "119.942437",
              "lat": "31.701187",
              "level": "district"
            },
            {
              "value": 848,
              "parent_id": 843,
              "label": "金坛区",
              "lng": "119.597811",
              "lat": "31.723219",
              "level": "district"
            },
            {
              "value": 849,
              "parent_id": 843,
              "label": "溧阳市",
              "lng": "119.48421",
              "lat": "31.416911",
              "level": "district"
            }
          ]
        },
        {
          "value": 850,
          "parent_id": 811,
          "label": "苏州市",
          "lng": "120.585728",
          "lat": "31.2974",
          "level": "city",
          "children": [
            {
              "value": 851,
              "parent_id": 850,
              "label": "虎丘区",
              "lng": "120.434238",
              "lat": "31.329601",
              "level": "district"
            },
            {
              "value": 852,
              "parent_id": 850,
              "label": "吴中区",
              "lng": "120.632308",
              "lat": "31.263183",
              "level": "district"
            },
            {
              "value": 853,
              "parent_id": 850,
              "label": "相城区",
              "lng": "120.642626",
              "lat": "31.369089",
              "level": "district"
            },
            {
              "value": 854,
              "parent_id": 850,
              "label": "姑苏区",
              "lng": "120.617369",
              "lat": "31.33565",
              "level": "district"
            },
            {
              "value": 855,
              "parent_id": 850,
              "label": "吴江区",
              "lng": "120.645157",
              "lat": "31.138677",
              "level": "district"
            },
            {
              "value": 856,
              "parent_id": 850,
              "label": "常熟市",
              "lng": "120.752481",
              "lat": "31.654375",
              "level": "district"
            },
            {
              "value": 857,
              "parent_id": 850,
              "label": "张家港市",
              "lng": "120.555982",
              "lat": "31.875571",
              "level": "district"
            },
            {
              "value": 858,
              "parent_id": 850,
              "label": "昆山市",
              "lng": "120.980736",
              "lat": "31.385597",
              "level": "district"
            },
            {
              "value": 859,
              "parent_id": 850,
              "label": "太仓市",
              "lng": "121.13055",
              "lat": "31.457735",
              "level": "district"
            },
            {
              "value": 3263,
              "parent_id": 850,
              "label": "高新区",
              "lng": "120",
              "lat": "30",
              "level": "district"
            }
          ]
        },
        {
          "value": 860,
          "parent_id": 811,
          "label": "南通市",
          "lng": "120.894676",
          "lat": "31.981143",
          "level": "city",
          "children": [
            {
              "value": 861,
              "parent_id": 860,
              "label": "崇川区",
              "lng": "120.857434",
              "lat": "32.009875",
              "level": "district"
            },
            {
              "value": 862,
              "parent_id": 860,
              "label": "港闸区",
              "lng": "120.818526",
              "lat": "32.032441",
              "level": "district"
            },
            {
              "value": 863,
              "parent_id": 860,
              "label": "通州区",
              "lng": "121.073828",
              "lat": "32.06568",
              "level": "district"
            },
            {
              "value": 864,
              "parent_id": 860,
              "label": "海安县",
              "lng": "120.467343",
              "lat": "32.533572",
              "level": "district"
            },
            {
              "value": 865,
              "parent_id": 860,
              "label": "如东县",
              "lng": "121.185201",
              "lat": "32.331765",
              "level": "district"
            },
            {
              "value": 866,
              "parent_id": 860,
              "label": "启东市",
              "lng": "121.655432",
              "lat": "31.793278",
              "level": "district"
            },
            {
              "value": 867,
              "parent_id": 860,
              "label": "如皋市",
              "lng": "120.573803",
              "lat": "32.371562",
              "level": "district"
            },
            {
              "value": 868,
              "parent_id": 860,
              "label": "海门市",
              "lng": "121.18181",
              "lat": "31.869483",
              "level": "district"
            }
          ]
        },
        {
          "value": 869,
          "parent_id": 811,
          "label": "连云港市",
          "lng": "119.221611",
          "lat": "34.596653",
          "level": "city",
          "children": [
            {
              "value": 870,
              "parent_id": 869,
              "label": "连云区",
              "lng": "119.338788",
              "lat": "34.760249",
              "level": "district"
            },
            {
              "value": 871,
              "parent_id": 869,
              "label": "海州区",
              "lng": "119.163509",
              "lat": "34.572274",
              "level": "district"
            },
            {
              "value": 872,
              "parent_id": 869,
              "label": "赣榆区",
              "lng": "119.17333",
              "lat": "34.841348",
              "level": "district"
            },
            {
              "value": 873,
              "parent_id": 869,
              "label": "东海县",
              "lng": "118.752842",
              "lat": "34.542308",
              "level": "district"
            },
            {
              "value": 874,
              "parent_id": 869,
              "label": "灌云县",
              "lng": "119.239381",
              "lat": "34.284381",
              "level": "district"
            },
            {
              "value": 875,
              "parent_id": 869,
              "label": "灌南县",
              "lng": "119.315651",
              "lat": "34.087134",
              "level": "district"
            }
          ]
        },
        {
          "value": 876,
          "parent_id": 811,
          "label": "淮安市",
          "lng": "119.113185",
          "lat": "33.551052",
          "level": "city",
          "children": [
            {
              "value": 877,
              "parent_id": 876,
              "label": "清江浦区",
              "lng": "119.026718",
              "lat": "33.552627",
              "level": "district"
            },
            {
              "value": 878,
              "parent_id": 876,
              "label": "淮安区",
              "lng": "119.141099",
              "lat": "33.502868",
              "level": "district"
            },
            {
              "value": 879,
              "parent_id": 876,
              "label": "淮阴区",
              "lng": "119.034725",
              "lat": "33.631892",
              "level": "district"
            },
            {
              "value": 880,
              "parent_id": 876,
              "label": "洪泽区",
              "lng": "118.873241",
              "lat": "33.294214",
              "level": "district"
            },
            {
              "value": 881,
              "parent_id": 876,
              "label": "涟水县",
              "lng": "119.260227",
              "lat": "33.781331",
              "level": "district"
            },
            {
              "value": 882,
              "parent_id": 876,
              "label": "盱眙县",
              "lng": "118.54436",
              "lat": "33.011971",
              "level": "district"
            },
            {
              "value": 883,
              "parent_id": 876,
              "label": "金湖县",
              "lng": "119.020584",
              "lat": "33.025433",
              "level": "district"
            }
          ]
        },
        {
          "value": 884,
          "parent_id": 811,
          "label": "盐城市",
          "lng": "120.163107",
          "lat": "33.347708",
          "level": "city",
          "children": [
            {
              "value": 885,
              "parent_id": 884,
              "label": "亭湖区",
              "lng": "120.197358",
              "lat": "33.390536",
              "level": "district"
            },
            {
              "value": 886,
              "parent_id": 884,
              "label": "盐都区",
              "lng": "120.153712",
              "lat": "33.338283",
              "level": "district"
            },
            {
              "value": 887,
              "parent_id": 884,
              "label": "大丰区",
              "lng": "120.50085",
              "lat": "33.200333",
              "level": "district"
            },
            {
              "value": 888,
              "parent_id": 884,
              "label": "响水县",
              "lng": "119.578364",
              "lat": "34.199479",
              "level": "district"
            },
            {
              "value": 889,
              "parent_id": 884,
              "label": "滨海县",
              "lng": "119.82083",
              "lat": "33.990334",
              "level": "district"
            },
            {
              "value": 890,
              "parent_id": 884,
              "label": "阜宁县",
              "lng": "119.802527",
              "lat": "33.759325",
              "level": "district"
            },
            {
              "value": 891,
              "parent_id": 884,
              "label": "射阳县",
              "lng": "120.229986",
              "lat": "33.758361",
              "level": "district"
            },
            {
              "value": 892,
              "parent_id": 884,
              "label": "建湖县",
              "lng": "119.7886",
              "lat": "33.439067",
              "level": "district"
            },
            {
              "value": 893,
              "parent_id": 884,
              "label": "东台市",
              "lng": "120.320328",
              "lat": "32.868426",
              "level": "district"
            }
          ]
        },
        {
          "value": 894,
          "parent_id": 811,
          "label": "扬州市",
          "lng": "119.412939",
          "lat": "32.394209",
          "level": "city",
          "children": [
            {
              "value": 895,
              "parent_id": 894,
              "label": "广陵区",
              "lng": "119.431849",
              "lat": "32.39472",
              "level": "district"
            },
            {
              "value": 896,
              "parent_id": 894,
              "label": "邗江区",
              "lng": "119.397994",
              "lat": "32.377655",
              "level": "district"
            },
            {
              "value": 897,
              "parent_id": 894,
              "label": "江都区",
              "lng": "119.569989",
              "lat": "32.434672",
              "level": "district"
            },
            {
              "value": 898,
              "parent_id": 894,
              "label": "宝应县",
              "lng": "119.360729",
              "lat": "33.240391",
              "level": "district"
            },
            {
              "value": 899,
              "parent_id": 894,
              "label": "仪征市",
              "lng": "119.184766",
              "lat": "32.272258",
              "level": "district"
            },
            {
              "value": 900,
              "parent_id": 894,
              "label": "高邮市",
              "lng": "119.459161",
              "lat": "32.781659",
              "level": "district"
            }
          ]
        },
        {
          "value": 901,
          "parent_id": 811,
          "label": "镇江市",
          "lng": "119.425836",
          "lat": "32.187849",
          "level": "city",
          "children": [
            {
              "value": 902,
              "parent_id": 901,
              "label": "京口区",
              "lng": "119.47016",
              "lat": "32.19828",
              "level": "district"
            },
            {
              "value": 903,
              "parent_id": 901,
              "label": "润州区",
              "lng": "119.411959",
              "lat": "32.195264",
              "level": "district"
            },
            {
              "value": 904,
              "parent_id": 901,
              "label": "丹徒区",
              "lng": "119.433853",
              "lat": "32.131962",
              "level": "district"
            },
            {
              "value": 905,
              "parent_id": 901,
              "label": "丹阳市",
              "lng": "119.606439",
              "lat": "32.010214",
              "level": "district"
            },
            {
              "value": 906,
              "parent_id": 901,
              "label": "扬中市",
              "lng": "119.797634",
              "lat": "32.23483",
              "level": "district"
            },
            {
              "value": 907,
              "parent_id": 901,
              "label": "句容市",
              "lng": "119.168695",
              "lat": "31.944998",
              "level": "district"
            }
          ]
        },
        {
          "value": 908,
          "parent_id": 811,
          "label": "泰州市",
          "lng": "119.922933",
          "lat": "32.455536",
          "level": "city",
          "children": [
            {
              "value": 909,
              "parent_id": 908,
              "label": "海陵区",
              "lng": "119.919424",
              "lat": "32.491016",
              "level": "district"
            },
            {
              "value": 910,
              "parent_id": 908,
              "label": "高港区",
              "lng": "119.881717",
              "lat": "32.318821",
              "level": "district"
            },
            {
              "value": 911,
              "parent_id": 908,
              "label": "姜堰区",
              "lng": "120.127934",
              "lat": "32.509155",
              "level": "district"
            },
            {
              "value": 912,
              "parent_id": 908,
              "label": "兴化市",
              "lng": "119.852541",
              "lat": "32.910459",
              "level": "district"
            },
            {
              "value": 913,
              "parent_id": 908,
              "label": "靖江市",
              "lng": "120.277138",
              "lat": "31.982751",
              "level": "district"
            },
            {
              "value": 914,
              "parent_id": 908,
              "label": "泰兴市",
              "lng": "120.051743",
              "lat": "32.171853",
              "level": "district"
            }
          ]
        },
        {
          "value": 915,
          "parent_id": 811,
          "label": "宿迁市",
          "lng": "118.275198",
          "lat": "33.963232",
          "level": "city",
          "children": [
            {
              "value": 916,
              "parent_id": 915,
              "label": "宿城区",
              "lng": "118.242533",
              "lat": "33.963029",
              "level": "district"
            },
            {
              "value": 917,
              "parent_id": 915,
              "label": "宿豫区",
              "lng": "118.330781",
              "lat": "33.946822",
              "level": "district"
            },
            {
              "value": 918,
              "parent_id": 915,
              "label": "沭阳县",
              "lng": "118.804784",
              "lat": "34.111022",
              "level": "district"
            },
            {
              "value": 919,
              "parent_id": 915,
              "label": "泗阳县",
              "lng": "118.703424",
              "lat": "33.722478",
              "level": "district"
            },
            {
              "value": 920,
              "parent_id": 915,
              "label": "泗洪县",
              "lng": "118.223591",
              "lat": "33.476051",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 921,
      "parent_id": 1,
      "label": "浙江省",
      "lng": "120.152585",
      "lat": "30.266597",
      "level": "province",
      "children": [
        {
          "value": 922,
          "parent_id": 921,
          "label": "杭州市",
          "lng": "120.209789",
          "lat": "30.24692",
          "level": "city",
          "children": [
            {
              "value": 923,
              "parent_id": 922,
              "label": "上城区",
              "lng": "120.169312",
              "lat": "30.242404",
              "level": "district"
            },
            {
              "value": 924,
              "parent_id": 922,
              "label": "下城区",
              "lng": "120.180891",
              "lat": "30.281677",
              "level": "district"
            },
            {
              "value": 925,
              "parent_id": 922,
              "label": "江干区",
              "lng": "120.205001",
              "lat": "30.257012",
              "level": "district"
            },
            {
              "value": 926,
              "parent_id": 922,
              "label": "拱墅区",
              "lng": "120.141406",
              "lat": "30.319037",
              "level": "district"
            },
            {
              "value": 927,
              "parent_id": 922,
              "label": "西湖区",
              "lng": "120.130194",
              "lat": "30.259463",
              "level": "district"
            },
            {
              "value": 928,
              "parent_id": 922,
              "label": "滨江区",
              "lng": "120.211623",
              "lat": "30.208847",
              "level": "district"
            },
            {
              "value": 929,
              "parent_id": 922,
              "label": "萧山区",
              "lng": "120.264253",
              "lat": "30.183806",
              "level": "district"
            },
            {
              "value": 930,
              "parent_id": 922,
              "label": "余杭区",
              "lng": "120.299401",
              "lat": "30.419045",
              "level": "district"
            },
            {
              "value": 931,
              "parent_id": 922,
              "label": "富阳区",
              "lng": "119.960076",
              "lat": "30.048692",
              "level": "district"
            },
            {
              "value": 932,
              "parent_id": 922,
              "label": "桐庐县",
              "lng": "119.691467",
              "lat": "29.79299",
              "level": "district"
            },
            {
              "value": 933,
              "parent_id": 922,
              "label": "淳安县",
              "lng": "119.042037",
              "lat": "29.608886",
              "level": "district"
            },
            {
              "value": 934,
              "parent_id": 922,
              "label": "建德市",
              "lng": "119.281231",
              "lat": "29.474759",
              "level": "district"
            },
            {
              "value": 935,
              "parent_id": 922,
              "label": "临安市",
              "lng": "119.724734",
              "lat": "30.233873",
              "level": "district"
            }
          ]
        },
        {
          "value": 936,
          "parent_id": 921,
          "label": "宁波市",
          "lng": "121.622485",
          "lat": "29.859971",
          "level": "city",
          "children": [
            {
              "value": 937,
              "parent_id": 936,
              "label": "海曙区",
              "lng": "121.550752",
              "lat": "29.874903",
              "level": "district"
            },
            {
              "value": 938,
              "parent_id": 936,
              "label": "江北区",
              "lng": "121.555081",
              "lat": "29.886781",
              "level": "district"
            },
            {
              "value": 939,
              "parent_id": 936,
              "label": "北仑区",
              "lng": "121.844172",
              "lat": "29.899778",
              "level": "district"
            },
            {
              "value": 940,
              "parent_id": 936,
              "label": "镇海区",
              "lng": "121.596496",
              "lat": "29.965203",
              "level": "district"
            },
            {
              "value": 941,
              "parent_id": 936,
              "label": "鄞州区",
              "lng": "121.546603",
              "lat": "29.816511",
              "level": "district"
            },
            {
              "value": 942,
              "parent_id": 936,
              "label": "象山县",
              "lng": "121.869339",
              "lat": "29.476705",
              "level": "district"
            },
            {
              "value": 943,
              "parent_id": 936,
              "label": "宁海县",
              "lng": "121.429477",
              "lat": "29.287939",
              "level": "district"
            },
            {
              "value": 944,
              "parent_id": 936,
              "label": "余姚市",
              "lng": "121.154629",
              "lat": "30.037106",
              "level": "district"
            },
            {
              "value": 945,
              "parent_id": 936,
              "label": "慈溪市",
              "lng": "121.266561",
              "lat": "30.170261",
              "level": "district"
            },
            {
              "value": 946,
              "parent_id": 936,
              "label": "奉化区",
              "lng": "121.406997",
              "lat": "29.655144",
              "level": "district"
            }
          ]
        },
        {
          "value": 947,
          "parent_id": 921,
          "label": "温州市",
          "lng": "120.699361",
          "lat": "27.993828",
          "level": "city",
          "children": [
            {
              "value": 948,
              "parent_id": 947,
              "label": "鹿城区",
              "lng": "120.655271",
              "lat": "28.015737",
              "level": "district"
            },
            {
              "value": 949,
              "parent_id": 947,
              "label": "龙湾区",
              "lng": "120.811213",
              "lat": "27.932747",
              "level": "district"
            },
            {
              "value": 950,
              "parent_id": 947,
              "label": "瓯海区",
              "lng": "120.61491",
              "lat": "27.966844",
              "level": "district"
            },
            {
              "value": 951,
              "parent_id": 947,
              "label": "洞头区",
              "lng": "121.157249",
              "lat": "27.836154",
              "level": "district"
            },
            {
              "value": 952,
              "parent_id": 947,
              "label": "永嘉县",
              "lng": "120.692025",
              "lat": "28.153607",
              "level": "district"
            },
            {
              "value": 953,
              "parent_id": 947,
              "label": "平阳县",
              "lng": "120.565793",
              "lat": "27.661918",
              "level": "district"
            },
            {
              "value": 954,
              "parent_id": 947,
              "label": "苍南县",
              "lng": "120.427619",
              "lat": "27.519773",
              "level": "district"
            },
            {
              "value": 955,
              "parent_id": 947,
              "label": "文成县",
              "lng": "120.091498",
              "lat": "27.786996",
              "level": "district"
            },
            {
              "value": 956,
              "parent_id": 947,
              "label": "泰顺县",
              "lng": "119.717649",
              "lat": "27.556884",
              "level": "district"
            },
            {
              "value": 957,
              "parent_id": 947,
              "label": "瑞安市",
              "lng": "120.655148",
              "lat": "27.778657",
              "level": "district"
            },
            {
              "value": 958,
              "parent_id": 947,
              "label": "乐清市",
              "lng": "120.983906",
              "lat": "28.113725",
              "level": "district"
            }
          ]
        },
        {
          "value": 959,
          "parent_id": 921,
          "label": "嘉兴市",
          "lng": "120.75547",
          "lat": "30.746191",
          "level": "city",
          "children": [
            {
              "value": 960,
              "parent_id": 959,
              "label": "南湖区",
              "lng": "120.783024",
              "lat": "30.747842",
              "level": "district"
            },
            {
              "value": 961,
              "parent_id": 959,
              "label": "秀洲区",
              "lng": "120.710082",
              "lat": "30.765188",
              "level": "district"
            },
            {
              "value": 962,
              "parent_id": 959,
              "label": "嘉善县",
              "lng": "120.926028",
              "lat": "30.830864",
              "level": "district"
            },
            {
              "value": 963,
              "parent_id": 959,
              "label": "海盐县",
              "lng": "120.946263",
              "lat": "30.526435",
              "level": "district"
            },
            {
              "value": 964,
              "parent_id": 959,
              "label": "海宁市",
              "lng": "120.680239",
              "lat": "30.511539",
              "level": "district"
            },
            {
              "value": 965,
              "parent_id": 959,
              "label": "平湖市",
              "lng": "121.015142",
              "lat": "30.677233",
              "level": "district"
            },
            {
              "value": 966,
              "parent_id": 959,
              "label": "桐乡市",
              "lng": "120.565098",
              "lat": "30.630173",
              "level": "district"
            }
          ]
        },
        {
          "value": 967,
          "parent_id": 921,
          "label": "湖州市",
          "lng": "120.086809",
          "lat": "30.89441",
          "level": "city",
          "children": [
            {
              "value": 968,
              "parent_id": 967,
              "label": "吴兴区",
              "lng": "120.185838",
              "lat": "30.857151",
              "level": "district"
            },
            {
              "value": 969,
              "parent_id": 967,
              "label": "南浔区",
              "lng": "120.418513",
              "lat": "30.849689",
              "level": "district"
            },
            {
              "value": 970,
              "parent_id": 967,
              "label": "德清县",
              "lng": "119.9774",
              "lat": "30.54251",
              "level": "district"
            },
            {
              "value": 971,
              "parent_id": 967,
              "label": "长兴县",
              "lng": "119.910952",
              "lat": "31.026665",
              "level": "district"
            },
            {
              "value": 972,
              "parent_id": 967,
              "label": "安吉县",
              "lng": "119.680353",
              "lat": "30.638674",
              "level": "district"
            }
          ]
        },
        {
          "value": 973,
          "parent_id": 921,
          "label": "绍兴市",
          "lng": "120.580364",
          "lat": "30.030192",
          "level": "city",
          "children": [
            {
              "value": 974,
              "parent_id": 973,
              "label": "越城区",
              "lng": "120.582633",
              "lat": "29.988244",
              "level": "district"
            },
            {
              "value": 975,
              "parent_id": 973,
              "label": "柯桥区",
              "lng": "120.495085",
              "lat": "30.081929",
              "level": "district"
            },
            {
              "value": 976,
              "parent_id": 973,
              "label": "上虞区",
              "lng": "120.868122",
              "lat": "30.03312",
              "level": "district"
            },
            {
              "value": 977,
              "parent_id": 973,
              "label": "新昌县",
              "lng": "120.903866",
              "lat": "29.499831",
              "level": "district"
            },
            {
              "value": 978,
              "parent_id": 973,
              "label": "诸暨市",
              "lng": "120.246863",
              "lat": "29.708692",
              "level": "district"
            },
            {
              "value": 979,
              "parent_id": 973,
              "label": "嵊州市",
              "lng": "120.831025",
              "lat": "29.56141",
              "level": "district"
            }
          ]
        },
        {
          "value": 980,
          "parent_id": 921,
          "label": "金华市",
          "lng": "119.647229",
          "lat": "29.079208",
          "level": "city",
          "children": [
            {
              "value": 981,
              "parent_id": 980,
              "label": "婺城区",
              "lng": "119.571728",
              "lat": "29.0872",
              "level": "district"
            },
            {
              "value": 982,
              "parent_id": 980,
              "label": "金东区",
              "lng": "119.69278",
              "lat": "29.099723",
              "level": "district"
            },
            {
              "value": 983,
              "parent_id": 980,
              "label": "武义县",
              "lng": "119.816562",
              "lat": "28.89267",
              "level": "district"
            },
            {
              "value": 984,
              "parent_id": 980,
              "label": "浦江县",
              "lng": "119.892222",
              "lat": "29.452476",
              "level": "district"
            },
            {
              "value": 985,
              "parent_id": 980,
              "label": "磐安县",
              "lng": "120.450005",
              "lat": "29.054548",
              "level": "district"
            },
            {
              "value": 986,
              "parent_id": 980,
              "label": "兰溪市",
              "lng": "119.460472",
              "lat": "29.2084",
              "level": "district"
            },
            {
              "value": 987,
              "parent_id": 980,
              "label": "义乌市",
              "lng": "120.075106",
              "lat": "29.306775",
              "level": "district"
            },
            {
              "value": 988,
              "parent_id": 980,
              "label": "东阳市",
              "lng": "120.241566",
              "lat": "29.289648",
              "level": "district"
            },
            {
              "value": 989,
              "parent_id": 980,
              "label": "永康市",
              "lng": "120.047651",
              "lat": "28.888555",
              "level": "district"
            }
          ]
        },
        {
          "value": 990,
          "parent_id": 921,
          "label": "衢州市",
          "lng": "118.859457",
          "lat": "28.970079",
          "level": "city",
          "children": [
            {
              "value": 991,
              "parent_id": 990,
              "label": "柯城区",
              "lng": "118.871516",
              "lat": "28.96862",
              "level": "district"
            },
            {
              "value": 992,
              "parent_id": 990,
              "label": "衢江区",
              "lng": "118.95946",
              "lat": "28.97978",
              "level": "district"
            },
            {
              "value": 993,
              "parent_id": 990,
              "label": "常山县",
              "lng": "118.511235",
              "lat": "28.901462",
              "level": "district"
            },
            {
              "value": 994,
              "parent_id": 990,
              "label": "开化县",
              "lng": "118.415495",
              "lat": "29.137336",
              "level": "district"
            },
            {
              "value": 995,
              "parent_id": 990,
              "label": "龙游县",
              "lng": "119.172189",
              "lat": "29.028439",
              "level": "district"
            },
            {
              "value": 996,
              "parent_id": 990,
              "label": "江山市",
              "lng": "118.626991",
              "lat": "28.737331",
              "level": "district"
            }
          ]
        },
        {
          "value": 997,
          "parent_id": 921,
          "label": "舟山市",
          "lng": "122.207106",
          "lat": "29.985553",
          "level": "city",
          "children": [
            {
              "value": 998,
              "parent_id": 997,
              "label": "定海区",
              "lng": "122.106773",
              "lat": "30.019858",
              "level": "district"
            },
            {
              "value": 999,
              "parent_id": 997,
              "label": "普陀区",
              "lng": "122.323867",
              "lat": "29.97176",
              "level": "district"
            },
            {
              "value": 1000,
              "parent_id": 997,
              "label": "岱山县",
              "lng": "122.226237",
              "lat": "30.264139",
              "level": "district"
            },
            {
              "value": 1001,
              "parent_id": 997,
              "label": "嵊泗县",
              "lng": "122.451382",
              "lat": "30.725686",
              "level": "district"
            }
          ]
        },
        {
          "value": 1002,
          "parent_id": 921,
          "label": "台州市",
          "lng": "121.42076",
          "lat": "28.65638",
          "level": "city",
          "children": [
            {
              "value": 1003,
              "parent_id": 1002,
              "label": "椒江区",
              "lng": "121.442978",
              "lat": "28.672981",
              "level": "district"
            },
            {
              "value": 1004,
              "parent_id": 1002,
              "label": "黄岩区",
              "lng": "121.261972",
              "lat": "28.650083",
              "level": "district"
            },
            {
              "value": 1005,
              "parent_id": 1002,
              "label": "路桥区",
              "lng": "121.365123",
              "lat": "28.582654",
              "level": "district"
            },
            {
              "value": 1006,
              "parent_id": 1002,
              "label": "玉环县",
              "lng": "121.231805",
              "lat": "28.135929",
              "level": "district"
            },
            {
              "value": 1007,
              "parent_id": 1002,
              "label": "三门县",
              "lng": "121.395711",
              "lat": "29.104789",
              "level": "district"
            },
            {
              "value": 1008,
              "parent_id": 1002,
              "label": "天台县",
              "lng": "121.006595",
              "lat": "29.144064",
              "level": "district"
            },
            {
              "value": 1009,
              "parent_id": 1002,
              "label": "仙居县",
              "lng": "120.728801",
              "lat": "28.846966",
              "level": "district"
            },
            {
              "value": 1010,
              "parent_id": 1002,
              "label": "温岭市",
              "lng": "121.385604",
              "lat": "28.372506",
              "level": "district"
            },
            {
              "value": 1011,
              "parent_id": 1002,
              "label": "临海市",
              "lng": "121.144556",
              "lat": "28.858881",
              "level": "district"
            }
          ]
        },
        {
          "value": 1012,
          "parent_id": 921,
          "label": "丽水市",
          "lng": "119.922796",
          "lat": "28.46763",
          "level": "city",
          "children": [
            {
              "value": 1013,
              "parent_id": 1012,
              "label": "莲都区",
              "lng": "119.912626",
              "lat": "28.445928",
              "level": "district"
            },
            {
              "value": 1014,
              "parent_id": 1012,
              "label": "青田县",
              "lng": "120.289478",
              "lat": "28.139837",
              "level": "district"
            },
            {
              "value": 1015,
              "parent_id": 1012,
              "label": "缙云县",
              "lng": "120.091572",
              "lat": "28.659283",
              "level": "district"
            },
            {
              "value": 1016,
              "parent_id": 1012,
              "label": "遂昌县",
              "lng": "119.276103",
              "lat": "28.592148",
              "level": "district"
            },
            {
              "value": 1017,
              "parent_id": 1012,
              "label": "松阳县",
              "lng": "119.481511",
              "lat": "28.448803",
              "level": "district"
            },
            {
              "value": 1018,
              "parent_id": 1012,
              "label": "云和县",
              "lng": "119.573397",
              "lat": "28.11579",
              "level": "district"
            },
            {
              "value": 1019,
              "parent_id": 1012,
              "label": "庆元县",
              "lng": "119.06259",
              "lat": "27.61922",
              "level": "district"
            },
            {
              "value": 1020,
              "parent_id": 1012,
              "label": "景宁畲族自治县",
              "lng": "119.635739",
              "lat": "27.9733",
              "level": "district"
            },
            {
              "value": 1021,
              "parent_id": 1012,
              "label": "龙泉市",
              "lng": "119.141473",
              "lat": "28.074649",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1022,
      "parent_id": 1,
      "label": "安徽省",
      "lng": "117.329949",
      "lat": "31.733806",
      "level": "province",
      "children": [
        {
          "value": 1023,
          "parent_id": 1022,
          "label": "合肥市",
          "lng": "117.227219",
          "lat": "31.820591",
          "level": "city",
          "children": [
            {
              "value": 1024,
              "parent_id": 1023,
              "label": "瑶海区",
              "lng": "117.309546",
              "lat": "31.857917",
              "level": "district"
            },
            {
              "value": 1025,
              "parent_id": 1023,
              "label": "庐阳区",
              "lng": "117.264786",
              "lat": "31.878589",
              "level": "district"
            },
            {
              "value": 1026,
              "parent_id": 1023,
              "label": "蜀山区",
              "lng": "117.260521",
              "lat": "31.85124",
              "level": "district"
            },
            {
              "value": 1027,
              "parent_id": 1023,
              "label": "包河区",
              "lng": "117.309519",
              "lat": "31.793859",
              "level": "district"
            },
            {
              "value": 1028,
              "parent_id": 1023,
              "label": "长丰县",
              "lng": "117.167564",
              "lat": "32.478018",
              "level": "district"
            },
            {
              "value": 1029,
              "parent_id": 1023,
              "label": "肥东县",
              "lng": "117.469382",
              "lat": "31.88794",
              "level": "district"
            },
            {
              "value": 1030,
              "parent_id": 1023,
              "label": "肥西县",
              "lng": "117.157981",
              "lat": "31.706809",
              "level": "district"
            },
            {
              "value": 1031,
              "parent_id": 1023,
              "label": "庐江县",
              "lng": "117.2882",
              "lat": "31.256524",
              "level": "district"
            },
            {
              "value": 1032,
              "parent_id": 1023,
              "label": "巢湖市",
              "lng": "117.890354",
              "lat": "31.624522",
              "level": "district"
            }
          ]
        },
        {
          "value": 1033,
          "parent_id": 1022,
          "label": "芜湖市",
          "lng": "118.432941",
          "lat": "31.352859",
          "level": "city",
          "children": [
            {
              "value": 1034,
              "parent_id": 1033,
              "label": "镜湖区",
              "lng": "118.385009",
              "lat": "31.340728",
              "level": "district"
            },
            {
              "value": 1035,
              "parent_id": 1033,
              "label": "弋江区",
              "lng": "118.372655",
              "lat": "31.311756",
              "level": "district"
            },
            {
              "value": 1036,
              "parent_id": 1033,
              "label": "鸠江区",
              "lng": "118.391734",
              "lat": "31.369373",
              "level": "district"
            },
            {
              "value": 1037,
              "parent_id": 1033,
              "label": "三山区",
              "lng": "118.268101",
              "lat": "31.219568",
              "level": "district"
            },
            {
              "value": 1038,
              "parent_id": 1033,
              "label": "芜湖县",
              "lng": "118.576124",
              "lat": "31.134809",
              "level": "district"
            },
            {
              "value": 1039,
              "parent_id": 1033,
              "label": "繁昌县",
              "lng": "118.198703",
              "lat": "31.101782",
              "level": "district"
            },
            {
              "value": 1040,
              "parent_id": 1033,
              "label": "南陵县",
              "lng": "118.334359",
              "lat": "30.914922",
              "level": "district"
            },
            {
              "value": 1041,
              "parent_id": 1033,
              "label": "无为县",
              "lng": "117.902366",
              "lat": "31.303167",
              "level": "district"
            }
          ]
        },
        {
          "value": 1042,
          "parent_id": 1022,
          "label": "蚌埠市",
          "lng": "117.388512",
          "lat": "32.91663",
          "level": "city",
          "children": [
            {
              "value": 1043,
              "parent_id": 1042,
              "label": "龙子湖区",
              "lng": "117.379778",
              "lat": "32.950611",
              "level": "district"
            },
            {
              "value": 1044,
              "parent_id": 1042,
              "label": "蚌山区",
              "lng": "117.373595",
              "lat": "32.917048",
              "level": "district"
            },
            {
              "value": 1045,
              "parent_id": 1042,
              "label": "禹会区",
              "lng": "117.342155",
              "lat": "32.929799",
              "level": "district"
            },
            {
              "value": 1046,
              "parent_id": 1042,
              "label": "淮上区",
              "lng": "117.35933",
              "lat": "32.965435",
              "level": "district"
            },
            {
              "value": 1047,
              "parent_id": 1042,
              "label": "怀远县",
              "lng": "117.205237",
              "lat": "32.970031",
              "level": "district"
            },
            {
              "value": 1048,
              "parent_id": 1042,
              "label": "五河县",
              "lng": "117.879486",
              "lat": "33.127823",
              "level": "district"
            },
            {
              "value": 1049,
              "parent_id": 1042,
              "label": "固镇县",
              "lng": "117.316913",
              "lat": "33.31688",
              "level": "district"
            }
          ]
        },
        {
          "value": 1050,
          "parent_id": 1022,
          "label": "淮南市",
          "lng": "117.018399",
          "lat": "32.587117",
          "level": "city",
          "children": [
            {
              "value": 1051,
              "parent_id": 1050,
              "label": "大通区",
              "lng": "117.053314",
              "lat": "32.631519",
              "level": "district"
            },
            {
              "value": 1052,
              "parent_id": 1050,
              "label": "田家庵区",
              "lng": "117.017349",
              "lat": "32.647277",
              "level": "district"
            },
            {
              "value": 1053,
              "parent_id": 1050,
              "label": "谢家集区",
              "lng": "116.859188",
              "lat": "32.600037",
              "level": "district"
            },
            {
              "value": 1054,
              "parent_id": 1050,
              "label": "八公山区",
              "lng": "116.83349",
              "lat": "32.631379",
              "level": "district"
            },
            {
              "value": 1055,
              "parent_id": 1050,
              "label": "潘集区",
              "lng": "116.834715",
              "lat": "32.77208",
              "level": "district"
            },
            {
              "value": 1056,
              "parent_id": 1050,
              "label": "凤台县",
              "lng": "116.71105",
              "lat": "32.709444",
              "level": "district"
            },
            {
              "value": 1057,
              "parent_id": 1050,
              "label": "寿县",
              "lng": "116.798232",
              "lat": "32.545109",
              "level": "district"
            }
          ]
        },
        {
          "value": 1058,
          "parent_id": 1022,
          "label": "马鞍山市",
          "lng": "118.507011",
          "lat": "31.67044",
          "level": "city",
          "children": [
            {
              "value": 1059,
              "parent_id": 1058,
              "label": "花山区",
              "lng": "118.492565",
              "lat": "31.71971",
              "level": "district"
            },
            {
              "value": 1060,
              "parent_id": 1058,
              "label": "雨山区",
              "lng": "118.498578",
              "lat": "31.682132",
              "level": "district"
            },
            {
              "value": 1061,
              "parent_id": 1058,
              "label": "博望区",
              "lng": "118.844538",
              "lat": "31.558471",
              "level": "district"
            },
            {
              "value": 1062,
              "parent_id": 1058,
              "label": "当涂县",
              "lng": "118.497972",
              "lat": "31.571213",
              "level": "district"
            },
            {
              "value": 1063,
              "parent_id": 1058,
              "label": "含山县",
              "lng": "118.101421",
              "lat": "31.735598",
              "level": "district"
            },
            {
              "value": 1064,
              "parent_id": 1058,
              "label": "和县",
              "lng": "118.353667",
              "lat": "31.742293",
              "level": "district"
            }
          ]
        },
        {
          "value": 1065,
          "parent_id": 1022,
          "label": "淮北市",
          "lng": "116.798265",
          "lat": "33.955844",
          "level": "city",
          "children": [
            {
              "value": 1066,
              "parent_id": 1065,
              "label": "杜集区",
              "lng": "116.828133",
              "lat": "33.991451",
              "level": "district"
            },
            {
              "value": 1067,
              "parent_id": 1065,
              "label": "相山区",
              "lng": "116.794344",
              "lat": "33.959892",
              "level": "district"
            },
            {
              "value": 1068,
              "parent_id": 1065,
              "label": "烈山区",
              "lng": "116.813042",
              "lat": "33.895139",
              "level": "district"
            },
            {
              "value": 1069,
              "parent_id": 1065,
              "label": "濉溪县",
              "lng": "116.766298",
              "lat": "33.915477",
              "level": "district"
            }
          ]
        },
        {
          "value": 1070,
          "parent_id": 1022,
          "label": "铜陵市",
          "lng": "117.81154",
          "lat": "30.945515",
          "level": "city",
          "children": [
            {
              "value": 1071,
              "parent_id": 1070,
              "label": "铜官区",
              "lng": "117.85616",
              "lat": "30.936272",
              "level": "district"
            },
            {
              "value": 1072,
              "parent_id": 1070,
              "label": "义安区",
              "lng": "117.791544",
              "lat": "30.952823",
              "level": "district"
            },
            {
              "value": 1073,
              "parent_id": 1070,
              "label": "郊区",
              "lng": "117.768026",
              "lat": "30.821069",
              "level": "district"
            },
            {
              "value": 1074,
              "parent_id": 1070,
              "label": "枞阳县",
              "lng": "117.250594",
              "lat": "30.706018",
              "level": "district"
            }
          ]
        },
        {
          "value": 1075,
          "parent_id": 1022,
          "label": "安庆市",
          "lng": "117.115101",
          "lat": "30.531919",
          "level": "city",
          "children": [
            {
              "value": 1076,
              "parent_id": 1075,
              "label": "迎江区",
              "lng": "117.09115",
              "lat": "30.511548",
              "level": "district"
            },
            {
              "value": 1077,
              "parent_id": 1075,
              "label": "大观区",
              "lng": "117.013469",
              "lat": "30.553697",
              "level": "district"
            },
            {
              "value": 1078,
              "parent_id": 1075,
              "label": "宜秀区",
              "lng": "116.987542",
              "lat": "30.613332",
              "level": "district"
            },
            {
              "value": 1079,
              "parent_id": 1075,
              "label": "怀宁县",
              "lng": "116.829475",
              "lat": "30.733824",
              "level": "district"
            },
            {
              "value": 1080,
              "parent_id": 1075,
              "label": "潜山县",
              "lng": "116.581371",
              "lat": "30.631136",
              "level": "district"
            },
            {
              "value": 1081,
              "parent_id": 1075,
              "label": "太湖县",
              "lng": "116.308795",
              "lat": "30.45422",
              "level": "district"
            },
            {
              "value": 1082,
              "parent_id": 1075,
              "label": "宿松县",
              "lng": "116.129105",
              "lat": "30.153746",
              "level": "district"
            },
            {
              "value": 1083,
              "parent_id": 1075,
              "label": "望江县",
              "lng": "116.706498",
              "lat": "30.128002",
              "level": "district"
            },
            {
              "value": 1084,
              "parent_id": 1075,
              "label": "岳西县",
              "lng": "116.359692",
              "lat": "30.849762",
              "level": "district"
            },
            {
              "value": 1085,
              "parent_id": 1075,
              "label": "桐城市",
              "lng": "116.936748",
              "lat": "31.035848",
              "level": "district"
            }
          ]
        },
        {
          "value": 1086,
          "parent_id": 1022,
          "label": "黄山市",
          "lng": "118.338272",
          "lat": "29.715185",
          "level": "city",
          "children": [
            {
              "value": 1087,
              "parent_id": 1086,
              "label": "屯溪区",
              "lng": "118.315329",
              "lat": "29.696108",
              "level": "district"
            },
            {
              "value": 1088,
              "parent_id": 1086,
              "label": "黄山区",
              "lng": "118.141567",
              "lat": "30.272942",
              "level": "district"
            },
            {
              "value": 1089,
              "parent_id": 1086,
              "label": "徽州区",
              "lng": "118.336743",
              "lat": "29.827271",
              "level": "district"
            },
            {
              "value": 1090,
              "parent_id": 1086,
              "label": "歙县",
              "lng": "118.415345",
              "lat": "29.861379",
              "level": "district"
            },
            {
              "value": 1091,
              "parent_id": 1086,
              "label": "休宁县",
              "lng": "118.193618",
              "lat": "29.784124",
              "level": "district"
            },
            {
              "value": 1092,
              "parent_id": 1086,
              "label": "黟县",
              "lng": "117.938373",
              "lat": "29.924805",
              "level": "district"
            },
            {
              "value": 1093,
              "parent_id": 1086,
              "label": "祁门县",
              "lng": "117.717396",
              "lat": "29.854055",
              "level": "district"
            }
          ]
        },
        {
          "value": 1094,
          "parent_id": 1022,
          "label": "滁州市",
          "lng": "118.327944",
          "lat": "32.255636",
          "level": "city",
          "children": [
            {
              "value": 1095,
              "parent_id": 1094,
              "label": "琅琊区",
              "lng": "118.305961",
              "lat": "32.294631",
              "level": "district"
            },
            {
              "value": 1096,
              "parent_id": 1094,
              "label": "南谯区",
              "lng": "118.41697",
              "lat": "32.200197",
              "level": "district"
            },
            {
              "value": 1097,
              "parent_id": 1094,
              "label": "来安县",
              "lng": "118.435718",
              "lat": "32.452199",
              "level": "district"
            },
            {
              "value": 1098,
              "parent_id": 1094,
              "label": "全椒县",
              "lng": "118.274149",
              "lat": "32.08593",
              "level": "district"
            },
            {
              "value": 1099,
              "parent_id": 1094,
              "label": "定远县",
              "lng": "117.698562",
              "lat": "32.530981",
              "level": "district"
            },
            {
              "value": 1100,
              "parent_id": 1094,
              "label": "凤阳县",
              "lng": "117.531622",
              "lat": "32.874735",
              "level": "district"
            },
            {
              "value": 1101,
              "parent_id": 1094,
              "label": "天长市",
              "lng": "119.004816",
              "lat": "32.667571",
              "level": "district"
            },
            {
              "value": 1102,
              "parent_id": 1094,
              "label": "明光市",
              "lng": "118.018193",
              "lat": "32.78196",
              "level": "district"
            }
          ]
        },
        {
          "value": 1103,
          "parent_id": 1022,
          "label": "阜阳市",
          "lng": "115.814504",
          "lat": "32.890479",
          "level": "city",
          "children": [
            {
              "value": 1104,
              "parent_id": 1103,
              "label": "颍州区",
              "lng": "115.806942",
              "lat": "32.883468",
              "level": "district"
            },
            {
              "value": 1105,
              "parent_id": 1103,
              "label": "颍东区",
              "lng": "115.856762",
              "lat": "32.912477",
              "level": "district"
            },
            {
              "value": 1106,
              "parent_id": 1103,
              "label": "颍泉区",
              "lng": "115.80835",
              "lat": "32.925211",
              "level": "district"
            },
            {
              "value": 1107,
              "parent_id": 1103,
              "label": "临泉县",
              "lng": "115.263115",
              "lat": "33.039715",
              "level": "district"
            },
            {
              "value": 1108,
              "parent_id": 1103,
              "label": "太和县",
              "lng": "115.621941",
              "lat": "33.160327",
              "level": "district"
            },
            {
              "value": 1109,
              "parent_id": 1103,
              "label": "阜南县",
              "lng": "115.595643",
              "lat": "32.658297",
              "level": "district"
            },
            {
              "value": 1110,
              "parent_id": 1103,
              "label": "颍上县",
              "lng": "116.256772",
              "lat": "32.653211",
              "level": "district"
            },
            {
              "value": 1111,
              "parent_id": 1103,
              "label": "界首市",
              "lng": "115.374821",
              "lat": "33.258244",
              "level": "district"
            }
          ]
        },
        {
          "value": 1112,
          "parent_id": 1022,
          "label": "宿州市",
          "lng": "116.964195",
          "lat": "33.647309",
          "level": "city",
          "children": [
            {
              "value": 1113,
              "parent_id": 1112,
              "label": "埇桥区",
              "lng": "116.977203",
              "lat": "33.64059",
              "level": "district"
            },
            {
              "value": 1114,
              "parent_id": 1112,
              "label": "砀山县",
              "lng": "116.367095",
              "lat": "34.442561",
              "level": "district"
            },
            {
              "value": 1115,
              "parent_id": 1112,
              "label": "萧县",
              "lng": "116.947349",
              "lat": "34.188732",
              "level": "district"
            },
            {
              "value": 1116,
              "parent_id": 1112,
              "label": "灵璧县",
              "lng": "117.549395",
              "lat": "33.554604",
              "level": "district"
            },
            {
              "value": 1117,
              "parent_id": 1112,
              "label": "泗县",
              "lng": "117.910629",
              "lat": "33.482982",
              "level": "district"
            }
          ]
        },
        {
          "value": 1118,
          "parent_id": 1022,
          "label": "六安市",
          "lng": "116.520139",
          "lat": "31.735456",
          "level": "city",
          "children": [
            {
              "value": 1119,
              "parent_id": 1118,
              "label": "金安区",
              "lng": "116.539173",
              "lat": "31.750119",
              "level": "district"
            },
            {
              "value": 1120,
              "parent_id": 1118,
              "label": "裕安区",
              "lng": "116.479829",
              "lat": "31.738183",
              "level": "district"
            },
            {
              "value": 1121,
              "parent_id": 1118,
              "label": "叶集区",
              "lng": "115.925271",
              "lat": "31.863693",
              "level": "district"
            },
            {
              "value": 1122,
              "parent_id": 1118,
              "label": "霍邱县",
              "lng": "116.277911",
              "lat": "32.353038",
              "level": "district"
            },
            {
              "value": 1123,
              "parent_id": 1118,
              "label": "舒城县",
              "lng": "116.948736",
              "lat": "31.462234",
              "level": "district"
            },
            {
              "value": 1124,
              "parent_id": 1118,
              "label": "金寨县",
              "lng": "115.934366",
              "lat": "31.72717",
              "level": "district"
            },
            {
              "value": 1125,
              "parent_id": 1118,
              "label": "霍山县",
              "lng": "116.351892",
              "lat": "31.410561",
              "level": "district"
            }
          ]
        },
        {
          "value": 1126,
          "parent_id": 1022,
          "label": "亳州市",
          "lng": "115.77867",
          "lat": "33.844592",
          "level": "city",
          "children": [
            {
              "value": 1127,
              "parent_id": 1126,
              "label": "谯城区",
              "lng": "115.779025",
              "lat": "33.876235",
              "level": "district"
            },
            {
              "value": 1128,
              "parent_id": 1126,
              "label": "涡阳县",
              "lng": "116.215665",
              "lat": "33.492921",
              "level": "district"
            },
            {
              "value": 1129,
              "parent_id": 1126,
              "label": "蒙城县",
              "lng": "116.564247",
              "lat": "33.26583",
              "level": "district"
            },
            {
              "value": 1130,
              "parent_id": 1126,
              "label": "利辛县",
              "lng": "116.208564",
              "lat": "33.144515",
              "level": "district"
            }
          ]
        },
        {
          "value": 1131,
          "parent_id": 1022,
          "label": "池州市",
          "lng": "117.491592",
          "lat": "30.664779",
          "level": "city",
          "children": [
            {
              "value": 1132,
              "parent_id": 1131,
              "label": "贵池区",
              "lng": "117.567264",
              "lat": "30.687219",
              "level": "district"
            },
            {
              "value": 1133,
              "parent_id": 1131,
              "label": "东至县",
              "lng": "117.027618",
              "lat": "30.111163",
              "level": "district"
            },
            {
              "value": 1134,
              "parent_id": 1131,
              "label": "石台县",
              "lng": "117.486306",
              "lat": "30.210313",
              "level": "district"
            },
            {
              "value": 1135,
              "parent_id": 1131,
              "label": "青阳县",
              "lng": "117.84743",
              "lat": "30.63923",
              "level": "district"
            }
          ]
        },
        {
          "value": 1136,
          "parent_id": 1022,
          "label": "宣城市",
          "lng": "118.75868",
          "lat": "30.940195",
          "level": "city",
          "children": [
            {
              "value": 1137,
              "parent_id": 1136,
              "label": "宣州区",
              "lng": "118.785561",
              "lat": "30.944076",
              "level": "district"
            },
            {
              "value": 1138,
              "parent_id": 1136,
              "label": "郎溪县",
              "lng": "119.179656",
              "lat": "31.126412",
              "level": "district"
            },
            {
              "value": 1139,
              "parent_id": 1136,
              "label": "广德县",
              "lng": "119.420935",
              "lat": "30.877555",
              "level": "district"
            },
            {
              "value": 1140,
              "parent_id": 1136,
              "label": "泾县",
              "lng": "118.419859",
              "lat": "30.688634",
              "level": "district"
            },
            {
              "value": 1141,
              "parent_id": 1136,
              "label": "绩溪县",
              "lng": "118.578519",
              "lat": "30.067533",
              "level": "district"
            },
            {
              "value": 1142,
              "parent_id": 1136,
              "label": "旌德县",
              "lng": "118.549861",
              "lat": "30.298142",
              "level": "district"
            },
            {
              "value": 1143,
              "parent_id": 1136,
              "label": "宁国市",
              "lng": "118.983171",
              "lat": "30.633882",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1144,
      "parent_id": 1,
      "label": "福建省",
      "lng": "119.295143",
      "lat": "26.100779",
      "level": "province",
      "children": [
        {
          "value": 1145,
          "parent_id": 1144,
          "label": "福州市",
          "lng": "119.296389",
          "lat": "26.074268",
          "level": "city",
          "children": [
            {
              "value": 1146,
              "parent_id": 1145,
              "label": "鼓楼区",
              "lng": "119.303917",
              "lat": "26.081983",
              "level": "district"
            },
            {
              "value": 1147,
              "parent_id": 1145,
              "label": "台江区",
              "lng": "119.314041",
              "lat": "26.052843",
              "level": "district"
            },
            {
              "value": 1148,
              "parent_id": 1145,
              "label": "仓山区",
              "lng": "119.273545",
              "lat": "26.046743",
              "level": "district"
            },
            {
              "value": 1149,
              "parent_id": 1145,
              "label": "马尾区",
              "lng": "119.455588",
              "lat": "25.9895",
              "level": "district"
            },
            {
              "value": 1150,
              "parent_id": 1145,
              "label": "晋安区",
              "lng": "119.328521",
              "lat": "26.082107",
              "level": "district"
            },
            {
              "value": 1151,
              "parent_id": 1145,
              "label": "闽侯县",
              "lng": "119.131724",
              "lat": "26.150047",
              "level": "district"
            },
            {
              "value": 1152,
              "parent_id": 1145,
              "label": "连江县",
              "lng": "119.539704",
              "lat": "26.197364",
              "level": "district"
            },
            {
              "value": 1153,
              "parent_id": 1145,
              "label": "罗源县",
              "lng": "119.549776",
              "lat": "26.489558",
              "level": "district"
            },
            {
              "value": 1154,
              "parent_id": 1145,
              "label": "闽清县",
              "lng": "118.863361",
              "lat": "26.221197",
              "level": "district"
            },
            {
              "value": 1155,
              "parent_id": 1145,
              "label": "永泰县",
              "lng": "118.932592",
              "lat": "25.866694",
              "level": "district"
            },
            {
              "value": 1156,
              "parent_id": 1145,
              "label": "平潭县",
              "lng": "119.790168",
              "lat": "25.49872",
              "level": "district"
            },
            {
              "value": 1157,
              "parent_id": 1145,
              "label": "福清市",
              "lng": "119.384201",
              "lat": "25.72071",
              "level": "district"
            },
            {
              "value": 1158,
              "parent_id": 1145,
              "label": "长乐市",
              "lng": "119.523266",
              "lat": "25.962888",
              "level": "district"
            }
          ]
        },
        {
          "value": 1159,
          "parent_id": 1144,
          "label": "厦门市",
          "lng": "118.089204",
          "lat": "24.479664",
          "level": "city",
          "children": [
            {
              "value": 1160,
              "parent_id": 1159,
              "label": "思明区",
              "lng": "118.082649",
              "lat": "24.445484",
              "level": "district"
            },
            {
              "value": 1161,
              "parent_id": 1159,
              "label": "海沧区",
              "lng": "118.032984",
              "lat": "24.484685",
              "level": "district"
            },
            {
              "value": 1162,
              "parent_id": 1159,
              "label": "湖里区",
              "lng": "118.146768",
              "lat": "24.512904",
              "level": "district"
            },
            {
              "value": 1163,
              "parent_id": 1159,
              "label": "集美区",
              "lng": "118.097337",
              "lat": "24.575969",
              "level": "district"
            },
            {
              "value": 1164,
              "parent_id": 1159,
              "label": "同安区",
              "lng": "118.152041",
              "lat": "24.723234",
              "level": "district"
            },
            {
              "value": 1165,
              "parent_id": 1159,
              "label": "翔安区",
              "lng": "118.248034",
              "lat": "24.618543",
              "level": "district"
            }
          ]
        },
        {
          "value": 1166,
          "parent_id": 1144,
          "label": "莆田市",
          "lng": "119.007777",
          "lat": "25.454084",
          "level": "city",
          "children": [
            {
              "value": 1167,
              "parent_id": 1166,
              "label": "城厢区",
              "lng": "118.993884",
              "lat": "25.419319",
              "level": "district"
            },
            {
              "value": 1168,
              "parent_id": 1166,
              "label": "涵江区",
              "lng": "119.116289",
              "lat": "25.45872",
              "level": "district"
            },
            {
              "value": 1169,
              "parent_id": 1166,
              "label": "荔城区",
              "lng": "119.015061",
              "lat": "25.431941",
              "level": "district"
            },
            {
              "value": 1170,
              "parent_id": 1166,
              "label": "秀屿区",
              "lng": "119.105494",
              "lat": "25.31836",
              "level": "district"
            },
            {
              "value": 1171,
              "parent_id": 1166,
              "label": "仙游县",
              "lng": "118.691637",
              "lat": "25.362093",
              "level": "district"
            }
          ]
        },
        {
          "value": 1172,
          "parent_id": 1144,
          "label": "三明市",
          "lng": "117.638678",
          "lat": "26.263406",
          "level": "city",
          "children": [
            {
              "value": 1173,
              "parent_id": 1172,
              "label": "梅列区",
              "lng": "117.645855",
              "lat": "26.271711",
              "level": "district"
            },
            {
              "value": 1174,
              "parent_id": 1172,
              "label": "三元区",
              "lng": "117.608044",
              "lat": "26.234019",
              "level": "district"
            },
            {
              "value": 1175,
              "parent_id": 1172,
              "label": "明溪县",
              "lng": "117.202226",
              "lat": "26.355856",
              "level": "district"
            },
            {
              "value": 1176,
              "parent_id": 1172,
              "label": "清流县",
              "lng": "116.816909",
              "lat": "26.177796",
              "level": "district"
            },
            {
              "value": 1177,
              "parent_id": 1172,
              "label": "宁化县",
              "lng": "116.654365",
              "lat": "26.261754",
              "level": "district"
            },
            {
              "value": 1178,
              "parent_id": 1172,
              "label": "大田县",
              "lng": "117.847115",
              "lat": "25.692699",
              "level": "district"
            },
            {
              "value": 1179,
              "parent_id": 1172,
              "label": "尤溪县",
              "lng": "118.190467",
              "lat": "26.170171",
              "level": "district"
            },
            {
              "value": 1180,
              "parent_id": 1172,
              "label": "沙县",
              "lng": "117.792396",
              "lat": "26.397199",
              "level": "district"
            },
            {
              "value": 1181,
              "parent_id": 1172,
              "label": "将乐县",
              "lng": "117.471372",
              "lat": "26.728952",
              "level": "district"
            },
            {
              "value": 1182,
              "parent_id": 1172,
              "label": "泰宁县",
              "lng": "117.17574",
              "lat": "26.900259",
              "level": "district"
            },
            {
              "value": 1183,
              "parent_id": 1172,
              "label": "建宁县",
              "lng": "116.848443",
              "lat": "26.833588",
              "level": "district"
            },
            {
              "value": 1184,
              "parent_id": 1172,
              "label": "永安市",
              "lng": "117.365052",
              "lat": "25.941937",
              "level": "district"
            }
          ]
        },
        {
          "value": 1185,
          "parent_id": 1144,
          "label": "泉州市",
          "lng": "118.675676",
          "lat": "24.874132",
          "level": "city",
          "children": [
            {
              "value": 1186,
              "parent_id": 1185,
              "label": "鲤城区",
              "lng": "118.587097",
              "lat": "24.907424",
              "level": "district"
            },
            {
              "value": 1187,
              "parent_id": 1185,
              "label": "丰泽区",
              "lng": "118.613172",
              "lat": "24.891173",
              "level": "district"
            },
            {
              "value": 1188,
              "parent_id": 1185,
              "label": "洛江区",
              "lng": "118.671193",
              "lat": "24.939796",
              "level": "district"
            },
            {
              "value": 1189,
              "parent_id": 1185,
              "label": "泉港区",
              "lng": "118.916309",
              "lat": "25.119815",
              "level": "district"
            },
            {
              "value": 1190,
              "parent_id": 1185,
              "label": "惠安县",
              "lng": "118.796607",
              "lat": "25.030801",
              "level": "district"
            },
            {
              "value": 1191,
              "parent_id": 1185,
              "label": "安溪县",
              "lng": "118.186288",
              "lat": "25.055954",
              "level": "district"
            },
            {
              "value": 1192,
              "parent_id": 1185,
              "label": "永春县",
              "lng": "118.294048",
              "lat": "25.321565",
              "level": "district"
            },
            {
              "value": 1193,
              "parent_id": 1185,
              "label": "德化县",
              "lng": "118.241094",
              "lat": "25.491493",
              "level": "district"
            },
            {
              "value": 1194,
              "parent_id": 1185,
              "label": "金门县",
              "lng": "118.323221",
              "lat": "24.436417",
              "level": "district"
            },
            {
              "value": 1195,
              "parent_id": 1185,
              "label": "石狮市",
              "lng": "118.648066",
              "lat": "24.732204",
              "level": "district"
            },
            {
              "value": 1196,
              "parent_id": 1185,
              "label": "晋江市",
              "lng": "118.551682",
              "lat": "24.781636",
              "level": "district"
            },
            {
              "value": 1197,
              "parent_id": 1185,
              "label": "南安市",
              "lng": "118.386279",
              "lat": "24.960385",
              "level": "district"
            }
          ]
        },
        {
          "value": 1198,
          "parent_id": 1144,
          "label": "漳州市",
          "lng": "117.647093",
          "lat": "24.513025",
          "level": "city",
          "children": [
            {
              "value": 1199,
              "parent_id": 1198,
              "label": "芗城区",
              "lng": "117.653968",
              "lat": "24.510787",
              "level": "district"
            },
            {
              "value": 1200,
              "parent_id": 1198,
              "label": "龙文区",
              "lng": "117.709754",
              "lat": "24.503113",
              "level": "district"
            },
            {
              "value": 1201,
              "parent_id": 1198,
              "label": "云霄县",
              "lng": "117.339573",
              "lat": "23.957936",
              "level": "district"
            },
            {
              "value": 1202,
              "parent_id": 1198,
              "label": "漳浦县",
              "lng": "117.613808",
              "lat": "24.117102",
              "level": "district"
            },
            {
              "value": 1203,
              "parent_id": 1198,
              "label": "诏安县",
              "lng": "117.175184",
              "lat": "23.711579",
              "level": "district"
            },
            {
              "value": 1204,
              "parent_id": 1198,
              "label": "长泰县",
              "lng": "117.759153",
              "lat": "24.625449",
              "level": "district"
            },
            {
              "value": 1205,
              "parent_id": 1198,
              "label": "东山县",
              "lng": "117.430061",
              "lat": "23.701262",
              "level": "district"
            },
            {
              "value": 1206,
              "parent_id": 1198,
              "label": "南靖县",
              "lng": "117.35732",
              "lat": "24.514654",
              "level": "district"
            },
            {
              "value": 1207,
              "parent_id": 1198,
              "label": "平和县",
              "lng": "117.315017",
              "lat": "24.363508",
              "level": "district"
            },
            {
              "value": 1208,
              "parent_id": 1198,
              "label": "华安县",
              "lng": "117.534103",
              "lat": "25.004425",
              "level": "district"
            },
            {
              "value": 1209,
              "parent_id": 1198,
              "label": "龙海市",
              "lng": "117.818197",
              "lat": "24.446706",
              "level": "district"
            }
          ]
        },
        {
          "value": 1210,
          "parent_id": 1144,
          "label": "南平市",
          "lng": "118.17771",
          "lat": "26.641774",
          "level": "city",
          "children": [
            {
              "value": 1211,
              "parent_id": 1210,
              "label": "延平区",
              "lng": "118.182036",
              "lat": "26.637438",
              "level": "district"
            },
            {
              "value": 1212,
              "parent_id": 1210,
              "label": "建阳区",
              "lng": "118.120464",
              "lat": "27.331876",
              "level": "district"
            },
            {
              "value": 1213,
              "parent_id": 1210,
              "label": "顺昌县",
              "lng": "117.810357",
              "lat": "26.793288",
              "level": "district"
            },
            {
              "value": 1214,
              "parent_id": 1210,
              "label": "浦城县",
              "lng": "118.541256",
              "lat": "27.917263",
              "level": "district"
            },
            {
              "value": 1215,
              "parent_id": 1210,
              "label": "光泽县",
              "lng": "117.334106",
              "lat": "27.540987",
              "level": "district"
            },
            {
              "value": 1216,
              "parent_id": 1210,
              "label": "松溪县",
              "lng": "118.785468",
              "lat": "27.526232",
              "level": "district"
            },
            {
              "value": 1217,
              "parent_id": 1210,
              "label": "政和县",
              "lng": "118.857642",
              "lat": "27.366104",
              "level": "district"
            },
            {
              "value": 1218,
              "parent_id": 1210,
              "label": "邵武市",
              "lng": "117.492533",
              "lat": "27.340326",
              "level": "district"
            },
            {
              "value": 1219,
              "parent_id": 1210,
              "label": "武夷山市",
              "lng": "118.035309",
              "lat": "27.756647",
              "level": "district"
            },
            {
              "value": 1220,
              "parent_id": 1210,
              "label": "建瓯市",
              "lng": "118.304966",
              "lat": "27.022774",
              "level": "district"
            }
          ]
        },
        {
          "value": 1221,
          "parent_id": 1144,
          "label": "龙岩市",
          "lng": "117.017295",
          "lat": "25.075119",
          "level": "city",
          "children": [
            {
              "value": 1222,
              "parent_id": 1221,
              "label": "新罗区",
              "lng": "117.037155",
              "lat": "25.098312",
              "level": "district"
            },
            {
              "value": 1223,
              "parent_id": 1221,
              "label": "永定区",
              "lng": "116.732091",
              "lat": "24.723961",
              "level": "district"
            },
            {
              "value": 1224,
              "parent_id": 1221,
              "label": "长汀县",
              "lng": "116.357581",
              "lat": "25.833531",
              "level": "district"
            },
            {
              "value": 1225,
              "parent_id": 1221,
              "label": "上杭县",
              "lng": "116.420098",
              "lat": "25.049518",
              "level": "district"
            },
            {
              "value": 1226,
              "parent_id": 1221,
              "label": "武平县",
              "lng": "116.100414",
              "lat": "25.095386",
              "level": "district"
            },
            {
              "value": 1227,
              "parent_id": 1221,
              "label": "连城县",
              "lng": "116.754472",
              "lat": "25.710538",
              "level": "district"
            },
            {
              "value": 1228,
              "parent_id": 1221,
              "label": "漳平市",
              "lng": "117.419998",
              "lat": "25.290184",
              "level": "district"
            }
          ]
        },
        {
          "value": 1229,
          "parent_id": 1144,
          "label": "宁德市",
          "lng": "119.547932",
          "lat": "26.665617",
          "level": "city",
          "children": [
            {
              "value": 1230,
              "parent_id": 1229,
              "label": "蕉城区",
              "lng": "119.526299",
              "lat": "26.66061",
              "level": "district"
            },
            {
              "value": 1231,
              "parent_id": 1229,
              "label": "霞浦县",
              "lng": "120.005146",
              "lat": "26.885703",
              "level": "district"
            },
            {
              "value": 1232,
              "parent_id": 1229,
              "label": "古田县",
              "lng": "118.746284",
              "lat": "26.577837",
              "level": "district"
            },
            {
              "value": 1233,
              "parent_id": 1229,
              "label": "屏南县",
              "lng": "118.985895",
              "lat": "26.908276",
              "level": "district"
            },
            {
              "value": 1234,
              "parent_id": 1229,
              "label": "寿宁县",
              "lng": "119.514986",
              "lat": "27.454479",
              "level": "district"
            },
            {
              "value": 1235,
              "parent_id": 1229,
              "label": "周宁县",
              "lng": "119.339025",
              "lat": "27.104591",
              "level": "district"
            },
            {
              "value": 1236,
              "parent_id": 1229,
              "label": "柘荣县",
              "lng": "119.900609",
              "lat": "27.233933",
              "level": "district"
            },
            {
              "value": 1237,
              "parent_id": 1229,
              "label": "福安市",
              "lng": "119.64785",
              "lat": "27.08834",
              "level": "district"
            },
            {
              "value": 1238,
              "parent_id": 1229,
              "label": "福鼎市",
              "lng": "120.216977",
              "lat": "27.324479",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1239,
      "parent_id": 1,
      "label": "江西省",
      "lng": "115.81635",
      "lat": "28.63666",
      "level": "province",
      "children": [
        {
          "value": 1240,
          "parent_id": 1239,
          "label": "南昌市",
          "lng": "115.858198",
          "lat": "28.682892",
          "level": "city",
          "children": [
            {
              "value": 1241,
              "parent_id": 1240,
              "label": "东湖区",
              "lng": "115.903526",
              "lat": "28.698731",
              "level": "district"
            },
            {
              "value": 1242,
              "parent_id": 1240,
              "label": "西湖区",
              "lng": "115.877233",
              "lat": "28.657595",
              "level": "district"
            },
            {
              "value": 1243,
              "parent_id": 1240,
              "label": "青云谱区",
              "lng": "115.925749",
              "lat": "28.621169",
              "level": "district"
            },
            {
              "value": 1244,
              "parent_id": 1240,
              "label": "湾里区",
              "lng": "115.730847",
              "lat": "28.714796",
              "level": "district"
            },
            {
              "value": 1245,
              "parent_id": 1240,
              "label": "青山湖区",
              "lng": "115.962144",
              "lat": "28.682984",
              "level": "district"
            },
            {
              "value": 1246,
              "parent_id": 1240,
              "label": "新建区",
              "lng": "115.815277",
              "lat": "28.692864",
              "level": "district"
            },
            {
              "value": 1247,
              "parent_id": 1240,
              "label": "南昌县",
              "lng": "115.933742",
              "lat": "28.558296",
              "level": "district"
            },
            {
              "value": 1248,
              "parent_id": 1240,
              "label": "安义县",
              "lng": "115.548658",
              "lat": "28.846",
              "level": "district"
            },
            {
              "value": 1249,
              "parent_id": 1240,
              "label": "进贤县",
              "lng": "116.241288",
              "lat": "28.377343",
              "level": "district"
            }
          ]
        },
        {
          "value": 1250,
          "parent_id": 1239,
          "label": "景德镇市",
          "lng": "117.178222",
          "lat": "29.268945",
          "level": "city",
          "children": [
            {
              "value": 1251,
              "parent_id": 1250,
              "label": "昌江区",
              "lng": "117.18363",
              "lat": "29.273565",
              "level": "district"
            },
            {
              "value": 1252,
              "parent_id": 1250,
              "label": "珠山区",
              "lng": "117.202919",
              "lat": "29.299938",
              "level": "district"
            },
            {
              "value": 1253,
              "parent_id": 1250,
              "label": "浮梁县",
              "lng": "117.215066",
              "lat": "29.352253",
              "level": "district"
            },
            {
              "value": 1254,
              "parent_id": 1250,
              "label": "乐平市",
              "lng": "117.151796",
              "lat": "28.97844",
              "level": "district"
            }
          ]
        },
        {
          "value": 1255,
          "parent_id": 1239,
          "label": "萍乡市",
          "lng": "113.887083",
          "lat": "27.658373",
          "level": "city",
          "children": [
            {
              "value": 1256,
              "parent_id": 1255,
              "label": "安源区",
              "lng": "113.870704",
              "lat": "27.61511",
              "level": "district"
            },
            {
              "value": 1257,
              "parent_id": 1255,
              "label": "湘东区",
              "lng": "113.733047",
              "lat": "27.640075",
              "level": "district"
            },
            {
              "value": 1258,
              "parent_id": 1255,
              "label": "莲花县",
              "lng": "113.961488",
              "lat": "27.127664",
              "level": "district"
            },
            {
              "value": 1259,
              "parent_id": 1255,
              "label": "上栗县",
              "lng": "113.795311",
              "lat": "27.880301",
              "level": "district"
            },
            {
              "value": 1260,
              "parent_id": 1255,
              "label": "芦溪县",
              "lng": "114.029827",
              "lat": "27.630806",
              "level": "district"
            }
          ]
        },
        {
          "value": 1261,
          "parent_id": 1239,
          "label": "九江市",
          "lng": "115.952914",
          "lat": "29.662117",
          "level": "city",
          "children": [
            {
              "value": 1262,
              "parent_id": 1261,
              "label": "濂溪区",
              "lng": "115.992842",
              "lat": "29.668064",
              "level": "district"
            },
            {
              "value": 1263,
              "parent_id": 1261,
              "label": "浔阳区",
              "lng": "115.990301",
              "lat": "29.727593",
              "level": "district"
            },
            {
              "value": 1264,
              "parent_id": 1261,
              "label": "九江县",
              "lng": "115.911323",
              "lat": "29.608431",
              "level": "district"
            },
            {
              "value": 1265,
              "parent_id": 1261,
              "label": "武宁县",
              "lng": "115.092757",
              "lat": "29.246591",
              "level": "district"
            },
            {
              "value": 1266,
              "parent_id": 1261,
              "label": "修水县",
              "lng": "114.546836",
              "lat": "29.025726",
              "level": "district"
            },
            {
              "value": 1267,
              "parent_id": 1261,
              "label": "永修县",
              "lng": "115.831956",
              "lat": "29.011871",
              "level": "district"
            },
            {
              "value": 1268,
              "parent_id": 1261,
              "label": "德安县",
              "lng": "115.767447",
              "lat": "29.298696",
              "level": "district"
            },
            {
              "value": 1269,
              "parent_id": 1261,
              "label": "庐山市",
              "lng": "116.04506",
              "lat": "29.448128",
              "level": "district"
            },
            {
              "value": 1270,
              "parent_id": 1261,
              "label": "都昌县",
              "lng": "116.203979",
              "lat": "29.273239",
              "level": "district"
            },
            {
              "value": 1271,
              "parent_id": 1261,
              "label": "湖口县",
              "lng": "116.251947",
              "lat": "29.731101",
              "level": "district"
            },
            {
              "value": 1272,
              "parent_id": 1261,
              "label": "彭泽县",
              "lng": "116.56438",
              "lat": "29.876991",
              "level": "district"
            },
            {
              "value": 1273,
              "parent_id": 1261,
              "label": "瑞昌市",
              "lng": "115.681335",
              "lat": "29.675834",
              "level": "district"
            },
            {
              "value": 1274,
              "parent_id": 1261,
              "label": "共青城市",
              "lng": "115.808844",
              "lat": "29.248316",
              "level": "district"
            }
          ]
        },
        {
          "value": 1275,
          "parent_id": 1239,
          "label": "新余市",
          "lng": "114.917346",
          "lat": "27.817808",
          "level": "city",
          "children": [
            {
              "value": 1276,
              "parent_id": 1275,
              "label": "渝水区",
              "lng": "114.944549",
              "lat": "27.800148",
              "level": "district"
            },
            {
              "value": 1277,
              "parent_id": 1275,
              "label": "分宜县",
              "lng": "114.692049",
              "lat": "27.814757",
              "level": "district"
            }
          ]
        },
        {
          "value": 1278,
          "parent_id": 1239,
          "label": "鹰潭市",
          "lng": "117.042173",
          "lat": "28.272537",
          "level": "city",
          "children": [
            {
              "value": 1279,
              "parent_id": 1278,
              "label": "月湖区",
              "lng": "117.102475",
              "lat": "28.267018",
              "level": "district"
            },
            {
              "value": 1280,
              "parent_id": 1278,
              "label": "余江县",
              "lng": "116.85926",
              "lat": "28.198652",
              "level": "district"
            },
            {
              "value": 1281,
              "parent_id": 1278,
              "label": "贵溪市",
              "lng": "117.245497",
              "lat": "28.292519",
              "level": "district"
            }
          ]
        },
        {
          "value": 1282,
          "parent_id": 1239,
          "label": "赣州市",
          "lng": "114.933546",
          "lat": "25.830694",
          "level": "city",
          "children": [
            {
              "value": 1283,
              "parent_id": 1282,
              "label": "章贡区",
              "lng": "114.921171",
              "lat": "25.817816",
              "level": "district"
            },
            {
              "value": 1284,
              "parent_id": 1282,
              "label": "南康区",
              "lng": "114.765412",
              "lat": "25.66145",
              "level": "district"
            },
            {
              "value": 1285,
              "parent_id": 1282,
              "label": "赣县区",
              "lng": "115.011561",
              "lat": "25.86069",
              "level": "district"
            },
            {
              "value": 1286,
              "parent_id": 1282,
              "label": "信丰县",
              "lng": "114.922922",
              "lat": "25.386379",
              "level": "district"
            },
            {
              "value": 1287,
              "parent_id": 1282,
              "label": "大余县",
              "lng": "114.362112",
              "lat": "25.401313",
              "level": "district"
            },
            {
              "value": 1288,
              "parent_id": 1282,
              "label": "上犹县",
              "lng": "114.551138",
              "lat": "25.785172",
              "level": "district"
            },
            {
              "value": 1289,
              "parent_id": 1282,
              "label": "崇义县",
              "lng": "114.308267",
              "lat": "25.681784",
              "level": "district"
            },
            {
              "value": 1290,
              "parent_id": 1282,
              "label": "安远县",
              "lng": "115.393922",
              "lat": "25.136927",
              "level": "district"
            },
            {
              "value": 1291,
              "parent_id": 1282,
              "label": "龙南县",
              "lng": "114.789873",
              "lat": "24.911069",
              "level": "district"
            },
            {
              "value": 1292,
              "parent_id": 1282,
              "label": "定南县",
              "lng": "115.027845",
              "lat": "24.78441",
              "level": "district"
            },
            {
              "value": 1293,
              "parent_id": 1282,
              "label": "全南县",
              "lng": "114.530125",
              "lat": "24.742403",
              "level": "district"
            },
            {
              "value": 1294,
              "parent_id": 1282,
              "label": "宁都县",
              "lng": "116.009472",
              "lat": "26.470116",
              "level": "district"
            },
            {
              "value": 1295,
              "parent_id": 1282,
              "label": "于都县",
              "lng": "115.415508",
              "lat": "25.952068",
              "level": "district"
            },
            {
              "value": 1296,
              "parent_id": 1282,
              "label": "兴国县",
              "lng": "115.363189",
              "lat": "26.337937",
              "level": "district"
            },
            {
              "value": 1297,
              "parent_id": 1282,
              "label": "会昌县",
              "lng": "115.786056",
              "lat": "25.600272",
              "level": "district"
            },
            {
              "value": 1298,
              "parent_id": 1282,
              "label": "寻乌县",
              "lng": "115.637933",
              "lat": "24.969167",
              "level": "district"
            },
            {
              "value": 1299,
              "parent_id": 1282,
              "label": "石城县",
              "lng": "116.346995",
              "lat": "26.314775",
              "level": "district"
            },
            {
              "value": 1300,
              "parent_id": 1282,
              "label": "瑞金市",
              "lng": "116.027134",
              "lat": "25.885555",
              "level": "district"
            }
          ]
        },
        {
          "value": 1301,
          "parent_id": 1239,
          "label": "吉安市",
          "lng": "114.966567",
          "lat": "27.090763",
          "level": "city",
          "children": [
            {
              "value": 1302,
              "parent_id": 1301,
              "label": "吉州区",
              "lng": "114.994763",
              "lat": "27.143801",
              "level": "district"
            },
            {
              "value": 1303,
              "parent_id": 1301,
              "label": "青原区",
              "lng": "115.014811",
              "lat": "27.081977",
              "level": "district"
            },
            {
              "value": 1304,
              "parent_id": 1301,
              "label": "吉安县",
              "lng": "114.907875",
              "lat": "27.039787",
              "level": "district"
            },
            {
              "value": 1305,
              "parent_id": 1301,
              "label": "吉水县",
              "lng": "115.135507",
              "lat": "27.229632",
              "level": "district"
            },
            {
              "value": 1306,
              "parent_id": 1301,
              "label": "峡江县",
              "lng": "115.316566",
              "lat": "27.582901",
              "level": "district"
            },
            {
              "value": 1307,
              "parent_id": 1301,
              "label": "新干县",
              "lng": "115.387052",
              "lat": "27.740191",
              "level": "district"
            },
            {
              "value": 1308,
              "parent_id": 1301,
              "label": "永丰县",
              "lng": "115.421344",
              "lat": "27.316939",
              "level": "district"
            },
            {
              "value": 1309,
              "parent_id": 1301,
              "label": "泰和县",
              "lng": "114.92299",
              "lat": "26.801628",
              "level": "district"
            },
            {
              "value": 1310,
              "parent_id": 1301,
              "label": "遂川县",
              "lng": "114.520537",
              "lat": "26.313737",
              "level": "district"
            },
            {
              "value": 1311,
              "parent_id": 1301,
              "label": "万安县",
              "lng": "114.759364",
              "lat": "26.456553",
              "level": "district"
            },
            {
              "value": 1312,
              "parent_id": 1301,
              "label": "安福县",
              "lng": "114.619893",
              "lat": "27.392873",
              "level": "district"
            },
            {
              "value": 1313,
              "parent_id": 1301,
              "label": "永新县",
              "lng": "114.243072",
              "lat": "26.944962",
              "level": "district"
            },
            {
              "value": 1314,
              "parent_id": 1301,
              "label": "井冈山市",
              "lng": "114.289228",
              "lat": "26.748081",
              "level": "district"
            }
          ]
        },
        {
          "value": 1315,
          "parent_id": 1239,
          "label": "宜春市",
          "lng": "114.416785",
          "lat": "27.815743",
          "level": "city",
          "children": [
            {
              "value": 1316,
              "parent_id": 1315,
              "label": "袁州区",
              "lng": "114.427858",
              "lat": "27.797091",
              "level": "district"
            },
            {
              "value": 1317,
              "parent_id": 1315,
              "label": "奉新县",
              "lng": "115.400491",
              "lat": "28.688423",
              "level": "district"
            },
            {
              "value": 1318,
              "parent_id": 1315,
              "label": "万载县",
              "lng": "114.444854",
              "lat": "28.105689",
              "level": "district"
            },
            {
              "value": 1319,
              "parent_id": 1315,
              "label": "上高县",
              "lng": "114.947683",
              "lat": "28.238061",
              "level": "district"
            },
            {
              "value": 1320,
              "parent_id": 1315,
              "label": "宜丰县",
              "lng": "114.802852",
              "lat": "28.394565",
              "level": "district"
            },
            {
              "value": 1321,
              "parent_id": 1315,
              "label": "靖安县",
              "lng": "115.362628",
              "lat": "28.861478",
              "level": "district"
            },
            {
              "value": 1322,
              "parent_id": 1315,
              "label": "铜鼓县",
              "lng": "114.371172",
              "lat": "28.520769",
              "level": "district"
            },
            {
              "value": 1323,
              "parent_id": 1315,
              "label": "丰城市",
              "lng": "115.771093",
              "lat": "28.159141",
              "level": "district"
            },
            {
              "value": 1324,
              "parent_id": 1315,
              "label": "樟树市",
              "lng": "115.546152",
              "lat": "28.055853",
              "level": "district"
            },
            {
              "value": 1325,
              "parent_id": 1315,
              "label": "高安市",
              "lng": "115.360619",
              "lat": "28.441152",
              "level": "district"
            }
          ]
        },
        {
          "value": 1326,
          "parent_id": 1239,
          "label": "抚州市",
          "lng": "116.358181",
          "lat": "27.949217",
          "level": "city",
          "children": [
            {
              "value": 1327,
              "parent_id": 1326,
              "label": "临川区",
              "lng": "116.312166",
              "lat": "27.934572",
              "level": "district"
            },
            {
              "value": 1328,
              "parent_id": 1326,
              "label": "南城县",
              "lng": "116.63704",
              "lat": "27.569678",
              "level": "district"
            },
            {
              "value": 1329,
              "parent_id": 1326,
              "label": "黎川县",
              "lng": "116.907681",
              "lat": "27.282333",
              "level": "district"
            },
            {
              "value": 1330,
              "parent_id": 1326,
              "label": "南丰县",
              "lng": "116.525725",
              "lat": "27.218444",
              "level": "district"
            },
            {
              "value": 1331,
              "parent_id": 1326,
              "label": "崇仁县",
              "lng": "116.07626",
              "lat": "27.754466",
              "level": "district"
            },
            {
              "value": 1332,
              "parent_id": 1326,
              "label": "乐安县",
              "lng": "115.83048",
              "lat": "27.428765",
              "level": "district"
            },
            {
              "value": 1333,
              "parent_id": 1326,
              "label": "宜黄县",
              "lng": "116.236201",
              "lat": "27.554886",
              "level": "district"
            },
            {
              "value": 1334,
              "parent_id": 1326,
              "label": "金溪县",
              "lng": "116.755058",
              "lat": "27.918959",
              "level": "district"
            },
            {
              "value": 1335,
              "parent_id": 1326,
              "label": "资溪县",
              "lng": "117.060263",
              "lat": "27.706101",
              "level": "district"
            },
            {
              "value": 1336,
              "parent_id": 1326,
              "label": "东乡县",
              "lng": "116.603559",
              "lat": "28.247696",
              "level": "district"
            },
            {
              "value": 1337,
              "parent_id": 1326,
              "label": "广昌县",
              "lng": "116.335686",
              "lat": "26.843684",
              "level": "district"
            }
          ]
        },
        {
          "value": 1338,
          "parent_id": 1239,
          "label": "上饶市",
          "lng": "117.943433",
          "lat": "28.454863",
          "level": "city",
          "children": [
            {
              "value": 1339,
              "parent_id": 1338,
              "label": "信州区",
              "lng": "117.966268",
              "lat": "28.431006",
              "level": "district"
            },
            {
              "value": 1340,
              "parent_id": 1338,
              "label": "广丰区",
              "lng": "118.19124",
              "lat": "28.436285",
              "level": "district"
            },
            {
              "value": 1341,
              "parent_id": 1338,
              "label": "上饶县",
              "lng": "117.907849",
              "lat": "28.448982",
              "level": "district"
            },
            {
              "value": 1342,
              "parent_id": 1338,
              "label": "玉山县",
              "lng": "118.244769",
              "lat": "28.682309",
              "level": "district"
            },
            {
              "value": 1343,
              "parent_id": 1338,
              "label": "铅山县",
              "lng": "117.709659",
              "lat": "28.315664",
              "level": "district"
            },
            {
              "value": 1344,
              "parent_id": 1338,
              "label": "横峰县",
              "lng": "117.596452",
              "lat": "28.407117",
              "level": "district"
            },
            {
              "value": 1345,
              "parent_id": 1338,
              "label": "弋阳县",
              "lng": "117.449588",
              "lat": "28.378044",
              "level": "district"
            },
            {
              "value": 1346,
              "parent_id": 1338,
              "label": "余干县",
              "lng": "116.695646",
              "lat": "28.702302",
              "level": "district"
            },
            {
              "value": 1347,
              "parent_id": 1338,
              "label": "鄱阳县",
              "lng": "116.70359",
              "lat": "29.004847",
              "level": "district"
            },
            {
              "value": 1348,
              "parent_id": 1338,
              "label": "万年县",
              "lng": "117.058445",
              "lat": "28.694582",
              "level": "district"
            },
            {
              "value": 1349,
              "parent_id": 1338,
              "label": "婺源县",
              "lng": "117.861797",
              "lat": "29.248085",
              "level": "district"
            },
            {
              "value": 1350,
              "parent_id": 1338,
              "label": "德兴市",
              "lng": "117.578713",
              "lat": "28.946464",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1351,
      "parent_id": 1,
      "label": "山东省",
      "lng": "117.019915",
      "lat": "36.671156",
      "level": "province",
      "children": [
        {
          "value": 1352,
          "parent_id": 1351,
          "label": "济南市",
          "lng": "117.120098",
          "lat": "36.6512",
          "level": "city",
          "children": [
            {
              "value": 1353,
              "parent_id": 1352,
              "label": "历下区",
              "lng": "117.076441",
              "lat": "36.666465",
              "level": "district"
            },
            {
              "value": 1354,
              "parent_id": 1352,
              "label": "市中区",
              "lng": "116.997845",
              "lat": "36.651335",
              "level": "district"
            },
            {
              "value": 1355,
              "parent_id": 1352,
              "label": "槐荫区",
              "lng": "116.901224",
              "lat": "36.651441",
              "level": "district"
            },
            {
              "value": 1356,
              "parent_id": 1352,
              "label": "天桥区",
              "lng": "116.987153",
              "lat": "36.678589",
              "level": "district"
            },
            {
              "value": 1357,
              "parent_id": 1352,
              "label": "历城区",
              "lng": "117.06523",
              "lat": "36.680259",
              "level": "district"
            },
            {
              "value": 1358,
              "parent_id": 1352,
              "label": "长清区",
              "lng": "116.751843",
              "lat": "36.55371",
              "level": "district"
            },
            {
              "value": 1359,
              "parent_id": 1352,
              "label": "平阴县",
              "lng": "116.456006",
              "lat": "36.289251",
              "level": "district"
            },
            {
              "value": 1360,
              "parent_id": 1352,
              "label": "济阳县",
              "lng": "117.173524",
              "lat": "36.978537",
              "level": "district"
            },
            {
              "value": 1361,
              "parent_id": 1352,
              "label": "商河县",
              "lng": "117.157232",
              "lat": "37.309041",
              "level": "district"
            },
            {
              "value": 1362,
              "parent_id": 1352,
              "label": "章丘区",
              "lng": "117.526228",
              "lat": "36.681258",
              "level": "district"
            }
          ]
        },
        {
          "value": 1363,
          "parent_id": 1351,
          "label": "青岛市",
          "lng": "120.382621",
          "lat": "36.067131",
          "level": "city",
          "children": [
            {
              "value": 1364,
              "parent_id": 1363,
              "label": "市南区",
              "lng": "120.412392",
              "lat": "36.075651",
              "level": "district"
            },
            {
              "value": 1365,
              "parent_id": 1363,
              "label": "市北区",
              "lng": "120.374701",
              "lat": "36.0876",
              "level": "district"
            },
            {
              "value": 1366,
              "parent_id": 1363,
              "label": "黄岛区",
              "lng": "120.198055",
              "lat": "35.960933",
              "level": "district"
            },
            {
              "value": 1367,
              "parent_id": 1363,
              "label": "崂山区",
              "lng": "120.468956",
              "lat": "36.107538",
              "level": "district"
            },
            {
              "value": 1368,
              "parent_id": 1363,
              "label": "李沧区",
              "lng": "120.432922",
              "lat": "36.145519",
              "level": "district"
            },
            {
              "value": 1369,
              "parent_id": 1363,
              "label": "城阳区",
              "lng": "120.396256",
              "lat": "36.307559",
              "level": "district"
            },
            {
              "value": 1370,
              "parent_id": 1363,
              "label": "胶州市",
              "lng": "120.033382",
              "lat": "36.26468",
              "level": "district"
            },
            {
              "value": 1371,
              "parent_id": 1363,
              "label": "即墨市",
              "lng": "120.447158",
              "lat": "36.389408",
              "level": "district"
            },
            {
              "value": 1372,
              "parent_id": 1363,
              "label": "平度市",
              "lng": "119.98842",
              "lat": "36.776357",
              "level": "district"
            },
            {
              "value": 1373,
              "parent_id": 1363,
              "label": "莱西市",
              "lng": "120.51769",
              "lat": "36.889084",
              "level": "district"
            }
          ]
        },
        {
          "value": 1374,
          "parent_id": 1351,
          "label": "淄博市",
          "lng": "118.055019",
          "lat": "36.813546",
          "level": "city",
          "children": [
            {
              "value": 1375,
              "parent_id": 1374,
              "label": "淄川区",
              "lng": "117.966723",
              "lat": "36.643452",
              "level": "district"
            },
            {
              "value": 1376,
              "parent_id": 1374,
              "label": "张店区",
              "lng": "118.017938",
              "lat": "36.806669",
              "level": "district"
            },
            {
              "value": 1377,
              "parent_id": 1374,
              "label": "博山区",
              "lng": "117.861851",
              "lat": "36.494701",
              "level": "district"
            },
            {
              "value": 1378,
              "parent_id": 1374,
              "label": "临淄区",
              "lng": "118.309118",
              "lat": "36.826981",
              "level": "district"
            },
            {
              "value": 1379,
              "parent_id": 1374,
              "label": "周村区",
              "lng": "117.869886",
              "lat": "36.803072",
              "level": "district"
            },
            {
              "value": 1380,
              "parent_id": 1374,
              "label": "桓台县",
              "lng": "118.097922",
              "lat": "36.959804",
              "level": "district"
            },
            {
              "value": 1381,
              "parent_id": 1374,
              "label": "高青县",
              "lng": "117.826924",
              "lat": "37.170979",
              "level": "district"
            },
            {
              "value": 1382,
              "parent_id": 1374,
              "label": "沂源县",
              "lng": "118.170855",
              "lat": "36.185038",
              "level": "district"
            }
          ]
        },
        {
          "value": 1383,
          "parent_id": 1351,
          "label": "枣庄市",
          "lng": "117.323725",
          "lat": "34.810488",
          "level": "city",
          "children": [
            {
              "value": 1384,
              "parent_id": 1383,
              "label": "市中区",
              "lng": "117.556139",
              "lat": "34.863554",
              "level": "district"
            },
            {
              "value": 1385,
              "parent_id": 1383,
              "label": "薛城区",
              "lng": "117.263164",
              "lat": "34.795062",
              "level": "district"
            },
            {
              "value": 1386,
              "parent_id": 1383,
              "label": "峄城区",
              "lng": "117.590816",
              "lat": "34.773263",
              "level": "district"
            },
            {
              "value": 1387,
              "parent_id": 1383,
              "label": "台儿庄区",
              "lng": "117.734414",
              "lat": "34.56244",
              "level": "district"
            },
            {
              "value": 1388,
              "parent_id": 1383,
              "label": "山亭区",
              "lng": "117.461517",
              "lat": "35.099528",
              "level": "district"
            },
            {
              "value": 1389,
              "parent_id": 1383,
              "label": "滕州市",
              "lng": "117.165824",
              "lat": "35.114155",
              "level": "district"
            }
          ]
        },
        {
          "value": 1390,
          "parent_id": 1351,
          "label": "东营市",
          "lng": "118.674614",
          "lat": "37.433963",
          "level": "city",
          "children": [
            {
              "value": 1391,
              "parent_id": 1390,
              "label": "东营区",
              "lng": "118.582184",
              "lat": "37.448964",
              "level": "district"
            },
            {
              "value": 1392,
              "parent_id": 1390,
              "label": "河口区",
              "lng": "118.525543",
              "lat": "37.886162",
              "level": "district"
            },
            {
              "value": 1393,
              "parent_id": 1390,
              "label": "垦利区",
              "lng": "118.575228",
              "lat": "37.573054",
              "level": "district"
            },
            {
              "value": 1394,
              "parent_id": 1390,
              "label": "利津县",
              "lng": "118.255287",
              "lat": "37.490328",
              "level": "district"
            },
            {
              "value": 1395,
              "parent_id": 1390,
              "label": "广饶县",
              "lng": "118.407107",
              "lat": "37.053555",
              "level": "district"
            }
          ]
        },
        {
          "value": 1396,
          "parent_id": 1351,
          "label": "烟台市",
          "lng": "121.447852",
          "lat": "37.464539",
          "level": "city",
          "children": [
            {
              "value": 1397,
              "parent_id": 1396,
              "label": "芝罘区",
              "lng": "121.400445",
              "lat": "37.541475",
              "level": "district"
            },
            {
              "value": 1398,
              "parent_id": 1396,
              "label": "福山区",
              "lng": "121.267741",
              "lat": "37.498246",
              "level": "district"
            },
            {
              "value": 1399,
              "parent_id": 1396,
              "label": "牟平区",
              "lng": "121.600455",
              "lat": "37.387061",
              "level": "district"
            },
            {
              "value": 1400,
              "parent_id": 1396,
              "label": "莱山区",
              "lng": "121.445301",
              "lat": "37.511291",
              "level": "district"
            },
            {
              "value": 1401,
              "parent_id": 1396,
              "label": "长岛县",
              "lng": "120.73658",
              "lat": "37.921368",
              "level": "district"
            },
            {
              "value": 1402,
              "parent_id": 1396,
              "label": "龙口市",
              "lng": "120.477813",
              "lat": "37.646107",
              "level": "district"
            },
            {
              "value": 1403,
              "parent_id": 1396,
              "label": "莱阳市",
              "lng": "120.711672",
              "lat": "36.978941",
              "level": "district"
            },
            {
              "value": 1404,
              "parent_id": 1396,
              "label": "莱州市",
              "lng": "119.942274",
              "lat": "37.177129",
              "level": "district"
            },
            {
              "value": 1405,
              "parent_id": 1396,
              "label": "蓬莱市",
              "lng": "120.758848",
              "lat": "37.810661",
              "level": "district"
            },
            {
              "value": 1406,
              "parent_id": 1396,
              "label": "招远市",
              "lng": "120.434071",
              "lat": "37.355469",
              "level": "district"
            },
            {
              "value": 1407,
              "parent_id": 1396,
              "label": "栖霞市",
              "lng": "120.849675",
              "lat": "37.335123",
              "level": "district"
            },
            {
              "value": 1408,
              "parent_id": 1396,
              "label": "海阳市",
              "lng": "121.173793",
              "lat": "36.688",
              "level": "district"
            }
          ]
        },
        {
          "value": 1409,
          "parent_id": 1351,
          "label": "潍坊市",
          "lng": "119.161748",
          "lat": "36.706962",
          "level": "city",
          "children": [
            {
              "value": 1410,
              "parent_id": 1409,
              "label": "潍城区",
              "lng": "119.024835",
              "lat": "36.7281",
              "level": "district"
            },
            {
              "value": 1411,
              "parent_id": 1409,
              "label": "寒亭区",
              "lng": "119.211157",
              "lat": "36.755623",
              "level": "district"
            },
            {
              "value": 1412,
              "parent_id": 1409,
              "label": "坊子区",
              "lng": "119.166485",
              "lat": "36.654448",
              "level": "district"
            },
            {
              "value": 1413,
              "parent_id": 1409,
              "label": "奎文区",
              "lng": "119.132482",
              "lat": "36.70759",
              "level": "district"
            },
            {
              "value": 1414,
              "parent_id": 1409,
              "label": "临朐县",
              "lng": "118.542982",
              "lat": "36.512506",
              "level": "district"
            },
            {
              "value": 1415,
              "parent_id": 1409,
              "label": "昌乐县",
              "lng": "118.829992",
              "lat": "36.706964",
              "level": "district"
            },
            {
              "value": 1416,
              "parent_id": 1409,
              "label": "青州市",
              "lng": "118.479654",
              "lat": "36.684789",
              "level": "district"
            },
            {
              "value": 1417,
              "parent_id": 1409,
              "label": "诸城市",
              "lng": "119.410103",
              "lat": "35.995654",
              "level": "district"
            },
            {
              "value": 1418,
              "parent_id": 1409,
              "label": "寿光市",
              "lng": "118.790739",
              "lat": "36.85576",
              "level": "district"
            },
            {
              "value": 1419,
              "parent_id": 1409,
              "label": "安丘市",
              "lng": "119.218978",
              "lat": "36.478493",
              "level": "district"
            },
            {
              "value": 1420,
              "parent_id": 1409,
              "label": "高密市",
              "lng": "119.755597",
              "lat": "36.382594",
              "level": "district"
            },
            {
              "value": 1421,
              "parent_id": 1409,
              "label": "昌邑市",
              "lng": "119.403069",
              "lat": "36.843319",
              "level": "district"
            },
            {
              "value": 3271,
              "parent_id": 1409,
              "label": "高新区",
              "lng": "119",
              "lat": "36",
              "level": "district"
            }
          ]
        },
        {
          "value": 1422,
          "parent_id": 1351,
          "label": "济宁市",
          "lng": "116.587282",
          "lat": "35.414982",
          "level": "city",
          "children": [
            {
              "value": 1423,
              "parent_id": 1422,
              "label": "任城区",
              "lng": "116.606103",
              "lat": "35.444028",
              "level": "district"
            },
            {
              "value": 1424,
              "parent_id": 1422,
              "label": "兖州区",
              "lng": "116.783833",
              "lat": "35.553144",
              "level": "district"
            },
            {
              "value": 1425,
              "parent_id": 1422,
              "label": "微山县",
              "lng": "117.128827",
              "lat": "34.806554",
              "level": "district"
            },
            {
              "value": 1426,
              "parent_id": 1422,
              "label": "鱼台县",
              "lng": "116.650608",
              "lat": "35.012749",
              "level": "district"
            },
            {
              "value": 1427,
              "parent_id": 1422,
              "label": "金乡县",
              "lng": "116.311532",
              "lat": "35.066619",
              "level": "district"
            },
            {
              "value": 1428,
              "parent_id": 1422,
              "label": "嘉祥县",
              "lng": "116.342449",
              "lat": "35.408824",
              "level": "district"
            },
            {
              "value": 1429,
              "parent_id": 1422,
              "label": "汶上县",
              "lng": "116.49708",
              "lat": "35.712298",
              "level": "district"
            },
            {
              "value": 1430,
              "parent_id": 1422,
              "label": "泗水县",
              "lng": "117.251195",
              "lat": "35.664323",
              "level": "district"
            },
            {
              "value": 1431,
              "parent_id": 1422,
              "label": "梁山县",
              "lng": "116.096044",
              "lat": "35.802306",
              "level": "district"
            },
            {
              "value": 1432,
              "parent_id": 1422,
              "label": "曲阜市",
              "lng": "116.986526",
              "lat": "35.581108",
              "level": "district"
            },
            {
              "value": 1433,
              "parent_id": 1422,
              "label": "邹城市",
              "lng": "117.007453",
              "lat": "35.40268",
              "level": "district"
            }
          ]
        },
        {
          "value": 1434,
          "parent_id": 1351,
          "label": "泰安市",
          "lng": "117.087614",
          "lat": "36.200252",
          "level": "city",
          "children": [
            {
              "value": 1435,
              "parent_id": 1434,
              "label": "泰山区",
              "lng": "117.135354",
              "lat": "36.192083",
              "level": "district"
            },
            {
              "value": 1436,
              "parent_id": 1434,
              "label": "岱岳区",
              "lng": "117.041581",
              "lat": "36.187989",
              "level": "district"
            },
            {
              "value": 1437,
              "parent_id": 1434,
              "label": "宁阳县",
              "lng": "116.805796",
              "lat": "35.758786",
              "level": "district"
            },
            {
              "value": 1438,
              "parent_id": 1434,
              "label": "东平县",
              "lng": "116.470304",
              "lat": "35.937102",
              "level": "district"
            },
            {
              "value": 1439,
              "parent_id": 1434,
              "label": "新泰市",
              "lng": "117.767952",
              "lat": "35.909032",
              "level": "district"
            },
            {
              "value": 1440,
              "parent_id": 1434,
              "label": "肥城市",
              "lng": "116.768358",
              "lat": "36.182571",
              "level": "district"
            }
          ]
        },
        {
          "value": 1441,
          "parent_id": 1351,
          "label": "威海市",
          "lng": "122.120282",
          "lat": "37.513412",
          "level": "city",
          "children": [
            {
              "value": 1442,
              "parent_id": 1441,
              "label": "环翠区",
              "lng": "122.123443",
              "lat": "37.50199",
              "level": "district"
            },
            {
              "value": 1443,
              "parent_id": 1441,
              "label": "文登区",
              "lng": "122.05767",
              "lat": "37.193735",
              "level": "district"
            },
            {
              "value": 1444,
              "parent_id": 1441,
              "label": "荣成市",
              "lng": "122.486657",
              "lat": "37.16516",
              "level": "district"
            },
            {
              "value": 1445,
              "parent_id": 1441,
              "label": "乳山市",
              "lng": "121.539764",
              "lat": "36.919816",
              "level": "district"
            }
          ]
        },
        {
          "value": 1446,
          "parent_id": 1351,
          "label": "日照市",
          "lng": "119.526925",
          "lat": "35.416734",
          "level": "city",
          "children": [
            {
              "value": 1447,
              "parent_id": 1446,
              "label": "东港区",
              "lng": "119.462267",
              "lat": "35.42548",
              "level": "district"
            },
            {
              "value": 1448,
              "parent_id": 1446,
              "label": "岚山区",
              "lng": "119.318928",
              "lat": "35.121884",
              "level": "district"
            },
            {
              "value": 1449,
              "parent_id": 1446,
              "label": "五莲县",
              "lng": "119.213619",
              "lat": "35.760228",
              "level": "district"
            },
            {
              "value": 1450,
              "parent_id": 1446,
              "label": "莒县",
              "lng": "118.837063",
              "lat": "35.579868",
              "level": "district"
            }
          ]
        },
        {
          "value": 1451,
          "parent_id": 1351,
          "label": "莱芜市",
          "lng": "117.676723",
          "lat": "36.213813",
          "level": "city",
          "children": [
            {
              "value": 1452,
              "parent_id": 1451,
              "label": "莱城区",
              "lng": "117.659884",
              "lat": "36.203179",
              "level": "district"
            },
            {
              "value": 1453,
              "parent_id": 1451,
              "label": "钢城区",
              "lng": "117.811354",
              "lat": "36.058572",
              "level": "district"
            }
          ]
        },
        {
          "value": 1454,
          "parent_id": 1351,
          "label": "临沂市",
          "lng": "118.356414",
          "lat": "35.104673",
          "level": "city",
          "children": [
            {
              "value": 1455,
              "parent_id": 1454,
              "label": "兰山区",
              "lng": "118.347842",
              "lat": "35.051804",
              "level": "district"
            },
            {
              "value": 1456,
              "parent_id": 1454,
              "label": "罗庄区",
              "lng": "118.284786",
              "lat": "34.996741",
              "level": "district"
            },
            {
              "value": 1457,
              "parent_id": 1454,
              "label": "河东区",
              "lng": "118.402893",
              "lat": "35.089916",
              "level": "district"
            },
            {
              "value": 1458,
              "parent_id": 1454,
              "label": "沂南县",
              "lng": "118.465221",
              "lat": "35.550217",
              "level": "district"
            },
            {
              "value": 1459,
              "parent_id": 1454,
              "label": "郯城县",
              "lng": "118.367215",
              "lat": "34.613586",
              "level": "district"
            },
            {
              "value": 1460,
              "parent_id": 1454,
              "label": "沂水县",
              "lng": "118.627917",
              "lat": "35.79045",
              "level": "district"
            },
            {
              "value": 1461,
              "parent_id": 1454,
              "label": "兰陵县",
              "lng": "118.07065",
              "lat": "34.857149",
              "level": "district"
            },
            {
              "value": 1462,
              "parent_id": 1454,
              "label": "费县",
              "lng": "117.977325",
              "lat": "35.26596",
              "level": "district"
            },
            {
              "value": 1463,
              "parent_id": 1454,
              "label": "平邑县",
              "lng": "117.640352",
              "lat": "35.505943",
              "level": "district"
            },
            {
              "value": 1464,
              "parent_id": 1454,
              "label": "莒南县",
              "lng": "118.835163",
              "lat": "35.174846",
              "level": "district"
            },
            {
              "value": 1465,
              "parent_id": 1454,
              "label": "蒙阴县",
              "lng": "117.953621",
              "lat": "35.719396",
              "level": "district"
            },
            {
              "value": 1466,
              "parent_id": 1454,
              "label": "临沭县",
              "lng": "118.650781",
              "lat": "34.919851",
              "level": "district"
            }
          ]
        },
        {
          "value": 1467,
          "parent_id": 1351,
          "label": "德州市",
          "lng": "116.359381",
          "lat": "37.436657",
          "level": "city",
          "children": [
            {
              "value": 1468,
              "parent_id": 1467,
              "label": "德城区",
              "lng": "116.29947",
              "lat": "37.450804",
              "level": "district"
            },
            {
              "value": 1469,
              "parent_id": 1467,
              "label": "陵城区",
              "lng": "116.576092",
              "lat": "37.335794",
              "level": "district"
            },
            {
              "value": 1470,
              "parent_id": 1467,
              "label": "宁津县",
              "lng": "116.800306",
              "lat": "37.652189",
              "level": "district"
            },
            {
              "value": 1471,
              "parent_id": 1467,
              "label": "庆云县",
              "lng": "117.385256",
              "lat": "37.775349",
              "level": "district"
            },
            {
              "value": 1472,
              "parent_id": 1467,
              "label": "临邑县",
              "lng": "116.866799",
              "lat": "37.189797",
              "level": "district"
            },
            {
              "value": 1473,
              "parent_id": 1467,
              "label": "齐河县",
              "lng": "116.762893",
              "lat": "36.784158",
              "level": "district"
            },
            {
              "value": 1474,
              "parent_id": 1467,
              "label": "平原县",
              "lng": "116.434032",
              "lat": "37.165323",
              "level": "district"
            },
            {
              "value": 1475,
              "parent_id": 1467,
              "label": "夏津县",
              "lng": "116.001726",
              "lat": "36.948371",
              "level": "district"
            },
            {
              "value": 1476,
              "parent_id": 1467,
              "label": "武城县",
              "lng": "116.069302",
              "lat": "37.213311",
              "level": "district"
            },
            {
              "value": 1477,
              "parent_id": 1467,
              "label": "乐陵市",
              "lng": "117.231934",
              "lat": "37.729907",
              "level": "district"
            },
            {
              "value": 1478,
              "parent_id": 1467,
              "label": "禹城市",
              "lng": "116.638327",
              "lat": "36.933812",
              "level": "district"
            }
          ]
        },
        {
          "value": 1479,
          "parent_id": 1351,
          "label": "聊城市",
          "lng": "115.985389",
          "lat": "36.456684",
          "level": "city",
          "children": [
            {
              "value": 1480,
              "parent_id": 1479,
              "label": "东昌府区",
              "lng": "115.988349",
              "lat": "36.434669",
              "level": "district"
            },
            {
              "value": 1481,
              "parent_id": 1479,
              "label": "阳谷县",
              "lng": "115.79182",
              "lat": "36.114392",
              "level": "district"
            },
            {
              "value": 1482,
              "parent_id": 1479,
              "label": "莘县",
              "lng": "115.671191",
              "lat": "36.233598",
              "level": "district"
            },
            {
              "value": 1483,
              "parent_id": 1479,
              "label": "茌平县",
              "lng": "116.25527",
              "lat": "36.580688",
              "level": "district"
            },
            {
              "value": 1484,
              "parent_id": 1479,
              "label": "东阿县",
              "lng": "116.247579",
              "lat": "36.334917",
              "level": "district"
            },
            {
              "value": 1485,
              "parent_id": 1479,
              "label": "冠县",
              "lng": "115.442739",
              "lat": "36.484009",
              "level": "district"
            },
            {
              "value": 1486,
              "parent_id": 1479,
              "label": "高唐县",
              "lng": "116.23016",
              "lat": "36.846762",
              "level": "district"
            },
            {
              "value": 1487,
              "parent_id": 1479,
              "label": "临清市",
              "lng": "115.704881",
              "lat": "36.838277",
              "level": "district"
            }
          ]
        },
        {
          "value": 1488,
          "parent_id": 1351,
          "label": "滨州市",
          "lng": "117.970699",
          "lat": "37.38198",
          "level": "city",
          "children": [
            {
              "value": 1489,
              "parent_id": 1488,
              "label": "滨城区",
              "lng": "118.019326",
              "lat": "37.430724",
              "level": "district"
            },
            {
              "value": 1490,
              "parent_id": 1488,
              "label": "沾化区",
              "lng": "118.098902",
              "lat": "37.69926",
              "level": "district"
            },
            {
              "value": 1491,
              "parent_id": 1488,
              "label": "惠民县",
              "lng": "117.509921",
              "lat": "37.489877",
              "level": "district"
            },
            {
              "value": 1492,
              "parent_id": 1488,
              "label": "阳信县",
              "lng": "117.603339",
              "lat": "37.632433",
              "level": "district"
            },
            {
              "value": 1493,
              "parent_id": 1488,
              "label": "无棣县",
              "lng": "117.625696",
              "lat": "37.77026",
              "level": "district"
            },
            {
              "value": 1494,
              "parent_id": 1488,
              "label": "博兴县",
              "lng": "118.110709",
              "lat": "37.15457",
              "level": "district"
            },
            {
              "value": 1495,
              "parent_id": 1488,
              "label": "邹平县",
              "lng": "117.743109",
              "lat": "36.862989",
              "level": "district"
            }
          ]
        },
        {
          "value": 1496,
          "parent_id": 1351,
          "label": "菏泽市",
          "lng": "115.480656",
          "lat": "35.23375",
          "level": "city",
          "children": [
            {
              "value": 1497,
              "parent_id": 1496,
              "label": "牡丹区",
              "lng": "115.417826",
              "lat": "35.252512",
              "level": "district"
            },
            {
              "value": 1498,
              "parent_id": 1496,
              "label": "定陶区",
              "lng": "115.57302",
              "lat": "35.070995",
              "level": "district"
            },
            {
              "value": 1499,
              "parent_id": 1496,
              "label": "曹县",
              "lng": "115.542328",
              "lat": "34.825508",
              "level": "district"
            },
            {
              "value": 1500,
              "parent_id": 1496,
              "label": "单县",
              "lng": "116.107428",
              "lat": "34.778808",
              "level": "district"
            },
            {
              "value": 1501,
              "parent_id": 1496,
              "label": "成武县",
              "lng": "115.889764",
              "lat": "34.952459",
              "level": "district"
            },
            {
              "value": 1502,
              "parent_id": 1496,
              "label": "巨野县",
              "lng": "116.062394",
              "lat": "35.388925",
              "level": "district"
            },
            {
              "value": 1503,
              "parent_id": 1496,
              "label": "郓城县",
              "lng": "115.9389",
              "lat": "35.575135",
              "level": "district"
            },
            {
              "value": 1504,
              "parent_id": 1496,
              "label": "鄄城县",
              "lng": "115.510192",
              "lat": "35.563408",
              "level": "district"
            },
            {
              "value": 1505,
              "parent_id": 1496,
              "label": "东明县",
              "lng": "115.107404",
              "lat": "35.276162",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1506,
      "parent_id": 1,
      "label": "河南省",
      "lng": "113.753394",
      "lat": "34.765869",
      "level": "province",
      "children": [
        {
          "value": 1507,
          "parent_id": 1506,
          "label": "郑州市",
          "lng": "113.625328",
          "lat": "34.746611",
          "level": "city",
          "children": [
            {
              "value": 1508,
              "parent_id": 1507,
              "label": "中原区",
              "lng": "113.613337",
              "lat": "34.748256",
              "level": "district"
            },
            {
              "value": 1509,
              "parent_id": 1507,
              "label": "二七区",
              "lng": "113.640211",
              "lat": "34.724114",
              "level": "district"
            },
            {
              "value": 1510,
              "parent_id": 1507,
              "label": "管城回族区",
              "lng": "113.6775",
              "lat": "34.75429",
              "level": "district"
            },
            {
              "value": 1511,
              "parent_id": 1507,
              "label": "金水区",
              "lng": "113.660617",
              "lat": "34.800004",
              "level": "district"
            },
            {
              "value": 1512,
              "parent_id": 1507,
              "label": "上街区",
              "lng": "113.30893",
              "lat": "34.802752",
              "level": "district"
            },
            {
              "value": 1513,
              "parent_id": 1507,
              "label": "惠济区",
              "lng": "113.6169",
              "lat": "34.867457",
              "level": "district"
            },
            {
              "value": 1514,
              "parent_id": 1507,
              "label": "中牟县",
              "lng": "113.976253",
              "lat": "34.718936",
              "level": "district"
            },
            {
              "value": 1515,
              "parent_id": 1507,
              "label": "巩义市",
              "lng": "113.022406",
              "lat": "34.7481",
              "level": "district"
            },
            {
              "value": 1516,
              "parent_id": 1507,
              "label": "荥阳市",
              "lng": "113.38324",
              "lat": "34.786948",
              "level": "district"
            },
            {
              "value": 1517,
              "parent_id": 1507,
              "label": "新密市",
              "lng": "113.391087",
              "lat": "34.539376",
              "level": "district"
            },
            {
              "value": 1518,
              "parent_id": 1507,
              "label": "新郑市",
              "lng": "113.740662",
              "lat": "34.395949",
              "level": "district"
            },
            {
              "value": 1519,
              "parent_id": 1507,
              "label": "登封市",
              "lng": "113.050581",
              "lat": "34.454443",
              "level": "district"
            }
          ]
        },
        {
          "value": 1520,
          "parent_id": 1506,
          "label": "开封市",
          "lng": "114.307677",
          "lat": "34.797966",
          "level": "city",
          "children": [
            {
              "value": 1521,
              "parent_id": 1520,
              "label": "龙亭区",
              "lng": "114.356076",
              "lat": "34.815565",
              "level": "district"
            },
            {
              "value": 1522,
              "parent_id": 1520,
              "label": "顺河回族区",
              "lng": "114.364875",
              "lat": "34.800458",
              "level": "district"
            },
            {
              "value": 1523,
              "parent_id": 1520,
              "label": "鼓楼区",
              "lng": "114.348306",
              "lat": "34.78856",
              "level": "district"
            },
            {
              "value": 1524,
              "parent_id": 1520,
              "label": "禹王台区",
              "lng": "114.34817",
              "lat": "34.777104",
              "level": "district"
            },
            {
              "value": 1525,
              "parent_id": 1520,
              "label": "祥符区",
              "lng": "114.441285",
              "lat": "34.756916",
              "level": "district"
            },
            {
              "value": 1526,
              "parent_id": 1520,
              "label": "杞县",
              "lng": "114.783139",
              "lat": "34.549174",
              "level": "district"
            },
            {
              "value": 1527,
              "parent_id": 1520,
              "label": "通许县",
              "lng": "114.467467",
              "lat": "34.480433",
              "level": "district"
            },
            {
              "value": 1528,
              "parent_id": 1520,
              "label": "尉氏县",
              "lng": "114.193081",
              "lat": "34.411494",
              "level": "district"
            },
            {
              "value": 1529,
              "parent_id": 1520,
              "label": "兰考县",
              "lng": "114.821348",
              "lat": "34.822211",
              "level": "district"
            }
          ]
        },
        {
          "value": 1530,
          "parent_id": 1506,
          "label": "洛阳市",
          "lng": "112.453926",
          "lat": "34.620202",
          "level": "city",
          "children": [
            {
              "value": 1531,
              "parent_id": 1530,
              "label": "老城区",
              "lng": "112.469766",
              "lat": "34.6842",
              "level": "district"
            },
            {
              "value": 1532,
              "parent_id": 1530,
              "label": "西工区",
              "lng": "112.427914",
              "lat": "34.660378",
              "level": "district"
            },
            {
              "value": 1533,
              "parent_id": 1530,
              "label": "瀍河回族区",
              "lng": "112.500131",
              "lat": "34.679773",
              "level": "district"
            },
            {
              "value": 1534,
              "parent_id": 1530,
              "label": "涧西区",
              "lng": "112.395756",
              "lat": "34.658033",
              "level": "district"
            },
            {
              "value": 1535,
              "parent_id": 1530,
              "label": "吉利区",
              "lng": "112.589112",
              "lat": "34.900467",
              "level": "district"
            },
            {
              "value": 1536,
              "parent_id": 1530,
              "label": "洛龙区",
              "lng": "112.463833",
              "lat": "34.619711",
              "level": "district"
            },
            {
              "value": 1537,
              "parent_id": 1530,
              "label": "孟津县",
              "lng": "112.445354",
              "lat": "34.825638",
              "level": "district"
            },
            {
              "value": 1538,
              "parent_id": 1530,
              "label": "新安县",
              "lng": "112.13244",
              "lat": "34.728284",
              "level": "district"
            },
            {
              "value": 1539,
              "parent_id": 1530,
              "label": "栾川县",
              "lng": "111.615768",
              "lat": "33.785698",
              "level": "district"
            },
            {
              "value": 1540,
              "parent_id": 1530,
              "label": "嵩县",
              "lng": "112.085634",
              "lat": "34.134516",
              "level": "district"
            },
            {
              "value": 1541,
              "parent_id": 1530,
              "label": "汝阳县",
              "lng": "112.473139",
              "lat": "34.153939",
              "level": "district"
            },
            {
              "value": 1542,
              "parent_id": 1530,
              "label": "宜阳县",
              "lng": "112.179238",
              "lat": "34.514644",
              "level": "district"
            },
            {
              "value": 1543,
              "parent_id": 1530,
              "label": "洛宁县",
              "lng": "111.653111",
              "lat": "34.389197",
              "level": "district"
            },
            {
              "value": 1544,
              "parent_id": 1530,
              "label": "伊川县",
              "lng": "112.425676",
              "lat": "34.421323",
              "level": "district"
            },
            {
              "value": 1545,
              "parent_id": 1530,
              "label": "偃师市",
              "lng": "112.789534",
              "lat": "34.72722",
              "level": "district"
            }
          ]
        },
        {
          "value": 1546,
          "parent_id": 1506,
          "label": "平顶山市",
          "lng": "113.192661",
          "lat": "33.766169",
          "level": "city",
          "children": [
            {
              "value": 1547,
              "parent_id": 1546,
              "label": "新华区",
              "lng": "113.293977",
              "lat": "33.737251",
              "level": "district"
            },
            {
              "value": 1548,
              "parent_id": 1546,
              "label": "卫东区",
              "lng": "113.335192",
              "lat": "33.734706",
              "level": "district"
            },
            {
              "value": 1549,
              "parent_id": 1546,
              "label": "石龙区",
              "lng": "112.898818",
              "lat": "33.898713",
              "level": "district"
            },
            {
              "value": 1550,
              "parent_id": 1546,
              "label": "湛河区",
              "lng": "113.320873",
              "lat": "33.725681",
              "level": "district"
            },
            {
              "value": 1551,
              "parent_id": 1546,
              "label": "宝丰县",
              "lng": "113.054801",
              "lat": "33.868434",
              "level": "district"
            },
            {
              "value": 1552,
              "parent_id": 1546,
              "label": "叶县",
              "lng": "113.357239",
              "lat": "33.626731",
              "level": "district"
            },
            {
              "value": 1553,
              "parent_id": 1546,
              "label": "鲁山县",
              "lng": "112.908202",
              "lat": "33.738293",
              "level": "district"
            },
            {
              "value": 1554,
              "parent_id": 1546,
              "label": "郏县",
              "lng": "113.212609",
              "lat": "33.971787",
              "level": "district"
            },
            {
              "value": 1555,
              "parent_id": 1546,
              "label": "舞钢市",
              "lng": "113.516343",
              "lat": "33.314033",
              "level": "district"
            },
            {
              "value": 1556,
              "parent_id": 1546,
              "label": "汝州市",
              "lng": "112.844517",
              "lat": "34.167029",
              "level": "district"
            }
          ]
        },
        {
          "value": 1557,
          "parent_id": 1506,
          "label": "安阳市",
          "lng": "114.392392",
          "lat": "36.097577",
          "level": "city",
          "children": [
            {
              "value": 1558,
              "parent_id": 1557,
              "label": "文峰区",
              "lng": "114.357082",
              "lat": "36.090468",
              "level": "district"
            },
            {
              "value": 1559,
              "parent_id": 1557,
              "label": "北关区",
              "lng": "114.355742",
              "lat": "36.10766",
              "level": "district"
            },
            {
              "value": 1560,
              "parent_id": 1557,
              "label": "殷都区",
              "lng": "114.303553",
              "lat": "36.10989",
              "level": "district"
            },
            {
              "value": 1561,
              "parent_id": 1557,
              "label": "龙安区",
              "lng": "114.301331",
              "lat": "36.076225",
              "level": "district"
            },
            {
              "value": 1562,
              "parent_id": 1557,
              "label": "安阳县",
              "lng": "114.130207",
              "lat": "36.130584",
              "level": "district"
            },
            {
              "value": 1563,
              "parent_id": 1557,
              "label": "汤阴县",
              "lng": "114.357763",
              "lat": "35.924514",
              "level": "district"
            },
            {
              "value": 1564,
              "parent_id": 1557,
              "label": "滑县",
              "lng": "114.519311",
              "lat": "35.575417",
              "level": "district"
            },
            {
              "value": 1565,
              "parent_id": 1557,
              "label": "内黄县",
              "lng": "114.901452",
              "lat": "35.971704",
              "level": "district"
            },
            {
              "value": 1566,
              "parent_id": 1557,
              "label": "林州市",
              "lng": "113.820129",
              "lat": "36.083046",
              "level": "district"
            }
          ]
        },
        {
          "value": 1567,
          "parent_id": 1506,
          "label": "鹤壁市",
          "lng": "114.297309",
          "lat": "35.748325",
          "level": "city",
          "children": [
            {
              "value": 1568,
              "parent_id": 1567,
              "label": "鹤山区",
              "lng": "114.163258",
              "lat": "35.954611",
              "level": "district"
            },
            {
              "value": 1569,
              "parent_id": 1567,
              "label": "山城区",
              "lng": "114.184318",
              "lat": "35.898033",
              "level": "district"
            },
            {
              "value": 1570,
              "parent_id": 1567,
              "label": "淇滨区",
              "lng": "114.298789",
              "lat": "35.741592",
              "level": "district"
            },
            {
              "value": 1571,
              "parent_id": 1567,
              "label": "浚县",
              "lng": "114.55091",
              "lat": "35.67636",
              "level": "district"
            },
            {
              "value": 1572,
              "parent_id": 1567,
              "label": "淇县",
              "lng": "114.208828",
              "lat": "35.622507",
              "level": "district"
            }
          ]
        },
        {
          "value": 1573,
          "parent_id": 1506,
          "label": "新乡市",
          "lng": "113.926763",
          "lat": "35.303704",
          "level": "city",
          "children": [
            {
              "value": 1574,
              "parent_id": 1573,
              "label": "红旗区",
              "lng": "113.875245",
              "lat": "35.30385",
              "level": "district"
            },
            {
              "value": 1575,
              "parent_id": 1573,
              "label": "卫滨区",
              "lng": "113.865663",
              "lat": "35.301992",
              "level": "district"
            },
            {
              "value": 1576,
              "parent_id": 1573,
              "label": "凤泉区",
              "lng": "113.915184",
              "lat": "35.383978",
              "level": "district"
            },
            {
              "value": 1577,
              "parent_id": 1573,
              "label": "牧野区",
              "lng": "113.908772",
              "lat": "35.315039",
              "level": "district"
            },
            {
              "value": 1578,
              "parent_id": 1573,
              "label": "新乡县",
              "lng": "113.805205",
              "lat": "35.190836",
              "level": "district"
            },
            {
              "value": 1579,
              "parent_id": 1573,
              "label": "获嘉县",
              "lng": "113.657433",
              "lat": "35.259808",
              "level": "district"
            },
            {
              "value": 1580,
              "parent_id": 1573,
              "label": "原阳县",
              "lng": "113.940046",
              "lat": "35.065587",
              "level": "district"
            },
            {
              "value": 1581,
              "parent_id": 1573,
              "label": "延津县",
              "lng": "114.20509",
              "lat": "35.141889",
              "level": "district"
            },
            {
              "value": 1582,
              "parent_id": 1573,
              "label": "封丘县",
              "lng": "114.418882",
              "lat": "35.041198",
              "level": "district"
            },
            {
              "value": 1583,
              "parent_id": 1573,
              "label": "长垣县",
              "lng": "114.668936",
              "lat": "35.201548",
              "level": "district"
            },
            {
              "value": 1584,
              "parent_id": 1573,
              "label": "卫辉市",
              "lng": "114.064907",
              "lat": "35.398494",
              "level": "district"
            },
            {
              "value": 1585,
              "parent_id": 1573,
              "label": "辉县市",
              "lng": "113.805468",
              "lat": "35.462312",
              "level": "district"
            }
          ]
        },
        {
          "value": 1586,
          "parent_id": 1506,
          "label": "焦作市",
          "lng": "113.241823",
          "lat": "35.215893",
          "level": "city",
          "children": [
            {
              "value": 1587,
              "parent_id": 1586,
              "label": "解放区",
              "lng": "113.230816",
              "lat": "35.240282",
              "level": "district"
            },
            {
              "value": 1588,
              "parent_id": 1586,
              "label": "中站区",
              "lng": "113.182946",
              "lat": "35.236819",
              "level": "district"
            },
            {
              "value": 1589,
              "parent_id": 1586,
              "label": "马村区",
              "lng": "113.322332",
              "lat": "35.256108",
              "level": "district"
            },
            {
              "value": 1590,
              "parent_id": 1586,
              "label": "山阳区",
              "lng": "113.254881",
              "lat": "35.214507",
              "level": "district"
            },
            {
              "value": 1591,
              "parent_id": 1586,
              "label": "修武县",
              "lng": "113.447755",
              "lat": "35.223514",
              "level": "district"
            },
            {
              "value": 1592,
              "parent_id": 1586,
              "label": "博爱县",
              "lng": "113.064379",
              "lat": "35.171045",
              "level": "district"
            },
            {
              "value": 1593,
              "parent_id": 1586,
              "label": "武陟县",
              "lng": "113.401679",
              "lat": "35.099378",
              "level": "district"
            },
            {
              "value": 1594,
              "parent_id": 1586,
              "label": "温县",
              "lng": "113.08053",
              "lat": "34.940189",
              "level": "district"
            },
            {
              "value": 1595,
              "parent_id": 1586,
              "label": "沁阳市",
              "lng": "112.950716",
              "lat": "35.087539",
              "level": "district"
            },
            {
              "value": 1596,
              "parent_id": 1586,
              "label": "孟州市",
              "lng": "112.791401",
              "lat": "34.907315",
              "level": "district"
            }
          ]
        },
        {
          "value": 1597,
          "parent_id": 1506,
          "label": "濮阳市",
          "lng": "115.029216",
          "lat": "35.761829",
          "level": "city",
          "children": [
            {
              "value": 1598,
              "parent_id": 1597,
              "label": "华龙区",
              "lng": "115.074151",
              "lat": "35.777346",
              "level": "district"
            },
            {
              "value": 1599,
              "parent_id": 1597,
              "label": "清丰县",
              "lng": "115.104389",
              "lat": "35.88518",
              "level": "district"
            },
            {
              "value": 1600,
              "parent_id": 1597,
              "label": "南乐县",
              "lng": "115.204675",
              "lat": "36.069476",
              "level": "district"
            },
            {
              "value": 1601,
              "parent_id": 1597,
              "label": "范县",
              "lng": "115.504201",
              "lat": "35.851906",
              "level": "district"
            },
            {
              "value": 1602,
              "parent_id": 1597,
              "label": "台前县",
              "lng": "115.871906",
              "lat": "35.96939",
              "level": "district"
            },
            {
              "value": 1603,
              "parent_id": 1597,
              "label": "濮阳县",
              "lng": "115.029078",
              "lat": "35.712193",
              "level": "district"
            }
          ]
        },
        {
          "value": 1604,
          "parent_id": 1506,
          "label": "许昌市",
          "lng": "113.852454",
          "lat": "34.035771",
          "level": "city",
          "children": [
            {
              "value": 1605,
              "parent_id": 1604,
              "label": "魏都区",
              "lng": "113.822647",
              "lat": "34.025341",
              "level": "district"
            },
            {
              "value": 1606,
              "parent_id": 1604,
              "label": "建安区",
              "lng": "113.822983",
              "lat": "34.12466",
              "level": "district"
            },
            {
              "value": 1607,
              "parent_id": 1604,
              "label": "鄢陵县",
              "lng": "114.177399",
              "lat": "34.102332",
              "level": "district"
            },
            {
              "value": 1608,
              "parent_id": 1604,
              "label": "襄城县",
              "lng": "113.505874",
              "lat": "33.851459",
              "level": "district"
            },
            {
              "value": 1609,
              "parent_id": 1604,
              "label": "禹州市",
              "lng": "113.488478",
              "lat": "34.140701",
              "level": "district"
            },
            {
              "value": 1610,
              "parent_id": 1604,
              "label": "长葛市",
              "lng": "113.813714",
              "lat": "34.19592",
              "level": "district"
            }
          ]
        },
        {
          "value": 1611,
          "parent_id": 1506,
          "label": "漯河市",
          "lng": "114.016536",
          "lat": "33.580873",
          "level": "city",
          "children": [
            {
              "value": 1612,
              "parent_id": 1611,
              "label": "源汇区",
              "lng": "114.017948",
              "lat": "33.565441",
              "level": "district"
            },
            {
              "value": 1613,
              "parent_id": 1611,
              "label": "郾城区",
              "lng": "114.006943",
              "lat": "33.587409",
              "level": "district"
            },
            {
              "value": 1614,
              "parent_id": 1611,
              "label": "召陵区",
              "lng": "114.093902",
              "lat": "33.586565",
              "level": "district"
            },
            {
              "value": 1615,
              "parent_id": 1611,
              "label": "舞阳县",
              "lng": "113.609286",
              "lat": "33.437876",
              "level": "district"
            },
            {
              "value": 1616,
              "parent_id": 1611,
              "label": "临颍县",
              "lng": "113.931261",
              "lat": "33.828042",
              "level": "district"
            }
          ]
        },
        {
          "value": 1617,
          "parent_id": 1506,
          "label": "三门峡市",
          "lng": "111.200367",
          "lat": "34.772792",
          "level": "city",
          "children": [
            {
              "value": 1618,
              "parent_id": 1617,
              "label": "湖滨区",
              "lng": "111.188397",
              "lat": "34.770886",
              "level": "district"
            },
            {
              "value": 1619,
              "parent_id": 1617,
              "label": "陕州区",
              "lng": "111.103563",
              "lat": "34.720547",
              "level": "district"
            },
            {
              "value": 1620,
              "parent_id": 1617,
              "label": "渑池县",
              "lng": "111.761797",
              "lat": "34.767951",
              "level": "district"
            },
            {
              "value": 1621,
              "parent_id": 1617,
              "label": "卢氏县",
              "lng": "111.047858",
              "lat": "34.054324",
              "level": "district"
            },
            {
              "value": 1622,
              "parent_id": 1617,
              "label": "义马市",
              "lng": "111.87448",
              "lat": "34.7474",
              "level": "district"
            },
            {
              "value": 1623,
              "parent_id": 1617,
              "label": "灵宝市",
              "lng": "110.89422",
              "lat": "34.516828",
              "level": "district"
            }
          ]
        },
        {
          "value": 1624,
          "parent_id": 1506,
          "label": "南阳市",
          "lng": "112.528308",
          "lat": "32.990664",
          "level": "city",
          "children": [
            {
              "value": 1625,
              "parent_id": 1624,
              "label": "宛城区",
              "lng": "112.539558",
              "lat": "33.003784",
              "level": "district"
            },
            {
              "value": 1626,
              "parent_id": 1624,
              "label": "卧龙区",
              "lng": "112.528789",
              "lat": "32.989877",
              "level": "district"
            },
            {
              "value": 1627,
              "parent_id": 1624,
              "label": "南召县",
              "lng": "112.429133",
              "lat": "33.489877",
              "level": "district"
            },
            {
              "value": 1628,
              "parent_id": 1624,
              "label": "方城县",
              "lng": "113.012494",
              "lat": "33.254391",
              "level": "district"
            },
            {
              "value": 1629,
              "parent_id": 1624,
              "label": "西峡县",
              "lng": "111.47353",
              "lat": "33.307294",
              "level": "district"
            },
            {
              "value": 1630,
              "parent_id": 1624,
              "label": "镇平县",
              "lng": "112.234697",
              "lat": "33.03411",
              "level": "district"
            },
            {
              "value": 1631,
              "parent_id": 1624,
              "label": "内乡县",
              "lng": "111.849392",
              "lat": "33.044864",
              "level": "district"
            },
            {
              "value": 1632,
              "parent_id": 1624,
              "label": "淅川县",
              "lng": "111.490964",
              "lat": "33.13782",
              "level": "district"
            },
            {
              "value": 1633,
              "parent_id": 1624,
              "label": "社旗县",
              "lng": "112.948245",
              "lat": "33.056109",
              "level": "district"
            },
            {
              "value": 1634,
              "parent_id": 1624,
              "label": "唐河县",
              "lng": "112.807636",
              "lat": "32.681335",
              "level": "district"
            },
            {
              "value": 1635,
              "parent_id": 1624,
              "label": "新野县",
              "lng": "112.360026",
              "lat": "32.520805",
              "level": "district"
            },
            {
              "value": 1636,
              "parent_id": 1624,
              "label": "桐柏县",
              "lng": "113.428287",
              "lat": "32.380073",
              "level": "district"
            },
            {
              "value": 1637,
              "parent_id": 1624,
              "label": "邓州市",
              "lng": "112.087493",
              "lat": "32.68758",
              "level": "district"
            }
          ]
        },
        {
          "value": 1638,
          "parent_id": 1506,
          "label": "商丘市",
          "lng": "115.656339",
          "lat": "34.414961",
          "level": "city",
          "children": [
            {
              "value": 1639,
              "parent_id": 1638,
              "label": "梁园区",
              "lng": "115.613965",
              "lat": "34.443893",
              "level": "district"
            },
            {
              "value": 1640,
              "parent_id": 1638,
              "label": "睢阳区",
              "lng": "115.653301",
              "lat": "34.388389",
              "level": "district"
            },
            {
              "value": 1641,
              "parent_id": 1638,
              "label": "民权县",
              "lng": "115.173971",
              "lat": "34.648191",
              "level": "district"
            },
            {
              "value": 1642,
              "parent_id": 1638,
              "label": "睢县",
              "lng": "115.071879",
              "lat": "34.445655",
              "level": "district"
            },
            {
              "value": 1643,
              "parent_id": 1638,
              "label": "宁陵县",
              "lng": "115.313743",
              "lat": "34.460399",
              "level": "district"
            },
            {
              "value": 1644,
              "parent_id": 1638,
              "label": "柘城县",
              "lng": "115.305708",
              "lat": "34.091082",
              "level": "district"
            },
            {
              "value": 1645,
              "parent_id": 1638,
              "label": "虞城县",
              "lng": "115.828319",
              "lat": "34.400835",
              "level": "district"
            },
            {
              "value": 1646,
              "parent_id": 1638,
              "label": "夏邑县",
              "lng": "116.131447",
              "lat": "34.237553",
              "level": "district"
            },
            {
              "value": 1647,
              "parent_id": 1638,
              "label": "永城市",
              "lng": "116.4495",
              "lat": "33.929291",
              "level": "district"
            }
          ]
        },
        {
          "value": 1648,
          "parent_id": 1506,
          "label": "信阳市",
          "lng": "114.091193",
          "lat": "32.147679",
          "level": "city",
          "children": [
            {
              "value": 1649,
              "parent_id": 1648,
              "label": "浉河区",
              "lng": "114.058713",
              "lat": "32.116803",
              "level": "district"
            },
            {
              "value": 1650,
              "parent_id": 1648,
              "label": "平桥区",
              "lng": "114.125656",
              "lat": "32.101031",
              "level": "district"
            },
            {
              "value": 1651,
              "parent_id": 1648,
              "label": "罗山县",
              "lng": "114.512872",
              "lat": "32.203883",
              "level": "district"
            },
            {
              "value": 1652,
              "parent_id": 1648,
              "label": "光山县",
              "lng": "114.919152",
              "lat": "32.010002",
              "level": "district"
            },
            {
              "value": 1653,
              "parent_id": 1648,
              "label": "新县",
              "lng": "114.879239",
              "lat": "31.643918",
              "level": "district"
            },
            {
              "value": 1654,
              "parent_id": 1648,
              "label": "商城县",
              "lng": "115.406862",
              "lat": "31.798377",
              "level": "district"
            },
            {
              "value": 1655,
              "parent_id": 1648,
              "label": "固始县",
              "lng": "115.654481",
              "lat": "32.168137",
              "level": "district"
            },
            {
              "value": 1656,
              "parent_id": 1648,
              "label": "潢川县",
              "lng": "115.051908",
              "lat": "32.131522",
              "level": "district"
            },
            {
              "value": 1657,
              "parent_id": 1648,
              "label": "淮滨县",
              "lng": "115.419537",
              "lat": "32.473258",
              "level": "district"
            },
            {
              "value": 1658,
              "parent_id": 1648,
              "label": "息县",
              "lng": "114.740456",
              "lat": "32.342792",
              "level": "district"
            }
          ]
        },
        {
          "value": 1659,
          "parent_id": 1506,
          "label": "周口市",
          "lng": "114.69695",
          "lat": "33.626149",
          "level": "city",
          "children": [
            {
              "value": 1660,
              "parent_id": 1659,
              "label": "川汇区",
              "lng": "114.650628",
              "lat": "33.647598",
              "level": "district"
            },
            {
              "value": 1661,
              "parent_id": 1659,
              "label": "扶沟县",
              "lng": "114.394821",
              "lat": "34.059968",
              "level": "district"
            },
            {
              "value": 1662,
              "parent_id": 1659,
              "label": "西华县",
              "lng": "114.529756",
              "lat": "33.767407",
              "level": "district"
            },
            {
              "value": 1663,
              "parent_id": 1659,
              "label": "商水县",
              "lng": "114.611651",
              "lat": "33.542138",
              "level": "district"
            },
            {
              "value": 1664,
              "parent_id": 1659,
              "label": "沈丘县",
              "lng": "115.098583",
              "lat": "33.409369",
              "level": "district"
            },
            {
              "value": 1665,
              "parent_id": 1659,
              "label": "郸城县",
              "lng": "115.177188",
              "lat": "33.644743",
              "level": "district"
            },
            {
              "value": 1666,
              "parent_id": 1659,
              "label": "淮阳县",
              "lng": "114.886153",
              "lat": "33.731561",
              "level": "district"
            },
            {
              "value": 1667,
              "parent_id": 1659,
              "label": "太康县",
              "lng": "114.837888",
              "lat": "34.064463",
              "level": "district"
            },
            {
              "value": 1668,
              "parent_id": 1659,
              "label": "鹿邑县",
              "lng": "115.484454",
              "lat": "33.86",
              "level": "district"
            },
            {
              "value": 1669,
              "parent_id": 1659,
              "label": "项城市",
              "lng": "114.875333",
              "lat": "33.465838",
              "level": "district"
            }
          ]
        },
        {
          "value": 1670,
          "parent_id": 1506,
          "label": "驻马店市",
          "lng": "114.022247",
          "lat": "33.012885",
          "level": "city",
          "children": [
            {
              "value": 1671,
              "parent_id": 1670,
              "label": "驿城区",
              "lng": "113.993914",
              "lat": "32.973054",
              "level": "district"
            },
            {
              "value": 1672,
              "parent_id": 1670,
              "label": "西平县",
              "lng": "114.021538",
              "lat": "33.387684",
              "level": "district"
            },
            {
              "value": 1673,
              "parent_id": 1670,
              "label": "上蔡县",
              "lng": "114.264381",
              "lat": "33.262439",
              "level": "district"
            },
            {
              "value": 1674,
              "parent_id": 1670,
              "label": "平舆县",
              "lng": "114.619159",
              "lat": "32.96271",
              "level": "district"
            },
            {
              "value": 1675,
              "parent_id": 1670,
              "label": "正阳县",
              "lng": "114.392773",
              "lat": "32.605697",
              "level": "district"
            },
            {
              "value": 1676,
              "parent_id": 1670,
              "label": "确山县",
              "lng": "114.026429",
              "lat": "32.802064",
              "level": "district"
            },
            {
              "value": 1677,
              "parent_id": 1670,
              "label": "泌阳县",
              "lng": "113.327144",
              "lat": "32.723975",
              "level": "district"
            },
            {
              "value": 1678,
              "parent_id": 1670,
              "label": "汝南县",
              "lng": "114.362379",
              "lat": "33.006729",
              "level": "district"
            },
            {
              "value": 1679,
              "parent_id": 1670,
              "label": "遂平县",
              "lng": "114.013182",
              "lat": "33.145649",
              "level": "district"
            },
            {
              "value": 1680,
              "parent_id": 1670,
              "label": "新蔡县",
              "lng": "114.96547",
              "lat": "32.744896",
              "level": "district"
            }
          ]
        },
        {
          "value": 1681,
          "parent_id": 1506,
          "label": "济源市",
          "lng": "112.602256",
          "lat": "35.067199",
          "level": "city",
          "children": [
            {
              "value": 1682,
              "parent_id": 1681,
              "label": "济源市",
              "lng": "112.602256",
              "lat": "35.067199",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1683,
      "parent_id": 1,
      "label": "湖北省",
      "lng": "114.341745",
      "lat": "30.546557",
      "level": "province",
      "children": [
        {
          "value": 1684,
          "parent_id": 1683,
          "label": "武汉市",
          "lng": "114.305469",
          "lat": "30.593175",
          "level": "city",
          "children": [
            {
              "value": 1685,
              "parent_id": 1684,
              "label": "江岸区",
              "lng": "114.30911",
              "lat": "30.600052",
              "level": "district"
            },
            {
              "value": 1686,
              "parent_id": 1684,
              "label": "江汉区",
              "lng": "114.270867",
              "lat": "30.601475",
              "level": "district"
            },
            {
              "value": 1687,
              "parent_id": 1684,
              "label": "硚口区",
              "lng": "114.21492",
              "lat": "30.582202",
              "level": "district"
            },
            {
              "value": 1688,
              "parent_id": 1684,
              "label": "汉阳区",
              "lng": "114.21861",
              "lat": "30.553983",
              "level": "district"
            },
            {
              "value": 1689,
              "parent_id": 1684,
              "label": "武昌区",
              "lng": "114.31665",
              "lat": "30.554408",
              "level": "district"
            },
            {
              "value": 1690,
              "parent_id": 1684,
              "label": "青山区",
              "lng": "114.384968",
              "lat": "30.640191",
              "level": "district"
            },
            {
              "value": 1691,
              "parent_id": 1684,
              "label": "洪山区",
              "lng": "114.343796",
              "lat": "30.500247",
              "level": "district"
            },
            {
              "value": 1692,
              "parent_id": 1684,
              "label": "东西湖区",
              "lng": "114.137116",
              "lat": "30.619917",
              "level": "district"
            },
            {
              "value": 1693,
              "parent_id": 1684,
              "label": "汉南区",
              "lng": "114.084597",
              "lat": "30.308829",
              "level": "district"
            },
            {
              "value": 1694,
              "parent_id": 1684,
              "label": "蔡甸区",
              "lng": "114.087285",
              "lat": "30.536454",
              "level": "district"
            },
            {
              "value": 1695,
              "parent_id": 1684,
              "label": "江夏区",
              "lng": "114.319097",
              "lat": "30.376308",
              "level": "district"
            },
            {
              "value": 1696,
              "parent_id": 1684,
              "label": "黄陂区",
              "lng": "114.375725",
              "lat": "30.882174",
              "level": "district"
            },
            {
              "value": 1697,
              "parent_id": 1684,
              "label": "新洲区",
              "lng": "114.801096",
              "lat": "30.841425",
              "level": "district"
            }
          ]
        },
        {
          "value": 1698,
          "parent_id": 1683,
          "label": "黄石市",
          "lng": "115.038962",
          "lat": "30.201038",
          "level": "city",
          "children": [
            {
              "value": 1699,
              "parent_id": 1698,
              "label": "黄石港区",
              "lng": "115.065849",
              "lat": "30.222938",
              "level": "district"
            },
            {
              "value": 1700,
              "parent_id": 1698,
              "label": "西塞山区",
              "lng": "115.109955",
              "lat": "30.204924",
              "level": "district"
            },
            {
              "value": 1701,
              "parent_id": 1698,
              "label": "下陆区",
              "lng": "114.961327",
              "lat": "30.173912",
              "level": "district"
            },
            {
              "value": 1702,
              "parent_id": 1698,
              "label": "铁山区",
              "lng": "114.891605",
              "lat": "30.203118",
              "level": "district"
            },
            {
              "value": 1703,
              "parent_id": 1698,
              "label": "阳新县",
              "lng": "115.215227",
              "lat": "29.830257",
              "level": "district"
            },
            {
              "value": 1704,
              "parent_id": 1698,
              "label": "大冶市",
              "lng": "114.980424",
              "lat": "30.096147",
              "level": "district"
            }
          ]
        },
        {
          "value": 1705,
          "parent_id": 1683,
          "label": "十堰市",
          "lng": "110.799291",
          "lat": "32.629462",
          "level": "city",
          "children": [
            {
              "value": 1706,
              "parent_id": 1705,
              "label": "茅箭区",
              "lng": "110.813719",
              "lat": "32.591904",
              "level": "district"
            },
            {
              "value": 1707,
              "parent_id": 1705,
              "label": "张湾区",
              "lng": "110.769132",
              "lat": "32.652297",
              "level": "district"
            },
            {
              "value": 1708,
              "parent_id": 1705,
              "label": "郧阳区",
              "lng": "110.81205",
              "lat": "32.834775",
              "level": "district"
            },
            {
              "value": 1709,
              "parent_id": 1705,
              "label": "郧西县",
              "lng": "110.425983",
              "lat": "32.993182",
              "level": "district"
            },
            {
              "value": 1710,
              "parent_id": 1705,
              "label": "竹山县",
              "lng": "110.228747",
              "lat": "32.224808",
              "level": "district"
            },
            {
              "value": 1711,
              "parent_id": 1705,
              "label": "竹溪县",
              "lng": "109.715304",
              "lat": "32.318255",
              "level": "district"
            },
            {
              "value": 1712,
              "parent_id": 1705,
              "label": "房县",
              "lng": "110.733181",
              "lat": "32.050378",
              "level": "district"
            },
            {
              "value": 1713,
              "parent_id": 1705,
              "label": "丹江口市",
              "lng": "111.513127",
              "lat": "32.540157",
              "level": "district"
            }
          ]
        },
        {
          "value": 1714,
          "parent_id": 1683,
          "label": "宜昌市",
          "lng": "111.286445",
          "lat": "30.691865",
          "level": "city",
          "children": [
            {
              "value": 1715,
              "parent_id": 1714,
              "label": "西陵区",
              "lng": "111.285646",
              "lat": "30.710781",
              "level": "district"
            },
            {
              "value": 1716,
              "parent_id": 1714,
              "label": "伍家岗区",
              "lng": "111.361037",
              "lat": "30.644334",
              "level": "district"
            },
            {
              "value": 1717,
              "parent_id": 1714,
              "label": "点军区",
              "lng": "111.268119",
              "lat": "30.693247",
              "level": "district"
            },
            {
              "value": 1718,
              "parent_id": 1714,
              "label": "猇亭区",
              "lng": "111.43462",
              "lat": "30.530903",
              "level": "district"
            },
            {
              "value": 1719,
              "parent_id": 1714,
              "label": "夷陵区",
              "lng": "111.32638",
              "lat": "30.770006",
              "level": "district"
            },
            {
              "value": 1720,
              "parent_id": 1714,
              "label": "远安县",
              "lng": "111.640508",
              "lat": "31.060869",
              "level": "district"
            },
            {
              "value": 1721,
              "parent_id": 1714,
              "label": "兴山县",
              "lng": "110.746804",
              "lat": "31.348196",
              "level": "district"
            },
            {
              "value": 1722,
              "parent_id": 1714,
              "label": "秭归县",
              "lng": "110.977711",
              "lat": "30.825897",
              "level": "district"
            },
            {
              "value": 1723,
              "parent_id": 1714,
              "label": "长阳土家族自治县",
              "lng": "111.207242",
              "lat": "30.472763",
              "level": "district"
            },
            {
              "value": 1724,
              "parent_id": 1714,
              "label": "五峰土家族自治县",
              "lng": "111.07374",
              "lat": "30.156741",
              "level": "district"
            },
            {
              "value": 1725,
              "parent_id": 1714,
              "label": "宜都市",
              "lng": "111.450096",
              "lat": "30.378299",
              "level": "district"
            },
            {
              "value": 1726,
              "parent_id": 1714,
              "label": "当阳市",
              "lng": "111.788312",
              "lat": "30.821266",
              "level": "district"
            },
            {
              "value": 1727,
              "parent_id": 1714,
              "label": "枝江市",
              "lng": "111.76053",
              "lat": "30.42594",
              "level": "district"
            }
          ]
        },
        {
          "value": 1728,
          "parent_id": 1683,
          "label": "襄阳市",
          "lng": "112.122426",
          "lat": "32.009016",
          "level": "city",
          "children": [
            {
              "value": 1729,
              "parent_id": 1728,
              "label": "襄城区",
              "lng": "112.134052",
              "lat": "32.010366",
              "level": "district"
            },
            {
              "value": 1730,
              "parent_id": 1728,
              "label": "樊城区",
              "lng": "112.135684",
              "lat": "32.044832",
              "level": "district"
            },
            {
              "value": 1731,
              "parent_id": 1728,
              "label": "襄州区",
              "lng": "112.211982",
              "lat": "32.087127",
              "level": "district"
            },
            {
              "value": 1732,
              "parent_id": 1728,
              "label": "南漳县",
              "lng": "111.838905",
              "lat": "31.774636",
              "level": "district"
            },
            {
              "value": 1733,
              "parent_id": 1728,
              "label": "谷城县",
              "lng": "111.652982",
              "lat": "32.263849",
              "level": "district"
            },
            {
              "value": 1734,
              "parent_id": 1728,
              "label": "保康县",
              "lng": "111.261308",
              "lat": "31.87831",
              "level": "district"
            },
            {
              "value": 1735,
              "parent_id": 1728,
              "label": "老河口市",
              "lng": "111.683861",
              "lat": "32.359068",
              "level": "district"
            },
            {
              "value": 1736,
              "parent_id": 1728,
              "label": "枣阳市",
              "lng": "112.771959",
              "lat": "32.128818",
              "level": "district"
            },
            {
              "value": 1737,
              "parent_id": 1728,
              "label": "宜城市",
              "lng": "112.257788",
              "lat": "31.719806",
              "level": "district"
            }
          ]
        },
        {
          "value": 1738,
          "parent_id": 1683,
          "label": "鄂州市",
          "lng": "114.894935",
          "lat": "30.391141",
          "level": "city",
          "children": [
            {
              "value": 1739,
              "parent_id": 1738,
              "label": "梁子湖区",
              "lng": "114.684731",
              "lat": "30.100141",
              "level": "district"
            },
            {
              "value": 1740,
              "parent_id": 1738,
              "label": "华容区",
              "lng": "114.729878",
              "lat": "30.534309",
              "level": "district"
            },
            {
              "value": 1741,
              "parent_id": 1738,
              "label": "鄂城区",
              "lng": "114.891586",
              "lat": "30.400651",
              "level": "district"
            }
          ]
        },
        {
          "value": 1742,
          "parent_id": 1683,
          "label": "荆门市",
          "lng": "112.199427",
          "lat": "31.035395",
          "level": "city",
          "children": [
            {
              "value": 1743,
              "parent_id": 1742,
              "label": "东宝区",
              "lng": "112.201493",
              "lat": "31.051852",
              "level": "district"
            },
            {
              "value": 1744,
              "parent_id": 1742,
              "label": "掇刀区",
              "lng": "112.207962",
              "lat": "30.973451",
              "level": "district"
            },
            {
              "value": 1745,
              "parent_id": 1742,
              "label": "京山县",
              "lng": "113.119566",
              "lat": "31.018457",
              "level": "district"
            },
            {
              "value": 1746,
              "parent_id": 1742,
              "label": "沙洋县",
              "lng": "112.588581",
              "lat": "30.709221",
              "level": "district"
            },
            {
              "value": 1747,
              "parent_id": 1742,
              "label": "钟祥市",
              "lng": "112.58812",
              "lat": "31.167819",
              "level": "district"
            }
          ]
        },
        {
          "value": 1748,
          "parent_id": 1683,
          "label": "孝感市",
          "lng": "113.957037",
          "lat": "30.917766",
          "level": "city",
          "children": [
            {
              "value": 1749,
              "parent_id": 1748,
              "label": "孝南区",
              "lng": "113.910705",
              "lat": "30.916812",
              "level": "district"
            },
            {
              "value": 1750,
              "parent_id": 1748,
              "label": "孝昌县",
              "lng": "113.998009",
              "lat": "31.258159",
              "level": "district"
            },
            {
              "value": 1751,
              "parent_id": 1748,
              "label": "大悟县",
              "lng": "114.127022",
              "lat": "31.561164",
              "level": "district"
            },
            {
              "value": 1752,
              "parent_id": 1748,
              "label": "云梦县",
              "lng": "113.753554",
              "lat": "31.020983",
              "level": "district"
            },
            {
              "value": 1753,
              "parent_id": 1748,
              "label": "应城市",
              "lng": "113.572707",
              "lat": "30.92837",
              "level": "district"
            },
            {
              "value": 1754,
              "parent_id": 1748,
              "label": "安陆市",
              "lng": "113.688941",
              "lat": "31.25561",
              "level": "district"
            },
            {
              "value": 1755,
              "parent_id": 1748,
              "label": "汉川市",
              "lng": "113.839149",
              "lat": "30.661243",
              "level": "district"
            }
          ]
        },
        {
          "value": 1756,
          "parent_id": 1683,
          "label": "荆州市",
          "lng": "112.239746",
          "lat": "30.335184",
          "level": "city",
          "children": [
            {
              "value": 1757,
              "parent_id": 1756,
              "label": "沙市区",
              "lng": "112.25193",
              "lat": "30.326009",
              "level": "district"
            },
            {
              "value": 1758,
              "parent_id": 1756,
              "label": "荆州区",
              "lng": "112.190185",
              "lat": "30.352853",
              "level": "district"
            },
            {
              "value": 1759,
              "parent_id": 1756,
              "label": "公安县",
              "lng": "112.229648",
              "lat": "30.058336",
              "level": "district"
            },
            {
              "value": 1760,
              "parent_id": 1756,
              "label": "监利县",
              "lng": "112.904788",
              "lat": "29.840179",
              "level": "district"
            },
            {
              "value": 1761,
              "parent_id": 1756,
              "label": "江陵县",
              "lng": "112.424664",
              "lat": "30.041822",
              "level": "district"
            },
            {
              "value": 1762,
              "parent_id": 1756,
              "label": "石首市",
              "lng": "112.425454",
              "lat": "29.720938",
              "level": "district"
            },
            {
              "value": 1763,
              "parent_id": 1756,
              "label": "洪湖市",
              "lng": "113.475801",
              "lat": "29.826916",
              "level": "district"
            },
            {
              "value": 1764,
              "parent_id": 1756,
              "label": "松滋市",
              "lng": "111.756781",
              "lat": "30.174529",
              "level": "district"
            }
          ]
        },
        {
          "value": 1765,
          "parent_id": 1683,
          "label": "黄冈市",
          "lng": "114.872199",
          "lat": "30.453667",
          "level": "city",
          "children": [
            {
              "value": 1766,
              "parent_id": 1765,
              "label": "黄州区",
              "lng": "114.880104",
              "lat": "30.434354",
              "level": "district"
            },
            {
              "value": 1767,
              "parent_id": 1765,
              "label": "团风县",
              "lng": "114.872191",
              "lat": "30.643569",
              "level": "district"
            },
            {
              "value": 1768,
              "parent_id": 1765,
              "label": "红安县",
              "lng": "114.618236",
              "lat": "31.288153",
              "level": "district"
            },
            {
              "value": 1769,
              "parent_id": 1765,
              "label": "罗田县",
              "lng": "115.399222",
              "lat": "30.78429",
              "level": "district"
            },
            {
              "value": 1770,
              "parent_id": 1765,
              "label": "英山县",
              "lng": "115.681359",
              "lat": "30.735157",
              "level": "district"
            },
            {
              "value": 1771,
              "parent_id": 1765,
              "label": "浠水县",
              "lng": "115.265355",
              "lat": "30.452115",
              "level": "district"
            },
            {
              "value": 1772,
              "parent_id": 1765,
              "label": "蕲春县",
              "lng": "115.437007",
              "lat": "30.225964",
              "level": "district"
            },
            {
              "value": 1773,
              "parent_id": 1765,
              "label": "黄梅县",
              "lng": "115.944219",
              "lat": "30.070453",
              "level": "district"
            },
            {
              "value": 1774,
              "parent_id": 1765,
              "label": "麻城市",
              "lng": "115.008163",
              "lat": "31.172739",
              "level": "district"
            },
            {
              "value": 1775,
              "parent_id": 1765,
              "label": "武穴市",
              "lng": "115.561217",
              "lat": "29.844107",
              "level": "district"
            }
          ]
        },
        {
          "value": 1776,
          "parent_id": 1683,
          "label": "咸宁市",
          "lng": "114.322616",
          "lat": "29.841362",
          "level": "city",
          "children": [
            {
              "value": 1777,
              "parent_id": 1776,
              "label": "咸安区",
              "lng": "114.298711",
              "lat": "29.852891",
              "level": "district"
            },
            {
              "value": 1778,
              "parent_id": 1776,
              "label": "嘉鱼县",
              "lng": "113.939271",
              "lat": "29.970676",
              "level": "district"
            },
            {
              "value": 1779,
              "parent_id": 1776,
              "label": "通城县",
              "lng": "113.816966",
              "lat": "29.245269",
              "level": "district"
            },
            {
              "value": 1780,
              "parent_id": 1776,
              "label": "崇阳县",
              "lng": "114.039523",
              "lat": "29.556688",
              "level": "district"
            },
            {
              "value": 1781,
              "parent_id": 1776,
              "label": "通山县",
              "lng": "114.482622",
              "lat": "29.606372",
              "level": "district"
            },
            {
              "value": 1782,
              "parent_id": 1776,
              "label": "赤壁市",
              "lng": "113.90038",
              "lat": "29.725184",
              "level": "district"
            }
          ]
        },
        {
          "value": 1783,
          "parent_id": 1683,
          "label": "随州市",
          "lng": "113.382515",
          "lat": "31.690191",
          "level": "city",
          "children": [
            {
              "value": 1784,
              "parent_id": 1783,
              "label": "曾都区",
              "lng": "113.37112",
              "lat": "31.71628",
              "level": "district"
            },
            {
              "value": 1785,
              "parent_id": 1783,
              "label": "随县",
              "lng": "113.290634",
              "lat": "31.883739",
              "level": "district"
            },
            {
              "value": 1786,
              "parent_id": 1783,
              "label": "广水市",
              "lng": "113.825889",
              "lat": "31.616853",
              "level": "district"
            }
          ]
        },
        {
          "value": 1787,
          "parent_id": 1683,
          "label": "恩施土家族苗族自治州",
          "lng": "109.488172",
          "lat": "30.272156",
          "level": "city",
          "children": [
            {
              "value": 1788,
              "parent_id": 1787,
              "label": "恩施市",
              "lng": "109.479664",
              "lat": "30.29468",
              "level": "district"
            },
            {
              "value": 1789,
              "parent_id": 1787,
              "label": "利川市",
              "lng": "108.936452",
              "lat": "30.29098",
              "level": "district"
            },
            {
              "value": 1790,
              "parent_id": 1787,
              "label": "建始县",
              "lng": "109.722109",
              "lat": "30.602129",
              "level": "district"
            },
            {
              "value": 1791,
              "parent_id": 1787,
              "label": "巴东县",
              "lng": "110.340756",
              "lat": "31.042324",
              "level": "district"
            },
            {
              "value": 1792,
              "parent_id": 1787,
              "label": "宣恩县",
              "lng": "109.489926",
              "lat": "29.98692",
              "level": "district"
            },
            {
              "value": 1793,
              "parent_id": 1787,
              "label": "咸丰县",
              "lng": "109.139726",
              "lat": "29.665202",
              "level": "district"
            },
            {
              "value": 1794,
              "parent_id": 1787,
              "label": "来凤县",
              "lng": "109.407828",
              "lat": "29.493484",
              "level": "district"
            },
            {
              "value": 1795,
              "parent_id": 1787,
              "label": "鹤峰县",
              "lng": "110.033662",
              "lat": "29.890171",
              "level": "district"
            }
          ]
        },
        {
          "value": 1796,
          "parent_id": 1683,
          "label": "潜江市",
          "lng": "112.899762",
          "lat": "30.402167",
          "level": "city",
          "children": [
            {
              "value": 1797,
              "parent_id": 1796,
              "label": "潜江市",
              "lng": "112.899762",
              "lat": "30.402167",
              "level": "district"
            }
          ]
        },
        {
          "value": 1798,
          "parent_id": 1683,
          "label": "神农架林区",
          "lng": "110.675743",
          "lat": "31.744915",
          "level": "city",
          "children": [
            {
              "value": 1799,
              "parent_id": 1798,
              "label": "神农架林区",
              "lng": "110.675743",
              "lat": "31.744915",
              "level": "district"
            }
          ]
        },
        {
          "value": 1800,
          "parent_id": 1683,
          "label": "天门市",
          "lng": "113.166078",
          "lat": "30.663337",
          "level": "city",
          "children": [
            {
              "value": 1801,
              "parent_id": 1800,
              "label": "天门市",
              "lng": "113.166078",
              "lat": "30.663337",
              "level": "district"
            }
          ]
        },
        {
          "value": 1802,
          "parent_id": 1683,
          "label": "仙桃市",
          "lng": "113.423583",
          "lat": "30.361438",
          "level": "city",
          "children": [
            {
              "value": 1803,
              "parent_id": 1802,
              "label": "仙桃市",
              "lng": "113.423583",
              "lat": "30.361438",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1804,
      "parent_id": 1,
      "label": "湖南省",
      "lng": "112.9836",
      "lat": "28.112743",
      "level": "province",
      "children": [
        {
          "value": 1805,
          "parent_id": 1804,
          "label": "长沙市",
          "lng": "112.938884",
          "lat": "28.22808",
          "level": "city",
          "children": [
            {
              "value": 1806,
              "parent_id": 1805,
              "label": "芙蓉区",
              "lng": "113.032539",
              "lat": "28.185389",
              "level": "district"
            },
            {
              "value": 1807,
              "parent_id": 1805,
              "label": "天心区",
              "lng": "112.989897",
              "lat": "28.114526",
              "level": "district"
            },
            {
              "value": 1808,
              "parent_id": 1805,
              "label": "岳麓区",
              "lng": "112.93132",
              "lat": "28.234538",
              "level": "district"
            },
            {
              "value": 1809,
              "parent_id": 1805,
              "label": "开福区",
              "lng": "112.985884",
              "lat": "28.256298",
              "level": "district"
            },
            {
              "value": 1810,
              "parent_id": 1805,
              "label": "雨花区",
              "lng": "113.03826",
              "lat": "28.135722",
              "level": "district"
            },
            {
              "value": 1811,
              "parent_id": 1805,
              "label": "望城区",
              "lng": "112.831176",
              "lat": "28.353434",
              "level": "district"
            },
            {
              "value": 1812,
              "parent_id": 1805,
              "label": "长沙县",
              "lng": "113.081097",
              "lat": "28.246918",
              "level": "district"
            },
            {
              "value": 1813,
              "parent_id": 1805,
              "label": "宁乡县",
              "lng": "112.551885",
              "lat": "28.277483",
              "level": "district"
            },
            {
              "value": 1814,
              "parent_id": 1805,
              "label": "浏阳市",
              "lng": "113.643076",
              "lat": "28.162833",
              "level": "district"
            }
          ]
        },
        {
          "value": 1815,
          "parent_id": 1804,
          "label": "株洲市",
          "lng": "113.133853",
          "lat": "27.827986",
          "level": "city",
          "children": [
            {
              "value": 1816,
              "parent_id": 1815,
              "label": "荷塘区",
              "lng": "113.173487",
              "lat": "27.855928",
              "level": "district"
            },
            {
              "value": 1817,
              "parent_id": 1815,
              "label": "芦淞区",
              "lng": "113.152724",
              "lat": "27.78507",
              "level": "district"
            },
            {
              "value": 1818,
              "parent_id": 1815,
              "label": "石峰区",
              "lng": "113.117731",
              "lat": "27.875445",
              "level": "district"
            },
            {
              "value": 1819,
              "parent_id": 1815,
              "label": "天元区",
              "lng": "113.082216",
              "lat": "27.826866",
              "level": "district"
            },
            {
              "value": 1820,
              "parent_id": 1815,
              "label": "株洲县",
              "lng": "113.144109",
              "lat": "27.699232",
              "level": "district"
            },
            {
              "value": 1821,
              "parent_id": 1815,
              "label": "攸县",
              "lng": "113.396385",
              "lat": "27.014583",
              "level": "district"
            },
            {
              "value": 1822,
              "parent_id": 1815,
              "label": "茶陵县",
              "lng": "113.539094",
              "lat": "26.777521",
              "level": "district"
            },
            {
              "value": 1823,
              "parent_id": 1815,
              "label": "炎陵县",
              "lng": "113.772655",
              "lat": "26.489902",
              "level": "district"
            },
            {
              "value": 1824,
              "parent_id": 1815,
              "label": "醴陵市",
              "lng": "113.496999",
              "lat": "27.646096",
              "level": "district"
            }
          ]
        },
        {
          "value": 1825,
          "parent_id": 1804,
          "label": "湘潭市",
          "lng": "112.944026",
          "lat": "27.829795",
          "level": "city",
          "children": [
            {
              "value": 1826,
              "parent_id": 1825,
              "label": "雨湖区",
              "lng": "112.907162",
              "lat": "27.856325",
              "level": "district"
            },
            {
              "value": 1827,
              "parent_id": 1825,
              "label": "岳塘区",
              "lng": "112.969479",
              "lat": "27.872028",
              "level": "district"
            },
            {
              "value": 1828,
              "parent_id": 1825,
              "label": "湘潭县",
              "lng": "112.950831",
              "lat": "27.778958",
              "level": "district"
            },
            {
              "value": 1829,
              "parent_id": 1825,
              "label": "湘乡市",
              "lng": "112.550205",
              "lat": "27.718549",
              "level": "district"
            },
            {
              "value": 1830,
              "parent_id": 1825,
              "label": "韶山市",
              "lng": "112.52667",
              "lat": "27.915008",
              "level": "district"
            }
          ]
        },
        {
          "value": 1831,
          "parent_id": 1804,
          "label": "衡阳市",
          "lng": "112.572018",
          "lat": "26.893368",
          "level": "city",
          "children": [
            {
              "value": 1832,
              "parent_id": 1831,
              "label": "珠晖区",
              "lng": "112.620209",
              "lat": "26.894765",
              "level": "district"
            },
            {
              "value": 1833,
              "parent_id": 1831,
              "label": "雁峰区",
              "lng": "112.6154",
              "lat": "26.840602",
              "level": "district"
            },
            {
              "value": 1834,
              "parent_id": 1831,
              "label": "石鼓区",
              "lng": "112.597992",
              "lat": "26.943755",
              "level": "district"
            },
            {
              "value": 1835,
              "parent_id": 1831,
              "label": "蒸湘区",
              "lng": "112.567107",
              "lat": "26.911854",
              "level": "district"
            },
            {
              "value": 1836,
              "parent_id": 1831,
              "label": "南岳区",
              "lng": "112.738604",
              "lat": "27.232443",
              "level": "district"
            },
            {
              "value": 1837,
              "parent_id": 1831,
              "label": "衡阳县",
              "lng": "112.370546",
              "lat": "26.969577",
              "level": "district"
            },
            {
              "value": 1838,
              "parent_id": 1831,
              "label": "衡南县",
              "lng": "112.677877",
              "lat": "26.738247",
              "level": "district"
            },
            {
              "value": 1839,
              "parent_id": 1831,
              "label": "衡山县",
              "lng": "112.868268",
              "lat": "27.23029",
              "level": "district"
            },
            {
              "value": 1840,
              "parent_id": 1831,
              "label": "衡东县",
              "lng": "112.953168",
              "lat": "27.08117",
              "level": "district"
            },
            {
              "value": 1841,
              "parent_id": 1831,
              "label": "祁东县",
              "lng": "112.090356",
              "lat": "26.799896",
              "level": "district"
            },
            {
              "value": 1842,
              "parent_id": 1831,
              "label": "耒阳市",
              "lng": "112.859759",
              "lat": "26.422277",
              "level": "district"
            },
            {
              "value": 1843,
              "parent_id": 1831,
              "label": "常宁市",
              "lng": "112.399878",
              "lat": "26.421956",
              "level": "district"
            }
          ]
        },
        {
          "value": 1844,
          "parent_id": 1804,
          "label": "邵阳市",
          "lng": "111.467674",
          "lat": "27.23895",
          "level": "city",
          "children": [
            {
              "value": 1845,
              "parent_id": 1844,
              "label": "双清区",
              "lng": "111.496341",
              "lat": "27.232708",
              "level": "district"
            },
            {
              "value": 1846,
              "parent_id": 1844,
              "label": "大祥区",
              "lng": "111.439091",
              "lat": "27.221452",
              "level": "district"
            },
            {
              "value": 1847,
              "parent_id": 1844,
              "label": "北塔区",
              "lng": "111.452196",
              "lat": "27.246489",
              "level": "district"
            },
            {
              "value": 1848,
              "parent_id": 1844,
              "label": "邵东县",
              "lng": "111.74427",
              "lat": "27.258987",
              "level": "district"
            },
            {
              "value": 1849,
              "parent_id": 1844,
              "label": "新邵县",
              "lng": "111.458656",
              "lat": "27.320917",
              "level": "district"
            },
            {
              "value": 1850,
              "parent_id": 1844,
              "label": "邵阳县",
              "lng": "111.273805",
              "lat": "26.990637",
              "level": "district"
            },
            {
              "value": 1851,
              "parent_id": 1844,
              "label": "隆回县",
              "lng": "111.032437",
              "lat": "27.113978",
              "level": "district"
            },
            {
              "value": 1852,
              "parent_id": 1844,
              "label": "洞口县",
              "lng": "110.575846",
              "lat": "27.06032",
              "level": "district"
            },
            {
              "value": 1853,
              "parent_id": 1844,
              "label": "绥宁县",
              "lng": "110.155655",
              "lat": "26.581954",
              "level": "district"
            },
            {
              "value": 1854,
              "parent_id": 1844,
              "label": "新宁县",
              "lng": "110.856988",
              "lat": "26.433367",
              "level": "district"
            },
            {
              "value": 1855,
              "parent_id": 1844,
              "label": "城步苗族自治县",
              "lng": "110.322239",
              "lat": "26.390598",
              "level": "district"
            },
            {
              "value": 1856,
              "parent_id": 1844,
              "label": "武冈市",
              "lng": "110.631884",
              "lat": "26.726599",
              "level": "district"
            }
          ]
        },
        {
          "value": 1857,
          "parent_id": 1804,
          "label": "岳阳市",
          "lng": "113.12873",
          "lat": "29.356803",
          "level": "city",
          "children": [
            {
              "value": 1858,
              "parent_id": 1857,
              "label": "岳阳楼区",
              "lng": "113.129684",
              "lat": "29.371814",
              "level": "district"
            },
            {
              "value": 1859,
              "parent_id": 1857,
              "label": "云溪区",
              "lng": "113.272312",
              "lat": "29.472745",
              "level": "district"
            },
            {
              "value": 1860,
              "parent_id": 1857,
              "label": "君山区",
              "lng": "113.006435",
              "lat": "29.461106",
              "level": "district"
            },
            {
              "value": 1861,
              "parent_id": 1857,
              "label": "岳阳县",
              "lng": "113.116418",
              "lat": "29.144066",
              "level": "district"
            },
            {
              "value": 1862,
              "parent_id": 1857,
              "label": "华容县",
              "lng": "112.540463",
              "lat": "29.531057",
              "level": "district"
            },
            {
              "value": 1863,
              "parent_id": 1857,
              "label": "湘阴县",
              "lng": "112.909426",
              "lat": "28.689104",
              "level": "district"
            },
            {
              "value": 1864,
              "parent_id": 1857,
              "label": "平江县",
              "lng": "113.581234",
              "lat": "28.701868",
              "level": "district"
            },
            {
              "value": 1865,
              "parent_id": 1857,
              "label": "汨罗市",
              "lng": "113.067251",
              "lat": "28.806881",
              "level": "district"
            },
            {
              "value": 1866,
              "parent_id": 1857,
              "label": "临湘市",
              "lng": "113.450423",
              "lat": "29.476849",
              "level": "district"
            }
          ]
        },
        {
          "value": 1867,
          "parent_id": 1804,
          "label": "常德市",
          "lng": "111.698784",
          "lat": "29.031654",
          "level": "city",
          "children": [
            {
              "value": 1868,
              "parent_id": 1867,
              "label": "武陵区",
              "lng": "111.683153",
              "lat": "29.055163",
              "level": "district"
            },
            {
              "value": 1869,
              "parent_id": 1867,
              "label": "鼎城区",
              "lng": "111.680783",
              "lat": "29.018593",
              "level": "district"
            },
            {
              "value": 1870,
              "parent_id": 1867,
              "label": "安乡县",
              "lng": "112.171131",
              "lat": "29.411309",
              "level": "district"
            },
            {
              "value": 1871,
              "parent_id": 1867,
              "label": "汉寿县",
              "lng": "111.970514",
              "lat": "28.906106",
              "level": "district"
            },
            {
              "value": 1872,
              "parent_id": 1867,
              "label": "澧县",
              "lng": "111.758702",
              "lat": "29.633236",
              "level": "district"
            },
            {
              "value": 1873,
              "parent_id": 1867,
              "label": "临澧县",
              "lng": "111.647517",
              "lat": "29.440793",
              "level": "district"
            },
            {
              "value": 1874,
              "parent_id": 1867,
              "label": "桃源县",
              "lng": "111.488925",
              "lat": "28.902503",
              "level": "district"
            },
            {
              "value": 1875,
              "parent_id": 1867,
              "label": "石门县",
              "lng": "111.380014",
              "lat": "29.584292",
              "level": "district"
            },
            {
              "value": 1876,
              "parent_id": 1867,
              "label": "津市市",
              "lng": "111.877499",
              "lat": "29.60548",
              "level": "district"
            }
          ]
        },
        {
          "value": 1877,
          "parent_id": 1804,
          "label": "张家界市",
          "lng": "110.479148",
          "lat": "29.117013",
          "level": "city",
          "children": [
            {
              "value": 1878,
              "parent_id": 1877,
              "label": "永定区",
              "lng": "110.537138",
              "lat": "29.119855",
              "level": "district"
            },
            {
              "value": 1879,
              "parent_id": 1877,
              "label": "武陵源区",
              "lng": "110.550433",
              "lat": "29.34573",
              "level": "district"
            },
            {
              "value": 1880,
              "parent_id": 1877,
              "label": "慈利县",
              "lng": "111.139775",
              "lat": "29.429999",
              "level": "district"
            },
            {
              "value": 1881,
              "parent_id": 1877,
              "label": "桑植县",
              "lng": "110.204652",
              "lat": "29.414111",
              "level": "district"
            }
          ]
        },
        {
          "value": 1882,
          "parent_id": 1804,
          "label": "益阳市",
          "lng": "112.355129",
          "lat": "28.554349",
          "level": "city",
          "children": [
            {
              "value": 1883,
              "parent_id": 1882,
              "label": "资阳区",
              "lng": "112.324272",
              "lat": "28.59111",
              "level": "district"
            },
            {
              "value": 1884,
              "parent_id": 1882,
              "label": "赫山区",
              "lng": "112.374145",
              "lat": "28.579494",
              "level": "district"
            },
            {
              "value": 1885,
              "parent_id": 1882,
              "label": "南县",
              "lng": "112.396337",
              "lat": "29.362275",
              "level": "district"
            },
            {
              "value": 1886,
              "parent_id": 1882,
              "label": "桃江县",
              "lng": "112.155822",
              "lat": "28.518084",
              "level": "district"
            },
            {
              "value": 1887,
              "parent_id": 1882,
              "label": "安化县",
              "lng": "111.212846",
              "lat": "28.374107",
              "level": "district"
            },
            {
              "value": 1888,
              "parent_id": 1882,
              "label": "沅江市",
              "lng": "112.355954",
              "lat": "28.847045",
              "level": "district"
            }
          ]
        },
        {
          "value": 1889,
          "parent_id": 1804,
          "label": "郴州市",
          "lng": "113.014984",
          "lat": "25.770532",
          "level": "city",
          "children": [
            {
              "value": 1890,
              "parent_id": 1889,
              "label": "北湖区",
              "lng": "113.011035",
              "lat": "25.784054",
              "level": "district"
            },
            {
              "value": 1891,
              "parent_id": 1889,
              "label": "苏仙区",
              "lng": "113.112105",
              "lat": "25.797013",
              "level": "district"
            },
            {
              "value": 1892,
              "parent_id": 1889,
              "label": "桂阳县",
              "lng": "112.734173",
              "lat": "25.754172",
              "level": "district"
            },
            {
              "value": 1893,
              "parent_id": 1889,
              "label": "宜章县",
              "lng": "112.948712",
              "lat": "25.399938",
              "level": "district"
            },
            {
              "value": 1894,
              "parent_id": 1889,
              "label": "永兴县",
              "lng": "113.116527",
              "lat": "26.12715",
              "level": "district"
            },
            {
              "value": 1895,
              "parent_id": 1889,
              "label": "嘉禾县",
              "lng": "112.36902",
              "lat": "25.587519",
              "level": "district"
            },
            {
              "value": 1896,
              "parent_id": 1889,
              "label": "临武县",
              "lng": "112.563456",
              "lat": "25.27556",
              "level": "district"
            },
            {
              "value": 1897,
              "parent_id": 1889,
              "label": "汝城县",
              "lng": "113.684727",
              "lat": "25.532816",
              "level": "district"
            },
            {
              "value": 1898,
              "parent_id": 1889,
              "label": "桂东县",
              "lng": "113.944614",
              "lat": "26.077616",
              "level": "district"
            },
            {
              "value": 1899,
              "parent_id": 1889,
              "label": "安仁县",
              "lng": "113.26932",
              "lat": "26.709061",
              "level": "district"
            },
            {
              "value": 1900,
              "parent_id": 1889,
              "label": "资兴市",
              "lng": "113.236146",
              "lat": "25.976243",
              "level": "district"
            }
          ]
        },
        {
          "value": 1901,
          "parent_id": 1804,
          "label": "永州市",
          "lng": "111.613418",
          "lat": "26.419641",
          "level": "city",
          "children": [
            {
              "value": 1902,
              "parent_id": 1901,
              "label": "零陵区",
              "lng": "111.631109",
              "lat": "26.221936",
              "level": "district"
            },
            {
              "value": 1903,
              "parent_id": 1901,
              "label": "冷水滩区",
              "lng": "111.592343",
              "lat": "26.46128",
              "level": "district"
            },
            {
              "value": 1904,
              "parent_id": 1901,
              "label": "祁阳县",
              "lng": "111.840657",
              "lat": "26.58012",
              "level": "district"
            },
            {
              "value": 1905,
              "parent_id": 1901,
              "label": "东安县",
              "lng": "111.316464",
              "lat": "26.392183",
              "level": "district"
            },
            {
              "value": 1906,
              "parent_id": 1901,
              "label": "双牌县",
              "lng": "111.659967",
              "lat": "25.961909",
              "level": "district"
            },
            {
              "value": 1907,
              "parent_id": 1901,
              "label": "道县",
              "lng": "111.600795",
              "lat": "25.526437",
              "level": "district"
            },
            {
              "value": 1908,
              "parent_id": 1901,
              "label": "江永县",
              "lng": "111.343911",
              "lat": "25.273539",
              "level": "district"
            },
            {
              "value": 1909,
              "parent_id": 1901,
              "label": "宁远县",
              "lng": "111.945844",
              "lat": "25.570888",
              "level": "district"
            },
            {
              "value": 1910,
              "parent_id": 1901,
              "label": "蓝山县",
              "lng": "112.196567",
              "lat": "25.369725",
              "level": "district"
            },
            {
              "value": 1911,
              "parent_id": 1901,
              "label": "新田县",
              "lng": "112.203287",
              "lat": "25.904305",
              "level": "district"
            },
            {
              "value": 1912,
              "parent_id": 1901,
              "label": "江华瑶族自治县",
              "lng": "111.579535",
              "lat": "25.185809",
              "level": "district"
            }
          ]
        },
        {
          "value": 1913,
          "parent_id": 1804,
          "label": "怀化市",
          "lng": "110.001923",
          "lat": "27.569517",
          "level": "city",
          "children": [
            {
              "value": 1914,
              "parent_id": 1913,
              "label": "鹤城区",
              "lng": "110.040315",
              "lat": "27.578926",
              "level": "district"
            },
            {
              "value": 1915,
              "parent_id": 1913,
              "label": "中方县",
              "lng": "109.944711",
              "lat": "27.440138",
              "level": "district"
            },
            {
              "value": 1916,
              "parent_id": 1913,
              "label": "沅陵县",
              "lng": "110.393844",
              "lat": "28.452686",
              "level": "district"
            },
            {
              "value": 1917,
              "parent_id": 1913,
              "label": "辰溪县",
              "lng": "110.183917",
              "lat": "28.006336",
              "level": "district"
            },
            {
              "value": 1918,
              "parent_id": 1913,
              "label": "溆浦县",
              "lng": "110.594879",
              "lat": "27.908267",
              "level": "district"
            },
            {
              "value": 1919,
              "parent_id": 1913,
              "label": "会同县",
              "lng": "109.735661",
              "lat": "26.887238",
              "level": "district"
            },
            {
              "value": 1920,
              "parent_id": 1913,
              "label": "麻阳苗族自治县",
              "lng": "109.81701",
              "lat": "27.857569",
              "level": "district"
            },
            {
              "value": 1921,
              "parent_id": 1913,
              "label": "新晃侗族自治县",
              "lng": "109.174932",
              "lat": "27.352673",
              "level": "district"
            },
            {
              "value": 1922,
              "parent_id": 1913,
              "label": "芷江侗族自治县",
              "lng": "109.684629",
              "lat": "27.443499",
              "level": "district"
            },
            {
              "value": 1923,
              "parent_id": 1913,
              "label": "靖州苗族侗族自治县",
              "lng": "109.696273",
              "lat": "26.575107",
              "level": "district"
            },
            {
              "value": 1924,
              "parent_id": 1913,
              "label": "通道侗族自治县",
              "lng": "109.784412",
              "lat": "26.158054",
              "level": "district"
            },
            {
              "value": 1925,
              "parent_id": 1913,
              "label": "洪江市",
              "lng": "109.836669",
              "lat": "27.208609",
              "level": "district"
            }
          ]
        },
        {
          "value": 1926,
          "parent_id": 1804,
          "label": "娄底市",
          "lng": "111.994482",
          "lat": "27.70027",
          "level": "city",
          "children": [
            {
              "value": 1927,
              "parent_id": 1926,
              "label": "娄星区",
              "lng": "112.001914",
              "lat": "27.729863",
              "level": "district"
            },
            {
              "value": 1928,
              "parent_id": 1926,
              "label": "双峰县",
              "lng": "112.175163",
              "lat": "27.457172",
              "level": "district"
            },
            {
              "value": 1929,
              "parent_id": 1926,
              "label": "新化县",
              "lng": "111.327412",
              "lat": "27.726514",
              "level": "district"
            },
            {
              "value": 1930,
              "parent_id": 1926,
              "label": "冷水江市",
              "lng": "111.434984",
              "lat": "27.686251",
              "level": "district"
            },
            {
              "value": 1931,
              "parent_id": 1926,
              "label": "涟源市",
              "lng": "111.664329",
              "lat": "27.692577",
              "level": "district"
            }
          ]
        },
        {
          "value": 1932,
          "parent_id": 1804,
          "label": "湘西土家族苗族自治州",
          "lng": "109.738906",
          "lat": "28.31195",
          "level": "city",
          "children": [
            {
              "value": 1933,
              "parent_id": 1932,
              "label": "吉首市",
              "lng": "109.698015",
              "lat": "28.262376",
              "level": "district"
            },
            {
              "value": 1934,
              "parent_id": 1932,
              "label": "泸溪县",
              "lng": "110.21961",
              "lat": "28.216641",
              "level": "district"
            },
            {
              "value": 1935,
              "parent_id": 1932,
              "label": "凤凰县",
              "lng": "109.581083",
              "lat": "27.958081",
              "level": "district"
            },
            {
              "value": 1936,
              "parent_id": 1932,
              "label": "花垣县",
              "lng": "109.482078",
              "lat": "28.572029",
              "level": "district"
            },
            {
              "value": 1937,
              "parent_id": 1932,
              "label": "保靖县",
              "lng": "109.660559",
              "lat": "28.699878",
              "level": "district"
            },
            {
              "value": 1938,
              "parent_id": 1932,
              "label": "古丈县",
              "lng": "109.950728",
              "lat": "28.616935",
              "level": "district"
            },
            {
              "value": 1939,
              "parent_id": 1932,
              "label": "永顺县",
              "lng": "109.856933",
              "lat": "28.979955",
              "level": "district"
            },
            {
              "value": 1940,
              "parent_id": 1932,
              "label": "龙山县",
              "lng": "109.443938",
              "lat": "29.457663",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 1941,
      "parent_id": 1,
      "label": "广东省",
      "lng": "113.26641",
      "lat": "23.132324",
      "level": "province",
      "children": [
        {
          "value": 1942,
          "parent_id": 1941,
          "label": "广州市",
          "lng": "113.264385",
          "lat": "23.12911",
          "level": "city",
          "children": [
            {
              "value": 1943,
              "parent_id": 1942,
              "label": "荔湾区",
              "lng": "113.244258",
              "lat": "23.125863",
              "level": "district"
            },
            {
              "value": 1944,
              "parent_id": 1942,
              "label": "越秀区",
              "lng": "113.266835",
              "lat": "23.128537",
              "level": "district"
            },
            {
              "value": 1945,
              "parent_id": 1942,
              "label": "海珠区",
              "lng": "113.317443",
              "lat": "23.083788",
              "level": "district"
            },
            {
              "value": 1946,
              "parent_id": 1942,
              "label": "天河区",
              "lng": "113.361575",
              "lat": "23.124807",
              "level": "district"
            },
            {
              "value": 1947,
              "parent_id": 1942,
              "label": "白云区",
              "lng": "113.273238",
              "lat": "23.157367",
              "level": "district"
            },
            {
              "value": 1948,
              "parent_id": 1942,
              "label": "黄埔区",
              "lng": "113.480541",
              "lat": "23.181706",
              "level": "district"
            },
            {
              "value": 1949,
              "parent_id": 1942,
              "label": "番禺区",
              "lng": "113.384152",
              "lat": "22.937556",
              "level": "district"
            },
            {
              "value": 1950,
              "parent_id": 1942,
              "label": "花都区",
              "lng": "113.220463",
              "lat": "23.403744",
              "level": "district"
            },
            {
              "value": 1951,
              "parent_id": 1942,
              "label": "南沙区",
              "lng": "113.525165",
              "lat": "22.801624",
              "level": "district"
            },
            {
              "value": 1952,
              "parent_id": 1942,
              "label": "从化区",
              "lng": "113.586679",
              "lat": "23.548748",
              "level": "district"
            },
            {
              "value": 1953,
              "parent_id": 1942,
              "label": "增城区",
              "lng": "113.810627",
              "lat": "23.261465",
              "level": "district"
            }
          ]
        },
        {
          "value": 1954,
          "parent_id": 1941,
          "label": "韶关市",
          "lng": "113.59762",
          "lat": "24.810879",
          "level": "city",
          "children": [
            {
              "value": 1955,
              "parent_id": 1954,
              "label": "武江区",
              "lng": "113.587756",
              "lat": "24.792926",
              "level": "district"
            },
            {
              "value": 1956,
              "parent_id": 1954,
              "label": "浈江区",
              "lng": "113.611098",
              "lat": "24.804381",
              "level": "district"
            },
            {
              "value": 1957,
              "parent_id": 1954,
              "label": "曲江区",
              "lng": "113.604535",
              "lat": "24.682501",
              "level": "district"
            },
            {
              "value": 1958,
              "parent_id": 1954,
              "label": "始兴县",
              "lng": "114.061789",
              "lat": "24.952976",
              "level": "district"
            },
            {
              "value": 1959,
              "parent_id": 1954,
              "label": "仁化县",
              "lng": "113.749027",
              "lat": "25.085621",
              "level": "district"
            },
            {
              "value": 1960,
              "parent_id": 1954,
              "label": "翁源县",
              "lng": "114.130342",
              "lat": "24.350346",
              "level": "district"
            },
            {
              "value": 1961,
              "parent_id": 1954,
              "label": "乳源瑶族自治县",
              "lng": "113.275883",
              "lat": "24.776078",
              "level": "district"
            },
            {
              "value": 1962,
              "parent_id": 1954,
              "label": "新丰县",
              "lng": "114.206867",
              "lat": "24.05976",
              "level": "district"
            },
            {
              "value": 1963,
              "parent_id": 1954,
              "label": "乐昌市",
              "lng": "113.347545",
              "lat": "25.130602",
              "level": "district"
            },
            {
              "value": 1964,
              "parent_id": 1954,
              "label": "南雄市",
              "lng": "114.311982",
              "lat": "25.117753",
              "level": "district"
            }
          ]
        },
        {
          "value": 1965,
          "parent_id": 1941,
          "label": "深圳市",
          "lng": "114.057939",
          "lat": "22.543527",
          "level": "city",
          "children": [
            {
              "value": 1966,
              "parent_id": 1965,
              "label": "罗湖区",
              "lng": "114.131459",
              "lat": "22.548389",
              "level": "district"
            },
            {
              "value": 1967,
              "parent_id": 1965,
              "label": "福田区",
              "lng": "114.055072",
              "lat": "22.521521",
              "level": "district"
            },
            {
              "value": 1968,
              "parent_id": 1965,
              "label": "南山区",
              "lng": "113.930413",
              "lat": "22.533287",
              "level": "district"
            },
            {
              "value": 1969,
              "parent_id": 1965,
              "label": "宝安区",
              "lng": "113.883802",
              "lat": "22.554996",
              "level": "district"
            },
            {
              "value": 1970,
              "parent_id": 1965,
              "label": "龙岗区",
              "lng": "114.246899",
              "lat": "22.720974",
              "level": "district"
            },
            {
              "value": 1971,
              "parent_id": 1965,
              "label": "盐田区",
              "lng": "114.236739",
              "lat": "22.557001",
              "level": "district"
            },
            {
              "value": 1972,
              "parent_id": 1965,
              "label": "龙华区",
              "lng": "114.045422",
              "lat": "22.696667",
              "level": "district"
            },
            {
              "value": 1973,
              "parent_id": 1965,
              "label": "坪山区",
              "lng": "114.350584",
              "lat": "22.708881",
              "level": "district"
            }
          ]
        },
        {
          "value": 1974,
          "parent_id": 1941,
          "label": "珠海市",
          "lng": "113.576677",
          "lat": "22.270978",
          "level": "city",
          "children": [
            {
              "value": 1975,
              "parent_id": 1974,
              "label": "香洲区",
              "lng": "113.543784",
              "lat": "22.265811",
              "level": "district"
            },
            {
              "value": 1976,
              "parent_id": 1974,
              "label": "斗门区",
              "lng": "113.296467",
              "lat": "22.2092",
              "level": "district"
            },
            {
              "value": 1977,
              "parent_id": 1974,
              "label": "金湾区",
              "lng": "113.362656",
              "lat": "22.147471",
              "level": "district"
            }
          ]
        },
        {
          "value": 1978,
          "parent_id": 1941,
          "label": "汕头市",
          "lng": "116.681972",
          "lat": "23.354091",
          "level": "city",
          "children": [
            {
              "value": 1979,
              "parent_id": 1978,
              "label": "龙湖区",
              "lng": "116.716446",
              "lat": "23.372254",
              "level": "district"
            },
            {
              "value": 1980,
              "parent_id": 1978,
              "label": "金平区",
              "lng": "116.70345",
              "lat": "23.365556",
              "level": "district"
            },
            {
              "value": 1981,
              "parent_id": 1978,
              "label": "濠江区",
              "lng": "116.726973",
              "lat": "23.286079",
              "level": "district"
            },
            {
              "value": 1982,
              "parent_id": 1978,
              "label": "潮阳区",
              "lng": "116.601509",
              "lat": "23.265356",
              "level": "district"
            },
            {
              "value": 1983,
              "parent_id": 1978,
              "label": "潮南区",
              "lng": "116.439178",
              "lat": "23.23865",
              "level": "district"
            },
            {
              "value": 1984,
              "parent_id": 1978,
              "label": "澄海区",
              "lng": "116.755992",
              "lat": "23.466709",
              "level": "district"
            },
            {
              "value": 1985,
              "parent_id": 1978,
              "label": "南澳县",
              "lng": "117.023374",
              "lat": "23.421724",
              "level": "district"
            }
          ]
        },
        {
          "value": 1986,
          "parent_id": 1941,
          "label": "佛山市",
          "lng": "113.121435",
          "lat": "23.021478",
          "level": "city",
          "children": [
            {
              "value": 1987,
              "parent_id": 1986,
              "label": "禅城区",
              "lng": "113.122421",
              "lat": "23.009551",
              "level": "district"
            },
            {
              "value": 1988,
              "parent_id": 1986,
              "label": "南海区",
              "lng": "113.143441",
              "lat": "23.028956",
              "level": "district"
            },
            {
              "value": 1989,
              "parent_id": 1986,
              "label": "顺德区",
              "lng": "113.293359",
              "lat": "22.80524",
              "level": "district"
            },
            {
              "value": 1990,
              "parent_id": 1986,
              "label": "三水区",
              "lng": "112.896685",
              "lat": "23.155931",
              "level": "district"
            },
            {
              "value": 1991,
              "parent_id": 1986,
              "label": "高明区",
              "lng": "112.892585",
              "lat": "22.900139",
              "level": "district"
            }
          ]
        },
        {
          "value": 1992,
          "parent_id": 1941,
          "label": "江门市",
          "lng": "113.081542",
          "lat": "22.57899",
          "level": "city",
          "children": [
            {
              "value": 1993,
              "parent_id": 1992,
              "label": "蓬江区",
              "lng": "113.078521",
              "lat": "22.595149",
              "level": "district"
            },
            {
              "value": 1994,
              "parent_id": 1992,
              "label": "江海区",
              "lng": "113.111612",
              "lat": "22.560473",
              "level": "district"
            },
            {
              "value": 1995,
              "parent_id": 1992,
              "label": "新会区",
              "lng": "113.034187",
              "lat": "22.4583",
              "level": "district"
            },
            {
              "value": 1996,
              "parent_id": 1992,
              "label": "台山市",
              "lng": "112.794065",
              "lat": "22.251924",
              "level": "district"
            },
            {
              "value": 1997,
              "parent_id": 1992,
              "label": "开平市",
              "lng": "112.698545",
              "lat": "22.376395",
              "level": "district"
            },
            {
              "value": 1998,
              "parent_id": 1992,
              "label": "鹤山市",
              "lng": "112.964252",
              "lat": "22.76545",
              "level": "district"
            },
            {
              "value": 1999,
              "parent_id": 1992,
              "label": "恩平市",
              "lng": "112.305145",
              "lat": "22.183206",
              "level": "district"
            }
          ]
        },
        {
          "value": 2000,
          "parent_id": 1941,
          "label": "湛江市",
          "lng": "110.356639",
          "lat": "21.270145",
          "level": "city",
          "children": [
            {
              "value": 2001,
              "parent_id": 2000,
              "label": "赤坎区",
              "lng": "110.365899",
              "lat": "21.266119",
              "level": "district"
            },
            {
              "value": 2002,
              "parent_id": 2000,
              "label": "霞山区",
              "lng": "110.397656",
              "lat": "21.192457",
              "level": "district"
            },
            {
              "value": 2003,
              "parent_id": 2000,
              "label": "坡头区",
              "lng": "110.455332",
              "lat": "21.244721",
              "level": "district"
            },
            {
              "value": 2004,
              "parent_id": 2000,
              "label": "麻章区",
              "lng": "110.334387",
              "lat": "21.263442",
              "level": "district"
            },
            {
              "value": 2005,
              "parent_id": 2000,
              "label": "遂溪县",
              "lng": "110.250123",
              "lat": "21.377246",
              "level": "district"
            },
            {
              "value": 2006,
              "parent_id": 2000,
              "label": "徐闻县",
              "lng": "110.176749",
              "lat": "20.325489",
              "level": "district"
            },
            {
              "value": 2007,
              "parent_id": 2000,
              "label": "廉江市",
              "lng": "110.286208",
              "lat": "21.6097",
              "level": "district"
            },
            {
              "value": 2008,
              "parent_id": 2000,
              "label": "雷州市",
              "lng": "110.096586",
              "lat": "20.914178",
              "level": "district"
            },
            {
              "value": 2009,
              "parent_id": 2000,
              "label": "吴川市",
              "lng": "110.778411",
              "lat": "21.441808",
              "level": "district"
            }
          ]
        },
        {
          "value": 2010,
          "parent_id": 1941,
          "label": "茂名市",
          "lng": "110.925439",
          "lat": "21.662991",
          "level": "city",
          "children": [
            {
              "value": 2011,
              "parent_id": 2010,
              "label": "茂南区",
              "lng": "110.918026",
              "lat": "21.641337",
              "level": "district"
            },
            {
              "value": 2012,
              "parent_id": 2010,
              "label": "电白区",
              "lng": "111.013556",
              "lat": "21.514163",
              "level": "district"
            },
            {
              "value": 2013,
              "parent_id": 2010,
              "label": "高州市",
              "lng": "110.853299",
              "lat": "21.918203",
              "level": "district"
            },
            {
              "value": 2014,
              "parent_id": 2010,
              "label": "化州市",
              "lng": "110.639565",
              "lat": "21.66463",
              "level": "district"
            },
            {
              "value": 2015,
              "parent_id": 2010,
              "label": "信宜市",
              "lng": "110.947043",
              "lat": "22.354385",
              "level": "district"
            }
          ]
        },
        {
          "value": 2016,
          "parent_id": 1941,
          "label": "肇庆市",
          "lng": "112.465091",
          "lat": "23.047191",
          "level": "city",
          "children": [
            {
              "value": 2017,
              "parent_id": 2016,
              "label": "端州区",
              "lng": "112.484848",
              "lat": "23.052101",
              "level": "district"
            },
            {
              "value": 2018,
              "parent_id": 2016,
              "label": "鼎湖区",
              "lng": "112.567588",
              "lat": "23.158447",
              "level": "district"
            },
            {
              "value": 2019,
              "parent_id": 2016,
              "label": "高要区",
              "lng": "112.457981",
              "lat": "23.025305",
              "level": "district"
            },
            {
              "value": 2020,
              "parent_id": 2016,
              "label": "广宁县",
              "lng": "112.44069",
              "lat": "23.634675",
              "level": "district"
            },
            {
              "value": 2021,
              "parent_id": 2016,
              "label": "怀集县",
              "lng": "112.167742",
              "lat": "23.92035",
              "level": "district"
            },
            {
              "value": 2022,
              "parent_id": 2016,
              "label": "封开县",
              "lng": "111.512343",
              "lat": "23.424033",
              "level": "district"
            },
            {
              "value": 2023,
              "parent_id": 2016,
              "label": "德庆县",
              "lng": "111.785937",
              "lat": "23.143722",
              "level": "district"
            },
            {
              "value": 2024,
              "parent_id": 2016,
              "label": "四会市",
              "lng": "112.734103",
              "lat": "23.327001",
              "level": "district"
            }
          ]
        },
        {
          "value": 2025,
          "parent_id": 1941,
          "label": "惠州市",
          "lng": "114.415612",
          "lat": "23.112381",
          "level": "city",
          "children": [
            {
              "value": 2026,
              "parent_id": 2025,
              "label": "惠城区",
              "lng": "114.382474",
              "lat": "23.084137",
              "level": "district"
            },
            {
              "value": 2027,
              "parent_id": 2025,
              "label": "惠阳区",
              "lng": "114.456176",
              "lat": "22.789788",
              "level": "district"
            },
            {
              "value": 2028,
              "parent_id": 2025,
              "label": "博罗县",
              "lng": "114.289528",
              "lat": "23.172771",
              "level": "district"
            },
            {
              "value": 2029,
              "parent_id": 2025,
              "label": "惠东县",
              "lng": "114.719988",
              "lat": "22.985014",
              "level": "district"
            },
            {
              "value": 2030,
              "parent_id": 2025,
              "label": "龙门县",
              "lng": "114.254863",
              "lat": "23.727737",
              "level": "district"
            }
          ]
        },
        {
          "value": 2031,
          "parent_id": 1941,
          "label": "梅州市",
          "lng": "116.122523",
          "lat": "24.288578",
          "level": "city",
          "children": [
            {
              "value": 2032,
              "parent_id": 2031,
              "label": "梅江区",
              "lng": "116.116695",
              "lat": "24.31049",
              "level": "district"
            },
            {
              "value": 2033,
              "parent_id": 2031,
              "label": "梅县区",
              "lng": "116.081656",
              "lat": "24.265926",
              "level": "district"
            },
            {
              "value": 2034,
              "parent_id": 2031,
              "label": "大埔县",
              "lng": "116.695195",
              "lat": "24.347782",
              "level": "district"
            },
            {
              "value": 2035,
              "parent_id": 2031,
              "label": "丰顺县",
              "lng": "116.181691",
              "lat": "23.739343",
              "level": "district"
            },
            {
              "value": 2036,
              "parent_id": 2031,
              "label": "五华县",
              "lng": "115.775788",
              "lat": "23.932409",
              "level": "district"
            },
            {
              "value": 2037,
              "parent_id": 2031,
              "label": "平远县",
              "lng": "115.891638",
              "lat": "24.567261",
              "level": "district"
            },
            {
              "value": 2038,
              "parent_id": 2031,
              "label": "蕉岭县",
              "lng": "116.171355",
              "lat": "24.658699",
              "level": "district"
            },
            {
              "value": 2039,
              "parent_id": 2031,
              "label": "兴宁市",
              "lng": "115.731167",
              "lat": "24.136708",
              "level": "district"
            }
          ]
        },
        {
          "value": 2040,
          "parent_id": 1941,
          "label": "汕尾市",
          "lng": "115.375431",
          "lat": "22.78705",
          "level": "city",
          "children": [
            {
              "value": 2041,
              "parent_id": 2040,
              "label": "城区",
              "lng": "115.365058",
              "lat": "22.779207",
              "level": "district"
            },
            {
              "value": 2042,
              "parent_id": 2040,
              "label": "海丰县",
              "lng": "115.323436",
              "lat": "22.966585",
              "level": "district"
            },
            {
              "value": 2043,
              "parent_id": 2040,
              "label": "陆河县",
              "lng": "115.660143",
              "lat": "23.301616",
              "level": "district"
            },
            {
              "value": 2044,
              "parent_id": 2040,
              "label": "陆丰市",
              "lng": "115.652151",
              "lat": "22.919228",
              "level": "district"
            }
          ]
        },
        {
          "value": 2045,
          "parent_id": 1941,
          "label": "河源市",
          "lng": "114.700961",
          "lat": "23.743686",
          "level": "city",
          "children": [
            {
              "value": 2046,
              "parent_id": 2045,
              "label": "源城区",
              "lng": "114.702517",
              "lat": "23.733969",
              "level": "district"
            },
            {
              "value": 2047,
              "parent_id": 2045,
              "label": "紫金县",
              "lng": "115.184107",
              "lat": "23.635745",
              "level": "district"
            },
            {
              "value": 2048,
              "parent_id": 2045,
              "label": "龙川县",
              "lng": "115.259871",
              "lat": "24.100066",
              "level": "district"
            },
            {
              "value": 2049,
              "parent_id": 2045,
              "label": "连平县",
              "lng": "114.488556",
              "lat": "24.369583",
              "level": "district"
            },
            {
              "value": 2050,
              "parent_id": 2045,
              "label": "和平县",
              "lng": "114.938684",
              "lat": "24.44218",
              "level": "district"
            },
            {
              "value": 2051,
              "parent_id": 2045,
              "label": "东源县",
              "lng": "114.746344",
              "lat": "23.788189",
              "level": "district"
            }
          ]
        },
        {
          "value": 2052,
          "parent_id": 1941,
          "label": "阳江市",
          "lng": "111.982589",
          "lat": "21.857887",
          "level": "city",
          "children": [
            {
              "value": 2053,
              "parent_id": 2052,
              "label": "江城区",
              "lng": "111.955058",
              "lat": "21.861786",
              "level": "district"
            },
            {
              "value": 2054,
              "parent_id": 2052,
              "label": "阳东区",
              "lng": "112.006363",
              "lat": "21.868337",
              "level": "district"
            },
            {
              "value": 2055,
              "parent_id": 2052,
              "label": "阳西县",
              "lng": "111.61766",
              "lat": "21.752771",
              "level": "district"
            },
            {
              "value": 2056,
              "parent_id": 2052,
              "label": "阳春市",
              "lng": "111.791587",
              "lat": "22.17041",
              "level": "district"
            }
          ]
        },
        {
          "value": 2057,
          "parent_id": 1941,
          "label": "清远市",
          "lng": "113.056042",
          "lat": "23.681774",
          "level": "city",
          "children": [
            {
              "value": 2058,
              "parent_id": 2057,
              "label": "清城区",
              "lng": "113.062692",
              "lat": "23.697899",
              "level": "district"
            },
            {
              "value": 2059,
              "parent_id": 2057,
              "label": "清新区",
              "lng": "113.017747",
              "lat": "23.734677",
              "level": "district"
            },
            {
              "value": 2060,
              "parent_id": 2057,
              "label": "佛冈县",
              "lng": "113.531607",
              "lat": "23.879192",
              "level": "district"
            },
            {
              "value": 2061,
              "parent_id": 2057,
              "label": "阳山县",
              "lng": "112.641363",
              "lat": "24.465359",
              "level": "district"
            },
            {
              "value": 2062,
              "parent_id": 2057,
              "label": "连山壮族瑶族自治县",
              "lng": "112.093617",
              "lat": "24.570491",
              "level": "district"
            },
            {
              "value": 2063,
              "parent_id": 2057,
              "label": "连南瑶族自治县",
              "lng": "112.287012",
              "lat": "24.726017",
              "level": "district"
            },
            {
              "value": 2064,
              "parent_id": 2057,
              "label": "英德市",
              "lng": "113.401701",
              "lat": "24.206986",
              "level": "district"
            },
            {
              "value": 2065,
              "parent_id": 2057,
              "label": "连州市",
              "lng": "112.377361",
              "lat": "24.780966",
              "level": "district"
            }
          ]
        },
        {
          "value": 2066,
          "parent_id": 1941,
          "label": "东莞市",
          "lng": "113.751799",
          "lat": "23.020673",
          "level": "city",
          "children": [
            {
              "value": 2067,
              "parent_id": 2066,
              "label": "东莞市",
              "lng": "113.751799",
              "lat": "23.020673",
              "level": "district"
            }
          ]
        },
        {
          "value": 2068,
          "parent_id": 1941,
          "label": "中山市",
          "lng": "113.39277",
          "lat": "22.517585",
          "level": "city",
          "children": [
            {
              "value": 2069,
              "parent_id": 2068,
              "label": "中山市",
              "lng": "113.39277",
              "lat": "22.517585",
              "level": "district"
            }
          ]
        },
        {
          "value": 2070,
          "parent_id": 1941,
          "label": "潮州市",
          "lng": "116.622444",
          "lat": "23.657262",
          "level": "city",
          "children": [
            {
              "value": 2071,
              "parent_id": 2070,
              "label": "湘桥区",
              "lng": "116.628627",
              "lat": "23.674387",
              "level": "district"
            },
            {
              "value": 2072,
              "parent_id": 2070,
              "label": "潮安区",
              "lng": "116.678203",
              "lat": "23.462613",
              "level": "district"
            },
            {
              "value": 2073,
              "parent_id": 2070,
              "label": "饶平县",
              "lng": "117.0039",
              "lat": "23.663824",
              "level": "district"
            }
          ]
        },
        {
          "value": 2074,
          "parent_id": 1941,
          "label": "揭阳市",
          "lng": "116.372708",
          "lat": "23.549701",
          "level": "city",
          "children": [
            {
              "value": 2075,
              "parent_id": 2074,
              "label": "榕城区",
              "lng": "116.367012",
              "lat": "23.525382",
              "level": "district"
            },
            {
              "value": 2076,
              "parent_id": 2074,
              "label": "揭东区",
              "lng": "116.412015",
              "lat": "23.566126",
              "level": "district"
            },
            {
              "value": 2077,
              "parent_id": 2074,
              "label": "揭西县",
              "lng": "115.841837",
              "lat": "23.431294",
              "level": "district"
            },
            {
              "value": 2078,
              "parent_id": 2074,
              "label": "惠来县",
              "lng": "116.29515",
              "lat": "23.033266",
              "level": "district"
            },
            {
              "value": 2079,
              "parent_id": 2074,
              "label": "普宁市",
              "lng": "116.165777",
              "lat": "23.297493",
              "level": "district"
            }
          ]
        },
        {
          "value": 2080,
          "parent_id": 1941,
          "label": "云浮市",
          "lng": "112.044491",
          "lat": "22.915094",
          "level": "city",
          "children": [
            {
              "value": 2081,
              "parent_id": 2080,
              "label": "云城区",
              "lng": "112.043945",
              "lat": "22.92815",
              "level": "district"
            },
            {
              "value": 2082,
              "parent_id": 2080,
              "label": "云安区",
              "lng": "112.003208",
              "lat": "23.071019",
              "level": "district"
            },
            {
              "value": 2083,
              "parent_id": 2080,
              "label": "新兴县",
              "lng": "112.225334",
              "lat": "22.69569",
              "level": "district"
            },
            {
              "value": 2084,
              "parent_id": 2080,
              "label": "郁南县",
              "lng": "111.535285",
              "lat": "23.23456",
              "level": "district"
            },
            {
              "value": 2085,
              "parent_id": 2080,
              "label": "罗定市",
              "lng": "111.569892",
              "lat": "22.768285",
              "level": "district"
            }
          ]
        },
        {
          "value": 2086,
          "parent_id": 1941,
          "label": "东沙群岛",
          "lng": "116.887613",
          "lat": "20.617825",
          "level": "city",
          "children": [
            {
              "value": 2087,
              "parent_id": 2086,
              "label": "东沙群岛",
              "lng": "116.887613",
              "lat": "20.617825",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2088,
      "parent_id": 1,
      "label": "广西壮族自治区",
      "lng": "108.327546",
      "lat": "22.815478",
      "level": "province",
      "children": [
        {
          "value": 2089,
          "parent_id": 2088,
          "label": "南宁市",
          "lng": "108.366543",
          "lat": "22.817002",
          "level": "city",
          "children": [
            {
              "value": 2090,
              "parent_id": 2089,
              "label": "兴宁区",
              "lng": "108.368871",
              "lat": "22.854021",
              "level": "district"
            },
            {
              "value": 2091,
              "parent_id": 2089,
              "label": "青秀区",
              "lng": "108.494024",
              "lat": "22.785879",
              "level": "district"
            },
            {
              "value": 2092,
              "parent_id": 2089,
              "label": "江南区",
              "lng": "108.273133",
              "lat": "22.78136",
              "level": "district"
            },
            {
              "value": 2093,
              "parent_id": 2089,
              "label": "西乡塘区",
              "lng": "108.313494",
              "lat": "22.833928",
              "level": "district"
            },
            {
              "value": 2094,
              "parent_id": 2089,
              "label": "良庆区",
              "lng": "108.39301",
              "lat": "22.752997",
              "level": "district"
            },
            {
              "value": 2095,
              "parent_id": 2089,
              "label": "邕宁区",
              "lng": "108.487368",
              "lat": "22.75839",
              "level": "district"
            },
            {
              "value": 2096,
              "parent_id": 2089,
              "label": "武鸣区",
              "lng": "108.27467",
              "lat": "23.158595",
              "level": "district"
            },
            {
              "value": 2097,
              "parent_id": 2089,
              "label": "隆安县",
              "lng": "107.696153",
              "lat": "23.166028",
              "level": "district"
            },
            {
              "value": 2098,
              "parent_id": 2089,
              "label": "马山县",
              "lng": "108.177019",
              "lat": "23.708321",
              "level": "district"
            },
            {
              "value": 2099,
              "parent_id": 2089,
              "label": "上林县",
              "lng": "108.602846",
              "lat": "23.431908",
              "level": "district"
            },
            {
              "value": 2100,
              "parent_id": 2089,
              "label": "宾阳县",
              "lng": "108.810326",
              "lat": "23.217786",
              "level": "district"
            },
            {
              "value": 2101,
              "parent_id": 2089,
              "label": "横县",
              "lng": "109.261384",
              "lat": "22.679931",
              "level": "district"
            }
          ]
        },
        {
          "value": 2102,
          "parent_id": 2088,
          "label": "柳州市",
          "lng": "109.428608",
          "lat": "24.326291",
          "level": "city",
          "children": [
            {
              "value": 2103,
              "parent_id": 2102,
              "label": "城中区",
              "lng": "109.4273",
              "lat": "24.366",
              "level": "district"
            },
            {
              "value": 2104,
              "parent_id": 2102,
              "label": "鱼峰区",
              "lng": "109.452442",
              "lat": "24.318516",
              "level": "district"
            },
            {
              "value": 2105,
              "parent_id": 2102,
              "label": "柳南区",
              "lng": "109.385518",
              "lat": "24.336229",
              "level": "district"
            },
            {
              "value": 2106,
              "parent_id": 2102,
              "label": "柳北区",
              "lng": "109.402049",
              "lat": "24.362691",
              "level": "district"
            },
            {
              "value": 2107,
              "parent_id": 2102,
              "label": "柳江区",
              "lng": "109.32638",
              "lat": "24.254891",
              "level": "district"
            },
            {
              "value": 2108,
              "parent_id": 2102,
              "label": "柳城县",
              "lng": "109.24473",
              "lat": "24.651518",
              "level": "district"
            },
            {
              "value": 2109,
              "parent_id": 2102,
              "label": "鹿寨县",
              "lng": "109.750638",
              "lat": "24.472897",
              "level": "district"
            },
            {
              "value": 2110,
              "parent_id": 2102,
              "label": "融安县",
              "lng": "109.397538",
              "lat": "25.224549",
              "level": "district"
            },
            {
              "value": 2111,
              "parent_id": 2102,
              "label": "融水苗族自治县",
              "lng": "109.256334",
              "lat": "25.065934",
              "level": "district"
            },
            {
              "value": 2112,
              "parent_id": 2102,
              "label": "三江侗族自治县",
              "lng": "109.607675",
              "lat": "25.783198",
              "level": "district"
            }
          ]
        },
        {
          "value": 2113,
          "parent_id": 2088,
          "label": "桂林市",
          "lng": "110.179953",
          "lat": "25.234479",
          "level": "city",
          "children": [
            {
              "value": 2114,
              "parent_id": 2113,
              "label": "秀峰区",
              "lng": "110.264183",
              "lat": "25.273625",
              "level": "district"
            },
            {
              "value": 2115,
              "parent_id": 2113,
              "label": "叠彩区",
              "lng": "110.301723",
              "lat": "25.314",
              "level": "district"
            },
            {
              "value": 2116,
              "parent_id": 2113,
              "label": "象山区",
              "lng": "110.281082",
              "lat": "25.261686",
              "level": "district"
            },
            {
              "value": 2117,
              "parent_id": 2113,
              "label": "七星区",
              "lng": "110.317826",
              "lat": "25.252701",
              "level": "district"
            },
            {
              "value": 2118,
              "parent_id": 2113,
              "label": "雁山区",
              "lng": "110.28669",
              "lat": "25.101934",
              "level": "district"
            },
            {
              "value": 2119,
              "parent_id": 2113,
              "label": "临桂区",
              "lng": "110.212463",
              "lat": "25.238628",
              "level": "district"
            },
            {
              "value": 2120,
              "parent_id": 2113,
              "label": "阳朔县",
              "lng": "110.496593",
              "lat": "24.77848",
              "level": "district"
            },
            {
              "value": 2121,
              "parent_id": 2113,
              "label": "灵川县",
              "lng": "110.319897",
              "lat": "25.394781",
              "level": "district"
            },
            {
              "value": 2122,
              "parent_id": 2113,
              "label": "全州县",
              "lng": "111.072946",
              "lat": "25.928387",
              "level": "district"
            },
            {
              "value": 2123,
              "parent_id": 2113,
              "label": "兴安县",
              "lng": "110.67167",
              "lat": "25.611704",
              "level": "district"
            },
            {
              "value": 2124,
              "parent_id": 2113,
              "label": "永福县",
              "lng": "109.983076",
              "lat": "24.979855",
              "level": "district"
            },
            {
              "value": 2125,
              "parent_id": 2113,
              "label": "灌阳县",
              "lng": "111.160851",
              "lat": "25.489383",
              "level": "district"
            },
            {
              "value": 2126,
              "parent_id": 2113,
              "label": "龙胜各族自治县",
              "lng": "110.011238",
              "lat": "25.797931",
              "level": "district"
            },
            {
              "value": 2127,
              "parent_id": 2113,
              "label": "资源县",
              "lng": "110.6527",
              "lat": "26.042443",
              "level": "district"
            },
            {
              "value": 2128,
              "parent_id": 2113,
              "label": "平乐县",
              "lng": "110.643305",
              "lat": "24.633362",
              "level": "district"
            },
            {
              "value": 2129,
              "parent_id": 2113,
              "label": "荔浦县",
              "lng": "110.395104",
              "lat": "24.488342",
              "level": "district"
            },
            {
              "value": 2130,
              "parent_id": 2113,
              "label": "恭城瑶族自治县",
              "lng": "110.828409",
              "lat": "24.831682",
              "level": "district"
            }
          ]
        },
        {
          "value": 2131,
          "parent_id": 2088,
          "label": "梧州市",
          "lng": "111.279115",
          "lat": "23.476962",
          "level": "city",
          "children": [
            {
              "value": 2132,
              "parent_id": 2131,
              "label": "万秀区",
              "lng": "111.320518",
              "lat": "23.472991",
              "level": "district"
            },
            {
              "value": 2133,
              "parent_id": 2131,
              "label": "长洲区",
              "lng": "111.274673",
              "lat": "23.485944",
              "level": "district"
            },
            {
              "value": 2134,
              "parent_id": 2131,
              "label": "龙圩区",
              "lng": "111.246606",
              "lat": "23.404772",
              "level": "district"
            },
            {
              "value": 2135,
              "parent_id": 2131,
              "label": "苍梧县",
              "lng": "111.544007",
              "lat": "23.845097",
              "level": "district"
            },
            {
              "value": 2136,
              "parent_id": 2131,
              "label": "藤县",
              "lng": "110.914849",
              "lat": "23.374983",
              "level": "district"
            },
            {
              "value": 2137,
              "parent_id": 2131,
              "label": "蒙山县",
              "lng": "110.525003",
              "lat": "24.19357",
              "level": "district"
            },
            {
              "value": 2138,
              "parent_id": 2131,
              "label": "岑溪市",
              "lng": "110.994913",
              "lat": "22.91835",
              "level": "district"
            }
          ]
        },
        {
          "value": 2139,
          "parent_id": 2088,
          "label": "北海市",
          "lng": "109.120161",
          "lat": "21.481291",
          "level": "city",
          "children": [
            {
              "value": 2140,
              "parent_id": 2139,
              "label": "海城区",
              "lng": "109.117209",
              "lat": "21.475004",
              "level": "district"
            },
            {
              "value": 2141,
              "parent_id": 2139,
              "label": "银海区",
              "lng": "109.139862",
              "lat": "21.449308",
              "level": "district"
            },
            {
              "value": 2142,
              "parent_id": 2139,
              "label": "铁山港区",
              "lng": "109.42158",
              "lat": "21.529127",
              "level": "district"
            },
            {
              "value": 2143,
              "parent_id": 2139,
              "label": "合浦县",
              "lng": "109.207335",
              "lat": "21.660935",
              "level": "district"
            }
          ]
        },
        {
          "value": 2144,
          "parent_id": 2088,
          "label": "防城港市",
          "lng": "108.353846",
          "lat": "21.68686",
          "level": "city",
          "children": [
            {
              "value": 2145,
              "parent_id": 2144,
              "label": "港口区",
              "lng": "108.380143",
              "lat": "21.643383",
              "level": "district"
            },
            {
              "value": 2146,
              "parent_id": 2144,
              "label": "防城区",
              "lng": "108.353499",
              "lat": "21.769211",
              "level": "district"
            },
            {
              "value": 2147,
              "parent_id": 2144,
              "label": "上思县",
              "lng": "107.983627",
              "lat": "22.153671",
              "level": "district"
            },
            {
              "value": 2148,
              "parent_id": 2144,
              "label": "东兴市",
              "lng": "107.971828",
              "lat": "21.547821",
              "level": "district"
            }
          ]
        },
        {
          "value": 2149,
          "parent_id": 2088,
          "label": "钦州市",
          "lng": "108.654146",
          "lat": "21.979933",
          "level": "city",
          "children": [
            {
              "value": 2150,
              "parent_id": 2149,
              "label": "钦南区",
              "lng": "108.657209",
              "lat": "21.938859",
              "level": "district"
            },
            {
              "value": 2151,
              "parent_id": 2149,
              "label": "钦北区",
              "lng": "108.44911",
              "lat": "22.132761",
              "level": "district"
            },
            {
              "value": 2152,
              "parent_id": 2149,
              "label": "灵山县",
              "lng": "109.291006",
              "lat": "22.416536",
              "level": "district"
            },
            {
              "value": 2153,
              "parent_id": 2149,
              "label": "浦北县",
              "lng": "109.556953",
              "lat": "22.271651",
              "level": "district"
            }
          ]
        },
        {
          "value": 2154,
          "parent_id": 2088,
          "label": "贵港市",
          "lng": "109.598926",
          "lat": "23.11153",
          "level": "city",
          "children": [
            {
              "value": 2155,
              "parent_id": 2154,
              "label": "港北区",
              "lng": "109.57224",
              "lat": "23.11153",
              "level": "district"
            },
            {
              "value": 2156,
              "parent_id": 2154,
              "label": "港南区",
              "lng": "109.599556",
              "lat": "23.075573",
              "level": "district"
            },
            {
              "value": 2157,
              "parent_id": 2154,
              "label": "覃塘区",
              "lng": "109.452662",
              "lat": "23.127149",
              "level": "district"
            },
            {
              "value": 2158,
              "parent_id": 2154,
              "label": "平南县",
              "lng": "110.392311",
              "lat": "23.539264",
              "level": "district"
            },
            {
              "value": 2159,
              "parent_id": 2154,
              "label": "桂平市",
              "lng": "110.079379",
              "lat": "23.394325",
              "level": "district"
            }
          ]
        },
        {
          "value": 2160,
          "parent_id": 2088,
          "label": "玉林市",
          "lng": "110.18122",
          "lat": "22.654032",
          "level": "city",
          "children": [
            {
              "value": 2161,
              "parent_id": 2160,
              "label": "玉州区",
              "lng": "110.151153",
              "lat": "22.628087",
              "level": "district"
            },
            {
              "value": 2162,
              "parent_id": 2160,
              "label": "福绵区",
              "lng": "110.059439",
              "lat": "22.585556",
              "level": "district"
            },
            {
              "value": 2163,
              "parent_id": 2160,
              "label": "容县",
              "lng": "110.558074",
              "lat": "22.857839",
              "level": "district"
            },
            {
              "value": 2164,
              "parent_id": 2160,
              "label": "陆川县",
              "lng": "110.264052",
              "lat": "22.321048",
              "level": "district"
            },
            {
              "value": 2165,
              "parent_id": 2160,
              "label": "博白县",
              "lng": "109.975985",
              "lat": "22.273048",
              "level": "district"
            },
            {
              "value": 2166,
              "parent_id": 2160,
              "label": "兴业县",
              "lng": "109.875304",
              "lat": "22.736421",
              "level": "district"
            },
            {
              "value": 2167,
              "parent_id": 2160,
              "label": "北流市",
              "lng": "110.354214",
              "lat": "22.70831",
              "level": "district"
            }
          ]
        },
        {
          "value": 2168,
          "parent_id": 2088,
          "label": "百色市",
          "lng": "106.618202",
          "lat": "23.90233",
          "level": "city",
          "children": [
            {
              "value": 2169,
              "parent_id": 2168,
              "label": "右江区",
              "lng": "106.618225",
              "lat": "23.90097",
              "level": "district"
            },
            {
              "value": 2170,
              "parent_id": 2168,
              "label": "田阳县",
              "lng": "106.915496",
              "lat": "23.735692",
              "level": "district"
            },
            {
              "value": 2171,
              "parent_id": 2168,
              "label": "田东县",
              "lng": "107.12608",
              "lat": "23.597194",
              "level": "district"
            },
            {
              "value": 2172,
              "parent_id": 2168,
              "label": "平果县",
              "lng": "107.589809",
              "lat": "23.329376",
              "level": "district"
            },
            {
              "value": 2173,
              "parent_id": 2168,
              "label": "德保县",
              "lng": "106.615373",
              "lat": "23.32345",
              "level": "district"
            },
            {
              "value": 2174,
              "parent_id": 2168,
              "label": "那坡县",
              "lng": "105.83253",
              "lat": "23.387441",
              "level": "district"
            },
            {
              "value": 2175,
              "parent_id": 2168,
              "label": "凌云县",
              "lng": "106.56131",
              "lat": "24.347557",
              "level": "district"
            },
            {
              "value": 2176,
              "parent_id": 2168,
              "label": "乐业县",
              "lng": "106.556519",
              "lat": "24.776827",
              "level": "district"
            },
            {
              "value": 2177,
              "parent_id": 2168,
              "label": "田林县",
              "lng": "106.228538",
              "lat": "24.294487",
              "level": "district"
            },
            {
              "value": 2178,
              "parent_id": 2168,
              "label": "西林县",
              "lng": "105.093825",
              "lat": "24.489823",
              "level": "district"
            },
            {
              "value": 2179,
              "parent_id": 2168,
              "label": "隆林各族自治县",
              "lng": "105.34404",
              "lat": "24.770896",
              "level": "district"
            },
            {
              "value": 2180,
              "parent_id": 2168,
              "label": "靖西市",
              "lng": "106.417805",
              "lat": "23.134117",
              "level": "district"
            }
          ]
        },
        {
          "value": 2181,
          "parent_id": 2088,
          "label": "贺州市",
          "lng": "111.566871",
          "lat": "24.403528",
          "level": "city",
          "children": [
            {
              "value": 2182,
              "parent_id": 2181,
              "label": "八步区",
              "lng": "111.552095",
              "lat": "24.411805",
              "level": "district"
            },
            {
              "value": 2183,
              "parent_id": 2181,
              "label": "平桂区",
              "lng": "111.479923",
              "lat": "24.453845",
              "level": "district"
            },
            {
              "value": 2184,
              "parent_id": 2181,
              "label": "昭平县",
              "lng": "110.811325",
              "lat": "24.169385",
              "level": "district"
            },
            {
              "value": 2185,
              "parent_id": 2181,
              "label": "钟山县",
              "lng": "111.303009",
              "lat": "24.525957",
              "level": "district"
            },
            {
              "value": 2186,
              "parent_id": 2181,
              "label": "富川瑶族自治县",
              "lng": "111.27745",
              "lat": "24.814443",
              "level": "district"
            }
          ]
        },
        {
          "value": 2187,
          "parent_id": 2088,
          "label": "河池市",
          "lng": "108.085261",
          "lat": "24.692931",
          "level": "city",
          "children": [
            {
              "value": 2188,
              "parent_id": 2187,
              "label": "金城江区",
              "lng": "108.037276",
              "lat": "24.689703",
              "level": "district"
            },
            {
              "value": 2189,
              "parent_id": 2187,
              "label": "南丹县",
              "lng": "107.541244",
              "lat": "24.975631",
              "level": "district"
            },
            {
              "value": 2190,
              "parent_id": 2187,
              "label": "天峨县",
              "lng": "107.173802",
              "lat": "24.999108",
              "level": "district"
            },
            {
              "value": 2191,
              "parent_id": 2187,
              "label": "凤山县",
              "lng": "107.04219",
              "lat": "24.546876",
              "level": "district"
            },
            {
              "value": 2192,
              "parent_id": 2187,
              "label": "东兰县",
              "lng": "107.374293",
              "lat": "24.510842",
              "level": "district"
            },
            {
              "value": 2193,
              "parent_id": 2187,
              "label": "罗城仫佬族自治县",
              "lng": "108.904706",
              "lat": "24.777411",
              "level": "district"
            },
            {
              "value": 2194,
              "parent_id": 2187,
              "label": "环江毛南族自治县",
              "lng": "108.258028",
              "lat": "24.825664",
              "level": "district"
            },
            {
              "value": 2195,
              "parent_id": 2187,
              "label": "巴马瑶族自治县",
              "lng": "107.258588",
              "lat": "24.142298",
              "level": "district"
            },
            {
              "value": 2196,
              "parent_id": 2187,
              "label": "都安瑶族自治县",
              "lng": "108.105311",
              "lat": "23.932675",
              "level": "district"
            },
            {
              "value": 2197,
              "parent_id": 2187,
              "label": "大化瑶族自治县",
              "lng": "107.998149",
              "lat": "23.736457",
              "level": "district"
            },
            {
              "value": 2198,
              "parent_id": 2187,
              "label": "宜州市",
              "lng": "108.636414",
              "lat": "24.485214",
              "level": "district"
            }
          ]
        },
        {
          "value": 2199,
          "parent_id": 2088,
          "label": "来宾市",
          "lng": "109.221465",
          "lat": "23.750306",
          "level": "city",
          "children": [
            {
              "value": 2200,
              "parent_id": 2199,
              "label": "兴宾区",
              "lng": "109.183333",
              "lat": "23.72892",
              "level": "district"
            },
            {
              "value": 2201,
              "parent_id": 2199,
              "label": "忻城县",
              "lng": "108.665666",
              "lat": "24.066234",
              "level": "district"
            },
            {
              "value": 2202,
              "parent_id": 2199,
              "label": "象州县",
              "lng": "109.705065",
              "lat": "23.973793",
              "level": "district"
            },
            {
              "value": 2203,
              "parent_id": 2199,
              "label": "武宣县",
              "lng": "109.663206",
              "lat": "23.59411",
              "level": "district"
            },
            {
              "value": 2204,
              "parent_id": 2199,
              "label": "金秀瑶族自治县",
              "lng": "110.189462",
              "lat": "24.130374",
              "level": "district"
            },
            {
              "value": 2205,
              "parent_id": 2199,
              "label": "合山市",
              "lng": "108.886082",
              "lat": "23.806535",
              "level": "district"
            }
          ]
        },
        {
          "value": 2206,
          "parent_id": 2088,
          "label": "崇左市",
          "lng": "107.365094",
          "lat": "22.377253",
          "level": "city",
          "children": [
            {
              "value": 2207,
              "parent_id": 2206,
              "label": "江州区",
              "lng": "107.353437",
              "lat": "22.405325",
              "level": "district"
            },
            {
              "value": 2208,
              "parent_id": 2206,
              "label": "扶绥县",
              "lng": "107.904186",
              "lat": "22.635012",
              "level": "district"
            },
            {
              "value": 2209,
              "parent_id": 2206,
              "label": "宁明县",
              "lng": "107.076456",
              "lat": "22.140192",
              "level": "district"
            },
            {
              "value": 2210,
              "parent_id": 2206,
              "label": "龙州县",
              "lng": "106.854482",
              "lat": "22.342778",
              "level": "district"
            },
            {
              "value": 2211,
              "parent_id": 2206,
              "label": "大新县",
              "lng": "107.200654",
              "lat": "22.829287",
              "level": "district"
            },
            {
              "value": 2212,
              "parent_id": 2206,
              "label": "天等县",
              "lng": "107.143432",
              "lat": "23.081394",
              "level": "district"
            },
            {
              "value": 2213,
              "parent_id": 2206,
              "label": "凭祥市",
              "lng": "106.766293",
              "lat": "22.094484",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2214,
      "parent_id": 1,
      "label": "海南省",
      "lng": "110.349228",
      "lat": "20.017377",
      "level": "province",
      "children": [
        {
          "value": 2215,
          "parent_id": 2214,
          "label": "白沙黎族自治县",
          "lng": "109.451484",
          "lat": "19.224823",
          "level": "city",
          "children": [
            {
              "value": 2216,
              "parent_id": 2215,
              "label": "白沙黎族自治县",
              "lng": "109.451484",
              "lat": "19.224823",
              "level": "district"
            }
          ]
        },
        {
          "value": 2217,
          "parent_id": 2214,
          "label": "保亭黎族苗族自治县",
          "lng": "109.70259",
          "lat": "18.63913",
          "level": "city",
          "children": [
            {
              "value": 2218,
              "parent_id": 2217,
              "label": "保亭黎族苗族自治县",
              "lng": "109.70259",
              "lat": "18.63913",
              "level": "district"
            }
          ]
        },
        {
          "value": 2219,
          "parent_id": 2214,
          "label": "昌江黎族自治县",
          "lng": "109.055739",
          "lat": "19.298184",
          "level": "city",
          "children": [
            {
              "value": 2220,
              "parent_id": 2219,
              "label": "昌江黎族自治县",
              "lng": "109.055739",
              "lat": "19.298184",
              "level": "district"
            }
          ]
        },
        {
          "value": 2221,
          "parent_id": 2214,
          "label": "澄迈县",
          "lng": "110.006754",
          "lat": "19.738521",
          "level": "city",
          "children": [
            {
              "value": 2222,
              "parent_id": 2221,
              "label": "澄迈县",
              "lng": "110.006754",
              "lat": "19.738521",
              "level": "district"
            }
          ]
        },
        {
          "value": 2223,
          "parent_id": 2214,
          "label": "海口市",
          "lng": "110.198286",
          "lat": "20.044412",
          "level": "city",
          "children": [
            {
              "value": 2224,
              "parent_id": 2223,
              "label": "秀英区",
              "lng": "110.293603",
              "lat": "20.007494",
              "level": "district"
            },
            {
              "value": 2225,
              "parent_id": 2223,
              "label": "龙华区",
              "lng": "110.328492",
              "lat": "20.031006",
              "level": "district"
            },
            {
              "value": 2226,
              "parent_id": 2223,
              "label": "琼山区",
              "lng": "110.353972",
              "lat": "20.003169",
              "level": "district"
            },
            {
              "value": 2227,
              "parent_id": 2223,
              "label": "美兰区",
              "lng": "110.366358",
              "lat": "20.029083",
              "level": "district"
            }
          ]
        },
        {
          "value": 2228,
          "parent_id": 2214,
          "label": "三亚市",
          "lng": "109.511772",
          "lat": "18.253135",
          "level": "city",
          "children": [
            {
              "value": 2229,
              "parent_id": 2228,
              "label": "海棠区",
              "lng": "109.752569",
              "lat": "18.400106",
              "level": "district"
            },
            {
              "value": 2230,
              "parent_id": 2228,
              "label": "吉阳区",
              "lng": "109.578336",
              "lat": "18.281406",
              "level": "district"
            },
            {
              "value": 2231,
              "parent_id": 2228,
              "label": "天涯区",
              "lng": "109.452378",
              "lat": "18.298156",
              "level": "district"
            },
            {
              "value": 2232,
              "parent_id": 2228,
              "label": "崖州区",
              "lng": "109.171841",
              "lat": "18.357291",
              "level": "district"
            }
          ]
        },
        {
          "value": 2233,
          "parent_id": 2214,
          "label": "三沙市",
          "lng": "112.338695",
          "lat": "16.831839",
          "level": "city",
          "children": [
            {
              "value": 2234,
              "parent_id": 2233,
              "label": "西沙群岛",
              "lng": "111.792944",
              "lat": "16.204546",
              "level": "district"
            },
            {
              "value": 2235,
              "parent_id": 2233,
              "label": "南沙群岛",
              "lng": "116.749997",
              "lat": "11.471888",
              "level": "district"
            },
            {
              "value": 2236,
              "parent_id": 2233,
              "label": "中沙群岛的岛礁及其海域",
              "lng": "117.740071",
              "lat": "15.112855",
              "level": "district"
            }
          ]
        },
        {
          "value": 2237,
          "parent_id": 2214,
          "label": "儋州市",
          "lng": "109.580811",
          "lat": "19.521134",
          "level": "city",
          "children": [
            {
              "value": 2238,
              "parent_id": 2237,
              "label": "儋州市",
              "lng": "109.580811",
              "lat": "19.521134",
              "level": "district"
            }
          ]
        },
        {
          "value": 2239,
          "parent_id": 2214,
          "label": "定安县",
          "lng": "110.359339",
          "lat": "19.681404",
          "level": "city",
          "children": [
            {
              "value": 2240,
              "parent_id": 2239,
              "label": "定安县",
              "lng": "110.359339",
              "lat": "19.681404",
              "level": "district"
            }
          ]
        },
        {
          "value": 2241,
          "parent_id": 2214,
          "label": "东方市",
          "lng": "108.651815",
          "lat": "19.095351",
          "level": "city",
          "children": [
            {
              "value": 2242,
              "parent_id": 2241,
              "label": "东方市",
              "lng": "108.651815",
              "lat": "19.095351",
              "level": "district"
            }
          ]
        },
        {
          "value": 2243,
          "parent_id": 2214,
          "label": "乐东黎族自治县",
          "lng": "109.173054",
          "lat": "18.750259",
          "level": "city",
          "children": [
            {
              "value": 2244,
              "parent_id": 2243,
              "label": "乐东黎族自治县",
              "lng": "109.173054",
              "lat": "18.750259",
              "level": "district"
            }
          ]
        },
        {
          "value": 2245,
          "parent_id": 2214,
          "label": "临高县",
          "lng": "109.690508",
          "lat": "19.912025",
          "level": "city",
          "children": [
            {
              "value": 2246,
              "parent_id": 2245,
              "label": "临高县",
              "lng": "109.690508",
              "lat": "19.912025",
              "level": "district"
            }
          ]
        },
        {
          "value": 2247,
          "parent_id": 2214,
          "label": "陵水黎族自治县",
          "lng": "110.037503",
          "lat": "18.506048",
          "level": "city",
          "children": [
            {
              "value": 2248,
              "parent_id": 2247,
              "label": "陵水黎族自治县",
              "lng": "110.037503",
              "lat": "18.506048",
              "level": "district"
            }
          ]
        },
        {
          "value": 2249,
          "parent_id": 2214,
          "label": "琼海市",
          "lng": "110.474497",
          "lat": "19.259134",
          "level": "city",
          "children": [
            {
              "value": 2250,
              "parent_id": 2249,
              "label": "琼海市",
              "lng": "110.474497",
              "lat": "19.259134",
              "level": "district"
            }
          ]
        },
        {
          "value": 2251,
          "parent_id": 2214,
          "label": "琼中黎族苗族自治县",
          "lng": "109.838389",
          "lat": "19.033369",
          "level": "city",
          "children": [
            {
              "value": 2252,
              "parent_id": 2251,
              "label": "琼中黎族苗族自治县",
              "lng": "109.838389",
              "lat": "19.033369",
              "level": "district"
            }
          ]
        },
        {
          "value": 2253,
          "parent_id": 2214,
          "label": "屯昌县",
          "lng": "110.103415",
          "lat": "19.351765",
          "level": "city",
          "children": [
            {
              "value": 2254,
              "parent_id": 2253,
              "label": "屯昌县",
              "lng": "110.103415",
              "lat": "19.351765",
              "level": "district"
            }
          ]
        },
        {
          "value": 2255,
          "parent_id": 2214,
          "label": "万宁市",
          "lng": "110.391073",
          "lat": "18.795143",
          "level": "city",
          "children": [
            {
              "value": 2256,
              "parent_id": 2255,
              "label": "万宁市",
              "lng": "110.391073",
              "lat": "18.795143",
              "level": "district"
            }
          ]
        },
        {
          "value": 2257,
          "parent_id": 2214,
          "label": "文昌市",
          "lng": "110.797717",
          "lat": "19.543422",
          "level": "city",
          "children": [
            {
              "value": 2258,
              "parent_id": 2257,
              "label": "文昌市",
              "lng": "110.797717",
              "lat": "19.543422",
              "level": "district"
            }
          ]
        },
        {
          "value": 2259,
          "parent_id": 2214,
          "label": "五指山市",
          "lng": "109.516925",
          "lat": "18.775146",
          "level": "city",
          "children": [
            {
              "value": 2260,
              "parent_id": 2259,
              "label": "五指山市",
              "lng": "109.516925",
              "lat": "18.775146",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2261,
      "parent_id": 1,
      "label": "重庆市",
      "lng": "106.551643",
      "lat": "29.562849",
      "level": "province",
      "children": [
        {
          "value": 2262,
          "parent_id": 2261,
          "label": "重庆市",
          "lng": "106.551643",
          "lat": "29.562849",
          "level": "city",
          "children": [
            {
              "value": 2263,
              "parent_id": 2262,
              "label": "万州区",
              "lng": "108.408661",
              "lat": "30.807667",
              "level": "district"
            },
            {
              "value": 2264,
              "parent_id": 2262,
              "label": "涪陵区",
              "lng": "107.38977",
              "lat": "29.703022",
              "level": "district"
            },
            {
              "value": 2265,
              "parent_id": 2262,
              "label": "渝中区",
              "lng": "106.568896",
              "lat": "29.552736",
              "level": "district"
            },
            {
              "value": 2266,
              "parent_id": 2262,
              "label": "大渡口区",
              "lng": "106.482346",
              "lat": "29.484527",
              "level": "district"
            },
            {
              "value": 2267,
              "parent_id": 2262,
              "label": "江北区",
              "lng": "106.574271",
              "lat": "29.606703",
              "level": "district"
            },
            {
              "value": 2268,
              "parent_id": 2262,
              "label": "沙坪坝区",
              "lng": "106.456878",
              "lat": "29.541144",
              "level": "district"
            },
            {
              "value": 2269,
              "parent_id": 2262,
              "label": "九龙坡区",
              "lng": "106.510676",
              "lat": "29.502272",
              "level": "district"
            },
            {
              "value": 2270,
              "parent_id": 2262,
              "label": "南岸区",
              "lng": "106.644447",
              "lat": "29.50126",
              "level": "district"
            },
            {
              "value": 2271,
              "parent_id": 2262,
              "label": "北碚区",
              "lng": "106.395612",
              "lat": "29.805107",
              "level": "district"
            },
            {
              "value": 2272,
              "parent_id": 2262,
              "label": "綦江区",
              "lng": "106.651361",
              "lat": "29.028066",
              "level": "district"
            },
            {
              "value": 2273,
              "parent_id": 2262,
              "label": "大足区",
              "lng": "105.721733",
              "lat": "29.707032",
              "level": "district"
            },
            {
              "value": 2274,
              "parent_id": 2262,
              "label": "渝北区",
              "lng": "106.631187",
              "lat": "29.718142",
              "level": "district"
            },
            {
              "value": 2275,
              "parent_id": 2262,
              "label": "巴南区",
              "lng": "106.540256",
              "lat": "29.402408",
              "level": "district"
            },
            {
              "value": 2276,
              "parent_id": 2262,
              "label": "黔江区",
              "lng": "108.770677",
              "lat": "29.533609",
              "level": "district"
            },
            {
              "value": 2277,
              "parent_id": 2262,
              "label": "长寿区",
              "lng": "107.080734",
              "lat": "29.857912",
              "level": "district"
            },
            {
              "value": 2278,
              "parent_id": 2262,
              "label": "江津区",
              "lng": "106.259281",
              "lat": "29.290069",
              "level": "district"
            },
            {
              "value": 2279,
              "parent_id": 2262,
              "label": "合川区",
              "lng": "106.27613",
              "lat": "29.972084",
              "level": "district"
            },
            {
              "value": 2280,
              "parent_id": 2262,
              "label": "永川区",
              "lng": "105.927001",
              "lat": "29.356311",
              "level": "district"
            },
            {
              "value": 2281,
              "parent_id": 2262,
              "label": "南川区",
              "lng": "107.099266",
              "lat": "29.15789",
              "level": "district"
            },
            {
              "value": 2282,
              "parent_id": 2262,
              "label": "璧山区",
              "lng": "106.227305",
              "lat": "29.592024",
              "level": "district"
            },
            {
              "value": 2283,
              "parent_id": 2262,
              "label": "铜梁区",
              "lng": "106.056404",
              "lat": "29.844811",
              "level": "district"
            },
            {
              "value": 2284,
              "parent_id": 2262,
              "label": "潼南区",
              "lng": "105.840431",
              "lat": "30.190992",
              "level": "district"
            },
            {
              "value": 2285,
              "parent_id": 2262,
              "label": "荣昌区",
              "lng": "105.594623",
              "lat": "29.405002",
              "level": "district"
            },
            {
              "value": 2286,
              "parent_id": 2262,
              "label": "开州区",
              "lng": "108.393135",
              "lat": "31.160711",
              "level": "district"
            }
          ]
        },
        {
          "value": 2287,
          "parent_id": 2261,
          "label": "重庆郊县",
          "lng": "108.165537",
          "lat": "29.293902",
          "level": "city",
          "children": [
            {
              "value": 2288,
              "parent_id": 2287,
              "label": "梁平区",
              "lng": "107.769568",
              "lat": "30.654233",
              "level": "district"
            },
            {
              "value": 2289,
              "parent_id": 2287,
              "label": "城口县",
              "lng": "108.664214",
              "lat": "31.947633",
              "level": "district"
            },
            {
              "value": 2290,
              "parent_id": 2287,
              "label": "丰都县",
              "lng": "107.730894",
              "lat": "29.8635",
              "level": "district"
            },
            {
              "value": 2291,
              "parent_id": 2287,
              "label": "垫江县",
              "lng": "107.33339",
              "lat": "30.327716",
              "level": "district"
            },
            {
              "value": 2292,
              "parent_id": 2287,
              "label": "武隆区",
              "lng": "107.760025",
              "lat": "29.325601",
              "level": "district"
            },
            {
              "value": 2293,
              "parent_id": 2287,
              "label": "忠县",
              "lng": "108.039002",
              "lat": "30.299559",
              "level": "district"
            },
            {
              "value": 2294,
              "parent_id": 2287,
              "label": "云阳县",
              "lng": "108.697324",
              "lat": "30.930612",
              "level": "district"
            },
            {
              "value": 2295,
              "parent_id": 2287,
              "label": "奉节县",
              "lng": "109.400403",
              "lat": "31.018363",
              "level": "district"
            },
            {
              "value": 2296,
              "parent_id": 2287,
              "label": "巫山县",
              "lng": "109.879153",
              "lat": "31.074834",
              "level": "district"
            },
            {
              "value": 2297,
              "parent_id": 2287,
              "label": "巫溪县",
              "lng": "109.570062",
              "lat": "31.398604",
              "level": "district"
            },
            {
              "value": 2298,
              "parent_id": 2287,
              "label": "石柱土家族自治县",
              "lng": "108.114069",
              "lat": "29.999285",
              "level": "district"
            },
            {
              "value": 2299,
              "parent_id": 2287,
              "label": "秀山土家族苗族自治县",
              "lng": "109.007094",
              "lat": "28.447997",
              "level": "district"
            },
            {
              "value": 2300,
              "parent_id": 2287,
              "label": "酉阳土家族苗族自治县",
              "lng": "108.767747",
              "lat": "28.841244",
              "level": "district"
            },
            {
              "value": 2301,
              "parent_id": 2287,
              "label": "彭水苗族土家族自治县",
              "lng": "108.165537",
              "lat": "29.293902",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2302,
      "parent_id": 1,
      "label": "四川省",
      "lng": "104.075809",
      "lat": "30.651239",
      "level": "province",
      "children": [
        {
          "value": 2303,
          "parent_id": 2302,
          "label": "成都市",
          "lng": "104.066794",
          "lat": "30.572893",
          "level": "city",
          "children": [
            {
              "value": 2304,
              "parent_id": 2303,
              "label": "锦江区",
              "lng": "104.117022",
              "lat": "30.598158",
              "level": "district"
            },
            {
              "value": 2305,
              "parent_id": 2303,
              "label": "青羊区",
              "lng": "104.061442",
              "lat": "30.673914",
              "level": "district"
            },
            {
              "value": 2306,
              "parent_id": 2303,
              "label": "金牛区",
              "lng": "104.052236",
              "lat": "30.691359",
              "level": "district"
            },
            {
              "value": 2307,
              "parent_id": 2303,
              "label": "武侯区",
              "lng": "104.043235",
              "lat": "30.641907",
              "level": "district"
            },
            {
              "value": 2308,
              "parent_id": 2303,
              "label": "成华区",
              "lng": "104.101515",
              "lat": "30.659966",
              "level": "district"
            },
            {
              "value": 2309,
              "parent_id": 2303,
              "label": "龙泉驿区",
              "lng": "104.274632",
              "lat": "30.556506",
              "level": "district"
            },
            {
              "value": 2310,
              "parent_id": 2303,
              "label": "青白江区",
              "lng": "104.250945",
              "lat": "30.878629",
              "level": "district"
            },
            {
              "value": 2311,
              "parent_id": 2303,
              "label": "新都区",
              "lng": "104.158705",
              "lat": "30.823498",
              "level": "district"
            },
            {
              "value": 2312,
              "parent_id": 2303,
              "label": "温江区",
              "lng": "103.856646",
              "lat": "30.682203",
              "level": "district"
            },
            {
              "value": 2313,
              "parent_id": 2303,
              "label": "双流区",
              "lng": "103.923566",
              "lat": "30.574449",
              "level": "district"
            },
            {
              "value": 2314,
              "parent_id": 2303,
              "label": "金堂县",
              "lng": "104.411976",
              "lat": "30.861979",
              "level": "district"
            },
            {
              "value": 2315,
              "parent_id": 2303,
              "label": "郫都区",
              "lng": "103.901091",
              "lat": "30.795854",
              "level": "district"
            },
            {
              "value": 2316,
              "parent_id": 2303,
              "label": "大邑县",
              "lng": "103.511865",
              "lat": "30.572268",
              "level": "district"
            },
            {
              "value": 2317,
              "parent_id": 2303,
              "label": "蒲江县",
              "lng": "103.506498",
              "lat": "30.196788",
              "level": "district"
            },
            {
              "value": 2318,
              "parent_id": 2303,
              "label": "新津县",
              "lng": "103.811286",
              "lat": "30.410346",
              "level": "district"
            },
            {
              "value": 2319,
              "parent_id": 2303,
              "label": "简阳市",
              "lng": "104.54722",
              "lat": "30.411264",
              "level": "district"
            },
            {
              "value": 2320,
              "parent_id": 2303,
              "label": "都江堰市",
              "lng": "103.647153",
              "lat": "30.988767",
              "level": "district"
            },
            {
              "value": 2321,
              "parent_id": 2303,
              "label": "彭州市",
              "lng": "103.957983",
              "lat": "30.990212",
              "level": "district"
            },
            {
              "value": 2322,
              "parent_id": 2303,
              "label": "邛崃市",
              "lng": "103.464207",
              "lat": "30.410324",
              "level": "district"
            },
            {
              "value": 2323,
              "parent_id": 2303,
              "label": "崇州市",
              "lng": "103.673001",
              "lat": "30.630122",
              "level": "district"
            }
          ]
        },
        {
          "value": 2324,
          "parent_id": 2302,
          "label": "自贡市",
          "lng": "104.778442",
          "lat": "29.33903",
          "level": "city",
          "children": [
            {
              "value": 2325,
              "parent_id": 2324,
              "label": "自流井区",
              "lng": "104.777191",
              "lat": "29.337429",
              "level": "district"
            },
            {
              "value": 2326,
              "parent_id": 2324,
              "label": "贡井区",
              "lng": "104.715288",
              "lat": "29.345313",
              "level": "district"
            },
            {
              "value": 2327,
              "parent_id": 2324,
              "label": "大安区",
              "lng": "104.773994",
              "lat": "29.363702",
              "level": "district"
            },
            {
              "value": 2328,
              "parent_id": 2324,
              "label": "沿滩区",
              "lng": "104.874079",
              "lat": "29.272586",
              "level": "district"
            },
            {
              "value": 2329,
              "parent_id": 2324,
              "label": "荣县",
              "lng": "104.417493",
              "lat": "29.445479",
              "level": "district"
            },
            {
              "value": 2330,
              "parent_id": 2324,
              "label": "富顺县",
              "lng": "104.975048",
              "lat": "29.181429",
              "level": "district"
            }
          ]
        },
        {
          "value": 2331,
          "parent_id": 2302,
          "label": "攀枝花市",
          "lng": "101.718637",
          "lat": "26.582347",
          "level": "city",
          "children": [
            {
              "value": 2332,
              "parent_id": 2331,
              "label": "东区",
              "lng": "101.704109",
              "lat": "26.546491",
              "level": "district"
            },
            {
              "value": 2333,
              "parent_id": 2331,
              "label": "西区",
              "lng": "101.630619",
              "lat": "26.597781",
              "level": "district"
            },
            {
              "value": 2334,
              "parent_id": 2331,
              "label": "仁和区",
              "lng": "101.738528",
              "lat": "26.497765",
              "level": "district"
            },
            {
              "value": 2335,
              "parent_id": 2331,
              "label": "米易县",
              "lng": "102.112895",
              "lat": "26.897694",
              "level": "district"
            },
            {
              "value": 2336,
              "parent_id": 2331,
              "label": "盐边县",
              "lng": "101.855071",
              "lat": "26.683213",
              "level": "district"
            }
          ]
        },
        {
          "value": 2337,
          "parent_id": 2302,
          "label": "泸州市",
          "lng": "105.442285",
          "lat": "28.871805",
          "level": "city",
          "children": [
            {
              "value": 2338,
              "parent_id": 2337,
              "label": "江阳区",
              "lng": "105.434982",
              "lat": "28.87881",
              "level": "district"
            },
            {
              "value": 2339,
              "parent_id": 2337,
              "label": "纳溪区",
              "lng": "105.371505",
              "lat": "28.773134",
              "level": "district"
            },
            {
              "value": 2340,
              "parent_id": 2337,
              "label": "龙马潭区",
              "lng": "105.437751",
              "lat": "28.913257",
              "level": "district"
            },
            {
              "value": 2341,
              "parent_id": 2337,
              "label": "泸县",
              "lng": "105.381893",
              "lat": "29.151534",
              "level": "district"
            },
            {
              "value": 2342,
              "parent_id": 2337,
              "label": "合江县",
              "lng": "105.830986",
              "lat": "28.811164",
              "level": "district"
            },
            {
              "value": 2343,
              "parent_id": 2337,
              "label": "叙永县",
              "lng": "105.444765",
              "lat": "28.155801",
              "level": "district"
            },
            {
              "value": 2344,
              "parent_id": 2337,
              "label": "古蔺县",
              "lng": "105.812601",
              "lat": "28.038801",
              "level": "district"
            }
          ]
        },
        {
          "value": 2345,
          "parent_id": 2302,
          "label": "德阳市",
          "lng": "104.397894",
          "lat": "31.126855",
          "level": "city",
          "children": [
            {
              "value": 2346,
              "parent_id": 2345,
              "label": "旌阳区",
              "lng": "104.416966",
              "lat": "31.142633",
              "level": "district"
            },
            {
              "value": 2347,
              "parent_id": 2345,
              "label": "中江县",
              "lng": "104.678751",
              "lat": "31.03307",
              "level": "district"
            },
            {
              "value": 2348,
              "parent_id": 2345,
              "label": "罗江县",
              "lng": "104.510249",
              "lat": "31.317045",
              "level": "district"
            },
            {
              "value": 2349,
              "parent_id": 2345,
              "label": "广汉市",
              "lng": "104.282429",
              "lat": "30.977119",
              "level": "district"
            },
            {
              "value": 2350,
              "parent_id": 2345,
              "label": "什邡市",
              "lng": "104.167501",
              "lat": "31.12678",
              "level": "district"
            },
            {
              "value": 2351,
              "parent_id": 2345,
              "label": "绵竹市",
              "lng": "104.22075",
              "lat": "31.338077",
              "level": "district"
            }
          ]
        },
        {
          "value": 2352,
          "parent_id": 2302,
          "label": "绵阳市",
          "lng": "104.679004",
          "lat": "31.467459",
          "level": "city",
          "children": [
            {
              "value": 2353,
              "parent_id": 2352,
              "label": "涪城区",
              "lng": "104.756944",
              "lat": "31.455101",
              "level": "district"
            },
            {
              "value": 2354,
              "parent_id": 2352,
              "label": "游仙区",
              "lng": "104.766392",
              "lat": "31.473779",
              "level": "district"
            },
            {
              "value": 2355,
              "parent_id": 2352,
              "label": "安州区",
              "lng": "104.567187",
              "lat": "31.534886",
              "level": "district"
            },
            {
              "value": 2356,
              "parent_id": 2352,
              "label": "三台县",
              "lng": "105.094586",
              "lat": "31.095979",
              "level": "district"
            },
            {
              "value": 2357,
              "parent_id": 2352,
              "label": "盐亭县",
              "lng": "105.389453",
              "lat": "31.208362",
              "level": "district"
            },
            {
              "value": 2358,
              "parent_id": 2352,
              "label": "梓潼县",
              "lng": "105.170845",
              "lat": "31.642718",
              "level": "district"
            },
            {
              "value": 2359,
              "parent_id": 2352,
              "label": "北川羌族自治县",
              "lng": "104.46797",
              "lat": "31.617203",
              "level": "district"
            },
            {
              "value": 2360,
              "parent_id": 2352,
              "label": "平武县",
              "lng": "104.555583",
              "lat": "32.409675",
              "level": "district"
            },
            {
              "value": 2361,
              "parent_id": 2352,
              "label": "江油市",
              "lng": "104.745915",
              "lat": "31.778026",
              "level": "district"
            }
          ]
        },
        {
          "value": 2362,
          "parent_id": 2302,
          "label": "广元市",
          "lng": "105.843357",
          "lat": "32.435435",
          "level": "city",
          "children": [
            {
              "value": 2363,
              "parent_id": 2362,
              "label": "利州区",
              "lng": "105.845307",
              "lat": "32.433756",
              "level": "district"
            },
            {
              "value": 2364,
              "parent_id": 2362,
              "label": "昭化区",
              "lng": "105.962819",
              "lat": "32.323256",
              "level": "district"
            },
            {
              "value": 2365,
              "parent_id": 2362,
              "label": "朝天区",
              "lng": "105.882642",
              "lat": "32.651336",
              "level": "district"
            },
            {
              "value": 2366,
              "parent_id": 2362,
              "label": "旺苍县",
              "lng": "106.289983",
              "lat": "32.229058",
              "level": "district"
            },
            {
              "value": 2367,
              "parent_id": 2362,
              "label": "青川县",
              "lng": "105.238842",
              "lat": "32.575484",
              "level": "district"
            },
            {
              "value": 2368,
              "parent_id": 2362,
              "label": "剑阁县",
              "lng": "105.524766",
              "lat": "32.287722",
              "level": "district"
            },
            {
              "value": 2369,
              "parent_id": 2362,
              "label": "苍溪县",
              "lng": "105.934756",
              "lat": "31.731709",
              "level": "district"
            }
          ]
        },
        {
          "value": 2370,
          "parent_id": 2302,
          "label": "遂宁市",
          "lng": "105.592803",
          "lat": "30.53292",
          "level": "city",
          "children": [
            {
              "value": 2371,
              "parent_id": 2370,
              "label": "船山区",
              "lng": "105.568297",
              "lat": "30.525475",
              "level": "district"
            },
            {
              "value": 2372,
              "parent_id": 2370,
              "label": "安居区",
              "lng": "105.456342",
              "lat": "30.355379",
              "level": "district"
            },
            {
              "value": 2373,
              "parent_id": 2370,
              "label": "蓬溪县",
              "lng": "105.70757",
              "lat": "30.757575",
              "level": "district"
            },
            {
              "value": 2374,
              "parent_id": 2370,
              "label": "射洪县",
              "lng": "105.388412",
              "lat": "30.871131",
              "level": "district"
            },
            {
              "value": 2375,
              "parent_id": 2370,
              "label": "大英县",
              "lng": "105.236923",
              "lat": "30.594409",
              "level": "district"
            }
          ]
        },
        {
          "value": 2376,
          "parent_id": 2302,
          "label": "内江市",
          "lng": "105.058432",
          "lat": "29.580228",
          "level": "city",
          "children": [
            {
              "value": 2377,
              "parent_id": 2376,
              "label": "市中区",
              "lng": "105.067597",
              "lat": "29.587053",
              "level": "district"
            },
            {
              "value": 2378,
              "parent_id": 2376,
              "label": "东兴区",
              "lng": "105.075489",
              "lat": "29.592756",
              "level": "district"
            },
            {
              "value": 2379,
              "parent_id": 2376,
              "label": "威远县",
              "lng": "104.668879",
              "lat": "29.52744",
              "level": "district"
            },
            {
              "value": 2380,
              "parent_id": 2376,
              "label": "资中县",
              "lng": "104.851944",
              "lat": "29.764059",
              "level": "district"
            },
            {
              "value": 2381,
              "parent_id": 2376,
              "label": "隆昌县",
              "lng": "105.287612",
              "lat": "29.339476",
              "level": "district"
            }
          ]
        },
        {
          "value": 2382,
          "parent_id": 2302,
          "label": "乐山市",
          "lng": "103.765678",
          "lat": "29.552115",
          "level": "city",
          "children": [
            {
              "value": 2383,
              "parent_id": 2382,
              "label": "市中区",
              "lng": "103.761329",
              "lat": "29.555374",
              "level": "district"
            },
            {
              "value": 2384,
              "parent_id": 2382,
              "label": "沙湾区",
              "lng": "103.549991",
              "lat": "29.413091",
              "level": "district"
            },
            {
              "value": 2385,
              "parent_id": 2382,
              "label": "五通桥区",
              "lng": "103.818014",
              "lat": "29.406945",
              "level": "district"
            },
            {
              "value": 2386,
              "parent_id": 2382,
              "label": "金口河区",
              "lng": "103.07862",
              "lat": "29.244345",
              "level": "district"
            },
            {
              "value": 2387,
              "parent_id": 2382,
              "label": "犍为县",
              "lng": "103.949326",
              "lat": "29.20817",
              "level": "district"
            },
            {
              "value": 2388,
              "parent_id": 2382,
              "label": "井研县",
              "lng": "104.069726",
              "lat": "29.651287",
              "level": "district"
            },
            {
              "value": 2389,
              "parent_id": 2382,
              "label": "夹江县",
              "lng": "103.571656",
              "lat": "29.73763",
              "level": "district"
            },
            {
              "value": 2390,
              "parent_id": 2382,
              "label": "沐川县",
              "lng": "103.902334",
              "lat": "28.956647",
              "level": "district"
            },
            {
              "value": 2391,
              "parent_id": 2382,
              "label": "峨边彝族自治县",
              "lng": "103.262048",
              "lat": "29.230425",
              "level": "district"
            },
            {
              "value": 2392,
              "parent_id": 2382,
              "label": "马边彝族自治县",
              "lng": "103.546347",
              "lat": "28.83552",
              "level": "district"
            },
            {
              "value": 2393,
              "parent_id": 2382,
              "label": "峨眉山市",
              "lng": "103.484503",
              "lat": "29.601198",
              "level": "district"
            }
          ]
        },
        {
          "value": 2394,
          "parent_id": 2302,
          "label": "南充市",
          "lng": "106.110698",
          "lat": "30.837793",
          "level": "city",
          "children": [
            {
              "value": 2395,
              "parent_id": 2394,
              "label": "顺庆区",
              "lng": "106.09245",
              "lat": "30.796803",
              "level": "district"
            },
            {
              "value": 2396,
              "parent_id": 2394,
              "label": "高坪区",
              "lng": "106.118808",
              "lat": "30.781623",
              "level": "district"
            },
            {
              "value": 2397,
              "parent_id": 2394,
              "label": "嘉陵区",
              "lng": "106.071876",
              "lat": "30.758823",
              "level": "district"
            },
            {
              "value": 2398,
              "parent_id": 2394,
              "label": "南部县",
              "lng": "106.036584",
              "lat": "31.347467",
              "level": "district"
            },
            {
              "value": 2399,
              "parent_id": 2394,
              "label": "营山县",
              "lng": "106.565519",
              "lat": "31.076579",
              "level": "district"
            },
            {
              "value": 2400,
              "parent_id": 2394,
              "label": "蓬安县",
              "lng": "106.412136",
              "lat": "31.029091",
              "level": "district"
            },
            {
              "value": 2401,
              "parent_id": 2394,
              "label": "仪陇县",
              "lng": "106.303042",
              "lat": "31.271561",
              "level": "district"
            },
            {
              "value": 2402,
              "parent_id": 2394,
              "label": "西充县",
              "lng": "105.90087",
              "lat": "30.995683",
              "level": "district"
            },
            {
              "value": 2403,
              "parent_id": 2394,
              "label": "阆中市",
              "lng": "106.005046",
              "lat": "31.558356",
              "level": "district"
            }
          ]
        },
        {
          "value": 2404,
          "parent_id": 2302,
          "label": "眉山市",
          "lng": "103.848403",
          "lat": "30.076994",
          "level": "city",
          "children": [
            {
              "value": 2405,
              "parent_id": 2404,
              "label": "东坡区",
              "lng": "103.831863",
              "lat": "30.042308",
              "level": "district"
            },
            {
              "value": 2406,
              "parent_id": 2404,
              "label": "彭山区",
              "lng": "103.872949",
              "lat": "30.193056",
              "level": "district"
            },
            {
              "value": 2407,
              "parent_id": 2404,
              "label": "仁寿县",
              "lng": "104.133995",
              "lat": "29.995635",
              "level": "district"
            },
            {
              "value": 2408,
              "parent_id": 2404,
              "label": "洪雅县",
              "lng": "103.372863",
              "lat": "29.90489",
              "level": "district"
            },
            {
              "value": 2409,
              "parent_id": 2404,
              "label": "丹棱县",
              "lng": "103.512783",
              "lat": "30.01521",
              "level": "district"
            },
            {
              "value": 2410,
              "parent_id": 2404,
              "label": "青神县",
              "lng": "103.846688",
              "lat": "29.831357",
              "level": "district"
            }
          ]
        },
        {
          "value": 2411,
          "parent_id": 2302,
          "label": "宜宾市",
          "lng": "104.642845",
          "lat": "28.752134",
          "level": "city",
          "children": [
            {
              "value": 2412,
              "parent_id": 2411,
              "label": "翠屏区",
              "lng": "104.620009",
              "lat": "28.765689",
              "level": "district"
            },
            {
              "value": 2413,
              "parent_id": 2411,
              "label": "南溪区",
              "lng": "104.969152",
              "lat": "28.846382",
              "level": "district"
            },
            {
              "value": 2414,
              "parent_id": 2411,
              "label": "宜宾县",
              "lng": "104.533212",
              "lat": "28.690045",
              "level": "district"
            },
            {
              "value": 2415,
              "parent_id": 2411,
              "label": "江安县",
              "lng": "105.066879",
              "lat": "28.723855",
              "level": "district"
            },
            {
              "value": 2416,
              "parent_id": 2411,
              "label": "长宁县",
              "lng": "104.921174",
              "lat": "28.582169",
              "level": "district"
            },
            {
              "value": 2417,
              "parent_id": 2411,
              "label": "高县",
              "lng": "104.517748",
              "lat": "28.436166",
              "level": "district"
            },
            {
              "value": 2418,
              "parent_id": 2411,
              "label": "珙县",
              "lng": "104.709202",
              "lat": "28.43863",
              "level": "district"
            },
            {
              "value": 2419,
              "parent_id": 2411,
              "label": "筠连县",
              "lng": "104.512025",
              "lat": "28.167831",
              "level": "district"
            },
            {
              "value": 2420,
              "parent_id": 2411,
              "label": "兴文县",
              "lng": "105.236325",
              "lat": "28.303614",
              "level": "district"
            },
            {
              "value": 2421,
              "parent_id": 2411,
              "label": "屏山县",
              "lng": "104.345974",
              "lat": "28.828482",
              "level": "district"
            }
          ]
        },
        {
          "value": 2422,
          "parent_id": 2302,
          "label": "广安市",
          "lng": "106.633088",
          "lat": "30.456224",
          "level": "city",
          "children": [
            {
              "value": 2423,
              "parent_id": 2422,
              "label": "广安区",
              "lng": "106.641662",
              "lat": "30.473913",
              "level": "district"
            },
            {
              "value": 2424,
              "parent_id": 2422,
              "label": "前锋区",
              "lng": "106.886143",
              "lat": "30.495804",
              "level": "district"
            },
            {
              "value": 2425,
              "parent_id": 2422,
              "label": "岳池县",
              "lng": "106.440114",
              "lat": "30.537863",
              "level": "district"
            },
            {
              "value": 2426,
              "parent_id": 2422,
              "label": "武胜县",
              "lng": "106.295764",
              "lat": "30.348772",
              "level": "district"
            },
            {
              "value": 2427,
              "parent_id": 2422,
              "label": "邻水县",
              "lng": "106.93038",
              "lat": "30.334768",
              "level": "district"
            },
            {
              "value": 2428,
              "parent_id": 2422,
              "label": "华蓥市",
              "lng": "106.7831",
              "lat": "30.390188",
              "level": "district"
            }
          ]
        },
        {
          "value": 2429,
          "parent_id": 2302,
          "label": "达州市",
          "lng": "107.467758",
          "lat": "31.209121",
          "level": "city",
          "children": [
            {
              "value": 2430,
              "parent_id": 2429,
              "label": "通川区",
              "lng": "107.504928",
              "lat": "31.214715",
              "level": "district"
            },
            {
              "value": 2431,
              "parent_id": 2429,
              "label": "达川区",
              "lng": "107.511749",
              "lat": "31.196157",
              "level": "district"
            },
            {
              "value": 2432,
              "parent_id": 2429,
              "label": "宣汉县",
              "lng": "107.72719",
              "lat": "31.353835",
              "level": "district"
            },
            {
              "value": 2433,
              "parent_id": 2429,
              "label": "开江县",
              "lng": "107.868736",
              "lat": "31.082986",
              "level": "district"
            },
            {
              "value": 2434,
              "parent_id": 2429,
              "label": "大竹县",
              "lng": "107.204795",
              "lat": "30.73641",
              "level": "district"
            },
            {
              "value": 2435,
              "parent_id": 2429,
              "label": "渠县",
              "lng": "106.97303",
              "lat": "30.836618",
              "level": "district"
            },
            {
              "value": 2436,
              "parent_id": 2429,
              "label": "万源市",
              "lng": "108.034657",
              "lat": "32.081631",
              "level": "district"
            }
          ]
        },
        {
          "value": 2437,
          "parent_id": 2302,
          "label": "雅安市",
          "lng": "103.042375",
          "lat": "30.010602",
          "level": "city",
          "children": [
            {
              "value": 2438,
              "parent_id": 2437,
              "label": "雨城区",
              "lng": "103.033026",
              "lat": "30.005461",
              "level": "district"
            },
            {
              "value": 2439,
              "parent_id": 2437,
              "label": "名山区",
              "lng": "103.109184",
              "lat": "30.069954",
              "level": "district"
            },
            {
              "value": 2440,
              "parent_id": 2437,
              "label": "荥经县",
              "lng": "102.846737",
              "lat": "29.792931",
              "level": "district"
            },
            {
              "value": 2441,
              "parent_id": 2437,
              "label": "汉源县",
              "lng": "102.645467",
              "lat": "29.347192",
              "level": "district"
            },
            {
              "value": 2442,
              "parent_id": 2437,
              "label": "石棉县",
              "lng": "102.359462",
              "lat": "29.227874",
              "level": "district"
            },
            {
              "value": 2443,
              "parent_id": 2437,
              "label": "天全县",
              "lng": "102.758317",
              "lat": "30.066712",
              "level": "district"
            },
            {
              "value": 2444,
              "parent_id": 2437,
              "label": "芦山县",
              "lng": "102.932385",
              "lat": "30.142307",
              "level": "district"
            },
            {
              "value": 2445,
              "parent_id": 2437,
              "label": "宝兴县",
              "lng": "102.815403",
              "lat": "30.37641",
              "level": "district"
            }
          ]
        },
        {
          "value": 2446,
          "parent_id": 2302,
          "label": "巴中市",
          "lng": "106.747477",
          "lat": "31.867903",
          "level": "city",
          "children": [
            {
              "value": 2447,
              "parent_id": 2446,
              "label": "巴州区",
              "lng": "106.768878",
              "lat": "31.851478",
              "level": "district"
            },
            {
              "value": 2448,
              "parent_id": 2446,
              "label": "恩阳区",
              "lng": "106.654386",
              "lat": "31.787186",
              "level": "district"
            },
            {
              "value": 2449,
              "parent_id": 2446,
              "label": "通江县",
              "lng": "107.245033",
              "lat": "31.911705",
              "level": "district"
            },
            {
              "value": 2450,
              "parent_id": 2446,
              "label": "南江县",
              "lng": "106.828697",
              "lat": "32.346589",
              "level": "district"
            },
            {
              "value": 2451,
              "parent_id": 2446,
              "label": "平昌县",
              "lng": "107.104008",
              "lat": "31.560874",
              "level": "district"
            }
          ]
        },
        {
          "value": 2452,
          "parent_id": 2302,
          "label": "资阳市",
          "lng": "104.627636",
          "lat": "30.128901",
          "level": "city",
          "children": [
            {
              "value": 2453,
              "parent_id": 2452,
              "label": "雁江区",
              "lng": "104.677091",
              "lat": "30.108216",
              "level": "district"
            },
            {
              "value": 2454,
              "parent_id": 2452,
              "label": "安岳县",
              "lng": "105.35534",
              "lat": "30.103107",
              "level": "district"
            },
            {
              "value": 2455,
              "parent_id": 2452,
              "label": "乐至县",
              "lng": "105.02019",
              "lat": "30.276121",
              "level": "district"
            }
          ]
        },
        {
          "value": 2456,
          "parent_id": 2302,
          "label": "阿坝藏族羌族自治州",
          "lng": "102.224653",
          "lat": "31.899413",
          "level": "city",
          "children": [
            {
              "value": 2457,
              "parent_id": 2456,
              "label": "马尔康市",
              "lng": "102.20652",
              "lat": "31.905693",
              "level": "district"
            },
            {
              "value": 2458,
              "parent_id": 2456,
              "label": "汶川县",
              "lng": "103.590179",
              "lat": "31.476854",
              "level": "district"
            },
            {
              "value": 2459,
              "parent_id": 2456,
              "label": "理县",
              "lng": "103.164661",
              "lat": "31.435174",
              "level": "district"
            },
            {
              "value": 2460,
              "parent_id": 2456,
              "label": "茂县",
              "lng": "103.853363",
              "lat": "31.681547",
              "level": "district"
            },
            {
              "value": 2461,
              "parent_id": 2456,
              "label": "松潘县",
              "lng": "103.604698",
              "lat": "32.655325",
              "level": "district"
            },
            {
              "value": 2462,
              "parent_id": 2456,
              "label": "九寨沟县",
              "lng": "104.243841",
              "lat": "33.252056",
              "level": "district"
            },
            {
              "value": 2463,
              "parent_id": 2456,
              "label": "金川县",
              "lng": "102.063829",
              "lat": "31.476277",
              "level": "district"
            },
            {
              "value": 2464,
              "parent_id": 2456,
              "label": "小金县",
              "lng": "102.362984",
              "lat": "30.995823",
              "level": "district"
            },
            {
              "value": 2465,
              "parent_id": 2456,
              "label": "黑水县",
              "lng": "102.990108",
              "lat": "32.061895",
              "level": "district"
            },
            {
              "value": 2466,
              "parent_id": 2456,
              "label": "壤塘县",
              "lng": "100.978526",
              "lat": "32.265796",
              "level": "district"
            },
            {
              "value": 2467,
              "parent_id": 2456,
              "label": "阿坝县",
              "lng": "101.706655",
              "lat": "32.902459",
              "level": "district"
            },
            {
              "value": 2468,
              "parent_id": 2456,
              "label": "若尔盖县",
              "lng": "102.967826",
              "lat": "33.578159",
              "level": "district"
            },
            {
              "value": 2469,
              "parent_id": 2456,
              "label": "红原县",
              "lng": "102.544405",
              "lat": "32.790891",
              "level": "district"
            }
          ]
        },
        {
          "value": 2470,
          "parent_id": 2302,
          "label": "甘孜藏族自治州",
          "lng": "101.96231",
          "lat": "30.04952",
          "level": "city",
          "children": [
            {
              "value": 2471,
              "parent_id": 2470,
              "label": "康定市",
              "lng": "101.957146",
              "lat": "29.998435",
              "level": "district"
            },
            {
              "value": 2472,
              "parent_id": 2470,
              "label": "泸定县",
              "lng": "102.234617",
              "lat": "29.91416",
              "level": "district"
            },
            {
              "value": 2473,
              "parent_id": 2470,
              "label": "丹巴县",
              "lng": "101.890358",
              "lat": "30.878577",
              "level": "district"
            },
            {
              "value": 2474,
              "parent_id": 2470,
              "label": "九龙县",
              "lng": "101.507294",
              "lat": "29.000347",
              "level": "district"
            },
            {
              "value": 2475,
              "parent_id": 2470,
              "label": "雅江县",
              "lng": "101.014425",
              "lat": "30.031533",
              "level": "district"
            },
            {
              "value": 2476,
              "parent_id": 2470,
              "label": "道孚县",
              "lng": "101.125237",
              "lat": "30.979545",
              "level": "district"
            },
            {
              "value": 2477,
              "parent_id": 2470,
              "label": "炉霍县",
              "lng": "100.676372",
              "lat": "31.39179",
              "level": "district"
            },
            {
              "value": 2478,
              "parent_id": 2470,
              "label": "甘孜县",
              "lng": "99.99267",
              "lat": "31.622933",
              "level": "district"
            },
            {
              "value": 2479,
              "parent_id": 2470,
              "label": "新龙县",
              "lng": "100.311368",
              "lat": "30.939169",
              "level": "district"
            },
            {
              "value": 2480,
              "parent_id": 2470,
              "label": "德格县",
              "lng": "98.580914",
              "lat": "31.806118",
              "level": "district"
            },
            {
              "value": 2481,
              "parent_id": 2470,
              "label": "白玉县",
              "lng": "98.824182",
              "lat": "31.209913",
              "level": "district"
            },
            {
              "value": 2482,
              "parent_id": 2470,
              "label": "石渠县",
              "lng": "98.102914",
              "lat": "32.97896",
              "level": "district"
            },
            {
              "value": 2483,
              "parent_id": 2470,
              "label": "色达县",
              "lng": "100.332743",
              "lat": "32.268129",
              "level": "district"
            },
            {
              "value": 2484,
              "parent_id": 2470,
              "label": "理塘县",
              "lng": "100.269817",
              "lat": "29.996049",
              "level": "district"
            },
            {
              "value": 2485,
              "parent_id": 2470,
              "label": "巴塘县",
              "lng": "99.110712",
              "lat": "30.004677",
              "level": "district"
            },
            {
              "value": 2486,
              "parent_id": 2470,
              "label": "乡城县",
              "lng": "99.798435",
              "lat": "28.931172",
              "level": "district"
            },
            {
              "value": 2487,
              "parent_id": 2470,
              "label": "稻城县",
              "lng": "100.298403",
              "lat": "29.037007",
              "level": "district"
            },
            {
              "value": 2488,
              "parent_id": 2470,
              "label": "得荣县",
              "lng": "99.286335",
              "lat": "28.713036",
              "level": "district"
            }
          ]
        },
        {
          "value": 2489,
          "parent_id": 2302,
          "label": "凉山彝族自治州",
          "lng": "102.267712",
          "lat": "27.88157",
          "level": "city",
          "children": [
            {
              "value": 2490,
              "parent_id": 2489,
              "label": "西昌市",
              "lng": "102.264449",
              "lat": "27.894504",
              "level": "district"
            },
            {
              "value": 2491,
              "parent_id": 2489,
              "label": "木里藏族自治县",
              "lng": "101.280205",
              "lat": "27.928835",
              "level": "district"
            },
            {
              "value": 2492,
              "parent_id": 2489,
              "label": "盐源县",
              "lng": "101.509188",
              "lat": "27.422645",
              "level": "district"
            },
            {
              "value": 2493,
              "parent_id": 2489,
              "label": "德昌县",
              "lng": "102.17567",
              "lat": "27.402839",
              "level": "district"
            },
            {
              "value": 2494,
              "parent_id": 2489,
              "label": "会理县",
              "lng": "102.244683",
              "lat": "26.655026",
              "level": "district"
            },
            {
              "value": 2495,
              "parent_id": 2489,
              "label": "会东县",
              "lng": "102.57796",
              "lat": "26.634669",
              "level": "district"
            },
            {
              "value": 2496,
              "parent_id": 2489,
              "label": "宁南县",
              "lng": "102.751745",
              "lat": "27.061189",
              "level": "district"
            },
            {
              "value": 2497,
              "parent_id": 2489,
              "label": "普格县",
              "lng": "102.540901",
              "lat": "27.376413",
              "level": "district"
            },
            {
              "value": 2498,
              "parent_id": 2489,
              "label": "布拖县",
              "lng": "102.812061",
              "lat": "27.706061",
              "level": "district"
            },
            {
              "value": 2499,
              "parent_id": 2489,
              "label": "金阳县",
              "lng": "103.248772",
              "lat": "27.69686",
              "level": "district"
            },
            {
              "value": 2500,
              "parent_id": 2489,
              "label": "昭觉县",
              "lng": "102.840264",
              "lat": "28.015333",
              "level": "district"
            },
            {
              "value": 2501,
              "parent_id": 2489,
              "label": "喜德县",
              "lng": "102.412518",
              "lat": "28.306726",
              "level": "district"
            },
            {
              "value": 2502,
              "parent_id": 2489,
              "label": "冕宁县",
              "lng": "102.17701",
              "lat": "28.549656",
              "level": "district"
            },
            {
              "value": 2503,
              "parent_id": 2489,
              "label": "越西县",
              "lng": "102.50768",
              "lat": "28.639801",
              "level": "district"
            },
            {
              "value": 2504,
              "parent_id": 2489,
              "label": "甘洛县",
              "lng": "102.771504",
              "lat": "28.959157",
              "level": "district"
            },
            {
              "value": 2505,
              "parent_id": 2489,
              "label": "美姑县",
              "lng": "103.132179",
              "lat": "28.32864",
              "level": "district"
            },
            {
              "value": 2506,
              "parent_id": 2489,
              "label": "雷波县",
              "lng": "103.571696",
              "lat": "28.262682",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2507,
      "parent_id": 1,
      "label": "贵州省",
      "lng": "106.70546",
      "lat": "26.600055",
      "level": "province",
      "children": [
        {
          "value": 2508,
          "parent_id": 2507,
          "label": "贵阳市",
          "lng": "106.630153",
          "lat": "26.647661",
          "level": "city",
          "children": [
            {
              "value": 2509,
              "parent_id": 2508,
              "label": "南明区",
              "lng": "106.714374",
              "lat": "26.567944",
              "level": "district"
            },
            {
              "value": 2510,
              "parent_id": 2508,
              "label": "云岩区",
              "lng": "106.724494",
              "lat": "26.604688",
              "level": "district"
            },
            {
              "value": 2511,
              "parent_id": 2508,
              "label": "花溪区",
              "lng": "106.67026",
              "lat": "26.409817",
              "level": "district"
            },
            {
              "value": 2512,
              "parent_id": 2508,
              "label": "乌当区",
              "lng": "106.750625",
              "lat": "26.630845",
              "level": "district"
            },
            {
              "value": 2513,
              "parent_id": 2508,
              "label": "白云区",
              "lng": "106.623007",
              "lat": "26.678561",
              "level": "district"
            },
            {
              "value": 2514,
              "parent_id": 2508,
              "label": "观山湖区",
              "lng": "106.622453",
              "lat": "26.60145",
              "level": "district"
            },
            {
              "value": 2515,
              "parent_id": 2508,
              "label": "开阳县",
              "lng": "106.965089",
              "lat": "27.057764",
              "level": "district"
            },
            {
              "value": 2516,
              "parent_id": 2508,
              "label": "息烽县",
              "lng": "106.740407",
              "lat": "27.090479",
              "level": "district"
            },
            {
              "value": 2517,
              "parent_id": 2508,
              "label": "修文县",
              "lng": "106.592108",
              "lat": "26.838926",
              "level": "district"
            },
            {
              "value": 2518,
              "parent_id": 2508,
              "label": "清镇市",
              "lng": "106.470714",
              "lat": "26.556079",
              "level": "district"
            }
          ]
        },
        {
          "value": 2519,
          "parent_id": 2507,
          "label": "六盘水市",
          "lng": "104.830458",
          "lat": "26.592707",
          "level": "city",
          "children": [
            {
              "value": 2520,
              "parent_id": 2519,
              "label": "钟山区",
              "lng": "104.843555",
              "lat": "26.574979",
              "level": "district"
            },
            {
              "value": 2521,
              "parent_id": 2519,
              "label": "六枝特区",
              "lng": "105.476608",
              "lat": "26.213108",
              "level": "district"
            },
            {
              "value": 2522,
              "parent_id": 2519,
              "label": "水城县",
              "lng": "104.95783",
              "lat": "26.547904",
              "level": "district"
            },
            {
              "value": 2523,
              "parent_id": 2519,
              "label": "盘县",
              "lng": "104.471375",
              "lat": "25.709852",
              "level": "district"
            }
          ]
        },
        {
          "value": 2524,
          "parent_id": 2507,
          "label": "遵义市",
          "lng": "106.927389",
          "lat": "27.725654",
          "level": "city",
          "children": [
            {
              "value": 2525,
              "parent_id": 2524,
              "label": "红花岗区",
              "lng": "106.8937",
              "lat": "27.644754",
              "level": "district"
            },
            {
              "value": 2526,
              "parent_id": 2524,
              "label": "汇川区",
              "lng": "106.93427",
              "lat": "27.750125",
              "level": "district"
            },
            {
              "value": 2527,
              "parent_id": 2524,
              "label": "播州区",
              "lng": "106.829574",
              "lat": "27.536298",
              "level": "district"
            },
            {
              "value": 2528,
              "parent_id": 2524,
              "label": "桐梓县",
              "lng": "106.825198",
              "lat": "28.133311",
              "level": "district"
            },
            {
              "value": 2529,
              "parent_id": 2524,
              "label": "绥阳县",
              "lng": "107.191222",
              "lat": "27.946222",
              "level": "district"
            },
            {
              "value": 2530,
              "parent_id": 2524,
              "label": "正安县",
              "lng": "107.453945",
              "lat": "28.553285",
              "level": "district"
            },
            {
              "value": 2531,
              "parent_id": 2524,
              "label": "道真仡佬族苗族自治县",
              "lng": "107.613133",
              "lat": "28.862425",
              "level": "district"
            },
            {
              "value": 2532,
              "parent_id": 2524,
              "label": "务川仡佬族苗族自治县",
              "lng": "107.898956",
              "lat": "28.563086",
              "level": "district"
            },
            {
              "value": 2533,
              "parent_id": 2524,
              "label": "凤冈县",
              "lng": "107.716355",
              "lat": "27.954695",
              "level": "district"
            },
            {
              "value": 2534,
              "parent_id": 2524,
              "label": "湄潭县",
              "lng": "107.465407",
              "lat": "27.749055",
              "level": "district"
            },
            {
              "value": 2535,
              "parent_id": 2524,
              "label": "余庆县",
              "lng": "107.905197",
              "lat": "27.215491",
              "level": "district"
            },
            {
              "value": 2536,
              "parent_id": 2524,
              "label": "习水县",
              "lng": "106.197137",
              "lat": "28.33127",
              "level": "district"
            },
            {
              "value": 2537,
              "parent_id": 2524,
              "label": "赤水市",
              "lng": "105.697472",
              "lat": "28.590337",
              "level": "district"
            },
            {
              "value": 2538,
              "parent_id": 2524,
              "label": "仁怀市",
              "lng": "106.40109",
              "lat": "27.792514",
              "level": "district"
            }
          ]
        },
        {
          "value": 2539,
          "parent_id": 2507,
          "label": "安顺市",
          "lng": "105.947594",
          "lat": "26.253088",
          "level": "city",
          "children": [
            {
              "value": 2540,
              "parent_id": 2539,
              "label": "西秀区",
              "lng": "105.965116",
              "lat": "26.245315",
              "level": "district"
            },
            {
              "value": 2541,
              "parent_id": 2539,
              "label": "平坝区",
              "lng": "106.256412",
              "lat": "26.405715",
              "level": "district"
            },
            {
              "value": 2542,
              "parent_id": 2539,
              "label": "普定县",
              "lng": "105.743277",
              "lat": "26.301565",
              "level": "district"
            },
            {
              "value": 2543,
              "parent_id": 2539,
              "label": "镇宁布依族苗族自治县",
              "lng": "105.770283",
              "lat": "26.058086",
              "level": "district"
            },
            {
              "value": 2544,
              "parent_id": 2539,
              "label": "关岭布依族苗族自治县",
              "lng": "105.61933",
              "lat": "25.94361",
              "level": "district"
            },
            {
              "value": 2545,
              "parent_id": 2539,
              "label": "紫云苗族布依族自治县",
              "lng": "106.084441",
              "lat": "25.751047",
              "level": "district"
            }
          ]
        },
        {
          "value": 2546,
          "parent_id": 2507,
          "label": "毕节市",
          "lng": "105.291702",
          "lat": "27.283908",
          "level": "city",
          "children": [
            {
              "value": 2547,
              "parent_id": 2546,
              "label": "七星关区",
              "lng": "105.30474",
              "lat": "27.298458",
              "level": "district"
            },
            {
              "value": 2548,
              "parent_id": 2546,
              "label": "大方县",
              "lng": "105.613037",
              "lat": "27.141735",
              "level": "district"
            },
            {
              "value": 2549,
              "parent_id": 2546,
              "label": "黔西县",
              "lng": "106.033544",
              "lat": "27.007713",
              "level": "district"
            },
            {
              "value": 2550,
              "parent_id": 2546,
              "label": "金沙县",
              "lng": "106.220227",
              "lat": "27.459214",
              "level": "district"
            },
            {
              "value": 2551,
              "parent_id": 2546,
              "label": "织金县",
              "lng": "105.770542",
              "lat": "26.663449",
              "level": "district"
            },
            {
              "value": 2552,
              "parent_id": 2546,
              "label": "纳雍县",
              "lng": "105.382714",
              "lat": "26.777645",
              "level": "district"
            },
            {
              "value": 2553,
              "parent_id": 2546,
              "label": "威宁彝族回族苗族自治县",
              "lng": "104.253071",
              "lat": "26.873806",
              "level": "district"
            },
            {
              "value": 2554,
              "parent_id": 2546,
              "label": "赫章县",
              "lng": "104.727418",
              "lat": "27.123078",
              "level": "district"
            }
          ]
        },
        {
          "value": 2555,
          "parent_id": 2507,
          "label": "铜仁市",
          "lng": "109.189598",
          "lat": "27.731514",
          "level": "city",
          "children": [
            {
              "value": 2556,
              "parent_id": 2555,
              "label": "碧江区",
              "lng": "109.263998",
              "lat": "27.815927",
              "level": "district"
            },
            {
              "value": 2557,
              "parent_id": 2555,
              "label": "万山区",
              "lng": "109.213644",
              "lat": "27.517896",
              "level": "district"
            },
            {
              "value": 2558,
              "parent_id": 2555,
              "label": "江口县",
              "lng": "108.839557",
              "lat": "27.69965",
              "level": "district"
            },
            {
              "value": 2559,
              "parent_id": 2555,
              "label": "玉屏侗族自治县",
              "lng": "108.906411",
              "lat": "27.235813",
              "level": "district"
            },
            {
              "value": 2560,
              "parent_id": 2555,
              "label": "石阡县",
              "lng": "108.223612",
              "lat": "27.513829",
              "level": "district"
            },
            {
              "value": 2561,
              "parent_id": 2555,
              "label": "思南县",
              "lng": "108.253882",
              "lat": "27.93755",
              "level": "district"
            },
            {
              "value": 2562,
              "parent_id": 2555,
              "label": "印江土家族苗族自治县",
              "lng": "108.409751",
              "lat": "27.994246",
              "level": "district"
            },
            {
              "value": 2563,
              "parent_id": 2555,
              "label": "德江县",
              "lng": "108.119807",
              "lat": "28.263963",
              "level": "district"
            },
            {
              "value": 2564,
              "parent_id": 2555,
              "label": "沿河土家族自治县",
              "lng": "108.50387",
              "lat": "28.563927",
              "level": "district"
            },
            {
              "value": 2565,
              "parent_id": 2555,
              "label": "松桃苗族自治县",
              "lng": "109.202886",
              "lat": "28.154071",
              "level": "district"
            }
          ]
        },
        {
          "value": 2566,
          "parent_id": 2507,
          "label": "黔西南布依族苗族自治州",
          "lng": "104.906397",
          "lat": "25.087856",
          "level": "city",
          "children": [
            {
              "value": 2567,
              "parent_id": 2566,
              "label": "兴义市",
              "lng": "104.895467",
              "lat": "25.09204",
              "level": "district"
            },
            {
              "value": 2568,
              "parent_id": 2566,
              "label": "兴仁县",
              "lng": "105.186237",
              "lat": "25.435183",
              "level": "district"
            },
            {
              "value": 2569,
              "parent_id": 2566,
              "label": "普安县",
              "lng": "104.953062",
              "lat": "25.784135",
              "level": "district"
            },
            {
              "value": 2570,
              "parent_id": 2566,
              "label": "晴隆县",
              "lng": "105.218991",
              "lat": "25.834783",
              "level": "district"
            },
            {
              "value": 2571,
              "parent_id": 2566,
              "label": "贞丰县",
              "lng": "105.649864",
              "lat": "25.38576",
              "level": "district"
            },
            {
              "value": 2572,
              "parent_id": 2566,
              "label": "望谟县",
              "lng": "106.099617",
              "lat": "25.178421",
              "level": "district"
            },
            {
              "value": 2573,
              "parent_id": 2566,
              "label": "册亨县",
              "lng": "105.811592",
              "lat": "24.983663",
              "level": "district"
            },
            {
              "value": 2574,
              "parent_id": 2566,
              "label": "安龙县",
              "lng": "105.442701",
              "lat": "25.099014",
              "level": "district"
            }
          ]
        },
        {
          "value": 2575,
          "parent_id": 2507,
          "label": "黔东南苗族侗族自治州",
          "lng": "107.982874",
          "lat": "26.583457",
          "level": "city",
          "children": [
            {
              "value": 2576,
              "parent_id": 2575,
              "label": "凯里市",
              "lng": "107.97754",
              "lat": "26.582963",
              "level": "district"
            },
            {
              "value": 2577,
              "parent_id": 2575,
              "label": "黄平县",
              "lng": "107.916411",
              "lat": "26.905396",
              "level": "district"
            },
            {
              "value": 2578,
              "parent_id": 2575,
              "label": "施秉县",
              "lng": "108.124379",
              "lat": "27.03292",
              "level": "district"
            },
            {
              "value": 2579,
              "parent_id": 2575,
              "label": "三穗县",
              "lng": "108.675267",
              "lat": "26.952967",
              "level": "district"
            },
            {
              "value": 2580,
              "parent_id": 2575,
              "label": "镇远县",
              "lng": "108.429534",
              "lat": "27.049497",
              "level": "district"
            },
            {
              "value": 2581,
              "parent_id": 2575,
              "label": "岑巩县",
              "lng": "108.81606",
              "lat": "27.173887",
              "level": "district"
            },
            {
              "value": 2582,
              "parent_id": 2575,
              "label": "天柱县",
              "lng": "109.207751",
              "lat": "26.909639",
              "level": "district"
            },
            {
              "value": 2583,
              "parent_id": 2575,
              "label": "锦屏县",
              "lng": "109.200534",
              "lat": "26.676233",
              "level": "district"
            },
            {
              "value": 2584,
              "parent_id": 2575,
              "label": "剑河县",
              "lng": "108.441501",
              "lat": "26.728274",
              "level": "district"
            },
            {
              "value": 2585,
              "parent_id": 2575,
              "label": "台江县",
              "lng": "108.321245",
              "lat": "26.667525",
              "level": "district"
            },
            {
              "value": 2586,
              "parent_id": 2575,
              "label": "黎平县",
              "lng": "109.136932",
              "lat": "26.230706",
              "level": "district"
            },
            {
              "value": 2587,
              "parent_id": 2575,
              "label": "榕江县",
              "lng": "108.52188",
              "lat": "25.931893",
              "level": "district"
            },
            {
              "value": 2588,
              "parent_id": 2575,
              "label": "从江县",
              "lng": "108.905329",
              "lat": "25.753009",
              "level": "district"
            },
            {
              "value": 2589,
              "parent_id": 2575,
              "label": "雷山县",
              "lng": "108.07754",
              "lat": "26.378442",
              "level": "district"
            },
            {
              "value": 2590,
              "parent_id": 2575,
              "label": "麻江县",
              "lng": "107.589359",
              "lat": "26.491105",
              "level": "district"
            },
            {
              "value": 2591,
              "parent_id": 2575,
              "label": "丹寨县",
              "lng": "107.788727",
              "lat": "26.19832",
              "level": "district"
            }
          ]
        },
        {
          "value": 2592,
          "parent_id": 2507,
          "label": "黔南布依族苗族自治州",
          "lng": "107.522171",
          "lat": "26.253275",
          "level": "city",
          "children": [
            {
              "value": 2593,
              "parent_id": 2592,
              "label": "都匀市",
              "lng": "107.518847",
              "lat": "26.259427",
              "level": "district"
            },
            {
              "value": 2594,
              "parent_id": 2592,
              "label": "福泉市",
              "lng": "107.520386",
              "lat": "26.686335",
              "level": "district"
            },
            {
              "value": 2595,
              "parent_id": 2592,
              "label": "荔波县",
              "lng": "107.898882",
              "lat": "25.423895",
              "level": "district"
            },
            {
              "value": 2596,
              "parent_id": 2592,
              "label": "贵定县",
              "lng": "107.232793",
              "lat": "26.557089",
              "level": "district"
            },
            {
              "value": 2597,
              "parent_id": 2592,
              "label": "瓮安县",
              "lng": "107.470942",
              "lat": "27.078441",
              "level": "district"
            },
            {
              "value": 2598,
              "parent_id": 2592,
              "label": "独山县",
              "lng": "107.545048",
              "lat": "25.822132",
              "level": "district"
            },
            {
              "value": 2599,
              "parent_id": 2592,
              "label": "平塘县",
              "lng": "107.322323",
              "lat": "25.822349",
              "level": "district"
            },
            {
              "value": 2600,
              "parent_id": 2592,
              "label": "罗甸县",
              "lng": "106.751589",
              "lat": "25.426173",
              "level": "district"
            },
            {
              "value": 2601,
              "parent_id": 2592,
              "label": "长顺县",
              "lng": "106.441805",
              "lat": "26.025626",
              "level": "district"
            },
            {
              "value": 2602,
              "parent_id": 2592,
              "label": "龙里县",
              "lng": "106.979524",
              "lat": "26.453154",
              "level": "district"
            },
            {
              "value": 2603,
              "parent_id": 2592,
              "label": "惠水县",
              "lng": "106.656442",
              "lat": "26.13278",
              "level": "district"
            },
            {
              "value": 2604,
              "parent_id": 2592,
              "label": "三都水族自治县",
              "lng": "107.869749",
              "lat": "25.983202",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2605,
      "parent_id": 1,
      "label": "云南省",
      "lng": "102.710002",
      "lat": "25.045806",
      "level": "province",
      "children": [
        {
          "value": 2606,
          "parent_id": 2605,
          "label": "昆明市",
          "lng": "102.832891",
          "lat": "24.880095",
          "level": "city",
          "children": [
            {
              "value": 2607,
              "parent_id": 2606,
              "label": "五华区",
              "lng": "102.707262",
              "lat": "25.043635",
              "level": "district"
            },
            {
              "value": 2608,
              "parent_id": 2606,
              "label": "盘龙区",
              "lng": "102.751941",
              "lat": "25.116465",
              "level": "district"
            },
            {
              "value": 2609,
              "parent_id": 2606,
              "label": "官渡区",
              "lng": "102.749026",
              "lat": "24.950231",
              "level": "district"
            },
            {
              "value": 2610,
              "parent_id": 2606,
              "label": "西山区",
              "lng": "102.664382",
              "lat": "25.038604",
              "level": "district"
            },
            {
              "value": 2611,
              "parent_id": 2606,
              "label": "东川区",
              "lng": "103.187824",
              "lat": "26.082873",
              "level": "district"
            },
            {
              "value": 2612,
              "parent_id": 2606,
              "label": "呈贡区",
              "lng": "102.821675",
              "lat": "24.885587",
              "level": "district"
            },
            {
              "value": 2613,
              "parent_id": 2606,
              "label": "晋宁区",
              "lng": "102.595412",
              "lat": "24.66974",
              "level": "district"
            },
            {
              "value": 2614,
              "parent_id": 2606,
              "label": "富民县",
              "lng": "102.4976",
              "lat": "25.221935",
              "level": "district"
            },
            {
              "value": 2615,
              "parent_id": 2606,
              "label": "宜良县",
              "lng": "103.141603",
              "lat": "24.919839",
              "level": "district"
            },
            {
              "value": 2616,
              "parent_id": 2606,
              "label": "石林彝族自治县",
              "lng": "103.290536",
              "lat": "24.771761",
              "level": "district"
            },
            {
              "value": 2617,
              "parent_id": 2606,
              "label": "嵩明县",
              "lng": "103.036908",
              "lat": "25.338643",
              "level": "district"
            },
            {
              "value": 2618,
              "parent_id": 2606,
              "label": "禄劝彝族苗族自治县",
              "lng": "102.471518",
              "lat": "25.551332",
              "level": "district"
            },
            {
              "value": 2619,
              "parent_id": 2606,
              "label": "寻甸回族彝族自治县",
              "lng": "103.256615",
              "lat": "25.558201",
              "level": "district"
            },
            {
              "value": 2620,
              "parent_id": 2606,
              "label": "安宁市",
              "lng": "102.478494",
              "lat": "24.919493",
              "level": "district"
            }
          ]
        },
        {
          "value": 2621,
          "parent_id": 2605,
          "label": "曲靖市",
          "lng": "103.796167",
          "lat": "25.489999",
          "level": "city",
          "children": [
            {
              "value": 2622,
              "parent_id": 2621,
              "label": "麒麟区",
              "lng": "103.80474",
              "lat": "25.495326",
              "level": "district"
            },
            {
              "value": 2623,
              "parent_id": 2621,
              "label": "沾益区",
              "lng": "103.822324",
              "lat": "25.600507",
              "level": "district"
            },
            {
              "value": 2624,
              "parent_id": 2621,
              "label": "马龙县",
              "lng": "103.578478",
              "lat": "25.42805",
              "level": "district"
            },
            {
              "value": 2625,
              "parent_id": 2621,
              "label": "陆良县",
              "lng": "103.666663",
              "lat": "25.030051",
              "level": "district"
            },
            {
              "value": 2626,
              "parent_id": 2621,
              "label": "师宗县",
              "lng": "103.985321",
              "lat": "24.822233",
              "level": "district"
            },
            {
              "value": 2627,
              "parent_id": 2621,
              "label": "罗平县",
              "lng": "104.308675",
              "lat": "24.884626",
              "level": "district"
            },
            {
              "value": 2628,
              "parent_id": 2621,
              "label": "富源县",
              "lng": "104.255014",
              "lat": "25.674238",
              "level": "district"
            },
            {
              "value": 2629,
              "parent_id": 2621,
              "label": "会泽县",
              "lng": "103.297386",
              "lat": "26.417345",
              "level": "district"
            },
            {
              "value": 2630,
              "parent_id": 2621,
              "label": "宣威市",
              "lng": "104.10455",
              "lat": "26.219735",
              "level": "district"
            }
          ]
        },
        {
          "value": 2631,
          "parent_id": 2605,
          "label": "玉溪市",
          "lng": "102.527197",
          "lat": "24.347324",
          "level": "city",
          "children": [
            {
              "value": 2632,
              "parent_id": 2631,
              "label": "红塔区",
              "lng": "102.540122",
              "lat": "24.341215",
              "level": "district"
            },
            {
              "value": 2633,
              "parent_id": 2631,
              "label": "江川区",
              "lng": "102.75344",
              "lat": "24.287485",
              "level": "district"
            },
            {
              "value": 2634,
              "parent_id": 2631,
              "label": "澄江县",
              "lng": "102.904629",
              "lat": "24.675689",
              "level": "district"
            },
            {
              "value": 2635,
              "parent_id": 2631,
              "label": "通海县",
              "lng": "102.725452",
              "lat": "24.111048",
              "level": "district"
            },
            {
              "value": 2636,
              "parent_id": 2631,
              "label": "华宁县",
              "lng": "102.928835",
              "lat": "24.19276",
              "level": "district"
            },
            {
              "value": 2637,
              "parent_id": 2631,
              "label": "易门县",
              "lng": "102.162531",
              "lat": "24.671651",
              "level": "district"
            },
            {
              "value": 2638,
              "parent_id": 2631,
              "label": "峨山彝族自治县",
              "lng": "102.405819",
              "lat": "24.168957",
              "level": "district"
            },
            {
              "value": 2639,
              "parent_id": 2631,
              "label": "新平彝族傣族自治县",
              "lng": "101.990157",
              "lat": "24.07005",
              "level": "district"
            },
            {
              "value": 2640,
              "parent_id": 2631,
              "label": "元江哈尼族彝族傣族自治县",
              "lng": "101.998103",
              "lat": "23.596503",
              "level": "district"
            }
          ]
        },
        {
          "value": 2641,
          "parent_id": 2605,
          "label": "保山市",
          "lng": "99.161761",
          "lat": "25.112046",
          "level": "city",
          "children": [
            {
              "value": 2642,
              "parent_id": 2641,
              "label": "隆阳区",
              "lng": "99.165607",
              "lat": "25.121154",
              "level": "district"
            },
            {
              "value": 2643,
              "parent_id": 2641,
              "label": "施甸县",
              "lng": "99.189221",
              "lat": "24.723064",
              "level": "district"
            },
            {
              "value": 2644,
              "parent_id": 2641,
              "label": "龙陵县",
              "lng": "98.689261",
              "lat": "24.586794",
              "level": "district"
            },
            {
              "value": 2645,
              "parent_id": 2641,
              "label": "昌宁县",
              "lng": "99.605142",
              "lat": "24.827839",
              "level": "district"
            },
            {
              "value": 2646,
              "parent_id": 2641,
              "label": "腾冲市",
              "lng": "98.490966",
              "lat": "25.020439",
              "level": "district"
            }
          ]
        },
        {
          "value": 2647,
          "parent_id": 2605,
          "label": "昭通市",
          "lng": "103.717465",
          "lat": "27.338257",
          "level": "city",
          "children": [
            {
              "value": 2648,
              "parent_id": 2647,
              "label": "昭阳区",
              "lng": "103.706539",
              "lat": "27.320075",
              "level": "district"
            },
            {
              "value": 2649,
              "parent_id": 2647,
              "label": "鲁甸县",
              "lng": "103.558042",
              "lat": "27.186659",
              "level": "district"
            },
            {
              "value": 2650,
              "parent_id": 2647,
              "label": "巧家县",
              "lng": "102.930164",
              "lat": "26.90846",
              "level": "district"
            },
            {
              "value": 2651,
              "parent_id": 2647,
              "label": "盐津县",
              "lng": "104.234441",
              "lat": "28.10871",
              "level": "district"
            },
            {
              "value": 2652,
              "parent_id": 2647,
              "label": "大关县",
              "lng": "103.891146",
              "lat": "27.747978",
              "level": "district"
            },
            {
              "value": 2653,
              "parent_id": 2647,
              "label": "永善县",
              "lng": "103.638067",
              "lat": "28.229112",
              "level": "district"
            },
            {
              "value": 2654,
              "parent_id": 2647,
              "label": "绥江县",
              "lng": "103.968978",
              "lat": "28.592099",
              "level": "district"
            },
            {
              "value": 2655,
              "parent_id": 2647,
              "label": "镇雄县",
              "lng": "104.87376",
              "lat": "27.441622",
              "level": "district"
            },
            {
              "value": 2656,
              "parent_id": 2647,
              "label": "彝良县",
              "lng": "104.048289",
              "lat": "27.625418",
              "level": "district"
            },
            {
              "value": 2657,
              "parent_id": 2647,
              "label": "威信县",
              "lng": "105.049027",
              "lat": "27.8469",
              "level": "district"
            },
            {
              "value": 2658,
              "parent_id": 2647,
              "label": "水富县",
              "lng": "104.41603",
              "lat": "28.62988",
              "level": "district"
            }
          ]
        },
        {
          "value": 2659,
          "parent_id": 2605,
          "label": "丽江市",
          "lng": "100.22775",
          "lat": "26.855047",
          "level": "city",
          "children": [
            {
              "value": 2660,
              "parent_id": 2659,
              "label": "古城区",
              "lng": "100.225784",
              "lat": "26.876927",
              "level": "district"
            },
            {
              "value": 2661,
              "parent_id": 2659,
              "label": "玉龙纳西族自治县",
              "lng": "100.236954",
              "lat": "26.821459",
              "level": "district"
            },
            {
              "value": 2662,
              "parent_id": 2659,
              "label": "永胜县",
              "lng": "100.750826",
              "lat": "26.684225",
              "level": "district"
            },
            {
              "value": 2663,
              "parent_id": 2659,
              "label": "华坪县",
              "lng": "101.266195",
              "lat": "26.629211",
              "level": "district"
            },
            {
              "value": 2664,
              "parent_id": 2659,
              "label": "宁蒗彝族自治县",
              "lng": "100.852001",
              "lat": "27.28207",
              "level": "district"
            }
          ]
        },
        {
          "value": 2665,
          "parent_id": 2605,
          "label": "普洱市",
          "lng": "100.966156",
          "lat": "22.825155",
          "level": "city",
          "children": [
            {
              "value": 2666,
              "parent_id": 2665,
              "label": "思茅区",
              "lng": "100.977256",
              "lat": "22.787115",
              "level": "district"
            },
            {
              "value": 2667,
              "parent_id": 2665,
              "label": "宁洱哈尼族彝族自治县",
              "lng": "101.045837",
              "lat": "23.048401",
              "level": "district"
            },
            {
              "value": 2668,
              "parent_id": 2665,
              "label": "墨江哈尼族自治县",
              "lng": "101.692461",
              "lat": "23.431894",
              "level": "district"
            },
            {
              "value": 2669,
              "parent_id": 2665,
              "label": "景东彝族自治县",
              "lng": "100.833877",
              "lat": "24.446731",
              "level": "district"
            },
            {
              "value": 2670,
              "parent_id": 2665,
              "label": "景谷傣族彝族自治县",
              "lng": "100.702871",
              "lat": "23.497028",
              "level": "district"
            },
            {
              "value": 2671,
              "parent_id": 2665,
              "label": "镇沅彝族哈尼族拉祜族自治县",
              "lng": "101.108595",
              "lat": "24.004441",
              "level": "district"
            },
            {
              "value": 2672,
              "parent_id": 2665,
              "label": "江城哈尼族彝族自治县",
              "lng": "101.86212",
              "lat": "22.585867",
              "level": "district"
            },
            {
              "value": 2673,
              "parent_id": 2665,
              "label": "孟连傣族拉祜族佤族自治县",
              "lng": "99.584157",
              "lat": "22.329099",
              "level": "district"
            },
            {
              "value": 2674,
              "parent_id": 2665,
              "label": "澜沧拉祜族自治县",
              "lng": "99.931975",
              "lat": "22.555904",
              "level": "district"
            },
            {
              "value": 2675,
              "parent_id": 2665,
              "label": "西盟佤族自治县",
              "lng": "99.590123",
              "lat": "22.644508",
              "level": "district"
            }
          ]
        },
        {
          "value": 2676,
          "parent_id": 2605,
          "label": "临沧市",
          "lng": "100.08879",
          "lat": "23.883955",
          "level": "city",
          "children": [
            {
              "value": 2677,
              "parent_id": 2676,
              "label": "临翔区",
              "lng": "100.082523",
              "lat": "23.895137",
              "level": "district"
            },
            {
              "value": 2678,
              "parent_id": 2676,
              "label": "凤庆县",
              "lng": "99.928459",
              "lat": "24.580424",
              "level": "district"
            },
            {
              "value": 2679,
              "parent_id": 2676,
              "label": "云县",
              "lng": "100.129354",
              "lat": "24.44422",
              "level": "district"
            },
            {
              "value": 2680,
              "parent_id": 2676,
              "label": "永德县",
              "lng": "99.259339",
              "lat": "24.018357",
              "level": "district"
            },
            {
              "value": 2681,
              "parent_id": 2676,
              "label": "镇康县",
              "lng": "98.825284",
              "lat": "23.762584",
              "level": "district"
            },
            {
              "value": 2682,
              "parent_id": 2676,
              "label": "双江拉祜族佤族布朗族傣族自治县",
              "lng": "99.827697",
              "lat": "23.473499",
              "level": "district"
            },
            {
              "value": 2683,
              "parent_id": 2676,
              "label": "耿马傣族佤族自治县",
              "lng": "99.397126",
              "lat": "23.538092",
              "level": "district"
            },
            {
              "value": 2684,
              "parent_id": 2676,
              "label": "沧源佤族自治县",
              "lng": "99.246196",
              "lat": "23.146712",
              "level": "district"
            }
          ]
        },
        {
          "value": 2685,
          "parent_id": 2605,
          "label": "楚雄彝族自治州",
          "lng": "101.527992",
          "lat": "25.045513",
          "level": "city",
          "children": [
            {
              "value": 2686,
              "parent_id": 2685,
              "label": "楚雄市",
              "lng": "101.545906",
              "lat": "25.032889",
              "level": "district"
            },
            {
              "value": 2687,
              "parent_id": 2685,
              "label": "双柏县",
              "lng": "101.641937",
              "lat": "24.688875",
              "level": "district"
            },
            {
              "value": 2688,
              "parent_id": 2685,
              "label": "牟定县",
              "lng": "101.546566",
              "lat": "25.313121",
              "level": "district"
            },
            {
              "value": 2689,
              "parent_id": 2685,
              "label": "南华县",
              "lng": "101.273577",
              "lat": "25.192293",
              "level": "district"
            },
            {
              "value": 2690,
              "parent_id": 2685,
              "label": "姚安县",
              "lng": "101.241728",
              "lat": "25.504173",
              "level": "district"
            },
            {
              "value": 2691,
              "parent_id": 2685,
              "label": "大姚县",
              "lng": "101.336617",
              "lat": "25.729513",
              "level": "district"
            },
            {
              "value": 2692,
              "parent_id": 2685,
              "label": "永仁县",
              "lng": "101.666132",
              "lat": "26.049464",
              "level": "district"
            },
            {
              "value": 2693,
              "parent_id": 2685,
              "label": "元谋县",
              "lng": "101.87452",
              "lat": "25.704338",
              "level": "district"
            },
            {
              "value": 2694,
              "parent_id": 2685,
              "label": "武定县",
              "lng": "102.404337",
              "lat": "25.530389",
              "level": "district"
            },
            {
              "value": 2695,
              "parent_id": 2685,
              "label": "禄丰县",
              "lng": "102.079027",
              "lat": "25.150111",
              "level": "district"
            }
          ]
        },
        {
          "value": 2696,
          "parent_id": 2605,
          "label": "红河哈尼族彝族自治州",
          "lng": "103.374893",
          "lat": "23.363245",
          "level": "city",
          "children": [
            {
              "value": 2697,
              "parent_id": 2696,
              "label": "个旧市",
              "lng": "103.160034",
              "lat": "23.359121",
              "level": "district"
            },
            {
              "value": 2698,
              "parent_id": 2696,
              "label": "开远市",
              "lng": "103.266624",
              "lat": "23.714523",
              "level": "district"
            },
            {
              "value": 2699,
              "parent_id": 2696,
              "label": "蒙自市",
              "lng": "103.364905",
              "lat": "23.396201",
              "level": "district"
            },
            {
              "value": 2700,
              "parent_id": 2696,
              "label": "弥勒市",
              "lng": "103.414874",
              "lat": "24.411912",
              "level": "district"
            },
            {
              "value": 2701,
              "parent_id": 2696,
              "label": "屏边苗族自治县",
              "lng": "103.687612",
              "lat": "22.983559",
              "level": "district"
            },
            {
              "value": 2702,
              "parent_id": 2696,
              "label": "建水县",
              "lng": "102.826557",
              "lat": "23.6347",
              "level": "district"
            },
            {
              "value": 2703,
              "parent_id": 2696,
              "label": "石屏县",
              "lng": "102.494983",
              "lat": "23.705936",
              "level": "district"
            },
            {
              "value": 2704,
              "parent_id": 2696,
              "label": "泸西县",
              "lng": "103.766196",
              "lat": "24.532025",
              "level": "district"
            },
            {
              "value": 2705,
              "parent_id": 2696,
              "label": "元阳县",
              "lng": "102.835223",
              "lat": "23.219932",
              "level": "district"
            },
            {
              "value": 2706,
              "parent_id": 2696,
              "label": "红河县",
              "lng": "102.4206",
              "lat": "23.369161",
              "level": "district"
            },
            {
              "value": 2707,
              "parent_id": 2696,
              "label": "金平苗族瑶族傣族自治县",
              "lng": "103.226448",
              "lat": "22.779543",
              "level": "district"
            },
            {
              "value": 2708,
              "parent_id": 2696,
              "label": "绿春县",
              "lng": "102.392463",
              "lat": "22.993717",
              "level": "district"
            },
            {
              "value": 2709,
              "parent_id": 2696,
              "label": "河口瑶族自治县",
              "lng": "103.93952",
              "lat": "22.529645",
              "level": "district"
            }
          ]
        },
        {
          "value": 2710,
          "parent_id": 2605,
          "label": "文山壮族苗族自治州",
          "lng": "104.216248",
          "lat": "23.400733",
          "level": "city",
          "children": [
            {
              "value": 2711,
              "parent_id": 2710,
              "label": "文山市",
              "lng": "104.232665",
              "lat": "23.386527",
              "level": "district"
            },
            {
              "value": 2712,
              "parent_id": 2710,
              "label": "砚山县",
              "lng": "104.337211",
              "lat": "23.605768",
              "level": "district"
            },
            {
              "value": 2713,
              "parent_id": 2710,
              "label": "西畴县",
              "lng": "104.672597",
              "lat": "23.437782",
              "level": "district"
            },
            {
              "value": 2714,
              "parent_id": 2710,
              "label": "麻栗坡县",
              "lng": "104.702799",
              "lat": "23.125714",
              "level": "district"
            },
            {
              "value": 2715,
              "parent_id": 2710,
              "label": "马关县",
              "lng": "104.394157",
              "lat": "23.012915",
              "level": "district"
            },
            {
              "value": 2716,
              "parent_id": 2710,
              "label": "丘北县",
              "lng": "104.166587",
              "lat": "24.051746",
              "level": "district"
            },
            {
              "value": 2717,
              "parent_id": 2710,
              "label": "广南县",
              "lng": "105.055107",
              "lat": "24.046386",
              "level": "district"
            },
            {
              "value": 2718,
              "parent_id": 2710,
              "label": "富宁县",
              "lng": "105.630999",
              "lat": "23.625283",
              "level": "district"
            }
          ]
        },
        {
          "value": 2719,
          "parent_id": 2605,
          "label": "西双版纳傣族自治州",
          "lng": "100.796984",
          "lat": "22.009113",
          "level": "city",
          "children": [
            {
              "value": 2720,
              "parent_id": 2719,
              "label": "景洪市",
              "lng": "100.799545",
              "lat": "22.011928",
              "level": "district"
            },
            {
              "value": 2721,
              "parent_id": 2719,
              "label": "勐海县",
              "lng": "100.452547",
              "lat": "21.957353",
              "level": "district"
            },
            {
              "value": 2722,
              "parent_id": 2719,
              "label": "勐腊县",
              "lng": "101.564635",
              "lat": "21.459233",
              "level": "district"
            }
          ]
        },
        {
          "value": 2723,
          "parent_id": 2605,
          "label": "大理白族自治州",
          "lng": "100.267638",
          "lat": "25.606486",
          "level": "city",
          "children": [
            {
              "value": 2724,
              "parent_id": 2723,
              "label": "大理市",
              "lng": "100.30127",
              "lat": "25.678068",
              "level": "district"
            },
            {
              "value": 2725,
              "parent_id": 2723,
              "label": "漾濞彝族自治县",
              "lng": "99.958015",
              "lat": "25.670148",
              "level": "district"
            },
            {
              "value": 2726,
              "parent_id": 2723,
              "label": "祥云县",
              "lng": "100.550945",
              "lat": "25.48385",
              "level": "district"
            },
            {
              "value": 2727,
              "parent_id": 2723,
              "label": "宾川县",
              "lng": "100.590473",
              "lat": "25.829828",
              "level": "district"
            },
            {
              "value": 2728,
              "parent_id": 2723,
              "label": "弥渡县",
              "lng": "100.49099",
              "lat": "25.343804",
              "level": "district"
            },
            {
              "value": 2729,
              "parent_id": 2723,
              "label": "南涧彝族自治县",
              "lng": "100.509035",
              "lat": "25.04351",
              "level": "district"
            },
            {
              "value": 2730,
              "parent_id": 2723,
              "label": "巍山彝族回族自治县",
              "lng": "100.307174",
              "lat": "25.227212",
              "level": "district"
            },
            {
              "value": 2731,
              "parent_id": 2723,
              "label": "永平县",
              "lng": "99.541236",
              "lat": "25.464681",
              "level": "district"
            },
            {
              "value": 2732,
              "parent_id": 2723,
              "label": "云龙县",
              "lng": "99.37112",
              "lat": "25.885595",
              "level": "district"
            },
            {
              "value": 2733,
              "parent_id": 2723,
              "label": "洱源县",
              "lng": "99.951053",
              "lat": "26.11116",
              "level": "district"
            },
            {
              "value": 2734,
              "parent_id": 2723,
              "label": "剑川县",
              "lng": "99.905559",
              "lat": "26.537033",
              "level": "district"
            },
            {
              "value": 2735,
              "parent_id": 2723,
              "label": "鹤庆县",
              "lng": "100.176498",
              "lat": "26.560231",
              "level": "district"
            }
          ]
        },
        {
          "value": 2736,
          "parent_id": 2605,
          "label": "德宏傣族景颇族自治州",
          "lng": "98.584895",
          "lat": "24.433353",
          "level": "city",
          "children": [
            {
              "value": 2737,
              "parent_id": 2736,
              "label": "瑞丽市",
              "lng": "97.85559",
              "lat": "24.017958",
              "level": "district"
            },
            {
              "value": 2738,
              "parent_id": 2736,
              "label": "芒市",
              "lng": "98.588086",
              "lat": "24.43369",
              "level": "district"
            },
            {
              "value": 2739,
              "parent_id": 2736,
              "label": "梁河县",
              "lng": "98.296657",
              "lat": "24.804232",
              "level": "district"
            },
            {
              "value": 2740,
              "parent_id": 2736,
              "label": "盈江县",
              "lng": "97.931936",
              "lat": "24.705164",
              "level": "district"
            },
            {
              "value": 2741,
              "parent_id": 2736,
              "label": "陇川县",
              "lng": "97.792104",
              "lat": "24.182965",
              "level": "district"
            }
          ]
        },
        {
          "value": 2742,
          "parent_id": 2605,
          "label": "怒江傈僳族自治州",
          "lng": "98.8566",
          "lat": "25.817555",
          "level": "city",
          "children": [
            {
              "value": 2743,
              "parent_id": 2742,
              "label": "泸水市",
              "lng": "98.857977",
              "lat": "25.822879",
              "level": "district"
            },
            {
              "value": 2744,
              "parent_id": 2742,
              "label": "福贡县",
              "lng": "98.869132",
              "lat": "26.901831",
              "level": "district"
            },
            {
              "value": 2745,
              "parent_id": 2742,
              "label": "贡山独龙族怒族自治县",
              "lng": "98.665964",
              "lat": "27.740999",
              "level": "district"
            },
            {
              "value": 2746,
              "parent_id": 2742,
              "label": "兰坪白族普米族自治县",
              "lng": "99.416677",
              "lat": "26.453571",
              "level": "district"
            }
          ]
        },
        {
          "value": 2747,
          "parent_id": 2605,
          "label": "迪庆藏族自治州",
          "lng": "99.702583",
          "lat": "27.818807",
          "level": "city",
          "children": [
            {
              "value": 2748,
              "parent_id": 2747,
              "label": "香格里拉市",
              "lng": "99.700904",
              "lat": "27.829578",
              "level": "district"
            },
            {
              "value": 2749,
              "parent_id": 2747,
              "label": "德钦县",
              "lng": "98.911559",
              "lat": "28.486163",
              "level": "district"
            },
            {
              "value": 2750,
              "parent_id": 2747,
              "label": "维西傈僳族自治县",
              "lng": "99.287173",
              "lat": "27.177161",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2751,
      "parent_id": 1,
      "label": "西藏自治区",
      "lng": "91.117525",
      "lat": "29.647535",
      "level": "province",
      "children": [
        {
          "value": 2752,
          "parent_id": 2751,
          "label": "拉萨市",
          "lng": "91.172148",
          "lat": "29.652341",
          "level": "city",
          "children": [
            {
              "value": 2753,
              "parent_id": 2752,
              "label": "城关区",
              "lng": "91.140552",
              "lat": "29.654838",
              "level": "district"
            },
            {
              "value": 2754,
              "parent_id": 2752,
              "label": "堆龙德庆区",
              "lng": "91.003339",
              "lat": "29.646063",
              "level": "district"
            },
            {
              "value": 2755,
              "parent_id": 2752,
              "label": "林周县",
              "lng": "91.265287",
              "lat": "29.893545",
              "level": "district"
            },
            {
              "value": 2756,
              "parent_id": 2752,
              "label": "当雄县",
              "lng": "91.101162",
              "lat": "30.473118",
              "level": "district"
            },
            {
              "value": 2757,
              "parent_id": 2752,
              "label": "尼木县",
              "lng": "90.164524",
              "lat": "29.431831",
              "level": "district"
            },
            {
              "value": 2758,
              "parent_id": 2752,
              "label": "曲水县",
              "lng": "90.743853",
              "lat": "29.353058",
              "level": "district"
            },
            {
              "value": 2759,
              "parent_id": 2752,
              "label": "达孜县",
              "lng": "91.349867",
              "lat": "29.66941",
              "level": "district"
            },
            {
              "value": 2760,
              "parent_id": 2752,
              "label": "墨竹工卡县",
              "lng": "91.730732",
              "lat": "29.834111",
              "level": "district"
            }
          ]
        },
        {
          "value": 2761,
          "parent_id": 2751,
          "label": "日喀则市",
          "lng": "88.880583",
          "lat": "29.266869",
          "level": "city",
          "children": [
            {
              "value": 2762,
              "parent_id": 2761,
              "label": "桑珠孜区",
              "lng": "88.898483",
              "lat": "29.24779",
              "level": "district"
            },
            {
              "value": 2763,
              "parent_id": 2761,
              "label": "南木林县",
              "lng": "89.099242",
              "lat": "29.68233",
              "level": "district"
            },
            {
              "value": 2764,
              "parent_id": 2761,
              "label": "江孜县",
              "lng": "89.605627",
              "lat": "28.911626",
              "level": "district"
            },
            {
              "value": 2765,
              "parent_id": 2761,
              "label": "定日县",
              "lng": "87.12612",
              "lat": "28.658743",
              "level": "district"
            },
            {
              "value": 2766,
              "parent_id": 2761,
              "label": "萨迦县",
              "lng": "88.021674",
              "lat": "28.899664",
              "level": "district"
            },
            {
              "value": 2767,
              "parent_id": 2761,
              "label": "拉孜县",
              "lng": "87.63704",
              "lat": "29.081659",
              "level": "district"
            },
            {
              "value": 2768,
              "parent_id": 2761,
              "label": "昂仁县",
              "lng": "87.236051",
              "lat": "29.294802",
              "level": "district"
            },
            {
              "value": 2769,
              "parent_id": 2761,
              "label": "谢通门县",
              "lng": "88.261664",
              "lat": "29.432476",
              "level": "district"
            },
            {
              "value": 2770,
              "parent_id": 2761,
              "label": "白朗县",
              "lng": "89.261977",
              "lat": "29.107688",
              "level": "district"
            },
            {
              "value": 2771,
              "parent_id": 2761,
              "label": "仁布县",
              "lng": "89.841983",
              "lat": "29.230933",
              "level": "district"
            },
            {
              "value": 2772,
              "parent_id": 2761,
              "label": "康马县",
              "lng": "89.681663",
              "lat": "28.555627",
              "level": "district"
            },
            {
              "value": 2773,
              "parent_id": 2761,
              "label": "定结县",
              "lng": "87.765872",
              "lat": "28.364159",
              "level": "district"
            },
            {
              "value": 2774,
              "parent_id": 2761,
              "label": "仲巴县",
              "lng": "84.03153",
              "lat": "29.770279",
              "level": "district"
            },
            {
              "value": 2775,
              "parent_id": 2761,
              "label": "亚东县",
              "lng": "88.907093",
              "lat": "27.484806",
              "level": "district"
            },
            {
              "value": 2776,
              "parent_id": 2761,
              "label": "吉隆县",
              "lng": "85.297534",
              "lat": "28.852393",
              "level": "district"
            },
            {
              "value": 2777,
              "parent_id": 2761,
              "label": "聂拉木县",
              "lng": "85.982237",
              "lat": "28.155186",
              "level": "district"
            },
            {
              "value": 2778,
              "parent_id": 2761,
              "label": "萨嘎县",
              "lng": "85.232941",
              "lat": "29.328818",
              "level": "district"
            },
            {
              "value": 2779,
              "parent_id": 2761,
              "label": "岗巴县",
              "lng": "88.520031",
              "lat": "28.274601",
              "level": "district"
            }
          ]
        },
        {
          "value": 2780,
          "parent_id": 2751,
          "label": "昌都市",
          "lng": "97.17202",
          "lat": "31.140969",
          "level": "city",
          "children": [
            {
              "value": 2781,
              "parent_id": 2780,
              "label": "卡若区",
              "lng": "97.196021",
              "lat": "31.112087",
              "level": "district"
            },
            {
              "value": 2782,
              "parent_id": 2780,
              "label": "江达县",
              "lng": "98.21843",
              "lat": "31.499202",
              "level": "district"
            },
            {
              "value": 2783,
              "parent_id": 2780,
              "label": "贡觉县",
              "lng": "98.27097",
              "lat": "30.860099",
              "level": "district"
            },
            {
              "value": 2784,
              "parent_id": 2780,
              "label": "类乌齐县",
              "lng": "96.600246",
              "lat": "31.211601",
              "level": "district"
            },
            {
              "value": 2785,
              "parent_id": 2780,
              "label": "丁青县",
              "lng": "95.619868",
              "lat": "31.409024",
              "level": "district"
            },
            {
              "value": 2786,
              "parent_id": 2780,
              "label": "察雅县",
              "lng": "97.568752",
              "lat": "30.653943",
              "level": "district"
            },
            {
              "value": 2787,
              "parent_id": 2780,
              "label": "八宿县",
              "lng": "96.917836",
              "lat": "30.053209",
              "level": "district"
            },
            {
              "value": 2788,
              "parent_id": 2780,
              "label": "左贡县",
              "lng": "97.841022",
              "lat": "29.671069",
              "level": "district"
            },
            {
              "value": 2789,
              "parent_id": 2780,
              "label": "芒康县",
              "lng": "98.593113",
              "lat": "29.679907",
              "level": "district"
            },
            {
              "value": 2790,
              "parent_id": 2780,
              "label": "洛隆县",
              "lng": "95.825197",
              "lat": "30.741845",
              "level": "district"
            },
            {
              "value": 2791,
              "parent_id": 2780,
              "label": "边坝县",
              "lng": "94.7078",
              "lat": "30.933652",
              "level": "district"
            }
          ]
        },
        {
          "value": 2792,
          "parent_id": 2751,
          "label": "林芝市",
          "lng": "94.36149",
          "lat": "29.649128",
          "level": "city",
          "children": [
            {
              "value": 2793,
              "parent_id": 2792,
              "label": "巴宜区",
              "lng": "94.361094",
              "lat": "29.636576",
              "level": "district"
            },
            {
              "value": 2794,
              "parent_id": 2792,
              "label": "工布江达县",
              "lng": "93.246077",
              "lat": "29.88528",
              "level": "district"
            },
            {
              "value": 2795,
              "parent_id": 2792,
              "label": "米林县",
              "lng": "94.213679",
              "lat": "29.213811",
              "level": "district"
            },
            {
              "value": 2796,
              "parent_id": 2792,
              "label": "墨脱县",
              "lng": "95.333197",
              "lat": "29.325298",
              "level": "district"
            },
            {
              "value": 2797,
              "parent_id": 2792,
              "label": "波密县",
              "lng": "95.767913",
              "lat": "29.859028",
              "level": "district"
            },
            {
              "value": 2798,
              "parent_id": 2792,
              "label": "察隅县",
              "lng": "97.466919",
              "lat": "28.66128",
              "level": "district"
            },
            {
              "value": 2799,
              "parent_id": 2792,
              "label": "朗县",
              "lng": "93.074702",
              "lat": "29.046337",
              "level": "district"
            }
          ]
        },
        {
          "value": 2800,
          "parent_id": 2751,
          "label": "山南市",
          "lng": "91.773134",
          "lat": "29.237137",
          "level": "city",
          "children": [
            {
              "value": 2801,
              "parent_id": 2800,
              "label": "乃东区",
              "lng": "91.761538",
              "lat": "29.224904",
              "level": "district"
            },
            {
              "value": 2802,
              "parent_id": 2800,
              "label": "扎囊县",
              "lng": "91.33725",
              "lat": "29.245113",
              "level": "district"
            },
            {
              "value": 2803,
              "parent_id": 2800,
              "label": "贡嘎县",
              "lng": "90.98414",
              "lat": "29.289455",
              "level": "district"
            },
            {
              "value": 2804,
              "parent_id": 2800,
              "label": "桑日县",
              "lng": "92.015818",
              "lat": "29.259189",
              "level": "district"
            },
            {
              "value": 2805,
              "parent_id": 2800,
              "label": "琼结县",
              "lng": "91.683881",
              "lat": "29.024625",
              "level": "district"
            },
            {
              "value": 2806,
              "parent_id": 2800,
              "label": "曲松县",
              "lng": "92.203738",
              "lat": "29.062826",
              "level": "district"
            },
            {
              "value": 2807,
              "parent_id": 2800,
              "label": "措美县",
              "lng": "91.433509",
              "lat": "28.438202",
              "level": "district"
            },
            {
              "value": 2808,
              "parent_id": 2800,
              "label": "洛扎县",
              "lng": "90.859992",
              "lat": "28.385713",
              "level": "district"
            },
            {
              "value": 2809,
              "parent_id": 2800,
              "label": "加查县",
              "lng": "92.593993",
              "lat": "29.14029",
              "level": "district"
            },
            {
              "value": 2810,
              "parent_id": 2800,
              "label": "隆子县",
              "lng": "92.463308",
              "lat": "28.408548",
              "level": "district"
            },
            {
              "value": 2811,
              "parent_id": 2800,
              "label": "错那县",
              "lng": "91.960132",
              "lat": "27.991707",
              "level": "district"
            },
            {
              "value": 2812,
              "parent_id": 2800,
              "label": "浪卡子县",
              "lng": "90.397977",
              "lat": "28.968031",
              "level": "district"
            }
          ]
        },
        {
          "value": 2813,
          "parent_id": 2751,
          "label": "那曲地区",
          "lng": "92.052064",
          "lat": "31.476479",
          "level": "city",
          "children": [
            {
              "value": 2814,
              "parent_id": 2813,
              "label": "那曲县",
              "lng": "92.0535",
              "lat": "31.469643",
              "level": "district"
            },
            {
              "value": 2815,
              "parent_id": 2813,
              "label": "嘉黎县",
              "lng": "93.232528",
              "lat": "30.640814",
              "level": "district"
            },
            {
              "value": 2816,
              "parent_id": 2813,
              "label": "比如县",
              "lng": "93.679639",
              "lat": "31.480249",
              "level": "district"
            },
            {
              "value": 2817,
              "parent_id": 2813,
              "label": "聂荣县",
              "lng": "92.303377",
              "lat": "32.10775",
              "level": "district"
            },
            {
              "value": 2818,
              "parent_id": 2813,
              "label": "安多县",
              "lng": "91.68233",
              "lat": "32.265176",
              "level": "district"
            },
            {
              "value": 2819,
              "parent_id": 2813,
              "label": "申扎县",
              "lng": "88.709852",
              "lat": "30.930505",
              "level": "district"
            },
            {
              "value": 2820,
              "parent_id": 2813,
              "label": "索县",
              "lng": "93.785516",
              "lat": "31.886671",
              "level": "district"
            },
            {
              "value": 2821,
              "parent_id": 2813,
              "label": "班戈县",
              "lng": "90.009957",
              "lat": "31.392411",
              "level": "district"
            },
            {
              "value": 2822,
              "parent_id": 2813,
              "label": "巴青县",
              "lng": "94.053438",
              "lat": "31.91847",
              "level": "district"
            },
            {
              "value": 2823,
              "parent_id": 2813,
              "label": "尼玛县",
              "lng": "87.236772",
              "lat": "31.784701",
              "level": "district"
            },
            {
              "value": 2824,
              "parent_id": 2813,
              "label": "双湖县",
              "lng": "88.837641",
              "lat": "33.188514",
              "level": "district"
            }
          ]
        },
        {
          "value": 2825,
          "parent_id": 2751,
          "label": "阿里地区",
          "lng": "80.105804",
          "lat": "32.501111",
          "level": "city",
          "children": [
            {
              "value": 2826,
              "parent_id": 2825,
              "label": "普兰县",
              "lng": "81.176237",
              "lat": "30.294402",
              "level": "district"
            },
            {
              "value": 2827,
              "parent_id": 2825,
              "label": "札达县",
              "lng": "79.802706",
              "lat": "31.479216",
              "level": "district"
            },
            {
              "value": 2828,
              "parent_id": 2825,
              "label": "噶尔县",
              "lng": "80.096419",
              "lat": "32.491488",
              "level": "district"
            },
            {
              "value": 2829,
              "parent_id": 2825,
              "label": "日土县",
              "lng": "79.732427",
              "lat": "33.381359",
              "level": "district"
            },
            {
              "value": 2830,
              "parent_id": 2825,
              "label": "革吉县",
              "lng": "81.145433",
              "lat": "32.387233",
              "level": "district"
            },
            {
              "value": 2831,
              "parent_id": 2825,
              "label": "改则县",
              "lng": "84.06259",
              "lat": "32.302713",
              "level": "district"
            },
            {
              "value": 2832,
              "parent_id": 2825,
              "label": "措勤县",
              "lng": "85.151455",
              "lat": "31.017312",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2833,
      "parent_id": 1,
      "label": "陕西省",
      "lng": "108.954347",
      "lat": "34.265502",
      "level": "province",
      "children": [
        {
          "value": 2834,
          "parent_id": 2833,
          "label": "西安市",
          "lng": "108.93977",
          "lat": "34.341574",
          "level": "city",
          "children": [
            {
              "value": 2835,
              "parent_id": 2834,
              "label": "新城区",
              "lng": "108.960716",
              "lat": "34.266447",
              "level": "district"
            },
            {
              "value": 2836,
              "parent_id": 2834,
              "label": "碑林区",
              "lng": "108.94059",
              "lat": "34.256783",
              "level": "district"
            },
            {
              "value": 2837,
              "parent_id": 2834,
              "label": "莲湖区",
              "lng": "108.943895",
              "lat": "34.265239",
              "level": "district"
            },
            {
              "value": 2838,
              "parent_id": 2834,
              "label": "灞桥区",
              "lng": "109.064646",
              "lat": "34.272793",
              "level": "district"
            },
            {
              "value": 2839,
              "parent_id": 2834,
              "label": "未央区",
              "lng": "108.946825",
              "lat": "34.29292",
              "level": "district"
            },
            {
              "value": 2840,
              "parent_id": 2834,
              "label": "雁塔区",
              "lng": "108.944644",
              "lat": "34.214113",
              "level": "district"
            },
            {
              "value": 2841,
              "parent_id": 2834,
              "label": "阎良区",
              "lng": "109.226124",
              "lat": "34.662232",
              "level": "district"
            },
            {
              "value": 2842,
              "parent_id": 2834,
              "label": "临潼区",
              "lng": "109.214237",
              "lat": "34.367069",
              "level": "district"
            },
            {
              "value": 2843,
              "parent_id": 2834,
              "label": "长安区",
              "lng": "108.907173",
              "lat": "34.158926",
              "level": "district"
            },
            {
              "value": 2844,
              "parent_id": 2834,
              "label": "高陵区",
              "lng": "109.088297",
              "lat": "34.534829",
              "level": "district"
            },
            {
              "value": 2845,
              "parent_id": 2834,
              "label": "蓝田县",
              "lng": "109.32345",
              "lat": "34.151298",
              "level": "district"
            },
            {
              "value": 2846,
              "parent_id": 2834,
              "label": "周至县",
              "lng": "108.222162",
              "lat": "34.163669",
              "level": "district"
            },
            {
              "value": 2847,
              "parent_id": 2834,
              "label": "鄠邑区",
              "lng": "108.604894",
              "lat": "34.109244",
              "level": "district"
            }
          ]
        },
        {
          "value": 2848,
          "parent_id": 2833,
          "label": "铜川市",
          "lng": "108.945019",
          "lat": "34.897887",
          "level": "city",
          "children": [
            {
              "value": 2849,
              "parent_id": 2848,
              "label": "王益区",
              "lng": "109.075578",
              "lat": "35.068964",
              "level": "district"
            },
            {
              "value": 2850,
              "parent_id": 2848,
              "label": "印台区",
              "lng": "109.099974",
              "lat": "35.114492",
              "level": "district"
            },
            {
              "value": 2851,
              "parent_id": 2848,
              "label": "耀州区",
              "lng": "108.980102",
              "lat": "34.909793",
              "level": "district"
            },
            {
              "value": 2852,
              "parent_id": 2848,
              "label": "宜君县",
              "lng": "109.116932",
              "lat": "35.398577",
              "level": "district"
            }
          ]
        },
        {
          "value": 2853,
          "parent_id": 2833,
          "label": "宝鸡市",
          "lng": "107.237743",
          "lat": "34.363184",
          "level": "city",
          "children": [
            {
              "value": 2854,
              "parent_id": 2853,
              "label": "渭滨区",
              "lng": "107.155344",
              "lat": "34.355068",
              "level": "district"
            },
            {
              "value": 2855,
              "parent_id": 2853,
              "label": "金台区",
              "lng": "107.146806",
              "lat": "34.376069",
              "level": "district"
            },
            {
              "value": 2856,
              "parent_id": 2853,
              "label": "陈仓区",
              "lng": "107.369987",
              "lat": "34.35147",
              "level": "district"
            },
            {
              "value": 2857,
              "parent_id": 2853,
              "label": "凤翔县",
              "lng": "107.400737",
              "lat": "34.521217",
              "level": "district"
            },
            {
              "value": 2858,
              "parent_id": 2853,
              "label": "岐山县",
              "lng": "107.621053",
              "lat": "34.443459",
              "level": "district"
            },
            {
              "value": 2859,
              "parent_id": 2853,
              "label": "扶风县",
              "lng": "107.900219",
              "lat": "34.37541",
              "level": "district"
            },
            {
              "value": 2860,
              "parent_id": 2853,
              "label": "眉县",
              "lng": "107.749766",
              "lat": "34.274246",
              "level": "district"
            },
            {
              "value": 2861,
              "parent_id": 2853,
              "label": "陇县",
              "lng": "106.864397",
              "lat": "34.89305",
              "level": "district"
            },
            {
              "value": 2862,
              "parent_id": 2853,
              "label": "千阳县",
              "lng": "107.132441",
              "lat": "34.642381",
              "level": "district"
            },
            {
              "value": 2863,
              "parent_id": 2853,
              "label": "麟游县",
              "lng": "107.793524",
              "lat": "34.677902",
              "level": "district"
            },
            {
              "value": 2864,
              "parent_id": 2853,
              "label": "凤县",
              "lng": "106.515803",
              "lat": "33.91091",
              "level": "district"
            },
            {
              "value": 2865,
              "parent_id": 2853,
              "label": "太白县",
              "lng": "107.319116",
              "lat": "34.058401",
              "level": "district"
            }
          ]
        },
        {
          "value": 2866,
          "parent_id": 2833,
          "label": "咸阳市",
          "lng": "108.709136",
          "lat": "34.32987",
          "level": "city",
          "children": [
            {
              "value": 2867,
              "parent_id": 2866,
              "label": "秦都区",
              "lng": "108.706272",
              "lat": "34.329567",
              "level": "district"
            },
            {
              "value": 2868,
              "parent_id": 2866,
              "label": "杨陵区",
              "lng": "108.084731",
              "lat": "34.272117",
              "level": "district"
            },
            {
              "value": 2869,
              "parent_id": 2866,
              "label": "渭城区",
              "lng": "108.737204",
              "lat": "34.36195",
              "level": "district"
            },
            {
              "value": 2870,
              "parent_id": 2866,
              "label": "三原县",
              "lng": "108.940509",
              "lat": "34.617381",
              "level": "district"
            },
            {
              "value": 2871,
              "parent_id": 2866,
              "label": "泾阳县",
              "lng": "108.842622",
              "lat": "34.527114",
              "level": "district"
            },
            {
              "value": 2872,
              "parent_id": 2866,
              "label": "乾县",
              "lng": "108.239473",
              "lat": "34.527551",
              "level": "district"
            },
            {
              "value": 2873,
              "parent_id": 2866,
              "label": "礼泉县",
              "lng": "108.425018",
              "lat": "34.481764",
              "level": "district"
            },
            {
              "value": 2874,
              "parent_id": 2866,
              "label": "永寿县",
              "lng": "108.142311",
              "lat": "34.691979",
              "level": "district"
            },
            {
              "value": 2875,
              "parent_id": 2866,
              "label": "彬县",
              "lng": "108.077658",
              "lat": "35.043911",
              "level": "district"
            },
            {
              "value": 2876,
              "parent_id": 2866,
              "label": "长武县",
              "lng": "107.798757",
              "lat": "35.205886",
              "level": "district"
            },
            {
              "value": 2877,
              "parent_id": 2866,
              "label": "旬邑县",
              "lng": "108.333986",
              "lat": "35.111978",
              "level": "district"
            },
            {
              "value": 2878,
              "parent_id": 2866,
              "label": "淳化县",
              "lng": "108.580681",
              "lat": "34.79925",
              "level": "district"
            },
            {
              "value": 2879,
              "parent_id": 2866,
              "label": "武功县",
              "lng": "108.200398",
              "lat": "34.260203",
              "level": "district"
            },
            {
              "value": 2880,
              "parent_id": 2866,
              "label": "兴平市",
              "lng": "108.490475",
              "lat": "34.29922",
              "level": "district"
            }
          ]
        },
        {
          "value": 2881,
          "parent_id": 2833,
          "label": "渭南市",
          "lng": "109.471094",
          "lat": "34.52044",
          "level": "city",
          "children": [
            {
              "value": 2882,
              "parent_id": 2881,
              "label": "临渭区",
              "lng": "109.510175",
              "lat": "34.499314",
              "level": "district"
            },
            {
              "value": 2883,
              "parent_id": 2881,
              "label": "华州区",
              "lng": "109.775247",
              "lat": "34.495915",
              "level": "district"
            },
            {
              "value": 2884,
              "parent_id": 2881,
              "label": "潼关县",
              "lng": "110.246349",
              "lat": "34.544296",
              "level": "district"
            },
            {
              "value": 2885,
              "parent_id": 2881,
              "label": "大荔县",
              "lng": "109.941734",
              "lat": "34.797259",
              "level": "district"
            },
            {
              "value": 2886,
              "parent_id": 2881,
              "label": "合阳县",
              "lng": "110.149453",
              "lat": "35.237988",
              "level": "district"
            },
            {
              "value": 2887,
              "parent_id": 2881,
              "label": "澄城县",
              "lng": "109.93235",
              "lat": "35.190245",
              "level": "district"
            },
            {
              "value": 2888,
              "parent_id": 2881,
              "label": "蒲城县",
              "lng": "109.586403",
              "lat": "34.955562",
              "level": "district"
            },
            {
              "value": 2889,
              "parent_id": 2881,
              "label": "白水县",
              "lng": "109.590671",
              "lat": "35.177451",
              "level": "district"
            },
            {
              "value": 2890,
              "parent_id": 2881,
              "label": "富平县",
              "lng": "109.18032",
              "lat": "34.751077",
              "level": "district"
            },
            {
              "value": 2891,
              "parent_id": 2881,
              "label": "韩城市",
              "lng": "110.442846",
              "lat": "35.476788",
              "level": "district"
            },
            {
              "value": 2892,
              "parent_id": 2881,
              "label": "华阴市",
              "lng": "110.092078",
              "lat": "34.566079",
              "level": "district"
            }
          ]
        },
        {
          "value": 2893,
          "parent_id": 2833,
          "label": "延安市",
          "lng": "109.494112",
          "lat": "36.651381",
          "level": "city",
          "children": [
            {
              "value": 2894,
              "parent_id": 2893,
              "label": "宝塔区",
              "lng": "109.48976",
              "lat": "36.585472",
              "level": "district"
            },
            {
              "value": 2895,
              "parent_id": 2893,
              "label": "延长县",
              "lng": "110.012334",
              "lat": "36.579313",
              "level": "district"
            },
            {
              "value": 2896,
              "parent_id": 2893,
              "label": "延川县",
              "lng": "110.193514",
              "lat": "36.878117",
              "level": "district"
            },
            {
              "value": 2897,
              "parent_id": 2893,
              "label": "子长县",
              "lng": "109.675264",
              "lat": "37.142535",
              "level": "district"
            },
            {
              "value": 2898,
              "parent_id": 2893,
              "label": "安塞区",
              "lng": "109.328842",
              "lat": "36.863853",
              "level": "district"
            },
            {
              "value": 2899,
              "parent_id": 2893,
              "label": "志丹县",
              "lng": "108.768432",
              "lat": "36.822194",
              "level": "district"
            },
            {
              "value": 2900,
              "parent_id": 2893,
              "label": "吴起县",
              "lng": "108.175933",
              "lat": "36.927215",
              "level": "district"
            },
            {
              "value": 2901,
              "parent_id": 2893,
              "label": "甘泉县",
              "lng": "109.351019",
              "lat": "36.276526",
              "level": "district"
            },
            {
              "value": 2902,
              "parent_id": 2893,
              "label": "富县",
              "lng": "109.379776",
              "lat": "35.987953",
              "level": "district"
            },
            {
              "value": 2903,
              "parent_id": 2893,
              "label": "洛川县",
              "lng": "109.432369",
              "lat": "35.761974",
              "level": "district"
            },
            {
              "value": 2904,
              "parent_id": 2893,
              "label": "宜川县",
              "lng": "110.168963",
              "lat": "36.050178",
              "level": "district"
            },
            {
              "value": 2905,
              "parent_id": 2893,
              "label": "黄龙县",
              "lng": "109.840314",
              "lat": "35.584743",
              "level": "district"
            },
            {
              "value": 2906,
              "parent_id": 2893,
              "label": "黄陵县",
              "lng": "109.262961",
              "lat": "35.579427",
              "level": "district"
            }
          ]
        },
        {
          "value": 2907,
          "parent_id": 2833,
          "label": "汉中市",
          "lng": "107.02305",
          "lat": "33.067225",
          "level": "city",
          "children": [
            {
              "value": 2908,
              "parent_id": 2907,
              "label": "汉台区",
              "lng": "107.031856",
              "lat": "33.067771",
              "level": "district"
            },
            {
              "value": 2909,
              "parent_id": 2907,
              "label": "南郑县",
              "lng": "106.93623",
              "lat": "32.999333",
              "level": "district"
            },
            {
              "value": 2910,
              "parent_id": 2907,
              "label": "城固县",
              "lng": "107.33393",
              "lat": "33.157131",
              "level": "district"
            },
            {
              "value": 2911,
              "parent_id": 2907,
              "label": "洋县",
              "lng": "107.545836",
              "lat": "33.222738",
              "level": "district"
            },
            {
              "value": 2912,
              "parent_id": 2907,
              "label": "西乡县",
              "lng": "107.766613",
              "lat": "32.983101",
              "level": "district"
            },
            {
              "value": 2913,
              "parent_id": 2907,
              "label": "勉县",
              "lng": "106.673221",
              "lat": "33.153553",
              "level": "district"
            },
            {
              "value": 2914,
              "parent_id": 2907,
              "label": "宁强县",
              "lng": "106.257171",
              "lat": "32.829694",
              "level": "district"
            },
            {
              "value": 2915,
              "parent_id": 2907,
              "label": "略阳县",
              "lng": "106.156718",
              "lat": "33.327281",
              "level": "district"
            },
            {
              "value": 2916,
              "parent_id": 2907,
              "label": "镇巴县",
              "lng": "107.895035",
              "lat": "32.536704",
              "level": "district"
            },
            {
              "value": 2917,
              "parent_id": 2907,
              "label": "留坝县",
              "lng": "106.920808",
              "lat": "33.617571",
              "level": "district"
            },
            {
              "value": 2918,
              "parent_id": 2907,
              "label": "佛坪县",
              "lng": "107.990538",
              "lat": "33.524359",
              "level": "district"
            }
          ]
        },
        {
          "value": 2919,
          "parent_id": 2833,
          "label": "榆林市",
          "lng": "109.734474",
          "lat": "38.285369",
          "level": "city",
          "children": [
            {
              "value": 2920,
              "parent_id": 2919,
              "label": "榆阳区",
              "lng": "109.721069",
              "lat": "38.277046",
              "level": "district"
            },
            {
              "value": 2921,
              "parent_id": 2919,
              "label": "横山区",
              "lng": "109.294346",
              "lat": "37.962208",
              "level": "district"
            },
            {
              "value": 2922,
              "parent_id": 2919,
              "label": "神木县",
              "lng": "110.498939",
              "lat": "38.842578",
              "level": "district"
            },
            {
              "value": 2923,
              "parent_id": 2919,
              "label": "府谷县",
              "lng": "111.067276",
              "lat": "39.028116",
              "level": "district"
            },
            {
              "value": 2924,
              "parent_id": 2919,
              "label": "靖边县",
              "lng": "108.793988",
              "lat": "37.599438",
              "level": "district"
            },
            {
              "value": 2925,
              "parent_id": 2919,
              "label": "定边县",
              "lng": "107.601267",
              "lat": "37.594612",
              "level": "district"
            },
            {
              "value": 2926,
              "parent_id": 2919,
              "label": "绥德县",
              "lng": "110.263362",
              "lat": "37.50294",
              "level": "district"
            },
            {
              "value": 2927,
              "parent_id": 2919,
              "label": "米脂县",
              "lng": "110.183754",
              "lat": "37.755416",
              "level": "district"
            },
            {
              "value": 2928,
              "parent_id": 2919,
              "label": "佳县",
              "lng": "110.491345",
              "lat": "38.01951",
              "level": "district"
            },
            {
              "value": 2929,
              "parent_id": 2919,
              "label": "吴堡县",
              "lng": "110.739673",
              "lat": "37.452067",
              "level": "district"
            },
            {
              "value": 2930,
              "parent_id": 2919,
              "label": "清涧县",
              "lng": "110.121209",
              "lat": "37.088878",
              "level": "district"
            },
            {
              "value": 2931,
              "parent_id": 2919,
              "label": "子洲县",
              "lng": "110.03525",
              "lat": "37.610683",
              "level": "district"
            }
          ]
        },
        {
          "value": 2932,
          "parent_id": 2833,
          "label": "安康市",
          "lng": "109.029113",
          "lat": "32.68481",
          "level": "city",
          "children": [
            {
              "value": 2933,
              "parent_id": 2932,
              "label": "汉滨区",
              "lng": "109.026836",
              "lat": "32.695172",
              "level": "district"
            },
            {
              "value": 2934,
              "parent_id": 2932,
              "label": "汉阴县",
              "lng": "108.508745",
              "lat": "32.893026",
              "level": "district"
            },
            {
              "value": 2935,
              "parent_id": 2932,
              "label": "石泉县",
              "lng": "108.247886",
              "lat": "33.038408",
              "level": "district"
            },
            {
              "value": 2936,
              "parent_id": 2932,
              "label": "宁陕县",
              "lng": "108.314283",
              "lat": "33.310527",
              "level": "district"
            },
            {
              "value": 2937,
              "parent_id": 2932,
              "label": "紫阳县",
              "lng": "108.534228",
              "lat": "32.520246",
              "level": "district"
            },
            {
              "value": 2938,
              "parent_id": 2932,
              "label": "岚皋县",
              "lng": "108.902049",
              "lat": "32.307001",
              "level": "district"
            },
            {
              "value": 2939,
              "parent_id": 2932,
              "label": "平利县",
              "lng": "109.361864",
              "lat": "32.388854",
              "level": "district"
            },
            {
              "value": 2940,
              "parent_id": 2932,
              "label": "镇坪县",
              "lng": "109.526873",
              "lat": "31.883672",
              "level": "district"
            },
            {
              "value": 2941,
              "parent_id": 2932,
              "label": "旬阳县",
              "lng": "109.361024",
              "lat": "32.832012",
              "level": "district"
            },
            {
              "value": 2942,
              "parent_id": 2932,
              "label": "白河县",
              "lng": "110.112629",
              "lat": "32.809026",
              "level": "district"
            }
          ]
        },
        {
          "value": 2943,
          "parent_id": 2833,
          "label": "商洛市",
          "lng": "109.91857",
          "lat": "33.872726",
          "level": "city",
          "children": [
            {
              "value": 2944,
              "parent_id": 2943,
              "label": "商州区",
              "lng": "109.941839",
              "lat": "33.862599",
              "level": "district"
            },
            {
              "value": 2945,
              "parent_id": 2943,
              "label": "洛南县",
              "lng": "110.148508",
              "lat": "34.090837",
              "level": "district"
            },
            {
              "value": 2946,
              "parent_id": 2943,
              "label": "丹凤县",
              "lng": "110.32733",
              "lat": "33.695783",
              "level": "district"
            },
            {
              "value": 2947,
              "parent_id": 2943,
              "label": "商南县",
              "lng": "110.881807",
              "lat": "33.530995",
              "level": "district"
            },
            {
              "value": 2948,
              "parent_id": 2943,
              "label": "山阳县",
              "lng": "109.882289",
              "lat": "33.532172",
              "level": "district"
            },
            {
              "value": 2949,
              "parent_id": 2943,
              "label": "镇安县",
              "lng": "109.152892",
              "lat": "33.423357",
              "level": "district"
            },
            {
              "value": 2950,
              "parent_id": 2943,
              "label": "柞水县",
              "lng": "109.114206",
              "lat": "33.68611",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 2951,
      "parent_id": 1,
      "label": "甘肃省",
      "lng": "103.826447",
      "lat": "36.05956",
      "level": "province",
      "children": [
        {
          "value": 2952,
          "parent_id": 2951,
          "label": "兰州市",
          "lng": "103.834303",
          "lat": "36.061089",
          "level": "city",
          "children": [
            {
              "value": 2953,
              "parent_id": 2952,
              "label": "城关区",
              "lng": "103.825307",
              "lat": "36.057464",
              "level": "district"
            },
            {
              "value": 2954,
              "parent_id": 2952,
              "label": "七里河区",
              "lng": "103.785949",
              "lat": "36.066146",
              "level": "district"
            },
            {
              "value": 2955,
              "parent_id": 2952,
              "label": "西固区",
              "lng": "103.627951",
              "lat": "36.088552",
              "level": "district"
            },
            {
              "value": 2956,
              "parent_id": 2952,
              "label": "安宁区",
              "lng": "103.719054",
              "lat": "36.104579",
              "level": "district"
            },
            {
              "value": 2957,
              "parent_id": 2952,
              "label": "红古区",
              "lng": "102.859323",
              "lat": "36.345669",
              "level": "district"
            },
            {
              "value": 2958,
              "parent_id": 2952,
              "label": "永登县",
              "lng": "103.26038",
              "lat": "36.736513",
              "level": "district"
            },
            {
              "value": 2959,
              "parent_id": 2952,
              "label": "皋兰县",
              "lng": "103.947377",
              "lat": "36.332663",
              "level": "district"
            },
            {
              "value": 2960,
              "parent_id": 2952,
              "label": "榆中县",
              "lng": "104.112527",
              "lat": "35.843056",
              "level": "district"
            }
          ]
        },
        {
          "value": 2961,
          "parent_id": 2951,
          "label": "嘉峪关市",
          "lng": "98.289419",
          "lat": "39.772554",
          "level": "city",
          "children": [
            {
              "value": 2962,
              "parent_id": 2961,
              "label": "嘉峪关市",
              "lng": "98.289419",
              "lat": "39.772554",
              "level": "district"
            }
          ]
        },
        {
          "value": 2963,
          "parent_id": 2951,
          "label": "金昌市",
          "lng": "102.188117",
          "lat": "38.520717",
          "level": "city",
          "children": [
            {
              "value": 2964,
              "parent_id": 2963,
              "label": "金川区",
              "lng": "102.194015",
              "lat": "38.521087",
              "level": "district"
            },
            {
              "value": 2965,
              "parent_id": 2963,
              "label": "永昌县",
              "lng": "101.984458",
              "lat": "38.243434",
              "level": "district"
            }
          ]
        },
        {
          "value": 2966,
          "parent_id": 2951,
          "label": "白银市",
          "lng": "104.138771",
          "lat": "36.545261",
          "level": "city",
          "children": [
            {
              "value": 2967,
              "parent_id": 2966,
              "label": "白银区",
              "lng": "104.148556",
              "lat": "36.535398",
              "level": "district"
            },
            {
              "value": 2968,
              "parent_id": 2966,
              "label": "平川区",
              "lng": "104.825208",
              "lat": "36.728304",
              "level": "district"
            },
            {
              "value": 2969,
              "parent_id": 2966,
              "label": "靖远县",
              "lng": "104.676774",
              "lat": "36.571365",
              "level": "district"
            },
            {
              "value": 2970,
              "parent_id": 2966,
              "label": "会宁县",
              "lng": "105.053358",
              "lat": "35.692823",
              "level": "district"
            },
            {
              "value": 2971,
              "parent_id": 2966,
              "label": "景泰县",
              "lng": "104.063091",
              "lat": "37.183804",
              "level": "district"
            }
          ]
        },
        {
          "value": 2972,
          "parent_id": 2951,
          "label": "天水市",
          "lng": "105.724979",
          "lat": "34.580885",
          "level": "city",
          "children": [
            {
              "value": 2973,
              "parent_id": 2972,
              "label": "秦州区",
              "lng": "105.724215",
              "lat": "34.580888",
              "level": "district"
            },
            {
              "value": 2974,
              "parent_id": 2972,
              "label": "麦积区",
              "lng": "105.889556",
              "lat": "34.570384",
              "level": "district"
            },
            {
              "value": 2975,
              "parent_id": 2972,
              "label": "清水县",
              "lng": "106.137293",
              "lat": "34.749864",
              "level": "district"
            },
            {
              "value": 2976,
              "parent_id": 2972,
              "label": "秦安县",
              "lng": "105.674982",
              "lat": "34.858916",
              "level": "district"
            },
            {
              "value": 2977,
              "parent_id": 2972,
              "label": "甘谷县",
              "lng": "105.340747",
              "lat": "34.745486",
              "level": "district"
            },
            {
              "value": 2978,
              "parent_id": 2972,
              "label": "武山县",
              "lng": "104.890587",
              "lat": "34.72139",
              "level": "district"
            },
            {
              "value": 2979,
              "parent_id": 2972,
              "label": "张家川回族自治县",
              "lng": "106.204517",
              "lat": "34.988037",
              "level": "district"
            }
          ]
        },
        {
          "value": 2980,
          "parent_id": 2951,
          "label": "武威市",
          "lng": "102.638201",
          "lat": "37.928267",
          "level": "city",
          "children": [
            {
              "value": 2981,
              "parent_id": 2980,
              "label": "凉州区",
              "lng": "102.642184",
              "lat": "37.928224",
              "level": "district"
            },
            {
              "value": 2982,
              "parent_id": 2980,
              "label": "民勤县",
              "lng": "103.093791",
              "lat": "38.62435",
              "level": "district"
            },
            {
              "value": 2983,
              "parent_id": 2980,
              "label": "古浪县",
              "lng": "102.897533",
              "lat": "37.47012",
              "level": "district"
            },
            {
              "value": 2984,
              "parent_id": 2980,
              "label": "天祝藏族自治县",
              "lng": "103.141757",
              "lat": "36.97174",
              "level": "district"
            }
          ]
        },
        {
          "value": 2985,
          "parent_id": 2951,
          "label": "张掖市",
          "lng": "100.449913",
          "lat": "38.925548",
          "level": "city",
          "children": [
            {
              "value": 2986,
              "parent_id": 2985,
              "label": "甘州区",
              "lng": "100.415096",
              "lat": "38.944662",
              "level": "district"
            },
            {
              "value": 2987,
              "parent_id": 2985,
              "label": "肃南裕固族自治县",
              "lng": "99.615601",
              "lat": "38.836931",
              "level": "district"
            },
            {
              "value": 2988,
              "parent_id": 2985,
              "label": "民乐县",
              "lng": "100.812629",
              "lat": "38.430347",
              "level": "district"
            },
            {
              "value": 2989,
              "parent_id": 2985,
              "label": "临泽县",
              "lng": "100.164283",
              "lat": "39.152462",
              "level": "district"
            },
            {
              "value": 2990,
              "parent_id": 2985,
              "label": "高台县",
              "lng": "99.819519",
              "lat": "39.378311",
              "level": "district"
            },
            {
              "value": 2991,
              "parent_id": 2985,
              "label": "山丹县",
              "lng": "101.088529",
              "lat": "38.784505",
              "level": "district"
            }
          ]
        },
        {
          "value": 2992,
          "parent_id": 2951,
          "label": "平凉市",
          "lng": "106.665061",
          "lat": "35.542606",
          "level": "city",
          "children": [
            {
              "value": 2993,
              "parent_id": 2992,
              "label": "崆峒区",
              "lng": "106.674767",
              "lat": "35.542491",
              "level": "district"
            },
            {
              "value": 2994,
              "parent_id": 2992,
              "label": "泾川县",
              "lng": "107.36785",
              "lat": "35.332666",
              "level": "district"
            },
            {
              "value": 2995,
              "parent_id": 2992,
              "label": "灵台县",
              "lng": "107.595874",
              "lat": "35.070027",
              "level": "district"
            },
            {
              "value": 2996,
              "parent_id": 2992,
              "label": "崇信县",
              "lng": "107.025763",
              "lat": "35.305596",
              "level": "district"
            },
            {
              "value": 2997,
              "parent_id": 2992,
              "label": "华亭县",
              "lng": "106.653158",
              "lat": "35.218292",
              "level": "district"
            },
            {
              "value": 2998,
              "parent_id": 2992,
              "label": "庄浪县",
              "lng": "106.036686",
              "lat": "35.202385",
              "level": "district"
            },
            {
              "value": 2999,
              "parent_id": 2992,
              "label": "静宁县",
              "lng": "105.732556",
              "lat": "35.521976",
              "level": "district"
            }
          ]
        },
        {
          "value": 3000,
          "parent_id": 2951,
          "label": "酒泉市",
          "lng": "98.493927",
          "lat": "39.732795",
          "level": "city",
          "children": [
            {
              "value": 3001,
              "parent_id": 3000,
              "label": "肃州区",
              "lng": "98.507843",
              "lat": "39.744953",
              "level": "district"
            },
            {
              "value": 3002,
              "parent_id": 3000,
              "label": "金塔县",
              "lng": "98.901252",
              "lat": "39.983955",
              "level": "district"
            },
            {
              "value": 3003,
              "parent_id": 3000,
              "label": "瓜州县",
              "lng": "95.782318",
              "lat": "40.520538",
              "level": "district"
            },
            {
              "value": 3004,
              "parent_id": 3000,
              "label": "肃北蒙古族自治县",
              "lng": "94.876579",
              "lat": "39.51245",
              "level": "district"
            },
            {
              "value": 3005,
              "parent_id": 3000,
              "label": "阿克塞哈萨克族自治县",
              "lng": "94.340204",
              "lat": "39.633943",
              "level": "district"
            },
            {
              "value": 3006,
              "parent_id": 3000,
              "label": "玉门市",
              "lng": "97.045661",
              "lat": "40.292106",
              "level": "district"
            },
            {
              "value": 3007,
              "parent_id": 3000,
              "label": "敦煌市",
              "lng": "94.661941",
              "lat": "40.142089",
              "level": "district"
            }
          ]
        },
        {
          "value": 3008,
          "parent_id": 2951,
          "label": "庆阳市",
          "lng": "107.643571",
          "lat": "35.70898",
          "level": "city",
          "children": [
            {
              "value": 3009,
              "parent_id": 3008,
              "label": "西峰区",
              "lng": "107.651077",
              "lat": "35.730652",
              "level": "district"
            },
            {
              "value": 3010,
              "parent_id": 3008,
              "label": "庆城县",
              "lng": "107.881802",
              "lat": "36.016299",
              "level": "district"
            },
            {
              "value": 3011,
              "parent_id": 3008,
              "label": "环县",
              "lng": "107.308501",
              "lat": "36.568434",
              "level": "district"
            },
            {
              "value": 3012,
              "parent_id": 3008,
              "label": "华池县",
              "lng": "107.990062",
              "lat": "36.461306",
              "level": "district"
            },
            {
              "value": 3013,
              "parent_id": 3008,
              "label": "合水县",
              "lng": "108.019554",
              "lat": "35.819194",
              "level": "district"
            },
            {
              "value": 3014,
              "parent_id": 3008,
              "label": "正宁县",
              "lng": "108.359865",
              "lat": "35.49178",
              "level": "district"
            },
            {
              "value": 3015,
              "parent_id": 3008,
              "label": "宁县",
              "lng": "107.928371",
              "lat": "35.502176",
              "level": "district"
            },
            {
              "value": 3016,
              "parent_id": 3008,
              "label": "镇原县",
              "lng": "107.200832",
              "lat": "35.677462",
              "level": "district"
            }
          ]
        },
        {
          "value": 3017,
          "parent_id": 2951,
          "label": "定西市",
          "lng": "104.592225",
          "lat": "35.606978",
          "level": "city",
          "children": [
            {
              "value": 3018,
              "parent_id": 3017,
              "label": "安定区",
              "lng": "104.610668",
              "lat": "35.580629",
              "level": "district"
            },
            {
              "value": 3019,
              "parent_id": 3017,
              "label": "通渭县",
              "lng": "105.24206",
              "lat": "35.210831",
              "level": "district"
            },
            {
              "value": 3020,
              "parent_id": 3017,
              "label": "陇西县",
              "lng": "104.634983",
              "lat": "35.00394",
              "level": "district"
            },
            {
              "value": 3021,
              "parent_id": 3017,
              "label": "渭源县",
              "lng": "104.215467",
              "lat": "35.136755",
              "level": "district"
            },
            {
              "value": 3022,
              "parent_id": 3017,
              "label": "临洮县",
              "lng": "103.859565",
              "lat": "35.394988",
              "level": "district"
            },
            {
              "value": 3023,
              "parent_id": 3017,
              "label": "漳县",
              "lng": "104.471572",
              "lat": "34.848444",
              "level": "district"
            },
            {
              "value": 3024,
              "parent_id": 3017,
              "label": "岷县",
              "lng": "104.03688",
              "lat": "34.438075",
              "level": "district"
            }
          ]
        },
        {
          "value": 3025,
          "parent_id": 2951,
          "label": "陇南市",
          "lng": "104.960851",
          "lat": "33.37068",
          "level": "city",
          "children": [
            {
              "value": 3026,
              "parent_id": 3025,
              "label": "武都区",
              "lng": "104.926337",
              "lat": "33.392211",
              "level": "district"
            },
            {
              "value": 3027,
              "parent_id": 3025,
              "label": "成县",
              "lng": "105.742424",
              "lat": "33.75061",
              "level": "district"
            },
            {
              "value": 3028,
              "parent_id": 3025,
              "label": "文县",
              "lng": "104.683433",
              "lat": "32.943815",
              "level": "district"
            },
            {
              "value": 3029,
              "parent_id": 3025,
              "label": "宕昌县",
              "lng": "104.393385",
              "lat": "34.047261",
              "level": "district"
            },
            {
              "value": 3030,
              "parent_id": 3025,
              "label": "康县",
              "lng": "105.609169",
              "lat": "33.329136",
              "level": "district"
            },
            {
              "value": 3031,
              "parent_id": 3025,
              "label": "西和县",
              "lng": "105.298756",
              "lat": "34.014215",
              "level": "district"
            },
            {
              "value": 3032,
              "parent_id": 3025,
              "label": "礼县",
              "lng": "105.17864",
              "lat": "34.189345",
              "level": "district"
            },
            {
              "value": 3033,
              "parent_id": 3025,
              "label": "徽县",
              "lng": "106.08778",
              "lat": "33.768826",
              "level": "district"
            },
            {
              "value": 3034,
              "parent_id": 3025,
              "label": "两当县",
              "lng": "106.304966",
              "lat": "33.908917",
              "level": "district"
            }
          ]
        },
        {
          "value": 3035,
          "parent_id": 2951,
          "label": "临夏回族自治州",
          "lng": "103.210655",
          "lat": "35.601352",
          "level": "city",
          "children": [
            {
              "value": 3036,
              "parent_id": 3035,
              "label": "临夏市",
              "lng": "103.243021",
              "lat": "35.604376",
              "level": "district"
            },
            {
              "value": 3037,
              "parent_id": 3035,
              "label": "临夏县",
              "lng": "103.039826",
              "lat": "35.478722",
              "level": "district"
            },
            {
              "value": 3038,
              "parent_id": 3035,
              "label": "康乐县",
              "lng": "103.708354",
              "lat": "35.370505",
              "level": "district"
            },
            {
              "value": 3039,
              "parent_id": 3035,
              "label": "永靖县",
              "lng": "103.285853",
              "lat": "35.958306",
              "level": "district"
            },
            {
              "value": 3040,
              "parent_id": 3035,
              "label": "广河县",
              "lng": "103.575834",
              "lat": "35.488051",
              "level": "district"
            },
            {
              "value": 3041,
              "parent_id": 3035,
              "label": "和政县",
              "lng": "103.350997",
              "lat": "35.424603",
              "level": "district"
            },
            {
              "value": 3042,
              "parent_id": 3035,
              "label": "东乡族自治县",
              "lng": "103.389346",
              "lat": "35.663752",
              "level": "district"
            },
            {
              "value": 3043,
              "parent_id": 3035,
              "label": "积石山保安族东乡族撒拉族自治县",
              "lng": "102.875843",
              "lat": "35.71766",
              "level": "district"
            }
          ]
        },
        {
          "value": 3044,
          "parent_id": 2951,
          "label": "甘南藏族自治州",
          "lng": "102.910995",
          "lat": "34.983409",
          "level": "city",
          "children": [
            {
              "value": 3045,
              "parent_id": 3044,
              "label": "合作市",
              "lng": "102.910484",
              "lat": "35.000286",
              "level": "district"
            },
            {
              "value": 3046,
              "parent_id": 3044,
              "label": "临潭县",
              "lng": "103.353919",
              "lat": "34.692747",
              "level": "district"
            },
            {
              "value": 3047,
              "parent_id": 3044,
              "label": "卓尼县",
              "lng": "103.507109",
              "lat": "34.589588",
              "level": "district"
            },
            {
              "value": 3048,
              "parent_id": 3044,
              "label": "舟曲县",
              "lng": "104.251482",
              "lat": "33.793631",
              "level": "district"
            },
            {
              "value": 3049,
              "parent_id": 3044,
              "label": "迭部县",
              "lng": "103.221869",
              "lat": "34.055938",
              "level": "district"
            },
            {
              "value": 3050,
              "parent_id": 3044,
              "label": "玛曲县",
              "lng": "102.072698",
              "lat": "33.997712",
              "level": "district"
            },
            {
              "value": 3051,
              "parent_id": 3044,
              "label": "碌曲县",
              "lng": "102.487327",
              "lat": "34.590944",
              "level": "district"
            },
            {
              "value": 3052,
              "parent_id": 3044,
              "label": "夏河县",
              "lng": "102.521807",
              "lat": "35.202503",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 3053,
      "parent_id": 1,
      "label": "青海省",
      "lng": "101.780268",
      "lat": "36.620939",
      "level": "province",
      "children": [
        {
          "value": 3054,
          "parent_id": 3053,
          "label": "西宁市",
          "lng": "101.778223",
          "lat": "36.617134",
          "level": "city",
          "children": [
            {
              "value": 3055,
              "parent_id": 3054,
              "label": "城东区",
              "lng": "101.803717",
              "lat": "36.599744",
              "level": "district"
            },
            {
              "value": 3056,
              "parent_id": 3054,
              "label": "城中区",
              "lng": "101.705298",
              "lat": "36.545652",
              "level": "district"
            },
            {
              "value": 3057,
              "parent_id": 3054,
              "label": "城西区",
              "lng": "101.765843",
              "lat": "36.628304",
              "level": "district"
            },
            {
              "value": 3058,
              "parent_id": 3054,
              "label": "城北区",
              "lng": "101.766228",
              "lat": "36.650038",
              "level": "district"
            },
            {
              "value": 3059,
              "parent_id": 3054,
              "label": "大通回族土族自治县",
              "lng": "101.685643",
              "lat": "36.926954",
              "level": "district"
            },
            {
              "value": 3060,
              "parent_id": 3054,
              "label": "湟中县",
              "lng": "101.571667",
              "lat": "36.500879",
              "level": "district"
            },
            {
              "value": 3061,
              "parent_id": 3054,
              "label": "湟源县",
              "lng": "101.256464",
              "lat": "36.682426",
              "level": "district"
            }
          ]
        },
        {
          "value": 3062,
          "parent_id": 3053,
          "label": "海东市",
          "lng": "102.104287",
          "lat": "36.502039",
          "level": "city",
          "children": [
            {
              "value": 3063,
              "parent_id": 3062,
              "label": "乐都区",
              "lng": "102.401724",
              "lat": "36.482058",
              "level": "district"
            },
            {
              "value": 3064,
              "parent_id": 3062,
              "label": "平安区",
              "lng": "102.108834",
              "lat": "36.500563",
              "level": "district"
            },
            {
              "value": 3065,
              "parent_id": 3062,
              "label": "民和回族土族自治县",
              "lng": "102.830892",
              "lat": "36.320321",
              "level": "district"
            },
            {
              "value": 3066,
              "parent_id": 3062,
              "label": "互助土族自治县",
              "lng": "101.959271",
              "lat": "36.844248",
              "level": "district"
            },
            {
              "value": 3067,
              "parent_id": 3062,
              "label": "化隆回族自治县",
              "lng": "102.264143",
              "lat": "36.094908",
              "level": "district"
            },
            {
              "value": 3068,
              "parent_id": 3062,
              "label": "循化撒拉族自治县",
              "lng": "102.489135",
              "lat": "35.851152",
              "level": "district"
            }
          ]
        },
        {
          "value": 3069,
          "parent_id": 3053,
          "label": "海北藏族自治州",
          "lng": "100.900997",
          "lat": "36.954413",
          "level": "city",
          "children": [
            {
              "value": 3070,
              "parent_id": 3069,
              "label": "门源回族自治县",
              "lng": "101.611539",
              "lat": "37.388746",
              "level": "district"
            },
            {
              "value": 3071,
              "parent_id": 3069,
              "label": "祁连县",
              "lng": "100.253211",
              "lat": "38.177112",
              "level": "district"
            },
            {
              "value": 3072,
              "parent_id": 3069,
              "label": "海晏县",
              "lng": "100.99426",
              "lat": "36.896359",
              "level": "district"
            },
            {
              "value": 3073,
              "parent_id": 3069,
              "label": "刚察县",
              "lng": "100.145833",
              "lat": "37.32547",
              "level": "district"
            }
          ]
        },
        {
          "value": 3074,
          "parent_id": 3053,
          "label": "黄南藏族自治州",
          "lng": "102.015248",
          "lat": "35.519548",
          "level": "city",
          "children": [
            {
              "value": 3075,
              "parent_id": 3074,
              "label": "同仁县",
              "lng": "102.018323",
              "lat": "35.516063",
              "level": "district"
            },
            {
              "value": 3076,
              "parent_id": 3074,
              "label": "尖扎县",
              "lng": "102.04014",
              "lat": "35.943156",
              "level": "district"
            },
            {
              "value": 3077,
              "parent_id": 3074,
              "label": "泽库县",
              "lng": "101.466689",
              "lat": "35.035313",
              "level": "district"
            },
            {
              "value": 3078,
              "parent_id": 3074,
              "label": "河南蒙古族自治县",
              "lng": "101.617503",
              "lat": "34.734568",
              "level": "district"
            }
          ]
        },
        {
          "value": 3079,
          "parent_id": 3053,
          "label": "海南藏族自治州",
          "lng": "100.622692",
          "lat": "36.296529",
          "level": "city",
          "children": [
            {
              "value": 3080,
              "parent_id": 3079,
              "label": "共和县",
              "lng": "100.620031",
              "lat": "36.284107",
              "level": "district"
            },
            {
              "value": 3081,
              "parent_id": 3079,
              "label": "同德县",
              "lng": "100.578051",
              "lat": "35.25479",
              "level": "district"
            },
            {
              "value": 3082,
              "parent_id": 3079,
              "label": "贵德县",
              "lng": "101.433391",
              "lat": "36.040166",
              "level": "district"
            },
            {
              "value": 3083,
              "parent_id": 3079,
              "label": "兴海县",
              "lng": "99.987965",
              "lat": "35.588612",
              "level": "district"
            },
            {
              "value": 3084,
              "parent_id": 3079,
              "label": "贵南县",
              "lng": "100.747503",
              "lat": "35.586714",
              "level": "district"
            }
          ]
        },
        {
          "value": 3085,
          "parent_id": 3053,
          "label": "果洛藏族自治州",
          "lng": "100.244808",
          "lat": "34.471431",
          "level": "city",
          "children": [
            {
              "value": 3086,
              "parent_id": 3085,
              "label": "玛沁县",
              "lng": "100.238888",
              "lat": "34.477433",
              "level": "district"
            },
            {
              "value": 3087,
              "parent_id": 3085,
              "label": "班玛县",
              "lng": "100.737138",
              "lat": "32.932723",
              "level": "district"
            },
            {
              "value": 3088,
              "parent_id": 3085,
              "label": "甘德县",
              "lng": "99.900923",
              "lat": "33.969216",
              "level": "district"
            },
            {
              "value": 3089,
              "parent_id": 3085,
              "label": "达日县",
              "lng": "99.651392",
              "lat": "33.74892",
              "level": "district"
            },
            {
              "value": 3090,
              "parent_id": 3085,
              "label": "久治县",
              "lng": "101.482831",
              "lat": "33.429471",
              "level": "district"
            },
            {
              "value": 3091,
              "parent_id": 3085,
              "label": "玛多县",
              "lng": "98.209206",
              "lat": "34.915946",
              "level": "district"
            }
          ]
        },
        {
          "value": 3092,
          "parent_id": 3053,
          "label": "玉树藏族自治州",
          "lng": "97.091934",
          "lat": "33.011674",
          "level": "city",
          "children": [
            {
              "value": 3093,
              "parent_id": 3092,
              "label": "玉树市",
              "lng": "97.008784",
              "lat": "32.993106",
              "level": "district"
            },
            {
              "value": 3094,
              "parent_id": 3092,
              "label": "杂多县",
              "lng": "95.300723",
              "lat": "32.893185",
              "level": "district"
            },
            {
              "value": 3095,
              "parent_id": 3092,
              "label": "称多县",
              "lng": "97.110831",
              "lat": "33.369218",
              "level": "district"
            },
            {
              "value": 3096,
              "parent_id": 3092,
              "label": "治多县",
              "lng": "95.61896",
              "lat": "33.844956",
              "level": "district"
            },
            {
              "value": 3097,
              "parent_id": 3092,
              "label": "囊谦县",
              "lng": "96.48943",
              "lat": "32.203432",
              "level": "district"
            },
            {
              "value": 3098,
              "parent_id": 3092,
              "label": "曲麻莱县",
              "lng": "95.797367",
              "lat": "34.126428",
              "level": "district"
            }
          ]
        },
        {
          "value": 3099,
          "parent_id": 3053,
          "label": "海西蒙古族藏族自治州",
          "lng": "97.369751",
          "lat": "37.377139",
          "level": "city",
          "children": [
            {
              "value": 3100,
              "parent_id": 3099,
              "label": "格尔木市",
              "lng": "94.928453",
              "lat": "36.406367",
              "level": "district"
            },
            {
              "value": 3101,
              "parent_id": 3099,
              "label": "德令哈市",
              "lng": "97.360984",
              "lat": "37.369436",
              "level": "district"
            },
            {
              "value": 3102,
              "parent_id": 3099,
              "label": "乌兰县",
              "lng": "98.480195",
              "lat": "36.929749",
              "level": "district"
            },
            {
              "value": 3103,
              "parent_id": 3099,
              "label": "都兰县",
              "lng": "98.095844",
              "lat": "36.302496",
              "level": "district"
            },
            {
              "value": 3104,
              "parent_id": 3099,
              "label": "天峻县",
              "lng": "99.022984",
              "lat": "37.300851",
              "level": "district"
            },
            {
              "value": 3105,
              "parent_id": 3099,
              "label": "海西蒙古族藏族自治州直辖",
              "lng": "95.356546",
              "lat": "37.853328",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 3106,
      "parent_id": 1,
      "label": "宁夏回族自治区",
      "lng": "106.259126",
      "lat": "38.472641",
      "level": "province",
      "children": [
        {
          "value": 3107,
          "parent_id": 3106,
          "label": "银川市",
          "lng": "106.230909",
          "lat": "38.487193",
          "level": "city",
          "children": [
            {
              "value": 3108,
              "parent_id": 3107,
              "label": "兴庆区",
              "lng": "106.28865",
              "lat": "38.473609",
              "level": "district"
            },
            {
              "value": 3109,
              "parent_id": 3107,
              "label": "西夏区",
              "lng": "106.161106",
              "lat": "38.502605",
              "level": "district"
            },
            {
              "value": 3110,
              "parent_id": 3107,
              "label": "金凤区",
              "lng": "106.239679",
              "lat": "38.47436",
              "level": "district"
            },
            {
              "value": 3111,
              "parent_id": 3107,
              "label": "永宁县",
              "lng": "106.253145",
              "lat": "38.277372",
              "level": "district"
            },
            {
              "value": 3112,
              "parent_id": 3107,
              "label": "贺兰县",
              "lng": "106.349861",
              "lat": "38.554599",
              "level": "district"
            },
            {
              "value": 3113,
              "parent_id": 3107,
              "label": "灵武市",
              "lng": "106.340053",
              "lat": "38.102655",
              "level": "district"
            }
          ]
        },
        {
          "value": 3114,
          "parent_id": 3106,
          "label": "石嘴山市",
          "lng": "106.383303",
          "lat": "38.983236",
          "level": "city",
          "children": [
            {
              "value": 3115,
              "parent_id": 3114,
              "label": "大武口区",
              "lng": "106.367958",
              "lat": "39.01918",
              "level": "district"
            },
            {
              "value": 3116,
              "parent_id": 3114,
              "label": "惠农区",
              "lng": "106.781176",
              "lat": "39.239302",
              "level": "district"
            },
            {
              "value": 3117,
              "parent_id": 3114,
              "label": "平罗县",
              "lng": "106.523474",
              "lat": "38.913544",
              "level": "district"
            }
          ]
        },
        {
          "value": 3118,
          "parent_id": 3106,
          "label": "吴忠市",
          "lng": "106.198913",
          "lat": "37.997428",
          "level": "city",
          "children": [
            {
              "value": 3119,
              "parent_id": 3118,
              "label": "利通区",
              "lng": "106.212613",
              "lat": "37.98349",
              "level": "district"
            },
            {
              "value": 3120,
              "parent_id": 3118,
              "label": "红寺堡区",
              "lng": "106.062113",
              "lat": "37.425702",
              "level": "district"
            },
            {
              "value": 3121,
              "parent_id": 3118,
              "label": "盐池县",
              "lng": "107.407358",
              "lat": "37.783205",
              "level": "district"
            },
            {
              "value": 3122,
              "parent_id": 3118,
              "label": "同心县",
              "lng": "105.895309",
              "lat": "36.95449",
              "level": "district"
            },
            {
              "value": 3123,
              "parent_id": 3118,
              "label": "青铜峡市",
              "lng": "106.078817",
              "lat": "38.021302",
              "level": "district"
            }
          ]
        },
        {
          "value": 3124,
          "parent_id": 3106,
          "label": "固原市",
          "lng": "106.24261",
          "lat": "36.015855",
          "level": "city",
          "children": [
            {
              "value": 3125,
              "parent_id": 3124,
              "label": "原州区",
              "lng": "106.287781",
              "lat": "36.003739",
              "level": "district"
            },
            {
              "value": 3126,
              "parent_id": 3124,
              "label": "西吉县",
              "lng": "105.729085",
              "lat": "35.963912",
              "level": "district"
            },
            {
              "value": 3127,
              "parent_id": 3124,
              "label": "隆德县",
              "lng": "106.111595",
              "lat": "35.625914",
              "level": "district"
            },
            {
              "value": 3128,
              "parent_id": 3124,
              "label": "泾源县",
              "lng": "106.330646",
              "lat": "35.498159",
              "level": "district"
            },
            {
              "value": 3129,
              "parent_id": 3124,
              "label": "彭阳县",
              "lng": "106.631809",
              "lat": "35.858815",
              "level": "district"
            }
          ]
        },
        {
          "value": 3130,
          "parent_id": 3106,
          "label": "中卫市",
          "lng": "105.196902",
          "lat": "37.499972",
          "level": "city",
          "children": [
            {
              "value": 3131,
              "parent_id": 3130,
              "label": "沙坡头区",
              "lng": "105.173721",
              "lat": "37.516883",
              "level": "district"
            },
            {
              "value": 3132,
              "parent_id": 3130,
              "label": "中宁县",
              "lng": "105.685218",
              "lat": "37.491546",
              "level": "district"
            },
            {
              "value": 3133,
              "parent_id": 3130,
              "label": "海原县",
              "lng": "105.643487",
              "lat": "36.565033",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 3134,
      "parent_id": 1,
      "label": "新疆维吾尔自治区",
      "lng": "87.627704",
      "lat": "43.793026",
      "level": "province",
      "children": [
        {
          "value": 3135,
          "parent_id": 3134,
          "label": "阿拉尔市",
          "lng": "81.280527",
          "lat": "40.547653",
          "level": "city",
          "children": [
            {
              "value": 3136,
              "parent_id": 3135,
              "label": "阿拉尔市",
              "lng": "81.280527",
              "lat": "40.547653",
              "level": "district"
            }
          ]
        },
        {
          "value": 3137,
          "parent_id": 3134,
          "label": "北屯市",
          "lng": "87.837075",
          "lat": "47.332643",
          "level": "city",
          "children": [
            {
              "value": 3138,
              "parent_id": 3137,
              "label": "北屯市",
              "lng": "87.837075",
              "lat": "47.332643",
              "level": "district"
            }
          ]
        },
        {
          "value": 3139,
          "parent_id": 3134,
          "label": "可克达拉市",
          "lng": "81.044542",
          "lat": "43.944798",
          "level": "city",
          "children": [
            {
              "value": 3140,
              "parent_id": 3139,
              "label": "可克达拉市",
              "lng": "81.044542",
              "lat": "43.944798",
              "level": "district"
            }
          ]
        },
        {
          "value": 3141,
          "parent_id": 3134,
          "label": "昆玉市",
          "lng": "79.291083",
          "lat": "37.209642",
          "level": "city",
          "children": [
            {
              "value": 3142,
              "parent_id": 3141,
              "label": "昆玉市",
              "lng": "79.291083",
              "lat": "37.209642",
              "level": "district"
            }
          ]
        },
        {
          "value": 3143,
          "parent_id": 3134,
          "label": "石河子市",
          "lng": "86.080602",
          "lat": "44.306097",
          "level": "city",
          "children": [
            {
              "value": 3144,
              "parent_id": 3143,
              "label": "石河子市",
              "lng": "86.080602",
              "lat": "44.306097",
              "level": "district"
            }
          ]
        },
        {
          "value": 3145,
          "parent_id": 3134,
          "label": "双河市",
          "lng": "82.353656",
          "lat": "44.840524",
          "level": "city",
          "children": [
            {
              "value": 3146,
              "parent_id": 3145,
              "label": "双河市",
              "lng": "82.353656",
              "lat": "44.840524",
              "level": "district"
            }
          ]
        },
        {
          "value": 3147,
          "parent_id": 3134,
          "label": "乌鲁木齐市",
          "lng": "87.616848",
          "lat": "43.825592",
          "level": "city",
          "children": [
            {
              "value": 3148,
              "parent_id": 3147,
              "label": "天山区",
              "lng": "87.631676",
              "lat": "43.794399",
              "level": "district"
            },
            {
              "value": 3149,
              "parent_id": 3147,
              "label": "沙依巴克区",
              "lng": "87.598195",
              "lat": "43.800939",
              "level": "district"
            },
            {
              "value": 3150,
              "parent_id": 3147,
              "label": "新市区",
              "lng": "87.569431",
              "lat": "43.855378",
              "level": "district"
            },
            {
              "value": 3151,
              "parent_id": 3147,
              "label": "水磨沟区",
              "lng": "87.642481",
              "lat": "43.832459",
              "level": "district"
            },
            {
              "value": 3152,
              "parent_id": 3147,
              "label": "头屯河区",
              "lng": "87.428141",
              "lat": "43.877664",
              "level": "district"
            },
            {
              "value": 3153,
              "parent_id": 3147,
              "label": "达坂城区",
              "lng": "88.311099",
              "lat": "43.363668",
              "level": "district"
            },
            {
              "value": 3154,
              "parent_id": 3147,
              "label": "米东区",
              "lng": "87.655935",
              "lat": "43.974784",
              "level": "district"
            },
            {
              "value": 3155,
              "parent_id": 3147,
              "label": "乌鲁木齐县",
              "lng": "87.409417",
              "lat": "43.47136",
              "level": "district"
            }
          ]
        },
        {
          "value": 3156,
          "parent_id": 3134,
          "label": "克拉玛依市",
          "lng": "84.889207",
          "lat": "45.579888",
          "level": "city",
          "children": [
            {
              "value": 3157,
              "parent_id": 3156,
              "label": "独山子区",
              "lng": "84.886974",
              "lat": "44.328095",
              "level": "district"
            },
            {
              "value": 3158,
              "parent_id": 3156,
              "label": "克拉玛依区",
              "lng": "84.867844",
              "lat": "45.602525",
              "level": "district"
            },
            {
              "value": 3159,
              "parent_id": 3156,
              "label": "白碱滩区",
              "lng": "85.131696",
              "lat": "45.687854",
              "level": "district"
            },
            {
              "value": 3160,
              "parent_id": 3156,
              "label": "乌尔禾区",
              "lng": "85.693742",
              "lat": "46.089148",
              "level": "district"
            }
          ]
        },
        {
          "value": 3161,
          "parent_id": 3134,
          "label": "吐鲁番市",
          "lng": "89.189752",
          "lat": "42.951303",
          "level": "city",
          "children": [
            {
              "value": 3162,
              "parent_id": 3161,
              "label": "高昌区",
              "lng": "89.185877",
              "lat": "42.942327",
              "level": "district"
            },
            {
              "value": 3163,
              "parent_id": 3161,
              "label": "鄯善县",
              "lng": "90.21333",
              "lat": "42.868744",
              "level": "district"
            },
            {
              "value": 3164,
              "parent_id": 3161,
              "label": "托克逊县",
              "lng": "88.653827",
              "lat": "42.792526",
              "level": "district"
            }
          ]
        },
        {
          "value": 3165,
          "parent_id": 3134,
          "label": "哈密市",
          "lng": "93.515224",
          "lat": "42.819541",
          "level": "city",
          "children": [
            {
              "value": 3166,
              "parent_id": 3165,
              "label": "伊州区",
              "lng": "93.514797",
              "lat": "42.827254",
              "level": "district"
            },
            {
              "value": 3167,
              "parent_id": 3165,
              "label": "巴里坤哈萨克自治县",
              "lng": "93.010383",
              "lat": "43.599929",
              "level": "district"
            },
            {
              "value": 3168,
              "parent_id": 3165,
              "label": "伊吾县",
              "lng": "94.697074",
              "lat": "43.254978",
              "level": "district"
            }
          ]
        },
        {
          "value": 3169,
          "parent_id": 3134,
          "label": "昌吉回族自治州",
          "lng": "87.308224",
          "lat": "44.011182",
          "level": "city",
          "children": [
            {
              "value": 3170,
              "parent_id": 3169,
              "label": "昌吉市",
              "lng": "87.267532",
              "lat": "44.014435",
              "level": "district"
            },
            {
              "value": 3171,
              "parent_id": 3169,
              "label": "阜康市",
              "lng": "87.952991",
              "lat": "44.164402",
              "level": "district"
            },
            {
              "value": 3172,
              "parent_id": 3169,
              "label": "呼图壁县",
              "lng": "86.871584",
              "lat": "44.179361",
              "level": "district"
            },
            {
              "value": 3173,
              "parent_id": 3169,
              "label": "玛纳斯县",
              "lng": "86.20368",
              "lat": "44.284722",
              "level": "district"
            },
            {
              "value": 3174,
              "parent_id": 3169,
              "label": "奇台县",
              "lng": "89.593967",
              "lat": "44.022066",
              "level": "district"
            },
            {
              "value": 3175,
              "parent_id": 3169,
              "label": "吉木萨尔县",
              "lng": "89.180437",
              "lat": "44.000497",
              "level": "district"
            },
            {
              "value": 3176,
              "parent_id": 3169,
              "label": "木垒哈萨克自治县",
              "lng": "90.286028",
              "lat": "43.834689",
              "level": "district"
            }
          ]
        },
        {
          "value": 3177,
          "parent_id": 3134,
          "label": "博尔塔拉蒙古自治州",
          "lng": "82.066363",
          "lat": "44.906039",
          "level": "city",
          "children": [
            {
              "value": 3178,
              "parent_id": 3177,
              "label": "博乐市",
              "lng": "82.051004",
              "lat": "44.853869",
              "level": "district"
            },
            {
              "value": 3179,
              "parent_id": 3177,
              "label": "阿拉山口市",
              "lng": "82.559396",
              "lat": "45.172227",
              "level": "district"
            },
            {
              "value": 3180,
              "parent_id": 3177,
              "label": "精河县",
              "lng": "82.890656",
              "lat": "44.599393",
              "level": "district"
            },
            {
              "value": 3181,
              "parent_id": 3177,
              "label": "温泉县",
              "lng": "81.024816",
              "lat": "44.968856",
              "level": "district"
            }
          ]
        },
        {
          "value": 3182,
          "parent_id": 3134,
          "label": "巴音郭楞蒙古自治州",
          "lng": "86.145297",
          "lat": "41.764115",
          "level": "city",
          "children": [
            {
              "value": 3183,
              "parent_id": 3182,
              "label": "库尔勒市",
              "lng": "86.174633",
              "lat": "41.725891",
              "level": "district"
            },
            {
              "value": 3184,
              "parent_id": 3182,
              "label": "轮台县",
              "lng": "84.252156",
              "lat": "41.777702",
              "level": "district"
            },
            {
              "value": 3185,
              "parent_id": 3182,
              "label": "尉犁县",
              "lng": "86.261321",
              "lat": "41.343933",
              "level": "district"
            },
            {
              "value": 3186,
              "parent_id": 3182,
              "label": "若羌县",
              "lng": "88.167152",
              "lat": "39.023241",
              "level": "district"
            },
            {
              "value": 3187,
              "parent_id": 3182,
              "label": "且末县",
              "lng": "85.529702",
              "lat": "38.145485",
              "level": "district"
            },
            {
              "value": 3188,
              "parent_id": 3182,
              "label": "焉耆回族自治县",
              "lng": "86.574067",
              "lat": "42.059759",
              "level": "district"
            },
            {
              "value": 3189,
              "parent_id": 3182,
              "label": "和静县",
              "lng": "86.384065",
              "lat": "42.323625",
              "level": "district"
            },
            {
              "value": 3190,
              "parent_id": 3182,
              "label": "和硕县",
              "lng": "86.876799",
              "lat": "42.284331",
              "level": "district"
            },
            {
              "value": 3191,
              "parent_id": 3182,
              "label": "博湖县",
              "lng": "86.631997",
              "lat": "41.980152",
              "level": "district"
            }
          ]
        },
        {
          "value": 3192,
          "parent_id": 3134,
          "label": "阿克苏地区",
          "lng": "80.260605",
          "lat": "41.168779",
          "level": "city",
          "children": [
            {
              "value": 3193,
              "parent_id": 3192,
              "label": "阿克苏市",
              "lng": "80.263387",
              "lat": "41.167548",
              "level": "district"
            },
            {
              "value": 3194,
              "parent_id": 3192,
              "label": "温宿县",
              "lng": "80.238959",
              "lat": "41.276688",
              "level": "district"
            },
            {
              "value": 3195,
              "parent_id": 3192,
              "label": "库车县",
              "lng": "82.987312",
              "lat": "41.714696",
              "level": "district"
            },
            {
              "value": 3196,
              "parent_id": 3192,
              "label": "沙雅县",
              "lng": "82.781818",
              "lat": "41.221666",
              "level": "district"
            },
            {
              "value": 3197,
              "parent_id": 3192,
              "label": "新和县",
              "lng": "82.618736",
              "lat": "41.551206",
              "level": "district"
            },
            {
              "value": 3198,
              "parent_id": 3192,
              "label": "拜城县",
              "lng": "81.85148",
              "lat": "41.795912",
              "level": "district"
            },
            {
              "value": 3199,
              "parent_id": 3192,
              "label": "乌什县",
              "lng": "79.224616",
              "lat": "41.222319",
              "level": "district"
            },
            {
              "value": 3200,
              "parent_id": 3192,
              "label": "阿瓦提县",
              "lng": "80.375053",
              "lat": "40.643647",
              "level": "district"
            },
            {
              "value": 3201,
              "parent_id": 3192,
              "label": "柯坪县",
              "lng": "79.054497",
              "lat": "40.501936",
              "level": "district"
            }
          ]
        },
        {
          "value": 3202,
          "parent_id": 3134,
          "label": "克孜勒苏柯尔克孜自治州",
          "lng": "76.167819",
          "lat": "39.714526",
          "level": "city",
          "children": [
            {
              "value": 3203,
              "parent_id": 3202,
              "label": "阿图什市",
              "lng": "76.1684",
              "lat": "39.71616",
              "level": "district"
            },
            {
              "value": 3204,
              "parent_id": 3202,
              "label": "阿克陶县",
              "lng": "75.947396",
              "lat": "39.147785",
              "level": "district"
            },
            {
              "value": 3205,
              "parent_id": 3202,
              "label": "阿合奇县",
              "lng": "78.446253",
              "lat": "40.936936",
              "level": "district"
            },
            {
              "value": 3206,
              "parent_id": 3202,
              "label": "乌恰县",
              "lng": "75.259227",
              "lat": "39.71931",
              "level": "district"
            }
          ]
        },
        {
          "value": 3207,
          "parent_id": 3134,
          "label": "喀什地区",
          "lng": "75.989741",
          "lat": "39.47046",
          "level": "city",
          "children": [
            {
              "value": 3208,
              "parent_id": 3207,
              "label": "喀什市",
              "lng": "75.99379",
              "lat": "39.467685",
              "level": "district"
            },
            {
              "value": 3209,
              "parent_id": 3207,
              "label": "疏附县",
              "lng": "75.862813",
              "lat": "39.375043",
              "level": "district"
            },
            {
              "value": 3210,
              "parent_id": 3207,
              "label": "疏勒县",
              "lng": "76.048139",
              "lat": "39.401384",
              "level": "district"
            },
            {
              "value": 3211,
              "parent_id": 3207,
              "label": "英吉沙县",
              "lng": "76.175729",
              "lat": "38.930381",
              "level": "district"
            },
            {
              "value": 3212,
              "parent_id": 3207,
              "label": "泽普县",
              "lng": "77.259675",
              "lat": "38.18529",
              "level": "district"
            },
            {
              "value": 3213,
              "parent_id": 3207,
              "label": "莎车县",
              "lng": "77.245761",
              "lat": "38.41422",
              "level": "district"
            },
            {
              "value": 3214,
              "parent_id": 3207,
              "label": "叶城县",
              "lng": "77.413836",
              "lat": "37.882989",
              "level": "district"
            },
            {
              "value": 3215,
              "parent_id": 3207,
              "label": "麦盖提县",
              "lng": "77.610125",
              "lat": "38.898001",
              "level": "district"
            },
            {
              "value": 3216,
              "parent_id": 3207,
              "label": "岳普湖县",
              "lng": "76.8212",
              "lat": "39.2198",
              "level": "district"
            },
            {
              "value": 3217,
              "parent_id": 3207,
              "label": "伽师县",
              "lng": "76.723719",
              "lat": "39.488181",
              "level": "district"
            },
            {
              "value": 3218,
              "parent_id": 3207,
              "label": "巴楚县",
              "lng": "78.549296",
              "lat": "39.785155",
              "level": "district"
            },
            {
              "value": 3219,
              "parent_id": 3207,
              "label": "塔什库尔干塔吉克自治县",
              "lng": "75.229889",
              "lat": "37.772094",
              "level": "district"
            }
          ]
        },
        {
          "value": 3220,
          "parent_id": 3134,
          "label": "和田地区",
          "lng": "79.922211",
          "lat": "37.114157",
          "level": "city",
          "children": [
            {
              "value": 3221,
              "parent_id": 3220,
              "label": "和田市",
              "lng": "79.913534",
              "lat": "37.112148",
              "level": "district"
            },
            {
              "value": 3222,
              "parent_id": 3220,
              "label": "和田县",
              "lng": "79.81907",
              "lat": "37.120031",
              "level": "district"
            },
            {
              "value": 3223,
              "parent_id": 3220,
              "label": "墨玉县",
              "lng": "79.728683",
              "lat": "37.277143",
              "level": "district"
            },
            {
              "value": 3224,
              "parent_id": 3220,
              "label": "皮山县",
              "lng": "78.283669",
              "lat": "37.62145",
              "level": "district"
            },
            {
              "value": 3225,
              "parent_id": 3220,
              "label": "洛浦县",
              "lng": "80.188986",
              "lat": "37.073667",
              "level": "district"
            },
            {
              "value": 3226,
              "parent_id": 3220,
              "label": "策勒县",
              "lng": "80.806159",
              "lat": "36.998335",
              "level": "district"
            },
            {
              "value": 3227,
              "parent_id": 3220,
              "label": "于田县",
              "lng": "81.677418",
              "lat": "36.85708",
              "level": "district"
            },
            {
              "value": 3228,
              "parent_id": 3220,
              "label": "民丰县",
              "lng": "82.695861",
              "lat": "37.06408",
              "level": "district"
            }
          ]
        },
        {
          "value": 3229,
          "parent_id": 3134,
          "label": "伊犁哈萨克自治州",
          "lng": "81.324136",
          "lat": "43.916823",
          "level": "city",
          "children": [
            {
              "value": 3230,
              "parent_id": 3229,
              "label": "伊宁市",
              "lng": "81.27795",
              "lat": "43.908558",
              "level": "district"
            },
            {
              "value": 3231,
              "parent_id": 3229,
              "label": "奎屯市",
              "lng": "84.903267",
              "lat": "44.426529",
              "level": "district"
            },
            {
              "value": 3232,
              "parent_id": 3229,
              "label": "霍尔果斯市",
              "lng": "80.411271",
              "lat": "44.213941",
              "level": "district"
            },
            {
              "value": 3233,
              "parent_id": 3229,
              "label": "伊宁县",
              "lng": "81.52745",
              "lat": "43.977119",
              "level": "district"
            },
            {
              "value": 3234,
              "parent_id": 3229,
              "label": "察布查尔锡伯自治县",
              "lng": "81.151337",
              "lat": "43.840726",
              "level": "district"
            },
            {
              "value": 3235,
              "parent_id": 3229,
              "label": "霍城县",
              "lng": "80.87898",
              "lat": "44.055984",
              "level": "district"
            },
            {
              "value": 3236,
              "parent_id": 3229,
              "label": "巩留县",
              "lng": "82.231718",
              "lat": "43.482628",
              "level": "district"
            },
            {
              "value": 3237,
              "parent_id": 3229,
              "label": "新源县",
              "lng": "83.232848",
              "lat": "43.433896",
              "level": "district"
            },
            {
              "value": 3238,
              "parent_id": 3229,
              "label": "昭苏县",
              "lng": "81.130974",
              "lat": "43.157293",
              "level": "district"
            },
            {
              "value": 3239,
              "parent_id": 3229,
              "label": "特克斯县",
              "lng": "81.836206",
              "lat": "43.217183",
              "level": "district"
            },
            {
              "value": 3240,
              "parent_id": 3229,
              "label": "尼勒克县",
              "lng": "82.511809",
              "lat": "43.800247",
              "level": "district"
            }
          ]
        },
        {
          "value": 3241,
          "parent_id": 3134,
          "label": "塔城地区",
          "lng": "82.980316",
          "lat": "46.745364",
          "level": "city",
          "children": [
            {
              "value": 3242,
              "parent_id": 3241,
              "label": "塔城市",
              "lng": "82.986978",
              "lat": "46.751428",
              "level": "district"
            },
            {
              "value": 3243,
              "parent_id": 3241,
              "label": "乌苏市",
              "lng": "84.713396",
              "lat": "44.41881",
              "level": "district"
            },
            {
              "value": 3244,
              "parent_id": 3241,
              "label": "额敏县",
              "lng": "83.628303",
              "lat": "46.524673",
              "level": "district"
            },
            {
              "value": 3245,
              "parent_id": 3241,
              "label": "沙湾县",
              "lng": "85.619416",
              "lat": "44.326388",
              "level": "district"
            },
            {
              "value": 3246,
              "parent_id": 3241,
              "label": "托里县",
              "lng": "83.60695",
              "lat": "45.947638",
              "level": "district"
            },
            {
              "value": 3247,
              "parent_id": 3241,
              "label": "裕民县",
              "lng": "82.982667",
              "lat": "46.201104",
              "level": "district"
            },
            {
              "value": 3248,
              "parent_id": 3241,
              "label": "和布克赛尔蒙古自治县",
              "lng": "85.728328",
              "lat": "46.793235",
              "level": "district"
            }
          ]
        },
        {
          "value": 3249,
          "parent_id": 3134,
          "label": "阿勒泰地区",
          "lng": "88.141253",
          "lat": "47.844924",
          "level": "city",
          "children": [
            {
              "value": 3250,
              "parent_id": 3249,
              "label": "阿勒泰市",
              "lng": "88.131842",
              "lat": "47.827308",
              "level": "district"
            },
            {
              "value": 3251,
              "parent_id": 3249,
              "label": "布尔津县",
              "lng": "86.874923",
              "lat": "47.702163",
              "level": "district"
            },
            {
              "value": 3252,
              "parent_id": 3249,
              "label": "富蕴县",
              "lng": "89.525504",
              "lat": "46.994115",
              "level": "district"
            },
            {
              "value": 3253,
              "parent_id": 3249,
              "label": "福海县",
              "lng": "87.486703",
              "lat": "47.111918",
              "level": "district"
            },
            {
              "value": 3254,
              "parent_id": 3249,
              "label": "哈巴河县",
              "lng": "86.418621",
              "lat": "48.060846",
              "level": "district"
            },
            {
              "value": 3255,
              "parent_id": 3249,
              "label": "青河县",
              "lng": "90.37555",
              "lat": "46.679113",
              "level": "district"
            },
            {
              "value": 3256,
              "parent_id": 3249,
              "label": "吉木乃县",
              "lng": "85.874096",
              "lat": "47.443101",
              "level": "district"
            }
          ]
        },
        {
          "value": 3257,
          "parent_id": 3134,
          "label": "铁门关市",
          "lng": "85.501217",
          "lat": "41.82725",
          "level": "city",
          "children": [
            {
              "value": 3258,
              "parent_id": 3257,
              "label": "铁门关市",
              "lng": "85.501217",
              "lat": "41.82725",
              "level": "district"
            }
          ]
        },
        {
          "value": 3259,
          "parent_id": 3134,
          "label": "图木舒克市",
          "lng": "79.073963",
          "lat": "39.868965",
          "level": "city",
          "children": [
            {
              "value": 3260,
              "parent_id": 3259,
              "label": "图木舒克市",
              "lng": "79.073963",
              "lat": "39.868965",
              "level": "district"
            }
          ]
        },
        {
          "value": 3261,
          "parent_id": 3134,
          "label": "五家渠市",
          "lng": "87.54324",
          "lat": "44.166756",
          "level": "city",
          "children": [
            {
              "value": 3262,
              "parent_id": 3261,
              "label": "五家渠市",
              "lng": "87.54324",
              "lat": "44.166756",
              "level": "district"
            }
          ]
        }
      ]
    },
    {
      "value": 3268,
      "parent_id": 1,
      "label": "其他",
      "lng": "0",
      "lat": "0",
      "level": "province",
      "children": [
        {
          "value": 3269,
          "parent_id": 3268,
          "label": "其他",
          "lng": "0",
          "lat": "0",
          "level": "city",
          "children": [
            {
              "value": 3270,
              "parent_id": 3269,
              "label": "其他",
              "lng": "0",
              "lat": "0",
              "level": "district"
            }
          ]
        }
      ]
    }
  ]

  export default city;