import cityjson from "./city.js"
function transitmeth() {
    
}

/**
 * 传入城市msg
 * 格式：北京/北京市/东城区
 */
function tocodecity(citymsg) {

    let cityarray = citymsg.split("/");

    var province, //省
        city, //市
        district; //区

    for (let i = 0; i < cityjson.length; i++) {
        if (cityarray[0] == cityjson[i].label) {
            province = cityjson[i].value;
            for (let j = 0; j < cityjson[i].children.length; j++) {
                if (cityarray[1] == cityjson[i].children[j].label) {
                    city = cityjson[i].children[j].value;
                    for (let f = 0; f < cityjson[i].children[j].children.length; f++) {
                        if (cityarray[2] == cityjson[i].children[j].children[f].label) {
                            district = cityjson[i].children[j].children[f].value
                        }
                    }
                }
            }
        }
    }
    return province + "/" + city + "/" + district
}
/**
 * 传入城市id
 * 格式：10/11/12
 */
function tomsgcity(cityid) {

    let cityarray = cityid.split("/");

    var province, //省
        city, //市
        district; //区

    for (let i = 0; i < cityjson.length; i++) {
        if (cityarray[0] == cityjson[i].value) {
            province = cityjson[i].label;
            for (let j = 0; j < cityjson[i].children.length; j++) {
                if (cityarray[1] == cityjson[i].children[j].value) {
                    city = cityjson[i].children[j].label;
                    for (let f = 0; f < cityjson[i].children[j].children.length; f++) {
                        if (cityarray[2] == cityjson[i].children[j].children[f].value) {
                            district = cityjson[i].children[j].children[f].label
                        }
                    }
                }
            }
        }
    }
    return province + "/" + city + "/" + district

}

export default {
    tocodecity,
    tomsgcity,
    transitmeth
};